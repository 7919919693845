/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { useDispatch } from "react-redux";
import { docuSign } from "../../redux/actions/DocuSign/DocuSign";
import Loading from "../../components/Loader";
import DocuSignLogin from "./DocuSignLogin";
import ActionRequired from "./ActionRequired";
import penWriting from "../../Assets/Images/pen-writing.svg";
import moment from "moment";
import { Table, Dropdown, Menu, notification, Checkbox } from "antd";
const ShareFile = () => {
  const dispatch = useDispatch();
  const [key, setKey] = useState("0");
  const [loader, setLoader] = useState(false);
  const [loginView, setloginView] = useState(false);
  const [actionRequired, setActionRequired] = useState(false);
  const [isReverseProps, setIsReverseProps] = useState(false);
  const [allSharedFolder, setAllSharedFolder] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [newSearchVale, setNewSearchValue] = useState();
  const [actionTabCount, setActionTabCount] = useState(0);
  const [completeTabCount, setCompleteTabCount] = useState(0);
  const [clientTabCount, setClientTabCount] = useState(0);
  const [viewFile, setViewFile] = useState(false);
  const [selectedEnv, setSelectedEnv] = useState();
  const [userDetails, setUserDetails] = useState(
    JSON.parse(localStorage.getItem("userDetails"))
  );
  const [mySignature, setMySignature] = useState(false);
  const [showAction, setActionShow] = useState(false);
  const [docuSignInfo, setDocuSignInfo] = useState(
    JSON.parse(localStorage.getItem("docuSign"))
  );
  const handleActionClose = () => {
    setActionShow(false);
  };
  const [searchList, setSearchList] = useState();
  const handleActionShow = () => setActionShow(true);
  const Notify = (type, mesage, icons) => {
    notification[type]({
      description: mesage,
      icon: icons,
    });
  };
  useEffect(() => {
    docuSignCheck();
  }, []);

  function docuSignCheck() {
    setLoader(true);
    //  e.preventDefault();
    dispatch(docuSign.docuSignCheck()).then((response) => {
      setLoader(false);
      if (response.type === "CHECK_DOCUSIGN_SUCCESS") {
        localStorage.setItem("docuSign", JSON.stringify(response.data));
        setloginView(false);
        setActionRequired(true);
        getCountList();
        setKey("1");
      } else {
        setloginView(true);
        setActionRequired(false);
      }
    });
  }
  function pull_data(data) {
    setMySignature(false);
    setViewFile(false);

    if (data.actionTab) {
      setActionTabCount(data.actionTab);
    } else if (data.complete) {
      setCompleteTabCount(data.complete);
    } else if (data.clientAction) {
      setClientTabCount(data.clientAction);
    }
  }

  function getSeachList() {
    setLoader(true);
    let userId = docuSignInfo?.user_info?.sub;
    dispatch(docuSign.getSearchList(userId, searchValue)).then((response) => {
      setLoader(false);
      if (response.type === "GET_SEARCH_SUCCESS") {
        setSearchList(response.data);
        setNewSearchValue(searchValue);
      } else {
        //setloginView(true);
      }
    });
  }

  function resendEnv(env) {
    const envelop_id = env.envelop_id ? env.envelop_id : env?.envelopeId
    setLoader(true);
    dispatch(docuSign.resendDocumnet(envelop_id)).then((response) => {
      setLoader(false);
      if (response.type === "RESEND_ENV_SUCCESS") {
        Notify(
          "success",
          `Successfully resent 
            ${env.email_subject? env.email_subject:env.emailSubject}
             to 
            ${env.sender.user_name?env.sender.user_name:env.sender.userName}`,
          <em className="icon-folder-download-filled"></em>
        );
      }else {
        Notify("error", response.error.message);
      }
    });
  }

  function getCountList() {
    setLoader(true);
    dispatch(docuSign.getCount()).then((response) => {
      setLoader(false);
      if (response.type === "GET_COUNT_LIST_SUCCESS") {
        console.log(response);
        setCompleteTabCount(response?.data?.completed);
        setActionTabCount(response?.data?.awaiting_my_signature);
        setClientTabCount(response?.data?.waiting_for_others);
      }
    });
  }

  function logout(){
    setLoader(true);
    dispatch(docuSign.docuSignLogout()).then((response) => {
      setLoader(false);
      if (response.type === "LOGOUT_DOCUSIGN_SUCCESS") {
        localStorage.removeItem("docuSign");
        docuSignCheck();
        setKey("0");
      }
    });
  }

  const getHighlightedText = (text, highlight) => {
    // Split on highlight term and include term into parts, ignore case
    const parts = text?.split(new RegExp(`(${highlight})`, "gi"));
    return (
      <span>
        {" "}
        {parts?.map((part, i) => (
          <span
            key={i}
            className={
              part.toLowerCase() === highlight.toLowerCase() ? "mark" : ""
            }
          >
            {part}
          </span>
        ))}{" "}
      </span>
    );
  };

  return (
    <div>
      {loader && <Loading active={loader} />}
      <div className="heading-wrap d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <span className="head-icon me-16">
            <em className="icon-document-text-filled"></em>
          </span>
          <h4 className="m-0">DocuSign</h4>
        </div>
        <div className="d-flex align-items-center">
          <div>
              <div className="agre-search position-relative me-16 d-none d-md-block">
                <div className="search-area">
                  <input
                    placeholder="Search document name..."
                    className="form-control"
                    disabled={loginView}
                    value={searchValue}
                    onChange={(e) => {
                      e.preventDefault();
                      setSearchValue(e.target.value);
                      if (e.target.value.length > 2) {
                        getSeachList(e.target.value);
                      } else {
                        // setSearchValue("");
                        setNewSearchValue("");
                        setSearchList(null);
                      }
                    }}
                  />
                  <button
                    className="search-btn icon-20"
                    onClick={(e) => {
                      e.preventDefault();

                      setSearchValue("");
                      setNewSearchValue("");
                      setSearchList(null);
                    }}
                  >
                    <em
                      className={
                        searchValue === ""
                          ? "icon-search-outline"
                          : "icon-x-circle-outline"
                      }
                    ></em>
                  </button>
                </div>

                <div
                  className={
                    searchList?.envelopes !== null &&
                    searchValue.length > 2 &&
                    searchList?.envelopes?.length > 0
                      ? "agre-search-dropdown d-block dropdown-menu custom-style-scroll"
                      : "agre-search-dropdown d-none dropdown-menu custom-style-scroll"
                  }
                >
                  <ul className="agre-search-list list-unstyled m-0">
                    {searchList?.envelopes !== null && searchValue.length > 2
                      ? searchList?.envelopes
                          ?.filter((env) => env.status !== "declined")
                          .map((env, index) => (
                            <li key={index}>
                              <div className="d-flex align-items-center">
                                <span className="icon-24 fs-18">
                                  <em
                                    className={
                                      env.status === "sent"
                                        ? "icon-clock-outline text-info"
                                        : env.status === "delivered"
                                        ? "icon-exclamation-circle-filled text-info"
                                        : "icon-check-circle-filled text-success"
                                    }
                                  ></em>
                                </span>
                                <div className="ps-16">
                                  <span className="d-block">
                                    {getHighlightedText(
                                      env?.emailSubject,
                                      searchValue
                                    )}
                                  </span>
                                  <div className="fs-14 lh-20 text-muted">
                                    <span className="d-block">
                                      Received:{" "}
                                      {moment.utc(env?.sent_date_time?env?.sent_date_time:env?.sentDateTime).local().format('ll')}
                                    </span>
                                    <span className="d-block">
                                      From: {env.sender.userName}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <button
                                type="button"
                                className="btn btn-outline-secondary"
                                onClick={(e) => {
                                  e.preventDefault();
                                  console.log(env);
                                  if (env.status === "sent") {
                                    resendEnv(env);
                                    setSearchValue("");
                                    setNewSearchValue("");
                                    setSearchList(null);
                                  } else {
                                    setSelectedEnv(env);
                                    setViewFile(true);
                                    setSearchValue("");
                                    setNewSearchValue("");
                                    setSearchList(null);
                                  }
                                }}
                              >
                                {env.status === "sent"
                                  ? "Resend"
                                  : env.status === "delivered"
                                  ? "Sign"
                                  : "View"}
                              </button>
                            </li>
                          ))
                      : ""}
                  </ul>
                </div>
              </div>
          </div>
          <button
            className="btn btn-outline-secondary d-none d-md-flex align-items-center"
            disabled={loginView}
            onClick={(e) => {
              e.preventDefault();
              setMySignature(true);
            }}
          >
            <span className="icon-24 me-8">
              <img src={penWriting} alt="" />
            </span>
            My Signature
          </button>
          <button
            className="btn btn-outline-secondary d-none d-md-flex align-items-center ms-16"
            disabled={loginView}
            onClick={(e) => {
              e.preventDefault();
              logout()
              //setMySignature(true);
            }}
          >
            <span className="icon-24 me-8">
              <em className="icon icon-logout-filled"></em>
            </span>
              Log Out of DocuSign
          </button>
          <button
            className="btn btn-outline-secondary btn-icon-only d-md-none me-16"
            onClick={(e) => {
              e.preventDefault();
              handleActionShow(true);
            }}
          >
            <span className="icon-24">
              <em className="icon-search-outline"></em>
            </span>
          </button>
          <button
            className="btn btn-outline-secondary btn-icon-only d-md-none"
            onClick={(e) => {
              e.preventDefault();
              setMySignature(true);
            }}
          >
            <span className="icon-24">
              <img src={penWriting} alt="" />
            </span>
          </button>
        </div>
      </div>
      <div className="tab-wrap">
        {/* Tabs */}
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => {
              setKey(k);
              setIsReverseProps(false);
          }}
          className={loginView ? "tab-disabled" : ""}
        >
          <Tab
            eventKey={1}
            title={
              <span className="d-md-flex align-items-center justify-content-center">
                <span className="tab-icon">
                  <em className="icon-exclamation-circle-filled"></em>
                </span>{" "}
                {userDetails?.user_type !== "advisors" && (
                  <span className="tab-text">
                    {`Action Required(${actionTabCount})`}
                  </span>
                )}
                {userDetails?.user_type === "advisors" && (
                  <span className="tab-text">
                    {`My Action(${actionTabCount})`}
                  </span>
                )}
              </span>
            }
          ></Tab>

          {userDetails?.user_type === "advisors" && (
            <Tab
              eventKey={3}
              title={
                <span className="d-md-flex align-items-center justify-content-center">
                  <span className="tab-icon">
                    <em className="icon-clock-filled fs-14"></em>
                  </span>{" "}
                  <span className="tab-text">
                    {`Client Actions(${clientTabCount})`}
                  </span>
                </span>
              }
            ></Tab>
          )}

          <Tab
            eventKey={2}
            title={
              <span className="d-md-flex align-items-center justify-content-center">
                <span className="tab-icon">
                  <em className="icon-check-circle-filled fs-14"></em>
                </span>{" "}
                <span className="tab-text">
                  {`Completed(${completeTabCount})`}
                </span>
              </span>
            }
          ></Tab>
        </Tabs>
      </div>
      <div className="content-area">
        {loginView && <DocuSignLogin props={key}></DocuSignLogin>}
        <div className="tab-content">
          {actionRequired && (
            <ActionRequired
              props={key}
              mySignature={mySignature}
              searchValue={searchValue}
              viewFile={viewFile}
              selectedEnv={selectedEnv}
              func={pull_data}
              isReverseProps={false}
            />
          )}
        </div>
      </div>
      {/* Sort Modal */}
      <Modal
        className="modal-full"
        show={showAction}
        onHide={handleActionClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Search</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="border-bottom px-24 py-12">
            <div className="search-area">
              <input
                placeholder="Search document name..."
                className="form-control"
              />
              <button className="search-btn icon-20">
                <em className="icon-search-outline"></em>
              </button>
            </div>
          </div>

          <div className="agre-search-dropdown d-block custom-style-scroll">
            <ul className="agre-search-list list-unstyled m-0">
              <li>
                <div className="d-flex align-items-center">
                  <span className="icon-24 fs-18">
                    <em className="icon-check-circle-filled text-success"></em>
                  </span>
                  <div className="ps-16">
                    <span className="d-block">qwe</span>
                    <div className="fs-14 lh-20 text-muted">
                      <span className="d-block">Received:</span>
                      <span className="d-block">From: Jeremy Davies</span>
                    </div>
                  </div>
                </div>
                <button type="button" className="btn btn-outline-secondary">
                  View
                </button>
              </li>
              <li>
                <div className="d-flex align-items-center">
                  <span className="icon-24 fs-18">
                    <em className="icon-exclamation-circle-filled text-info"></em>
                  </span>
                  <div className="ps-16">
                    <span className="d-block">Roth IRA Agreement 2022</span>
                    <div className="fs-14 lh-20 text-muted">
                      <span className="d-block">Received: Jan 5, 2022</span>
                      <span className="d-block">From: Jeremy Davies</span>
                    </div>
                  </div>
                </div>
                <button type="button" className="btn btn-success">
                  Resend
                </button>
              </li>
            </ul>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ShareFile;
