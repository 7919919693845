import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import ReactCodeInput from "react-verification-code-input";
import { profileSetting } from "../../redux/actions/ProfileSetting/ProfileSetting";
import { notification } from "antd";

const ResetPassword = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //Error Message
  const [errorMsg, setErrorMsg] = useState("");

  //Toggle for password  , otp , valida otp
  const [otpSend, setOtpSend] = useState(false);
  const [validateOtp, setValidateOtp] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);

  //OTP
  const [verificationCode, setVerificationCode] = useState("");

  //Current and New Password
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const [newPasswordShown, setNewPasswordShown] = useState(false);
  const [CurrentPasswordShown, setCurrentPasswordShown] = useState(false);

  //Confirm Password
  const [newConfirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const [samePassword, setSamePassword] = useState(false);

  const [validError, setValidError] = useState(false);

  //Password Condition
  const [eightCharacter, setEightCharacter] = useState(false);
  const [oneNumber, setOneNumber] = useState(false);
  const [capitalChar, setCapitalChar] = useState(false);
  const [specialChar, setSpecialChar] = useState(false);

  const [loader, setLoader] = useState(false);

  const Notify = (type, mesage, icons) => {
    notification[type]({
      description: mesage,
      icon: icons,
      top: 65,
    });
  };
  useEffect(() => {}, []);

  function sendOtp() {
    let param = {
      mobile_number: props?.profile?.phone_number,
    };
    setLoader(true);
    dispatch(profileSetting.sendOtp(param)).then((response) => {
      setLoader(false);
      if (response.type === "OTP_SUCCESS") {
        setOtpSend(true);
        Notify(
          "success",
          `One-Time Passcode (OTP) sent successfully.`,
          <em className="icon-check-outline"></em>
        );
      }
    });
  }

  function handleChangeConfirmPassword(e) {
    let confirmPass = e.target.value;
    setConfirmNewPassword(confirmPass);
    setSamePassword(confirmPass === newPassword ? true : false);
  }

  function verifyOtp() {
    let param = {
      token: verificationCode,
    };
    setLoader(true);
    dispatch(profileSetting.validateCode(param)).then((response) => {
      setLoader(false);
      if (response.type === "OTP_VALID_SUCCESS") {
        setValidateOtp(true);
      } else {
        if (response?.error?.Message) {
          setErrorMsg(response?.error?.Message);
        } else {
          setErrorMsg(response?.error?.error);
        }
      }
    });
  }

  function changePassword() {
    let param = {
      current_password: currentPassword,
      new_password: newPassword,
    };
    if (
      !eightCharacter ||
      !oneNumber ||
      !capitalChar ||
      !specialChar ||
      !samePassword
    ) {
      return;
    }
    setLoader(true);
    dispatch(profileSetting.changePassword(param)).then((response) => {
      setLoader(false);
      if (response.type === "RESET_PASSWORD_SUCCESS") {
        //setOtpSend(true);
        Notify(
          "success",
          `Updated password`,
          <em className="icon-check-outline"></em>
        );
        const { closeModal } = props;
        closeModal();
      } else {
        setErrorMsg(response?.error?.message);
      }
    });
  }

  function handleChangeNewPassword(e) {
    let newpass = e.target.value;
    setNewPassword(newpass);
    //Eight Character Check
    setEightCharacter(newpass.length >= 8 ? true : false);
    //One NUmber
    let oneNumberRegex = "(?=.*?[0-9])";
    setOneNumber(newpass.match(oneNumberRegex) ? true : false);
    //Capital letter
    let capitalCharRegex = "(?=.*?[A-Z])";
    setCapitalChar(newpass.match(capitalCharRegex) ? true : false);
    //Special Character
    let specialCharRegex = "(?=.*?[!@#$%])";
    setSpecialChar(newpass.match(specialCharRegex) ? true : false);
    //Same Password
    // setSamePassword(confirmNewPassword === newPassword ? true : false)
  }

  return (
    <>
      {!otpSend && (
        <div className="py-24 border-bottom">
          <h4 className="mb-8 fs-18 lh-24">Change Password</h4>
          <p className="fs-18 lh-32 mb-24">
            We’ll text you a one-time passcode to change your password.
          </p>
          <div className="form-group">
            <label className="form-label">Verification Method</label>
            <input
              type="text"
              name="mobile"
              className="form-control"
              disabled={true}
              value={
                `Text Message - *** ***` +
                props?.profile?.phone_number.substr(
                  props?.profile?.phone_number.length - 4
                )
              }
            />
          </div>
          <button
            type="button"
            className="btn btn-success"
            onClick={(e) => {
              sendOtp();
            }}
          >
            Send Verification Code
          </button>
        </div>
      )}
      {!validateOtp && otpSend && (
        <div className="py-24 border-bottom">
          <h4 className="mb-8 fs-18 lh-24">Change Password</h4>
          <p className="fs-18 lh-32 mb-24">
            Please enter the verification code sent to your phone.
          </p>
          <div className="form-group">
            <label className="form-label">
              Verification Code <sup>*</sup>
            </label>
            <div className="max-w-410">
              <ReactCodeInput
                values={[...verificationCode]}
                fields={8}
                onChange={(newValue) => {
                  setVerificationCode(newValue);
                  setErrorMsg("");
                }}
              />
            </div>
          </div>
          <div className="d-flex">
            <button
              type="button"
              className="btn btn-success"
              disabled={verificationCode.length === 7 ? true : false}
              onClick={(e) => {
                verifyOtp();
              }}
            >
              Verify Code
            </button>
            <button
              type="button"
              className="btn btn-link"
              onClick={(e) => {
                sendOtp();
              }}
            >
              Resend Code
            </button>
          </div>
          {errorMsg.length > 0 && (
            <div className="invalid-feedback d-block">
              {errorMsg?.includes("token") ? "Token Expired" : errorMsg}
            </div>
          )}
        </div>
      )}
      {validateOtp && otpSend && !resetPassword && (
        <div className="py-24 border-bottom">
          <h4 className="mb-24 fs-18 lh-24">Change Password</h4>
          <form action="">
            <div className="form-group">
              <label className="form-label">
                Current Password <sup>*</sup>
              </label>
              <div className="password-field">
                <input
                  type={CurrentPasswordShown ? "text" : "password"}
                  className="form-control"
                  name="currentPassword"
                  onChange={(e) => {
                    setCurrentPassword(e.target.value);
                    setErrorMsg("");
                  }}
                />
                <em
                  className={
                    CurrentPasswordShown
                      ? "icon-eye-reveal-filled"
                      : "icon-eye-hide-filled"
                  }
                  onClick={(e) => {
                    setCurrentPasswordShown(!CurrentPasswordShown);
                  }}
                ></em>
              </div>
            </div>
            <div className="form-group">
              <label className="form-label">
                New Password <sup>*</sup>
              </label>
              <div className="password-field">
                <input
                  type={newPasswordShown ? "text" : "password"}
                  className="form-control"
                  name="newPassword"
                  onChange={(e) => {
                    handleChangeNewPassword(e);
                    setNewPassword(e.target.value);
                    setErrorMsg("");
                  }}
                />
                <em
                  className={
                    newPasswordShown
                      ? "icon-eye-reveal-filled"
                      : "icon-eye-hide-filled"
                  }
                  onClick={(e) => {
                    setNewPasswordShown(!newPasswordShown);
                  }}
                ></em>
              </div>
            </div>
            <div className="form-group">
              <label className="form-label">
                Confirm New Password <sup>*</sup>
              </label>
              <div className="password-field">
                <input
                  type={newConfirmPasswordShown ? "text" : "password"}
                  className="form-control"
                  name="newPassword"
                  value={confirmNewPassword}
                  onChange={(e) => {
                    handleChangeConfirmPassword(e);
                    //setValidError(false);
                  }}
                />
                <em
                  className={
                    newConfirmPasswordShown
                      ? "icon-eye-reveal-filled"
                      : "icon-eye-hide-filled"
                  }
                  onClick={(e) => {
                    newConfirmPasswordShown
                      ? setConfirmPasswordShown(false)
                      : setConfirmPasswordShown(true);
                  }}
                ></em>
              </div>
            </div>
            <div className="pass-require">
              <span className="d-block mb-8 fw-medium">
                Your password requirements:
              </span>
              <div>
                <p>
                  <em
                    className={
                      eightCharacter
                        ? "icon-check-circle-filled"
                        : "icon-x-circle-filled"
                    }
                  ></em>{" "}
                  At least 8 characters
                </p>
                <p>
                  <em
                    className={
                      oneNumber
                        ? "icon-check-circle-filled"
                        : "icon-x-circle-filled"
                    }
                  ></em>{" "}
                  At least 1 number
                </p>
                <p>
                  <em
                    className={
                      capitalChar
                        ? "icon-check-circle-filled"
                        : "icon-x-circle-filled"
                    }
                  ></em>{" "}
                  At least 1 capital letter
                </p>
                <p>
                  <em
                    className={
                      specialChar
                        ? "icon-check-circle-filled"
                        : "icon-x-circle-filled"
                    }
                  ></em>{" "}
                  At least 1 special character (!@#$%)
                </p>
                <p>
                  <em
                    className={
                      samePassword
                        ? "icon-check-circle-filled"
                        : "icon-x-circle-filled"
                    }
                  ></em>{" "}
                  Passwords must match
                </p>
              </div>
            </div>
          </form>
          <div className="d-flex">
            <button
              type="button"
              className="btn btn-success"
              onClick={(e) => {
                changePassword();
                setErrorMsg("");
              }}
            >
              Update
            </button>
          </div>
          {errorMsg?.length > 0 && (
            <div className="invalid-feedback d-block">{errorMsg}</div>
          )}
        </div>
      )}
    </>
  );
};

export default ResetPassword;
