import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Avatar from "../../Assets/Images/Avatar.png";
import ProfilePhotoSetting from "./ProfilePhotoSetting";
import { profileSetting } from "../../redux/actions/ProfileSetting/ProfileSetting";
import ResetPassword from "./ResetPassword";
import { utility } from "../../components/Utility";
import NotificationUpdate from "./NotificationUpdate";
import { Dropdown, Menu, Switch, notification, Checkbox, Radio } from "antd";
import RemoveProfilePhoto from "./RemoveProfilePhoto";
import moment from "moment";
import { calendar } from "../../redux/actions/Calendar/Calendar";
import { Select } from "antd";
import timeRange from "./TimeRange";
import { useNavigate } from "react-router";
import Loading from "../../components/Loader";

const ProfileSetting = () => {
  const { Option } = Select;
  const [showProfRemove, setProfRemoveShow] = useState(false);
  const [showProfUpdate, setProfUpdateShow] = useState(false);
  const [showProfAction, setProfActionShow] = useState(false);
  const [showFrequency, setFrequencyShow] = useState(false);
  const [showTime, setTimeShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [timeZoneList, setTimeZoneList] = useState([]);
  const [showTimeZone, setShowTimeZone] = useState(false);
  const [selectedTimeZone, setSelectedTimeZone] = useState();
  const [searchText, setSearchText] = useState("");
  const [profileTab, setProfileTab] = useState(true);
  const [notificationPreferenceTab, setNotificationPreferenceTab] =
    useState(false);
  const [isResponsiveMode, setIsResponsiveMode] = useState(false);
  //Change Password Hide and Show.
  const [changePasswordShow, setChangePasswordShow] = useState(false);

  //Notification Toggle
  const [notificationToggle, setNotificationToggle] = useState(false);

  //Edit Time Zone
  const [editTimeZone, setEditTimeZone] = useState(false);

  //Notification
  const [notificationSetting, setNotificationSetting] = useState();

  //Drop Down
  const [messageDropDown, setMessageDropDown] = useState(false);
  const [articleDropDown, setArticleDropDown] = useState(false);
  const [calendarDropDown, setCalendarDropDown] = useState(false);
  const [docuSignDropDown, setDocuSignDropDown] = useState(false);
  const [shareFileDropDown, setShareFileDropDown] = useState(false);

  //Message Notification
  const [enableReadReceiptsMessage, setEnableReadReceiptsMessage] = useState();
  const [enablePushMessage, setEnablePushMessage] = useState();
  const [enableMissedMessage, setEnableMissedMessage] = useState();

  //Docusign
  const [signatureRemindersPush, setSignatureRemindersPush] = useState();
  const [signatureRequestsPush, setSignatureRequestsPush] = useState();
  const [signatureResponsesPush, setSignatureResponsesPush] = useState();

  //Calendar Notifications
  // const [meetingInvitesPush, setMeetingInvitesPush] = useState();
  // const [attendeeResponsesPush, setAttendeeResponsesPush] = useState();
  // const [upcomingMeetingRemindersPush, setUpcomingMeetingRemindersPush] =
  //   useState();

  // const [meetingInvitesEmail, setMeetingInvitesEmail] = useState();
  // const [attendeeResponsesEmail, setAttendeeResponsesEmail] = useState();
  // const [upcomingMeetingRemindersEmail, setUpcomingMeetingRemindersEmail] =
  //   useState();
  const [userDetails, setUserDetails] = useState(
    JSON.parse(localStorage.getItem("userDetails"))
  );
  //Profile Info
  const [profileInfo, setProfileInfo] = useState();

  //Selected Start and End Date
  const [selectedStartDate, setSelectedStartDate] = useState();
  const [selectedEndDate, setSelectedEndDate] = useState();
  const [isEveryDay, setIsEveryDay] = useState(false);

  //Range List
  const [startTimeRange, setStartTimeRange] = useState();
  const [endTimeRange, setEndTimeRange] = useState();

  const [showEndTime, setEndTimeShow] = useState(false);
  const handleEndTimeClose = () => {
    setEndTimeShow(false);
  };
  const handleEndTimeShow = () => setEndTimeShow(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleProfRemoveClose = () => {
    setProfRemoveShow(false);
  };
  const handleProfRemoveShow = () => setProfRemoveShow(true);

  const handleProfUpdateClose = () => {
    setProfUpdateShow(false);
  };
  const handleProfUpdateShow = () => setProfUpdateShow(true);

  const handleProfActionClose = () => {
    setProfActionShow(false);
  };
  const handleProfActionShow = () => setProfActionShow(true);

  const handleFrequencyClose = () => {
    setFrequencyShow(false);
  };
  const handleFrequencyShow = () => setFrequencyShow(true);

  const handleTimeClose = () => {
    setTimeShow(false);
  };
  const handleTimeShow = () => setTimeShow(true);

  useEffect(() => {
    if (window.innerWidth < 700) {
      setIsResponsiveMode(true);
    } else {
      setIsResponsiveMode(false);
    }
    setStartTimeRange(timeRange);
    setEndTimeRange(timeRange);
    getNotificationPreference();
    getProfileInfo();
    //getTimeZone();
    // console.log(Utility.getUuid())
  }, []);
  const [articleToggle, setArticleToggle] = useState();
  const [shareFileToggle, setShareFileToggle] = useState();

  useEffect(() => {
    if (profileInfo?.timezone_id) {
      let defalutSelected = timeZoneList?.results?.filter(
        (c) => c?.uuid === profileInfo?.timezone_id
      );
      setSelectedTimeZone(defalutSelected[0]);
    }
  }, [timeZoneList]);

  const Notify = (type, mesage, icons) => {
    notification[type]({
      description: mesage,
      icon: icons,
      top: 65,
    });
  };

  function getNotificationPreference() {
    //setLoader(true);
    dispatch(profileSetting.getNotificationPreferences(`/notification_preferences?on_off_status=1`)).then((response) => {
      //setLoader(false);
      if (response.type === "GET_NOTIFICATION_SUCCESS") {
        setNotificationSetting(response.data);
        // setNotificationValue(response.data)
      }
    });
  }

  function callPrefererenceApi(preference_type){
    dispatch(profileSetting.getNotificationPreferences(`/notification_preferences?preference_type=${preference_type}`)).then((response) => {
      //setLoader(false);
      if (response.type === "GET_NOTIFICATION_SUCCESS") {
        setNotificationValue(response.data);
      }
    });
  }

  function getTime(value) {
    let startDate = timeRange.filter((c) => c.index === parseInt(value));
    return startDate[0]?.value;
  }

  function getProfileInfo() {
    setLoader(true);
    dispatch(profileSetting.getProfileInfo(utility.getUuid())).then(
      (response) => {
        setLoader(false);
        if (response.type === "GET_PROFILE_INFO_SUCCESS") {
          getTimeZone();
          setProfileInfo(response.data);
          settingUpComponent(response?.data);
          setNotificationToggle(response?.data?.notification_toggle);
          let userDetails = JSON.parse(localStorage.getItem("userDetails"));
          userDetails.profile_image = response.data?.profile_image;
          localStorage.setItem("userDetails", JSON.stringify(userDetails));
          //window.dispatchEvent(new Event("storage"));
          setNotificationToggle(true);
        }
      }
    );
  }

  function setWorkingHours() {
    let startDate = timeRange.filter(
      (c) => c.index === parseInt(selectedStartDate)
    );
    let endDate = timeRange.filter(
      (c) => c.index === parseInt(selectedEndDate)
    );
    let param = {
      timezone: selectedTimeZone?.uuid,
      work_hours_start_time: utility.convertTime(startDate[0].value),
      work_hours_end_time: utility.convertTime(endDate[0].value),
    };
    setLoader(true);
    dispatch(profileSetting.setWorkingHours(param)).then((response) => {
      setLoader(false);
      if (response.type === "WORKING_HOUR_SUCCESS") {
        Notify(
          "success",
          `Updated working hours schedule`,
          <em className="icon-check-outline"></em>
        );
      }
      let defalutSelected = timeZoneList?.results?.filter(
        (c) => c?.uuid === response?.data?.timezone
      );
      let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      userDetails.timezone = defalutSelected[0];

      localStorage.setItem("userDetails", JSON.stringify(userDetails));
      setUserDetails(JSON.parse(localStorage.getItem("userDetails")));
    });
  }

  function settingUpComponent(profile) {
    let endTime = utility.tConvert(profile?.work_hours_end_time);
    let getEndTimeIndex = timeRange.filter((t) => t.value.includes(endTime));

    let startTime = utility.tConvert(profile?.work_hours_start_time);
    let getStartTimeIndex = timeRange.filter((t) =>
      t.value.includes(startTime)
    );
    setSelectedEndDate(getEndTimeIndex[0]?.index);
    setSelectedStartDate(getStartTimeIndex[0]?.index);
  }

  function setNotificationValue(data) {
    for (let i = 0; i < data.length; i++) {
      if (
        data[i]?.preference_name === "Enable read receipts" &&
        data[i].preference_type === "message"
      ) {
        setEnableReadReceiptsMessage(data[i]);
      } else if (
        data[i]?.preference_name === "Push" &&
        data[i].preference_type === "message"
      ) {
        setEnablePushMessage(data[i]);
      } else if (
        data[i]?.preference_name ===
          "Enable email notifications for missed chat messages" &&
        data[i].preference_type === "message"
      ) {
        setEnableMissedMessage(data[i]);
      } 
      // else if (
      //   data[i]?.preference_name === "Meeting invites" &&
      //   data[i].setting_type === "push_notification"
      // ) {
      //   setMeetingInvitesPush(data[i]);
      // } else if (
      //   data[i]?.preference_name === "Meeting invites" &&
      //   data[i].setting_type === "email"
      // ) {
      //   setMeetingInvitesEmail(data[i]);
      // } else if (
      //   data[i]?.preference_name === "Attendee responses" &&
      //   data[i].setting_type === "push_notification"
      // ) {
      //   setAttendeeResponsesPush(data[i]);
      // } else if (
      //   data[i]?.preference_name === "Attendee responses" &&
      //   data[i].setting_type === "email"
      // ) {
      //   setAttendeeResponsesEmail(data[i]);
      // } else if (
      //   data[i]?.preference_name === "Upcoming meetings reminders" &&
      //   data[i].setting_type === "push_notification"
      // ) {
      //   setUpcomingMeetingRemindersPush(data[i]);
      // } else if (
      //   data[i]?.preference_name === "Upcoming meetings reminders" &&
      //   data[i].setting_type === "email"
      // ) {
      //   setUpcomingMeetingRemindersEmail(data[i]);
      // } 
      else if (
        data[i]?.preference_name === "Signature reminders" &&
        data[i].setting_type === "push_notification"
      ) {
        setSignatureRemindersPush(data[i]);
      } else if (
        data[i]?.preference_name === "Signature requests" &&
        data[i].setting_type === "push_notification"
      ) {
        setSignatureRequestsPush(data[i]);
      } else if (
        data[i]?.preference_name === "Signature responses" &&
        data[i].setting_type === "push_notification"
      ) {
        setSignatureResponsesPush(data[i]);
      }else if (
        data[i]?.preference_name === "Allow Article Push Notification" &&
        data[i].setting_type === "push_notification"
      ) {
        setArticleToggle(data[i]);
      }else if (
        data[i]?.preference_name === "Enable Sharefile push notification" &&
        data[i].setting_type === "push_notification"
      ) {
        setShareFileToggle(data[i]);
      }
    }
  }

  function changeNotificationValue(preferenceId, value, single = false) {
    let param;
    if (single) {
      param = {
        preferences: [
          {
            preference_id: preferenceId,
            value: value,
          },
        ],
      };
    } else if (preferenceId === "docu") {
      param = {
        preferences: [
          {
            preference_id: signatureRemindersPush?.id,
            value: value,
          },
          {
            preference_id: signatureRequestsPush?.id,
            value: value,
          },
          {
            preference_id: signatureResponsesPush?.id,
            value: value,
          },
        ],
      };
    } 
    // else if (preferenceId === "emailCal") {
    //   param = {
    //     preferences: [
    //       {
    //         preference_id: meetingInvitesEmail?.id,
    //         value: value,
    //       },
    //       {
    //         preference_id: attendeeResponsesEmail?.id,
    //         value: value,
    //       },
    //       {
    //         preference_id: upcomingMeetingRemindersEmail?.id,
    //         value: value,
    //       },
    //     ],
    //   };
    // } else if (preferenceId === "pushCal") {
    //   param = {
    //     preferences: [
    //       {
    //         preference_id: meetingInvitesPush?.id,
    //         value: value,
    //       },
    //       {
    //         preference_id: attendeeResponsesPush?.id,
    //         value: value,
    //       },
    //       {
    //         preference_id: upcomingMeetingRemindersPush?.id,
    //         value: value,
    //       },
    //     ],
    //   };
    // }
    dispatch(profileSetting.changeNotificationPreferences(param)).then(
      (response) => {
        //setLoader(false);
        if (response.type === "CHANGE_NOTIFICATION_SUCCESS") {
          getNotificationPreference();
        } else {
          getNotificationPreference();
        }
      }
    );
  }

  function getTimeZone() {
    //setLoader(true);
    dispatch(calendar.getTimeZone()).then((response) => {
      //setLoader(false);
      if (response.type === "GET_TIMEZONE_SUCCESS") {
        setTimeZoneList(response.data);
        setSelectedTimeZone(response.data.results[0]);
        // let defalutSelected = response?.data?.results?.filter(
        //   (c) => c?.uuid === userDetails?.timezone?.uuid
        // );
        // console.log(defalutSelected , "This is defaulted selected value");
        // let userDetails = JSON.parse(localStorage.getItem("userDetails"));
        // userDetails.timezone= defalutSelected[0];

        // localStorage.setItem("userDetails",JSON.stringify(userDetails));
        // setUserDetails(JSON.parse(localStorage.getItem("userDetails")))
      }
    });
  }

  const menu = (
    <div>
      {profileInfo?.profile_image ? (
        <Menu onClick={handleMenuClick}>
          <Menu.Item key="1">Update</Menu.Item>
          <Menu.Item key="2">Remove</Menu.Item>
        </Menu>
      ) : (
        <Menu onClick={handleMenuClick}>
          <Menu.Item key="1">Update</Menu.Item>
        </Menu>
      )}
    </div>
  );

  function handleMenuClick(e) {
    if (e.key === "1") {
      setProfUpdateShow(true);
    }
    if (e.key === "2") {
      setProfRemoveShow(true);
    }
  }

  return (
    <>
      {loader && <Loading noTimeOut={loader} />}
      <div className="heading-wrap">
        <div className="d-flex align-items-center">
          <span className="head-icon me-16">
            <em className="icon-user-filled"></em>
          </span>
          <h4 className="m-0">Manage Account</h4>
        </div>
      </div>
      <div className="tab-wrap d-md-none">
        <ul className="nav nav-tabs" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              type="button"
              className={profileTab ? "nav-link active" : "nav-link"}
              onClick={(e) => {
                setProfileTab(true);
                setNotificationPreferenceTab(false);
              }}
            >
              <span className="d-md-flex align-items-center justify-content-center">
                <span className="tab-icon">
                  <em className="icon-user-filled"></em>
                </span>
                <span className="tab-text">Your Profile</span>
              </span>
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              type="button"
              className={
                notificationPreferenceTab ? "nav-link active" : "nav-link"
              }
              onClick={(e) => {
                setProfileTab(false);
                setNotificationPreferenceTab(true);
              }}
            >
              <span className="d-md-flex align-items-center justify-content-center">
                <span className="tab-icon">
                  <em className="icon-bell-filled"></em>
                </span>
                <span className="tab-text">Notification Preferences</span>
              </span>
            </button>
          </li>
        </ul>
      </div>
      <div className="content-area px-24 pt-24">
        <div className="row">
          <div className="col-xl-5">
            <div className="card mb-xl-0 mb-24">
              {((isResponsiveMode && profileTab) || !isResponsiveMode) && (
                <div className="card-body py-24">
                  <div className="d-md-flex align-items-center mb-24 text-center text-md-start">
                    <div className="profile-pic-dropdown dropdown d-none d-md-inline-block">
                      <Dropdown
                        onClick={() => {
                          // setSelectRow(record);
                        }}
                        placement="bottomRight"
                        trigger={["click"]}
                        overlay={menu}
                      >
                        <div className="profile-pic-circle me-md-24 d-flex align-items-center justify-content-center cursor-pointer">
                          {/* {profileInfo?.profile_image ? (
                          <img src={profileInfo?.profile_image} alt="" />
                        ) : (
                          "SM"
                        )} */}
                          {utility.getProfileImage(
                            profileInfo?.profile_image,
                            userDetails?.first_name?.charAt(0),
                            userDetails?.last_name?.charAt(0)
                          )}

                          <div className="profile-pic-upload">
                            <em className="icon icon-camera-filled"></em>
                          </div>
                        </div>
                      </Dropdown>
                    </div>
                    <div
                      className="profile-pic-circle mx-auto d-flex d-md-none align-items-center justify-content-center cursor-pointer mb-8"
                      onClick={(e) => {
                        e.preventDefault();
                        handleProfActionShow();
                      }}
                    >
                      {utility.getProfileImage(
                        profileInfo?.profile_image,
                        userDetails?.first_name?.charAt(0),
                        userDetails?.last_name?.charAt(0)
                      )}
                      {/* <em className='icon icon-user-filled'></em> */}
                      <div className="profile-pic-upload">
                        <em className="icon icon-camera-filled"></em>
                      </div>
                    </div>
                    <div>
                      <h4 className="fs-18 lh-32 m-0">
                        {(profileInfo?.first_name
                          ? profileInfo?.first_name
                          : "") +
                          " " +
                          (profileInfo?.last_name
                            ? profileInfo?.last_name
                            : "")}
                      </h4>
                      <a href="#" className="text-muted">
                        {profileInfo?.email ? profileInfo?.email : ""}
                      </a>
                    </div>
                  </div>
                  <div className="profile-icons border-bottom pb-12">
                    <div className="d-flex align-items-center mb-12">
                      <span className="icon-24 me-8">
                        <em className="icon icon-phone-outline"></em>
                      </span>
                      {profileInfo?.phone_number
                        ? profileInfo?.phone_number
                        : ""}
                    </div>
                    {!editTimeZone && (
                      <div className="d-flex align-items-center justify-content-between mb-12">
                        <div className="d-flex align-items-center">
                          <span className="icon-24 me-8">
                            <em className="icon icon-globe-outline"></em>
                          </span>
                          <div>
                            {userDetails?.timezone?.description}
                            <span className="text-muted d-block">
                              {moment()
                                ?.tz(userDetails?.timezone?.live_meeting)
                                ?.format("hh:mm A")}
                            </span>
                          </div>
                        </div>
                        {utility.getUserType() === "clients" && !editTimeZone && (
                          <span
                            className="icon-24 cursor-pointer"
                            onClick={(e) => {
                              setEditTimeZone(!editTimeZone);
                            }}
                          >
                            <em className="icon-pencil-filled"></em>
                          </span>
                        )}
                      </div>
                    )}
                    {editTimeZone && (
                      <div className="mb-12">
                        <div className="timezone-block position-relative mb-12 open">
                          <div
                            className="form-control d-flex align-items-center justify-content-between cursor-pointer"
                            onClick={(e) => {
                              setShowTimeZone(!showTimeZone);
                            }}
                          >
                            {selectedTimeZone?.description
                              ? selectedTimeZone?.description +
                                "(" +
                                moment
                                  .tz(selectedTimeZone?.live_meeting)
                                  .format("LT") +
                                ")"
                              : ""}
                            <span className="icon-20 ms-8">
                              <em className="icon-chevron-down-outline lh-20"></em>
                            </span>
                          </div>
                          {timeZoneList?.results?.length > 0 && showTimeZone && (
                            <div className="timezon-area mt-8 mw-100">
                              <div className="timezon-head">
                                <div className="search-area">
                                  <input
                                    placeholder="Search..."
                                    className="form-control"
                                    value={searchText}
                                    onChange={(e) => {
                                      setSearchText(e.target.value);
                                    }}
                                  />
                                  <button className="search-btn icon-20">
                                    <em className="icon-search-outline"></em>
                                  </button>
                                </div>
                              </div>
                              <div className="timezon-body">
                                <h6 className="text-uppercase mb-16">
                                  {selectedTimeZone?.description
                                    ?.substring(
                                      selectedTimeZone?.description?.indexOf(
                                        "-"
                                      ) + 1
                                    )
                                    ?.replace("and", "/")
                                    ?.replace(/\s/g, "")}
                                </h6>

                                <ul className="timezone-type list-unstyled m-0 custom-style-scroll">
                                  {timeZoneList?.results
                                    ?.filter(
                                      (c) =>
                                        searchText === "" ||
                                        c.description
                                          .toLowerCase()
                                          .includes(searchText?.toLowerCase())
                                    )
                                    ?.map((time, index) => (
                                      <li
                                        className="cursor-pointer"
                                        key={index}
                                        onClick={(e) => {
                                          setSelectedTimeZone(time);
                                          setShowTimeZone(false);
                                        }}
                                      >
                                        <span>{time?.description}</span>
                                        <span>
                                          {moment
                                            .tz(time?.live_meeting)
                                            .format("LT")}
                                        </span>
                                      </li>
                                    ))}
                                </ul>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="d-flex">
                          <button
                            className="btn btn-outline-secondary"
                            onClick={(e) => {
                              setEditTimeZone(false);
                              let defalutSelected =
                                timeZoneList?.results?.filter(
                                  (c) => c?.uuid === userDetails?.timezone?.uuid
                                );
                              setSelectedTimeZone(defalutSelected[0]);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            className="btn btn-success ms-16"
                            onClick={(e) => {
                              setWorkingHours();
                              setEditTimeZone(false);
                            }}
                          >
                            Save Changes
                          </button>
                        </div>
                      </div>
                    )}
                  </div>

                  {notificationToggle && (
                    <NotificationUpdate
                      profile={profileInfo}
                    ></NotificationUpdate>
                  )}

                  {utility.getUserType() === "advisors" && (
                    <div className="py-24 border-bottom work-hours-block">
                      <h4 className="mb-24 fs-18 lh-24">Working Hours</h4>
                      <p>Set your working hours for Monday through Friday.</p>
                      <p className="text-muted fs-14 lh-24 mb-8">
                        Set the time when you begin and end your work day.
                      </p>
                      <div className="row gx-28">
                        <div className="col-sm-6 position-relative">
                          <div className="mb-16">
                            <label className="form-label">Start Time</label>
                            <Select
                              className="d-none d-md-block"
                              // defaultValue={selectedStartDate}
                              value={selectedStartDate}
                              onChange={(e) => {
                                setSelectedStartDate(e);
                              }}
                            >
                              {startTimeRange?.map((value, index) => (
                                <Option key={index} value={value?.index}>
                                  {value?.value}
                                </Option>
                              ))}
                            </Select>
                            <div
                              className="form-select d-md-none"
                              onClick={(e) => {
                                e.preventDefault();
                                handleTimeShow();
                              }}
                            >
                              {" "}
                              {getTime(selectedStartDate)}
                            </div>
                          </div>
                          <span className="totext-center">To</span>
                        </div>
                        <div className="col-sm-6">
                          <div className="mb-16">
                            <label className="form-label">End Time</label>
                            <Select
                              className="d-none d-md-block"
                              // defaultValue={selectedStartDate}
                              value={selectedEndDate}
                              onChange={(e) => {
                                setSelectedEndDate(e);
                              }}
                            >
                              {endTimeRange?.map((value, index) => (
                                <Option key={index} value={value?.index}>
                                  {value?.value}
                                </Option>
                              ))}
                            </Select>
                            <div
                              className="form-select d-md-none"
                              onClick={(e) => {
                                e.preventDefault();
                                handleEndTimeShow();
                              }}
                            >
                              {" "}
                              {getTime(selectedEndDate)}
                            </div>
                          </div>
                        </div>
                      </div>
                      <p className="text-muted fs-14 lh-24 mb-16">
                        Set the timezone that you are based in.
                      </p>
                      {/* add "open" this class with "timezone-block" for open dropdown */}
                      <div className="timezone-block position-relative mb-24 open">
                        <div
                          className="timezon-selected d-flex align-items-center cursor-pointer"
                          onClick={(e) => {
                            setShowTimeZone(!showTimeZone);
                          }}
                        >
                          <span className="icon-20 me-8">
                            <em className="icon-globe-alt-outline"></em>
                          </span>
                          {selectedTimeZone?.description
                            ? selectedTimeZone?.description +
                              "(" +
                              moment
                                .tz(selectedTimeZone?.live_meeting)
                                .format("LT") +
                              ")"
                            : ""}
                          <span className="icon-20 ms-8">
                            <em className="icon-chevron-down-outline"></em>
                          </span>
                        </div>
                        {timeZoneList?.results?.length > 0 && showTimeZone && (
                          <div className="timezon-area">
                            <div className="timezon-head">
                              <div className="search-area">
                                <input
                                  placeholder="Search..."
                                  className="form-control"
                                  value={searchText}
                                  onChange={(e) => {
                                    setSearchText(e.target.value);
                                  }}
                                />
                                <button className="search-btn icon-20">
                                  <em className="icon-search-outline"></em>
                                </button>
                              </div>
                            </div>
                            <div className="timezon-body">
                              <h6 className="text-uppercase mb-16">
                                {selectedTimeZone?.description
                                  ?.substring(
                                    selectedTimeZone?.description?.indexOf(
                                      "-"
                                    ) + 1
                                  )
                                  ?.replace("and", "/")
                                  ?.replace(/\s/g, "")}
                              </h6>

                              <ul className="timezone-type list-unstyled m-0 custom-style-scroll">
                                {timeZoneList?.results
                                  ?.filter(
                                    (c) =>
                                      searchText === "" ||
                                      c.description
                                        .toLowerCase()
                                        .includes(searchText?.toLowerCase())
                                  )
                                  ?.map((time, index) => (
                                    <li
                                      className="cursor-pointer"
                                      key={index}
                                      onClick={(e) => {
                                        setSelectedTimeZone(time);
                                        setShowTimeZone(false);
                                      }}
                                    >
                                      <span>{time?.description}</span>
                                      <span>
                                        {moment
                                          .tz(time?.live_meeting)
                                          .format("LT")}
                                      </span>
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          </div>
                        )}
                      </div>
                      <button
                        type="button"
                        className="btn btn-success"
                        onClick={(e) => {
                          setWorkingHours();
                        }}
                      >
                        Update
                      </button>
                    </div>
                  )}
                  {!changePasswordShow && utility.getUserType() !== "advisors" && (
                    <div className="py-24 border-bottom">
                      <h4 className="mb-24 fs-18 lh-24">Password</h4>
                      <button
                        type="button"
                        className="btn btn-success"
                        onClick={(e) => setChangePasswordShow(true)}
                      >
                        Change Password
                      </button>
                    </div>
                  )}
                  {changePasswordShow && (
                    <ResetPassword
                      profile={profileInfo}
                      closeModal={() => {
                        setChangePasswordShow(false);
                      }}
                    ></ResetPassword>
                  )}
                  <div className="pt-24">
                   
                    <span className="text-muted d-block mb-24">
                      {utility?.getUserType() === "clients" ? "For account support, please contact your advisory team." : "For account support, please use the Help Desk."}
                    </span>
                    
                    <span
                      className="cursor-pointer text-danger d-flex align-items-center py-8 mb-24"
                      onClick={() => {

                        localStorage.setItem("user", false);
                        localStorage.removeItem("token");
                        navigate("/");

                      }}
                    >
                      <span className="icon-24 me-8">
                        <em className="icon icon-logout-filled"></em>
                      </span>
                      Log out
                    </span>
                    <h4 className="fs-18 lh-24 mb-md-4 mb-0">
                      Version {process.env.REACT_APP_Version}
                    </h4>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-xl-7">
            {(notificationSetting?.find((x) => x.preference_type === 'calendar')?.value ||
              notificationSetting?.find((x) => x.preference_type === 'docusign')?.value ||
              notificationSetting?.find((x) => x.preference_type === 'message')?.value ||
              notificationSetting?.find((x) => x.preference_type === 'files')?.value) && (
              <div className="card">
                {((isResponsiveMode && notificationPreferenceTab) ||
                  !isResponsiveMode) && (
                  <div className="card-body py-24">
                    {userDetails?.user_type === "clients" &&
                    <div className={articleDropDown?"profnotif-block profnotif-open":"profnotif-block"}>
                      <div>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="mb-4 mb-md-0">
                            <h4 className="fs-18 lh-24 mb-md-4 mb-0">Articles</h4>
                            <p className="m-0 d-none d-md-block">
                              Article Notifications
                            </p>
                          </div>
                          <div className="d-flex align-items-center">
                            <span
                              className="cursor-pointer icon-32"
                              onClick={(e) => {
                                setArticleDropDown(!articleDropDown);
                                if (!articleDropDown){
                                  callPrefererenceApi('articles')
                                }
                              }}
                            >
                              <em className="icon icon-chevron-down-outline fs-16"></em>
                            </span>
                          </div>
                        </div>
                      </div>
                      {articleDropDown &&
                      <div className="pt-24">
                        <div className="notif-type">
                          <div className="d-flex align-items-center justify-content-between mb-20">
                            <h6 className="m-0 pe-8">
                              Allow push notifications for Articles
                            </h6>
                            <Switch
                              checked={articleToggle?.value}
                              onChange={(e) => {
                                articleToggle["value"] = e;
                                setArticleToggle(articleToggle);
                                changeNotificationValue(
                                  articleToggle?.id,
                                  e,
                                  true
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      }
                    </div>
                    }
                    {notificationSetting?.find((x) => x.preference_type === 'message')?.value && (
                      <div
                        className={
                          messageDropDown
                            ? "profnotif-block profnotif-open"
                            : "profnotif-block"
                        }
                      > 
                        <div>
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="mb-4 mb-md-0">
                              <h4 className="fs-18 lh-24 mb-md-4 mb-0">
                                Messages
                              </h4>
                              <p className="m-0 d-none d-md-block">
                                New messages, new channels.
                              </p>
                            </div>
                            <div className="d-flex align-items-center">
                              <span
                                className="cursor-pointer icon-32"
                                onClick={(e) => {
                                  setMessageDropDown(!messageDropDown);
                                  if (!messageDropDown){
                                    callPrefererenceApi('message')
                                  }
                                }}
                              >
                                <em className="icon icon-chevron-down-outline fs-16"></em>
                              </span>
                            </div>
                          </div>
                          <p className="m-0 d-md-none">
                            New messages, new channels.
                          </p>
                        </div>
                        {/* Notification Dropdown */}
                        {messageDropDown && (
                          <div className="pt-24">
                            <div className="notif-type d-flex justify-content-between">
                              <div className="mb-4 mb-md-0">
                                <h6 className="m-0 pe-8">Send Read Receipts</h6>
                                <p className="text-muted mb-20 fs-14 lh-20">
                                When this is on, people are notified when you have read their messages. 
                                This enables read receipts for all conversations.
                                </p>
                              </div>
                              <Switch
                                checked={enableReadReceiptsMessage?.value}
                                onChange={(e) => {
                                  enableReadReceiptsMessage["value"] = e;
                                  setEnableReadReceiptsMessage(
                                    enableReadReceiptsMessage
                                  );
                                  changeNotificationValue(
                                    enableReadReceiptsMessage?.id,
                                    e,
                                    true
                                  );
                                }}
                              />
                            </div>
                            <div className="notif-type">
                              <div className="typesof-not d-flex align-items-center justify-content-between">
                                <h6 className="mb-8">
                                Allow push notifications for messages
                                </h6>
                                <Switch
                                  checked={enablePushMessage?.value}
                                  onChange={(e) => {
                                    enablePushMessage["value"] = e;
                                    setEnablePushMessage(enablePushMessage);
                                    changeNotificationValue(
                                      enablePushMessage?.id,
                                      e,
                                      true
                                    );
                                  }}
                                />
                              </div>
                            </div>
                            <div className="notif-type">
                              <div className="d-flex align-items-center justify-content-between">
                                <h6 className="mb-8">
                                  Enable email notifications for missed chat messages
                                </h6>
                                <Switch
                                  checked={enableMissedMessage?.value!== "none"}
                                  onChange={(e) => {
                                    if (enableMissedMessage?.value !== "none"
                                    ) {
                                      enableMissedMessage.value = "none";
                                    }
                                    else
                                    {
                                      enableMissedMessage.value = "Once daily";

                                    }
                                    setEnableMissedMessage(enableMissedMessage);
                                    changeNotificationValue(
                                      enableMissedMessage?.id,
                                      enableMissedMessage.value,
                                      true
                                    );
                                  }}
                                />
                              </div>
                              <div className="pt-20">
                                <Radio.Group
                                  className=""
                                  onChange={(e) => {
                                    setEnableMissedMessage({...enableMissedMessage,"value":e.target.value});
                                    changeNotificationValue(
                                      enableMissedMessage?.id,
                                      e?.target?.value,
                                      true
                                    );
                                  }}
                                  value={enableMissedMessage?.value}
                                >
                                  <Radio value={"Once every hour"}>
                                    Once every hour
                                  </Radio>
                                  <Radio value={"Once every 8 hours"}>
                                    Once every 8 hours
                                  </Radio>
                                  <Radio value={"Once daily"}>Once daily</Radio>
                                </Radio.Group>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    {/* {notificationSetting?.find((x) => x.preference_type === 'calendar')?.value && (
                      <div
                        className={
                          calendarDropDown
                            ? "profnotif-block profnotif-open"
                            : "profnotif-block"
                        }
                      >
                        <div>
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="mb-4 mb-md-0">
                              <h4 className="fs-18 lh-24 mb-md-4 mb-0">
                                Calendar
                              </h4>
                              <p className="m-0 d-none d-md-block">
                                Meeting invites, attendee responses.
                              </p>
                            </div>
                            <div className="d-flex align-items-center">
                              <span
                                className="cursor-pointer icon-32"
                                onClick={(e) => {
                                  setCalendarDropDown(!calendarDropDown);
                                  if (!calendarDropDown){
                                    callPrefererenceApi("calendar")
                                  }
                                }}
                              >
                                <em className="icon icon-chevron-down-outline fs-16"></em>
                              </span>
                            </div>
                          </div>
                          <p className="m-0 d-md-none">
                            Meeting invites, attendee responses.
                          </p>
                        </div>
                        {calendarDropDown && (
                          <div className="pt-24">
                            <div className="notif-type">
                              <div className="typesof-not d-flex align-items-center justify-content-between">
                                <h6 className="mb-8">
                                Allow push notifications for the Calendar feature
                                </h6>
                                <Switch
                                  checked={
                                    meetingInvitesPush?.value ||
                                    attendeeResponsesPush?.value
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => {
                                    changeNotificationValue("pushCal", e);
                                    setMeetingInvitesPush({...meetingInvitesPush,"value":e})
                                    setAttendeeResponsesPush({...attendeeResponsesPush,"value":e})
                                  }}
                                />
                              </div>
                              <div>
                                <div className="typesof-not d-flex align-items-center justify-content-between">
                                  <span className="lh-20">Meeting invites</span>
                                  <Switch
                                    checked={meetingInvitesPush?.value}
                                    onChange={(e) => {
                                      meetingInvitesPush["value"] = e;
                                      setMeetingInvitesPush(meetingInvitesPush);
                                      changeNotificationValue(
                                        meetingInvitesPush?.id,
                                        e,
                                        true
                                      );
                                    }}
                                  />
                                </div>
                                <div className="typesof-not d-flex align-items-center justify-content-between">
                                  <span className="lh-20">
                                    Attendee responses
                                  </span>
                                  <Switch
                                    checked={attendeeResponsesPush?.value}
                                    onChange={(e) => {
                                      attendeeResponsesPush["value"] = e;
                                      setAttendeeResponsesPush(
                                        attendeeResponsesPush
                                      );
                                      changeNotificationValue(
                                        attendeeResponsesPush?.id,
                                        e,
                                        true
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                              <div>
                                <div className="typesof-not d-flex align-items-center justify-content-between">
                            <span className="lh-20">Attendee responses</span>
                            <Switch
                              checked={attendeeResponsesEmail?.value}
                              onChange={(e) => {
                                attendeeResponsesEmail["value"] = e;
                                setAttendeeResponsesEmail(
                                  attendeeResponsesEmail
                                );
                                changeNotificationValue(
                                  attendeeResponsesEmail?.uuid,
                                  e
                                );
                              }}
                            />
                          </div>
                          <div className="typesof-not d-flex align-items-center justify-content-between">
                            <span className="lh-20">
                              Upcoming meeting reminders
                            </span>
                            <Switch
                              checked={upcomingMeetingRemindersEmail?.value}
                              onChange={(e) => {
                                upcomingMeetingRemindersEmail["value"] = e;
                                setUpcomingMeetingRemindersEmail(
                                  upcomingMeetingRemindersEmail
                                );
                                changeNotificationValue(
                                  upcomingMeetingRemindersEmail?.uuid,
                                  e
                                );
                              }}
                            />
                          </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )} */}
                    {notificationSetting?.find((x) => x.preference_type === 'docusign')?.value && (
                      <div
                        className={
                          docuSignDropDown
                            ? "profnotif-block profnotif-open"
                            : "profnotif-block"
                        }
                      >
                        <div>
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="mb-4 mb-md-0">
                              <h4 className="fs-18 lh-24 mb-md-4 mb-0">
                                DocuSign
                              </h4>
                              <p className="m-0 d-none d-md-block">
                                Signature requests, signature responses.
                              </p>
                            </div>
                            <div className="d-flex align-items-center">
                              <span
                                className="cursor-pointer icon-32"
                                onClick={(e) => {
                                  setDocuSignDropDown(!docuSignDropDown);
                                  if (!docuSignDropDown){
                                    callPrefererenceApi('docusign')
                                  }
                                }}
                              >
                                <em className="icon icon-chevron-down-outline fs-16"></em>
                              </span>
                            </div>
                          </div>
                          <p className="m-0 d-md-none">
                            Meeting invites, attendee responses.
                          </p>
                        </div>
                        {/* Notification Dropdown */}
                        {docuSignDropDown && (
                          <div className="pt-24">
                            <div className="notif-type">
                              <div className="typesof-not d-flex align-items-center justify-content-between">
                                <h6 className="mb-2">
                                  Allow push notifications for DocuSign
                                </h6>
                                {/* <Switch
                                  checked={
                                    signatureRequestsPush?.value ||
                                    signatureResponsesPush?.value
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => {
                                    changeNotificationValue("docu", e);
                                    setSignatureRequestsPush({...signatureRequestsPush,"value":e})
                                    setSignatureResponsesPush({...signatureResponsesPush,"value":e})
                                  }}
                                /> */}
                              </div>
                              <div>
                                {/* <div className="typesof-not   d-flex align-items-center justify-content-between">
                            <span className="lh-20">Email</span>
                            <Switch />
                          </div> */}
                              </div>
                            </div>
                            <div className="pt-8">
                              <h6 className="mb-8">
                              </h6>
                              <div>
                                <div className="typesof-not d-flex align-items-center justify-content-between">
                                  <span className="lh-20">
                                    Signature requests
                                  </span>
                                  <Switch
                                    checked={signatureRequestsPush?.value}
                                    onChange={(e) => {
                                      signatureRequestsPush["value"] = e;
                                      setSignatureRequestsPush(
                                        signatureRequestsPush
                                      );
                                      changeNotificationValue(
                                        signatureRequestsPush?.id,
                                        e,
                                        true
                                      );
                                    }}
                                  />
                                </div>
                                <div className="typesof-not d-flex align-items-center justify-content-between">
                                  <span className="lh-20">
                                    Signature responses
                                  </span>
                                  <Switch
                                    checked={signatureResponsesPush?.value}
                                    onChange={(e) => {
                                      signatureResponsesPush["value"] = e;
                                      setSignatureResponsesPush(
                                        signatureResponsesPush
                                      );
                                      changeNotificationValue(
                                        signatureResponsesPush?.id,
                                        e,
                                        true
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    {notificationSetting?.find((x) => x.preference_type === 'files')?.value && (
                      <div
                        className={
                          shareFileDropDown
                            ? "profnotif-block profnotif-open"
                            : "profnotif-block"
                        }
                      >
                        <div>
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="mb-4 mb-md-0">
                              <h4 className="fs-18 lh-24 mb-md-4 mb-0">
                                Files
                              </h4>
                              <p className="m-0 d-none d-md-block">
                                File upload and download notifications.
                              </p>
                            </div>
                            <div className="d-flex align-items-center">
                              <span
                                className="cursor-pointer icon-32"
                                onClick={(e) => {
                                  setShareFileDropDown(!shareFileDropDown);
                                  if (!shareFileDropDown){
                                    callPrefererenceApi('files')
                                  }
                                }}
                              >
                                <em className="icon icon-chevron-down-outline fs-16"></em>
                              </span>
                            </div>
                          </div>
                        </div>
                        {/* Notification Dropdown */}
                        {shareFileDropDown && (
                          <div className="pt-24">
                            <div className="notif-type">
                              <div className="d-flex align-items-center justify-content-between mb-20">
                                <h6 className="m-0 pe-8">
                                  Enable Sharefile push notification
                                </h6>
                                <Switch
                                  checked={shareFileToggle?.value}
                                  onChange={(e) => {
                                    shareFileToggle["value"] = e;
                                    setShareFileToggle(shareFileToggle);
                                    changeNotificationValue(
                                      shareFileToggle?.id,
                                      e,
                                      true
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Remove Profile Photo */}
      {showProfRemove && (
        <RemoveProfilePhoto
          visible={showProfRemove}
          profile={profileInfo}
          closeModal={() => {
            setProfRemoveShow(false);
            getProfileInfo();
          }}
        ></RemoveProfilePhoto>
      )}

      {/* Profile Update Model */}
      {showProfUpdate && (
        <ProfilePhotoSetting
          visible={showProfUpdate}
          profile={profileInfo}
          closeModal={() => {
            setProfUpdateShow(false);
            getProfileInfo();
          }}
        ></ProfilePhotoSetting>
      )}

      {/* ProfAction Model */}
      <Modal size="sm" show={showProfAction} onHide={handleProfActionClose}>
        <Modal.Header closeButton>
          <Modal.Title>Actions</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <ul className="list-unstyled bulk-list m-0">
            <li
              onClick={(e) => {
                e.preventDefault();
                handleProfUpdateShow();
              }}
            >
              Update
            </li>
            <li
              onClick={(e) => {
                e.preventDefault();
                handleProfRemoveShow();
              }}
            >
              Remove
            </li>
          </ul>
        </Modal.Body>
      </Modal>

      {/* Select Frequency Model */}
      <Modal size="sm" show={showFrequency} onHide={handleFrequencyClose}>
        <Modal.Header closeButton>
          <Modal.Title>Select Frequency</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <ul className="list-unstyled bulk-list m-0">
            <li>Every day</li>
            <li>Weekdays</li>
          </ul>
        </Modal.Body>
      </Modal>

      {/* Select Time Model */}
      <Modal size="sm" show={showTime} onHide={handleTimeClose}>
        <Modal.Header closeButton>
          <Modal.Title>Select Time</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <ul className="list-unstyled bulk-list m-0">
            {timeRange?.map((value, index) => (
              <li
                key={index}
                onClick={() => {
                  setSelectedStartDate(value?.index);
                  setTimeShow(false);
                }}
              >
                {value?.value}
              </li>
            ))}
          </ul>
        </Modal.Body>
      </Modal>

      <Modal size="sm" show={showEndTime} onHide={handleEndTimeClose}>
        <Modal.Header closeButton>
          <Modal.Title>Select Time</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <ul className="list-unstyled bulk-list m-0">
            {timeRange?.map((value, index) => (
              <li
                key={index}
                onClick={() => {
                  setSelectedEndDate(value?.index);
                  setEndTimeShow(false);
                }}
              >
                {value?.value}
              </li>
            ))}
          </ul>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProfileSetting;
