import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Switch, Table, Menu, Dropdown, Tooltip, notification } from "antd";
import cdc from "../../Assets/Images/cdc-icon.svg";
import { dashboard } from "../../redux/actions/Dashboard/dashboard";
import { useDispatch } from "react-redux";
import moment from "moment";
import TopicDropdownDiv from "./TopicDropdownDiv";
import { Calendar } from "react-modern-calendar-datepicker";
import DashboardCalendarLocale from "../Dashboard/DashboardCalendarLocale";
import { utility } from "../../components/Utility";
import PublishDropDown from "./PublishDropDown";
import Avatar from "react-avatar";
import TeamDropdownDiv from "./TeamDropdownDiv";

const CDC = () => {
  const [userDetails, setUserDetails] = useState(
    JSON.parse(localStorage.getItem("userDetails"))
  );
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [articlesDataList, setArticlesDataList] = useState([]);
  const [handlePublish, setHandlePublish] = useState({});
  const [showWarning, setShowWarning] = useState(false);
  const [isFeatured, setIsFeatured] = useState(false);
  const [filterStatus, setFilterStatus] = useState([
    "Published",
    "Ready To Publish",
  ]);
  const [dropdownShown, setDropdownShown] = useState(false);
  const [dropdownTeamShown, setDropdownTeamShown] = useState(false);
  const [dropdownClientShown, setDropdownClientShown] = useState(false);
  const [currentValue, setCurrentValue] = useState("1");
  const [topic, setTopic] = useState([]);
  const [teamListData, setTeamListData] = useState([]);
  const [clientSelected, setClientSelected] = useState([]);
  const [dropDownClient, setDropDownClient] = useState("");
  const [dropDownTopic, setDropDownTopic] = useState([]);
  const [dropDownTeam, setDropDownTeam] = useState([]);
  const [removed, setRemoved] = useState("");
  const [removedTeam, setRemovedTeam] = useState("");
  const [showCalenderFilter, setshowCalenderFilter] = useState(false);
  const [showCalenderPublishedDateFilter, setshowCalenderPublishedDateFilter] =
    useState();
  const [selectedDayPublishedDateSingle, setSelectedDayPublishedDateSingle] =
    useState("");
  const [currentDateText, setcurrentDateText] = useState();
  const [currentDatePublishedDateText, setcurrentDatePublishedDateText] =
    useState();
  const [selectedDaySingle, setSelectedDaySingle] = useState("");
  const [topicUuid, setTopicUuid] = useState("");
  const [expandAll, setExpandAll] = useState(true);
  const [onexpandAll, setOnExpandAll] = useState(true);
  const [newTopicUuid, setNewTopicUuid] = useState("");
  const [clientData, setClientData] = useState([]);
  const [prevClientData, setPrevClientData] = useState([]);
  const [selectedDayRange, setSelectedDayRange] = useState({
    from: null,
    to: null,
  });
  const [selectedDayPublishedDateRange, setSelectedDayPublishedDateRange] =
    useState({
      from: null,
      to: null,
    });
  const [selected, setSelected] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [showRangeCalender, setShowRangeCalender] = useState(false);
  const [showRangePublishedDateCalender, setShowRangePublishedDateCalender] =
    useState(false);
  const [ischecked, setIsChecked] = useState(false);
  const [isPublishedDatechecked, setIsPublishedDateChecked] = useState(false);
  const [filterData, setfilterData] = useState([ { uuid: "Published", label: "status: Published", type: 4 },{ uuid: "Ready To Publish", label:  "status: Ready To Publish", type: 4 }]);
  const [valueFilter, setValue] = useState("");
  const [rtpChecked, setRtpChecked] = useState(true);
  const [pChecked, setPChecked] = useState(true);
  const [rChecked, setRChecked] = useState(true);
  const handlePublishModalClose = () => {
    setShowPublishModal(false);
    setShowWarning(false);
    setPrevClientData([]);
    setClientData([]);
    setDropDownClient([]);
    setDropDownClientnew([]);
    setClientSelected([]);
    setAllSelect("");
  };
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [featuredData, setFeaturedData] = useState(false);

  const [singleArticleData, setSingleArticleData] = useState({});
  const [menuDisplay, handleMenuDisplay] = useState("");

  const [showFilterContent, setFilterContentShow] = useState(false);
  const handleFilterContentClose = () => {
    setFilterContentShow(false);
  };
  const handleFilterContentShow = () => {
    setFilterContentShow(true);
    setDropdownTeamShown(false);
    setDropdownShown(false);
    if (!rChecked && !pChecked) {
      setTimeout(() => {
        document.getElementById("publish-cal").className += " disabled-div";
      }, 200);
    }
  };

  const [showRemoveArticle, setRemoveArticleShow] = useState(false);
  const handleRemoveArticleClose = () => {
    setSingleArticleData({});
    setRemoveArticleShow(false);
  };


  const handleShowRemoveModal = (record) => {
    setSingleArticleData(record);
    setRemoveArticleShow(true);
  };
  const [next, setNext] = useState("");
  const [articleUrl, setArticleUrl] = useState("");
  const [dropDownClientnew, setDropDownClientnew] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [disableFeaturedCheckbox, setDisableFeaturedCheckbox] = useState(false);
  const [showClientDanger, setShowClientDanger] = useState(false);
  const [featuredChecked, setFeaturedChecked] = useState(false);
  const [allSelect, setAllSelect] = useState("");
  const [fromAllTeam, setFromAllTeam] = useState(false);
  const [alreadySelectedLength, setAlreadySelectedLength] = useState(false);

  const [isSortAsc, setisSortAsc] = useState(
    userDetails?.admin_type === "firm_admin" ? true : null
  );
  const [isSortAscPublishDate, setisSortAscPublishDate] = useState(null);
  const [isSortAscPostedDate, setisSortAscPostedDate] = useState(null);
  const [isSortAscPostTitle, setisSortAscPostTitle] = useState(null);
  const [isSortAscStatus, setisSortAscStatus] = useState(null);
  const [isSortAscPublishedTo, setisSortAscPublishedTo] = useState(null);
  const [isSortAscTopics, setisSortAscTopics] = useState(null);
  const [isSortArchiveDate, setisSortArchiveDate] = useState(null);
  const [showRemoved, setShowRemoved] = useState(false);
  const [numberofDays,setNumberofDays] = useState(90);
  const [isArchievedArticle,setIsArchievedArticle] = useState(true);

  const names = new Set();

  const handleEnter = event => {
    if (event.keyCode === 13 || event.keyCode === 40) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const resetStates = (record,button=null,descp=null) =>{
    articleclientdata(
      record.uuid,
      record.publish_to,
      record.team_id
    )
    record.is_featured
      ? (setFeaturedChecked(true),
      setIsFeatured(true),
      setShowWarning(true))
      : (setFeaturedChecked(false),
      setIsFeatured(false),
      setShowWarning(false)),
    record.publish_to !== "Specific"
      ? (setCurrentValue("1"),
      setDisableFeaturedCheckbox(false),
      setIsActive(false))
      : (setCurrentValue("2"),
      setDisableFeaturedCheckbox(true),
      setIsActive(true)),
    setShowClientDanger(false),
    setShowPublishModal(true),
    setDropdownClientShown(false),
    setHandlePublish({
      uuid: record.uuid,
      map_id: record.map_id,
      title: record.title,
      modal_title: `${button?button:'Republish'} Article`,
      modal_desc:descp?descp:
        "Select more \
                  clients with whome you want to share this article on Moneta client portal and mobile app:",
      publish_type: record.status === "Archived" ? 1 : record.status === "Ready To Publish"?1:0,
      team_id: record.team_id,
      button_name: button?button:"Republish",
    })
  }

  const menu = (record, value) => {
    return (
      <div>
        <Menu>
          <Menu.Item
            key="1"
            onClick={() => {
              value === "Republish"
                ? (resetStates(record),
                handleMenuDisplay("")
                  )
              : (handleShowRemoveModal(record), handleMenuDisplay(""));
            }}
          >
            {value}
          </Menu.Item>
        </Menu>
      </div>
    );
  };
  const menufeatured = (record,value=null) => {
    return (
      <div>
        <Menu>
          <Menu.Item
            key="1"
            onClick={() => {value?resetStates(record,"Publish","Are you sure \
               you want to publish this article to Moneta client portal and mobile app?"):(
              resetStates(record),
              handleMenuDisplay("")
            )}}
          >
            {value?value:"Republish"}
          </Menu.Item>
          <Menu.Item
            key="2"
            onClick={() => (
              handleShowRemoveModal(record), handleMenuDisplay("")
            )}
          >
            Archive
          </Menu.Item>
        </Menu>
      </div>
    );
  };

  const columns = [
    userDetails?.admin_type === "firm_admin"
      ? {
        title: (
          <>
            Team Name
            <em
              className={`icon icon-sort-${
                isSortAsc !== null
                  ? isSortAsc
                    ? "ascending-outline text-success"
                    : "descending-outline text-success"
                  : "descending-outline"
              }  ms-8 cursor-pointer`}
              onClick={(e) => {
                e.preventDefault();
                setisSortAsc(!isSortAsc);
                setisSortArchiveDate(null);
                setisSortAscPostedDate(null);
                setisSortAscPublishDate(null);
                setisSortAscPostTitle(null);
                setisSortAscPublishedTo(null);
                setisSortAscStatus(null);
                setisSortAscTopics(null);
              }}
            ></em>
          </>
        ),
        dataIndex: "",
        key: "team_id",
        className:"ps-16",
        width:"80px",
        render: (record) => (
          <>
            <span
              className="text-172935  line-clamp-two team-span"
              id={record.map_id}
            >
              {record.team_name}
            </span>
          </>
        ),
      }
      : {
        title: "hide",
        hidden: true,
      },
    userDetails?.admin_type !== "firm_admin"
      ? {
        title: (
          <>
            Post Title
            <em
              className={`icon icon-sort-${
                isSortAscPostTitle !== null
                  ? isSortAscPostTitle
                    ? "ascending-outline text-success"
                    : "descending-outline text-success"
                  : "descending-outline"
              }  ms-8 cursor-pointer`}
              onClick={(e) => {
                e.preventDefault();
                setisSortAscPostTitle(!isSortAscPostTitle);
                // // setisSortAsc(null)
                setisSortArchiveDate(null);
                setisSortAscPostedDate(null);
                setisSortAscPublishDate(null);
                setisSortAscStatus(null);
                setisSortAscPublishedTo(null);
                setisSortAscTopics(null);
              }}
            ></em>
          </>
        ),
        dataIndex: "",
        key: "title",
        width:"200px",
        render: (record, index) => (
          <div className="d-flex align-items-center">
            {topicUuid.includes(record.map_id) ? (
              <span
                className="icon-24 cursor-pointer me-16"
                id={record.map_id}
                onClick={(e) => {
                  if (expandAll) {
                    setOnExpandAll(false);
                    setExpandAll(false);
                  }
                  var index = topicUuid.indexOf(record.map_id);

                  if (index > -1) {
                    const removed = topicUuid.splice(index, 1);
                    setTopicUuid([...topicUuid]);
                  }
                }}
              >
                <em className="icon-chevron-up-outline"></em>
              </span>
            ) : (
              <span
                className="icon-24 cursor-pointer me-16"
                id={record.map_id}
                onClick={(e) => {
                  var index = newTopicUuid.indexOf(record.map_id);
                  setTopicUuid([...topicUuid, record.map_id])
                }}
              >
                <em className="icon-chevron-down-outline"></em>
              </span>
            )}
            <div>
              <Tooltip placement="bottomLeft" title={record.title}>
                <p className="lh-20 max-214 mb-0 d-inline me-4">
                  {topicUuid.includes(record.map_id) ? (
                    <a
                      className="text-172935 cursor-pointer text-underline"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={record.public_url}
                    >
                      {valueFilter !== ""
                        ? utility.getHighlightedText(
                          record?.title,
                          valueFilter
                        )
                        : record?.title}
                    </a>
                  ) : (
                    <a
                      className="text-172935 cursor-pointer text-underline line-clamp-two"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={record.public_url}
                    >
                      {valueFilter !== ""
                        ? utility.getHighlightedText(
                          record?.title,
                          valueFilter
                        )
                        : record?.title}
                    </a>
                  )}
                </p>
              </Tooltip>
              {record.is_featured && (
                <span className="badge badge-warning d-inline">Featured</span>
              )}
            </div>
          </div>
        ),
      }
      : {
        title: (
          <>
            Post Title
            <em
              className={`icon icon-sort-${
                isSortAscPostTitle !== null
                  ? isSortAscPostTitle
                    ? "ascending-outline text-success"
                    : "descending-outline text-success"
                  : "descending-outline"
              }  ms-8 cursor-pointer`}
              onClick={(e) => {
                e.preventDefault();
                setisSortAscPostTitle(!isSortAscPostTitle);
                setisSortArchiveDate(null);
                setisSortAscPostedDate(null);
                setisSortAscPublishDate(null);
                setisSortAscStatus(null);
                setisSortAscPublishedTo(null);
                setisSortAscTopics(null);
              }}
            ></em>
          </>
        ),
        dataIndex: "",
        key: "title",
        width:'130px',
        render: (record) => (
          <div>
            <Tooltip placement="bottomLeft" title={record.title}>
              <p className="lh-20 max-214 mb-0 d-inline me-4">
                {topicUuid.includes(record.map_id) ? (
                  <a
                    className="text-172935 cursor-pointer text-underline"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={record.public_url}
                  >
                    {valueFilter !== ""
                      ? utility.getHighlightedText(record?.title, valueFilter)
                      : record?.title}
                  </a>
                ) : (
                  <a
                    className="text-172935 cursor-pointer text-underline line-clamp-two"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={record.public_url}
                  >
                    {valueFilter !== ""
                      ? utility.getHighlightedText(record?.title, valueFilter)
                      : record?.title}
                  </a>
                )}
              </p>
            </Tooltip>
            {record.is_featured && (
              <span className="badge badge-warning d-inline">Featured</span>
            )}
          </div>
        ),
      },
    {
      title: (
        <>
          Topics
          <em
            className={`icon icon-sort-${
              isSortAscTopics !== null
                ? isSortAscTopics
                  ? "ascending-outline text-success"
                  : "descending-outline text-success"
                : "descending-outline"
            }  ms-8 cursor-pointer`}
            onClick={(e) => {
              e.preventDefault();
              setisSortAscTopics(!isSortAscTopics);
              setisSortArchiveDate(null);
              setisSortAscPostedDate(null);
              setisSortAscPublishDate(null);
              setisSortAscPostTitle(null);
              setisSortAscPublishedTo(null);
              setisSortAscStatus(null);
            }}
          ></em>
        </>
      ),
      dataIndex: "",
      key: "topic",
      width:"130px",
      render: (record, index) => (
        <div className="d-flex align-items-start">
          <div className="max-w-176">
            <Tooltip
              key={topic.uuid}
              color="#284254"
              placement="bottomLeft"
              title={utility.decodeEntity(
                record.topic[0]?.name === undefined ? "" : record.topic[0]?.name
              )}
            >
              {record.topic.length > 0 ? (
                <span className="badge badge-outline-secondary lh-26 fs-16 radius-24 d-inline-block text-start" >
                  {utility.ellipsify(
                    utility.decodeEntity(
                      record.topic[0]?.name === undefined
                        ? ""
                        : record.topic[0]?.name
                    ),
                    20
                  )}
                </span>
              ) : (
                <span className="badge badge-outline-secondary lh-26 fs-16 radius-24 d-inline-block text-start">
                  {utility.ellipsify(
                    utility.decodeEntity(
                      record.topic[0]?.name === undefined
                        ? ""
                        : record.topic[0]?.name
                    ),
                    20
                  )}
                </span>
              )}
            </Tooltip>
            {topicUuid.includes(record.map_id) &&
              record.topic.map(
                (topic, index) =>
                  index > 0 && (
                    <Tooltip
                      key={topic.uuid}
                      color="#284254"
                      placement="bottomLeft"
                      title={utility.decodeEntity(topic.name)}
                    >
                      <span className="badge badge-outline-secondary lh-26 fs-16 radius-24 d-inline-block text-start mt-4">
                        {utility.ellipsify(
                          utility.decodeEntity(topic.name),
                          20
                        )}
                      </span>
                    </Tooltip>
                  )
              )}
          </div>
          {record.topic.length > 1 && !topicUuid.includes(record.map_id) && (
            <a className="cursor-pointer ms-8">+{record.topic.length - 1}</a>
          )}
        </div>
      ),
    },
    {
      title: (
        <>
          Status
          <em
            className={`icon icon-sort-${
              isSortAscStatus !== null
                ? isSortAscStatus
                  ? "ascending-outline text-success"
                  : "descending-outline text-success"
                : "descending-outline"
            }  ms-8 cursor-pointer`}
            onClick={(e) => {
              e.preventDefault();
              setisSortAscStatus(!isSortAscStatus);
              setisSortArchiveDate(null);
              setisSortAscPostedDate(null);
              setisSortAscPublishDate(null);
              setisSortAscPostTitle(null);
              setisSortAscPublishedTo(null);
              setisSortAscTopics(null);
            }}
          ></em>
        </>
      ),
      dataIndex: "status",
      key: "status",
      width:"100px",
      render: (record) => (
        <>
          {(record === "Ready To Publish" || record === "ready_to_publish") && (
            <span className="badge badge-outline-secondary lh-26 fs-16 radius-24">
              <em className="icon-clock-filled me-4 fs-12"></em>
              Ready To Publish
            </span>
          )}
          {record === "Published" && (
            <span className="badge badge-outline-success lh-26 fs-16 radius-24">
              <em className="icon icon-check-circle-filled text-success me-4 fs-12"></em>
              {record}
            </span>
          )}
          {record === "Archived" && (
            <span className="badge badge-outline-secondary lh-26 fs-16 radius-24">
              <em className="icon-x-circle-filled me-4 fs-12"></em>
              {record}
            </span>
          )}
        </>
      ),
    },
    {
      title: (
        <div className={userDetails?.admin_type === "firm_admin"?"d-flex justify-content-end":""}>
          Posted To CDC
          <em
            className={`icon icon-sort-${
              isSortAscPostedDate !== null
                ? isSortAscPostedDate
                  ? "ascending-outline text-success"
                  : "descending-outline text-success"
                : "descending-outline"
            }  ms-8 cursor-pointer`}
            onClick={(e) => {
              e.preventDefault();
              setisSortAscPostedDate(!isSortAscPostedDate);
              setisSortArchiveDate(null);
              setisSortAscPublishDate(null);
              setisSortAscPostTitle(null);
              setisSortAscStatus(null);
              setisSortAscPublishedTo(null);
              setisSortAscTopics(null);
            }}
          ></em>
        </div>
      ),
      dataIndex: "created_at",
      key: "created_at",
      className: "text-end",
      width:"100px",
      render: (record) => (
        <span className="d-block lh-20 text-end">{record}</span>
      ),
    },
    {
      title: (
        <div className={userDetails?.admin_type === "firm_admin"?"d-flex justify-content-end":""}>
          Published Date
          <em
            className={`icon icon-sort-${
              isSortAscPublishDate !== null
                ? isSortAscPublishDate
                  ? "ascending-outline text-success"
                  : "descending-outline text-success"
                : "descending-outline"
            }  ms-8 cursor-pointer`}
            onClick={(e) => {
              e.preventDefault();
              setisSortAscPublishDate(!isSortAscPublishDate);
              setisSortArchiveDate(null);
              setisSortAscPostedDate(null);
              setisSortAscStatus(null);
              setisSortAscPostTitle(null);
              setisSortAscPublishedTo(null);
              setisSortAscTopics(null);
            }}
          ></em>
        </div>
      ),
      dataIndex: "publish_date",
      key: "publish_date",
      className: "text-end",
      width:"100px",
      render: (record, today = new Date()) => (
        <span className="d-block lh-20 text-end">
          {record !== "" && record !== "Invalid date" ? record : "-"}
        </span>
      ),
    },
    {
      title: (
        <div className={userDetails?.admin_type === "firm_admin"?"d-flex justify-content-end":""}>
          Archive Date
          <em
            className={`icon icon-sort-${
              isSortArchiveDate !== null
                ? isSortArchiveDate
                  ? "ascending-outline text-success"
                  : "descending-outline text-success"
                : "descending-outline"
            }  ms-8 cursor-pointer`}
            onClick={(e) => {
              e.preventDefault();
              setisSortArchiveDate(!isSortArchiveDate);
              setisSortAscPublishDate(null);
              setisSortAscPostedDate(null);
              setisSortAscStatus(null);
              setisSortAscPostTitle(null);
              setisSortAscPublishedTo(null);
              setisSortAscTopics(null);
            }}
          ></em>
        </div>
      ),
      dataIndex: "archive_date",
      key: "archive_date",
      className: "text-end",
      width:"100px",
      render: (record, today = new Date()) => (
        <span className="d-block lh-20 text-end">
          {record !== "" && record !== "Invalid date" ? record : "-"}
        </span>
      ),
    },
    {
      title: (
        <div className={userDetails?.admin_type === "firm_admin"?"d-flex justify-content-end":""}>
          Published To
          <em
            className={`icon icon-sort-${
              isSortAscPublishedTo !== null
                ? isSortAscPublishedTo
                  ? "ascending-outline text-success"
                  : "descending-outline text-success"
                : "descending-outline"
            }  ms-8 cursor-pointer`}
            onClick={(e) => {
              e.preventDefault();
              setisSortAscPublishedTo(!isSortAscPublishedTo);
              setisSortArchiveDate(null);
              setisSortAscPostedDate(null);
              setisSortAscPublishDate(null);
              setisSortAscStatus(null);
              setisSortAscPostTitle(null);
              setisSortAscTopics(null);
            }}
          ></em>
        </div>
      ),
      dataIndex: "publish_to",
      key: "publish_to",
      className: "text-end",
      width:"100px",
      render: (record) => (
        <>
          <span className="d-block lh-20 text-end">{record}</span>
        </>
      ),
    },
    userDetails?.user_type === "advisors" && userDetails?.admin_type !== "none"
      ? {
        title: "Actions",
        dataIndex: "",
        key: "x",
        className: "text-end",
        width:"80px",
        render: (record) =>
          record.status === "Ready To Publish" ||
          record.status === "ready_to_publish" ? (
            <Dropdown
              placement="bottomRight"
              onVisibleChange={(visible) => {
                handleMenuDisplay(visible === true ? record.map_id : "");
              }}
              visible={menuDisplay === record.map_id}
              trigger={["click"]}
              overlay={
                record.status === "Archived"
                  ? menu(record, "Republish")
                  : menufeatured(record,"Publish")
              }
            >
              <button
                type="button"
                className="btn btn-outline-secondary btn-icon-only ms-auto"
              >
                <em className="icon-dots-horizontal-outline"></em>
              </button>
            </Dropdown>
          ) : (
            <Dropdown
              placement="bottomRight"
              onVisibleChange={(visible) => {
                handleMenuDisplay(visible === true ? record.map_id : "");
              }}
              visible={menuDisplay === record.map_id}
              trigger={["click"]}
              overlay={
                record.status === "Archived"
                  ? menu(record, "Republish")
                  : menufeatured(record)
              }
            >
              <button
                type="button"
                className="btn btn-outline-secondary btn-icon-only ms-auto"
              >
                <em className="icon-dots-horizontal-outline"></em>
              </button>
            </Dropdown>
          ),
      }
      : {
        title: "hide",
        hidden: true,
      },
  ].filter((item) => !item.hidden);


  const Notifyupdate = (type, mesage, icon) => {
    notification[type]({
      message: mesage,
      icon: icon,
    });
  };

  function resettopicteamdropdown() {
    const newState = topic.map((obj) => {
      if (obj.isSelected === true) {
        return { ...obj, isSelected: false };
      }
      return obj;
    });
    setTopic(newState);

    const teamState = teamListData.map((obj) => {
      if (obj.isSelected === true) {
        return { ...obj, isSelected: false };
      }
      return obj;
    });
    setTeamListData(teamState);
  }


  function updatestatus(
    article_uuid,
    map_id,
    publish_type,
    team_id,
    is_article_publish
  ) {
    var today = new Date();
    payload = {
      status: "Published",
      article_id: article_uuid,
      is_publish: publish_type,
      team_id: team_id,
      is_article_publish: is_article_publish,
      publish_date: moment(today).format("YYYY-MM-DD HH:MM:S"),
      archive_after:isArchievedArticle?parseInt(numberofDays):null
    }
    const client_ids = dropDownClient && dropDownClient?.map(({ uuid }) => uuid);
    if (dropDownClient.length !== 0) {
      var payload = {...payload,
        client_ids: client_ids,
        is_featured: false,
      };
    } else {
      payload = {
        ...payload,
        is_featured: isFeatured,
      };
    }
    dispatch(dashboard.updatestatus(`article/update`, payload)).then(
      (response) => {
        setLoader(false);
        if (response.type === "GET_PUBLISH_UPDATE_SUCCESS") {
          setShowPublishModal(false);
          if(showRemoved){
            filterapply({ uuid: "Archived", label: "Status:" + "Archived", type: 4 });
            if (!filterStatus.includes("Archived")){
              setFilterStatus([...filterStatus, "Archived"])
            }
          }
          else{
            onShowArticleFalse();
            // getArticle(`article?from_cdc=1&status=Ready To Publish,Published`);
          }
          setShowWarning(false);
          setIsFeatured(false);
          setNumberofDays(90);
          setIsArchievedArticle(true);
          Notifyupdate(
            "success",
            response.data.message,
            <em className="icon-check-circle-filled"></em>
          );
        } else {
          console.log("error");
        }
      }
    );
  }


  function removeArticle(article_uuid, map_id, team_id) {
    let payload = {
      status: "Archived",
      article_id: article_uuid,
      team_id: team_id,
    };
    dispatch(dashboard.updatestatus(`article/update`, payload)).then(
      (response) => {
        setLoader(false);
        if (response.type === "GET_PUBLISH_UPDATE_SUCCESS") {
          filterapply()
          handleRemoveArticleClose();
          Notifyupdate(
            "success",
            response.data.message,
            <em className="icon-check-circle-filled"></em>
          );
        } else {
          console.log("error");
        }
      }
    );
  }

  function onChangeToggle(checked) {
    setShowRangeCalender(checked);
    if (checked === true) {
      setIsChecked(true);
      setSelectedDaySingle("");
    } else {
      setIsChecked(false);
      setSelectedDayRange({
        from: null,
        to: null,
      });
    }
  }

  function onChangePublishedDateToggle(checked) {
    setShowRangePublishedDateCalender(checked);
    if (checked === true) {
      setIsPublishedDateChecked(true);
      setSelectedDayPublishedDateSingle("");
    } else {
      setIsPublishedDateChecked(false);
      setSelectedDayPublishedDateRange({
        from: null,
        to: null,
      });
    }
  }

  function resetandclosefilter(reset) {
    if (!reset) {
      setFilterContentShow(false);
    }
    setRChecked(true);
    setRtpChecked(true);
    setPChecked(true);
    setFilterStatus(["Published", "Ready To Publish"]);
    setSelected([]);
    setcurrentDateText("");
    setcurrentDatePublishedDateText("");
    setSelectedDaySingle("");
    setSelectedDayRange({
      from: null,
      to: null,
    });
    setDropDownTeam([]);
    setSelectedTeam([]);
    setDropDownTopic([]);
    setSelectedDayPublishedDateSingle("");
    setSelectedDayPublishedDateRange({
      from: null,
      to: null,
    });
    setshowCalenderFilter(false);
    setshowCalenderPublishedDateFilter(false);
    setDropdownShown(false);
    resettopicteamdropdown();
    document
      .getElementById("posted-date-calendar")
      .classList.remove("text-success");
    document
      .getElementById("published-date-calendar")
      .classList.remove("text-success");
  }

  function handleFilter(is_checked, value) {
    if (is_checked) {
      if (!filterStatus.includes(value))
        setFilterStatus([...filterStatus, value]);
    } else {
      var index = filterStatus.indexOf(value);
      if (index > -1) {
        const removed = filterStatus.splice(index, 1);
        setFilterStatus([...filterStatus]);
      }
    }
  }

  function handleSelectedDaySingle(value) {
    setSelectedDaySingle({
      year: parseInt(value.year),
      month: parseInt(("0" + value.month).slice(-2)),
      day: parseInt(("0" + value.day).slice(-2)),
    });
    setcurrentDateText(
      moment(value.month + "/" + value.day + "/" + value.year).format(
        "MMM DD, yyyy"
      )
    );
    setshowCalenderFilter(!showCalenderFilter);
    document.getElementById("posted-date-calendar").className +=
      " text-success";
  }

  function handleSelectedDayPublishedDateSingle(value) {
    setSelectedDayPublishedDateSingle({
      year: parseInt(value.year),
      month: parseInt(("0" + value.month).slice(-2)),
      day: parseInt(("0" + value.day).slice(-2)),
    });
    setcurrentDatePublishedDateText(
      moment(value.month + "/" + value.day + "/" + value.year).format(
        "MMM DD, yyyy"
      )
    );
    setshowCalenderPublishedDateFilter(!showCalenderPublishedDateFilter);
  }

  useEffect(() => {
    if (selectedDayRange.to !== null) {
      setcurrentDateText(
        moment(
          selectedDayRange.from.month +
            "/" +
            selectedDayRange.from.day +
            "/" +
            selectedDayRange.from.year
        ).format("MMM DD, yyyy") +
          " - " +
          moment(
            selectedDayRange.to.month +
              "/" +
              selectedDayRange.to.day +
              "/" +
              selectedDayRange.to.year
          ).format("MMM DD, yyyy")
      );
      setshowCalenderFilter(!showCalenderFilter);
    }
  }, [selectedDayRange]);

  useEffect(() => {
    if (selectedDayPublishedDateRange.to !== null) {
      setcurrentDatePublishedDateText(
        moment(
          selectedDayPublishedDateRange.from.month +
            "/" +
            selectedDayPublishedDateRange.from.day +
            "/" +
            selectedDayPublishedDateRange.from.year
        ).format("MMM DD, yyyy") +
          " - " +
          moment(
            selectedDayPublishedDateRange.to.month +
              "/" +
              selectedDayPublishedDateRange.to.day +
              "/" +
              selectedDayPublishedDateRange.to.year
          ).format("MMM DD, yyyy")
      );
      setshowCalenderPublishedDateFilter(!showCalenderPublishedDateFilter);
    }
  }, [selectedDayPublishedDateRange]);

  if (articlesDataList?.length > 0) {
    document.querySelector(".ant-table-body").onscroll = function () {
      onScroll(event);
    };
  }

  const onScroll = (e) => {
    handleMenuDisplay("");
    const bottom =
      parseInt(
        e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight
      ) < 2;
    if (bottom === true && next) {
      dispatch(dashboard.getArticles(next)).then((response) => {
        if (response?.type === "GET_ARTICLE_SUCCESS") {
          const full_article_array = articlesDataList.concat(
            response.data.results
          );
          const names = new Set();
          if (userDetails?.admin_type === "firm_admin") {
            var newState = full_article_array.map((obj) => {
              // if (names.has(value)) {
              //   obj.props.rowSpan = 0;
              // } else {
              // if (names.has(value)) {
              const occurCount = full_article_array.filter(
                (data) => data.team_id === obj.team_id
              ).length;
              if (occurCount > 1 && names.has(obj.team_id)) {
                return { ...obj, team_name: "" };
              }
              names.add(obj.team_id);
              return obj;
            });
          } else {
            newState = full_article_array;
          }
          setArticlesDataList([...newState]);
          if (expandAll) {
            const map_data = full_article_array?.map((filter) => {
              return filter.map_id;
            });
            setTopicUuid(map_data);
          }
          // setFeaturedData({ "title": filtered[0]?.title });
          setLoader(false);
          setFilterContentShow(false);
          setNext(response.data.next);
        } else {
          setLoader(false);
        }
      });
    }
  };

  setTimeout(() => {
    if (articlesDataList?.length !== 0) {
      for (let i = 0; i <= articlesDataList?.length; i++) {
        try {
          var span = document.getElementById(articlesDataList[i]["map_id"]);
        } catch (error) {
          console.log("data loading");
        }
        if (span?.childNodes?.length === 0) {
          span?.parentElement.parentElement.classList.add("border-0");
        }
        else{
          span?.parentElement.parentElement.classList.remove("border-0");
        }
      }
    }
  }, 100);

  useEffect(() => {
    getTopic();
    getTeam();
  }, []);

  // useEffect to call topic , article and bookmark apis
  useEffect(() => {
    if (filterData){
      filterapply();
    }
    else{
      getArticle(`article?from_cdc=1&status=Ready To Publish,Published`);
    }
  }, [
    isSortAsc,
    isSortAscPublishDate,
    isSortAscPostedDate,
    isSortAscStatus,
    isSortAscPostTitle,
    isSortAscPublishedTo,
    isSortAscTopics,
    isSortArchiveDate,
  ]);

  //  function to get list of Articles
  function getArticle(article_url) {
    setLoader(true);
    let querryParameters = "";
    if (isSortAsc !== null) {
      querryParameters +=
        "&team_order_by=" +
        (isSortAsc ? "" : "-") +
        "article_team_map__client_team__name";
    }
    if (isSortAscPublishDate !== null) {
      querryParameters +=
        "&order_by=" +
        (isSortAscPublishDate ? "" : "-") +
        "article_team_map__publish_date";
    } else if (isSortAscPostedDate !== null) {
      querryParameters +=
        "&order_by=" + (isSortAscPostedDate ? "" : "-") + "created_at";
    } else if (isSortAscPostTitle !== null) {
      querryParameters +=
        "&order_by=" + (isSortAscPostTitle ? "" : "-") + "title";
    } else if (isSortAscStatus !== null) {
      querryParameters +=
        "&order_by=" +
        (isSortAscStatus ? "" : "-") +
        "article_team_map__status";
    } else if (isSortAscPublishedTo !== null) {
      querryParameters +=
        "&order_by=" +
        (isSortAscPublishedTo ? "" : "-") +
        "article_team_map__publish_to";
    }else if (isSortArchiveDate !== null) {
      querryParameters +=
        "&order_by=" +
        (isSortArchiveDate ? "" : "-") +
        "article_team_map__archive_date";
    } else if (isSortAscTopics !== null) {
      querryParameters +=
        "&order_by=" + (isSortAscTopics ? "" : "-") + "topic__name";
    }
    dispatch(
      dashboard.getArticles(
        article_url
          ? article_url + querryParameters
          : "article?from_cdc=1" + querryParameters
      )
    ).then((response) => {
      if (response.type === "GET_ARTICLE_SUCCESS") {
        setNext(response.data.next);
        if (userDetails?.admin_type === "firm_admin") {
          var newState = response.data.results.map((obj) => {
            const occurCount = response.data.results.filter(
              (data) => data.team_id === obj.team_id
            ).length;
            if (occurCount > 1 && names.has(obj.team_id)) {
              return { ...obj, team_name: "" };
            }
            names.add(obj.team_id);
            return obj;
          });
        } else {
          newState = response.data.results;
        }
        setArticlesDataList(newState);
        if (expandAll) {
          const map_data = response.data.results?.map((filter) => {
            return filter.map_id;
          });
          setTopicUuid(map_data);
        }
        setLoader(false);
        setFilterContentShow(false);
      } else {
        setLoader(false);
      }
    });
  }

  function getTopic() {
    dispatch(dashboard.getTopics("topic?from_cdc=1")).then((response) => {
      if (response.type === "GET_TOPIC_SUCCESS") {
        setTopic(response.data);
      }
    });
  }

  function getTeam() {
    dispatch(dashboard.getTeams("team")).then((response) => {
      if (response.type === "GET_TEAM_SUCCESS") {
        response.data?.map((obj)=>{
          if (
            obj?.display_name
          ) {
            return { ...obj, name: obj?.display_name };
          }
          return obj;
        })
        setTeamListData(response.data);
      }
    });
  }


  function checkisExist(arr,status){
    var IsExists = false;
    for(var i = 0; i < arr.length; i++)
    {    
      if( arr[i].uuid === status )
      {   
        IsExists = true; 
        break;        
      }    
    } 
    return IsExists 
  }

  function filterapply(status=null) {
    var filterarr = [];
    var publish_date = "";
    var posted_cdc = "";
    var topic_data = "";
    var team_data = "";
    var article_url
    {
      selected?.map((select) => {
        filterarr.push({
          uuid: select.value,
          label: "Topic: " + select.label,
          type: 3,
        });
        if (topic_data === "") {
          topic_data = select.value;
        } else {
          topic_data += "," + select.value;
        }
      });
    }
    {
      selectedTeam?.map((select) => {
        filterarr.push({
          uuid: select.value,
          label: "Team: " + select.label,
          type: 8,
        });
        if (team_data === "") {
          team_data = select.value;
        } else {
          team_data += "," + select.value;
        }
      });
    }
    let Statusvalue = filterStatus
    if (status==="Archived"){
      Statusvalue = filterStatus.filter(item => item !== "Archived")
      filterStatus?.map((select, index) => {
        filterarr.push({ uuid: select, label: "Status:" + select, type: 4 });
      });
    }
    else if (status){
      const IsExists = checkisExist(filterarr,"Archived")  

      if (!IsExists){
        filterarr.push(status)
      }
      if (!filterStatus.includes("Archived")){
        Statusvalue = `Archived`
      }
    }
    else{
      filterStatus?.map((select, index) => {
        filterarr.push({ uuid: select, label: "Status:" + select, type: 4 });
      });
    }
    if (selectedDayRange.from !== null) {
      posted_cdc =
        selectedDayRange.from.year +
        "-" +
        selectedDayRange.from.month +
        "-" +
        selectedDayRange.from.day +
        " 00:00:00 , " +
        selectedDayRange.to.year +
        "-" +
        selectedDayRange.to.month +
        "-" +
        selectedDayRange.to.day +
        " 23:59:00";
      filterarr.push({
        uuid: "1",
        label: "Posted to CDC: " + currentDateText,
        type: "range",
        for_request: posted_cdc,
      });
    } else if (selectedDaySingle) {
      posted_cdc =
        moment(currentDateText).format("YYYY-MM-DD 00:0:00") +
        "," +
        moment(currentDateText).format("YYYY-MM-DD 23:59:00");
      filterarr.push({
        uuid: "1",
        label: "Posted to CDC: " + currentDateText,
        type: "single",
        for_request: posted_cdc,
      });
    }
    if (selectedDayPublishedDateRange.from !== null) {
      publish_date =
        selectedDayPublishedDateRange.from.year +
        "-" +
        selectedDayPublishedDateRange.from.month +
        "-" +
        selectedDayPublishedDateRange.from.day +
        " 00:00:00 ," +
        selectedDayPublishedDateRange.to.year +
        "-" +
        selectedDayPublishedDateRange.to.month +
        "-" +
        selectedDayPublishedDateRange.to.day +
        " 23:59:00";
      filterarr.push({
        uuid: "2",
        label: "Published Date: " + currentDatePublishedDateText,
        type: "range",
        for_request: publish_date,
      });
    }
    if (selectedDayPublishedDateSingle) {
      publish_date =
        moment(currentDatePublishedDateText).format("YYYY-MM-DD 00:00:00 , ") +
        moment(currentDatePublishedDateText).format("YYYY-MM-DD 23:59:00");
      filterarr.push({
        uuid: "2",
        label: "Published Date: " + currentDatePublishedDateText,
        type: "single",
        for_request: publish_date,
      });
    }
    setfilterData(filterarr);
    article_url =
    "article?from_cdc=1&status=" +
    Statusvalue +
    "&topic__uuid__in=" +
    topic_data +
    "&team_ids=" +
    team_data +
    "&publish_date=" +
    publish_date +
    "&created_at__range=" +
    posted_cdc;
    
    getArticle(article_url);
    setArticleUrl(article_url);
  }

  function removefilter(uuid, type) {
    var topic_list = "";
    var team_list = "";
    var publish_date = "";
    var created_at = "";
    var status = "";
    const filtered = filterData.filter((filter) => {
      return filter.uuid !== uuid;
    });
    if (type === 3) {
      const newState = topic.map((obj) => {
        if (obj.uuid === uuid) {
          return { ...obj, isSelected: false };
        }
        return obj;
      });
      setTopic(newState);
      setDropDownTopic({
        newState,
      });
      if (dropDownTopic) {
        const newRelatedfilteredvalues = dropDownTopic?.filter((filter) => {
          return filter.uuid !== uuid;
        });
        setDropDownTopic(newRelatedfilteredvalues);
      }
      const newselected = selected?.filter((filter) => {
        return filter.value !== uuid;
      });
      setSelected(newselected);
    }
    if (type === 8) {
      const newState = teamListData.map((obj) => {
        if (obj.id === uuid) {
          return { ...obj, isSelected: false };
        }
        return obj;
      });
      setTeamListData(newState);
      setDropDownTeam({
        newState,
      });
      if (dropDownTeam) {
        const newRelatedfilteredvalues = dropDownTeam?.filter((filter) => {
          return filter.uuid !== uuid;
        });
        setDropDownTeam(newRelatedfilteredvalues);
      }
      const newselected = selectedTeam?.filter((filter) => {
        return filter.value !== uuid;
      });
      setSelectedTeam(newselected);
    } else if (uuid === "1") {
      setcurrentDateText("");
      setSelectedDayRange({
        from: null,
        to: null,
      });
      setSelectedDaySingle("");
      setIsChecked(false);
      setShowRangeCalender(false);
    } else if (uuid === "2") {
      setcurrentDatePublishedDateText("");
      setSelectedDayPublishedDateRange({
        from: null,
        to: null,
      });
      setSelectedDayPublishedDateSingle("");
      setIsPublishedDateChecked(false);
      setshowCalenderPublishedDateFilter(false);
    } else if (type === 4 && filterData.length > 1) {
      const new_status_list = filterStatus.filter((value) => {
        return value !== uuid;
      });
      setFilterStatus(new_status_list);
      if (uuid === "Archived") {
        setRChecked(false);
      } else if (uuid === "Published") {
        setPChecked(false);
      } else {
        setRtpChecked(false);
      }
    } else if (filterData.length === 1) {
      setFilterStatus(["Published", "Ready To Publish"]);
      setRChecked(true);
      setPChecked(true);
      setRtpChecked(true);
    }
    setfilterData(filtered);
    filtered.map((data) => {
      if (data.type === 3) {
        topic_list === ""
          ? (topic_list = data.uuid)
          : (topic_list += "," + data.uuid);
      }
      if (data.type === 8) {
        team_list === ""
          ? (team_list = data.uuid)
          : (team_list += "," + data.uuid);
      } else if (data.type === 4) {
        status === "" ? (status = data.uuid) : (status += "," + data.uuid);
      } else if (data.uuid === "1") {
        created_at = data.for_request;
      } else if (data.uuid === "2") {
        publish_date = data.for_request;
      }
    });
    const article_url =
      "article?from_cdc=1&topic__uuid__in=" +
      topic_list +
      "&status=" +
      status +
      "&team_ids=" +
      team_list +
      "&publish_date=" +
      publish_date +
      "&created_at__range=" +
      created_at;
    getArticle(article_url);
    setArticleUrl(article_url);
    // setFilterStatus([]);
  }

  const onChangeCollapse = (checked) => {
    if (checked) {
      setExpandAll(true);
      const map_data = articlesDataList?.map((filter) => {
        return filter.map_id;
      });
      setTopicUuid(map_data);
      setOnExpandAll(true);
    } else {
      setExpandAll(false);
      setTopicUuid([]);
      setOnExpandAll(false);
    }
  };

  function articleclientdata(article_id, published_to, team_id) {
    dispatch(dashboard.getArticleClientList(article_id, team_id)).then(
      (response) => {
        if (response.type === "GET_ARTICLECLIENTLIST_SUCCESS") {
          setClientData(response.data["data"]);
          setPrevClientData(response.data["data"]);
          if (response.data["article_title"] !== "") {
            setFeaturedData({ title: response.data["article_title"] });
          }
          if (published_to === "Specific") {
            const selected_client_data = response.data["data"]
              .filter((obj) => obj.is_selected === true)
              .map((filteeddata) => {
                return {
                  name:
                    (filteeddata?.last_name !== null
                      ? filteeddata?.last_name + ", "
                      : "") +
                    " " +
                    filteeddata?.first_name,
                  image: filteeddata?.profile_image,
                  uuid: filteeddata?.user_uuid,
                };
              });

            const selected_data = response.data["data"]
              .filter((obj) => obj.is_selected === true)
              .map((filteeddata) => {
                return {
                  value: filteeddata?.user_uuid,
                  label:
                    (filteeddata?.last_name !== null
                      ? filteeddata?.last_name + ", "
                      : "") +
                    " " +
                    filteeddata?.first_name,
                };
              });
            setAlreadySelectedLength(selected_client_data.length);
            setDropDownClient(selected_client_data);
            setClientSelected(selected_data);
          } else {
            setDropDownClient([]);
            setClientSelected([]);
            setAlreadySelectedLength(0);
          }
        }
      }
    );
  }

  useEffect(() => {
    if (dropDownClient.length > 2) {
      if (removed !== "") {
        var index = dropDownClientnew.indexOf(removed);
        if (index > -1) {
          //Make sure item is present in the array, without if condition, -n indexes will be considered from the end of the array.
          const removed_val = dropDownClientnew.splice(index, 1);
          const last_value = dropDownClientnew.splice(-1, 1);
          setDropDownClientnew([
            ...dropDownClient.slice(0, 2),
            " +" + (last_value - 1),
          ]);
        } else {
          const length = dropDownClient.length;
          const first_to_elemenet = dropDownClient.slice(0, 2);
          const val = " +" + (length - 2);
          setDropDownClientnew([...first_to_elemenet, val]);
        }
        setRemoved("");
      } else {
        const length = dropDownClient.length;
        const first_to_elemenet = dropDownClient.slice(0, 2);
        const val = " +" + (length - 2);
        setDropDownClientnew([...first_to_elemenet, val]);
      }
    } else {
      setDropDownClientnew(dropDownClient);
    }
    if (dropDownClient.length === 0 && currentValue === "2") {
      setShowClientDanger(true);
    }
    // }
  }, [dropDownClient]);

  function resetclientdropdown() {
    const newState = clientData.map((obj) => {
      if (obj.is_selected === true) {
        return { ...obj, is_selected: false };
      }
      return obj;
    });
    setClientData(newState);
  }

  function onShowArticleFalse(){
    filterapply("Archived");
    const filteredArray = filterStatus.filter(item => item !== "Archived")
    const filteredDataArray = filterData.filter(item => item.uuid !== "Archived")
    if(!filteredArray?.includes("Published"))
      filteredArray.push("Published")
    if (!filteredArray?.includes("Ready To Publish"))
      filteredArray.push("Ready To Publish")
    setFilterStatus(filteredArray);
    const IsExistsPublished = checkisExist(filteredDataArray,"Published")  
    if (!IsExistsPublished){
      filteredDataArray.push({ uuid: "Published", label: "status: Published", type: 4 })
    }
    const IsExistsRTP = checkisExist(filteredDataArray,"Ready To Publish")  
    if (!IsExistsRTP){
      filteredDataArray.push({ uuid: "Ready To Publish", label: "status: Ready To Publish", type: 4 })
    }
    setfilterData(filteredDataArray)
    setRtpChecked(true);
    setPChecked(true);
  }

  return (
    <div>
      <div className="heading-wrap">
        <div className="d-flex align-items-center">
          <span className="head-icon me-16">
            <img src={cdc} alt="" />
          </span>
          <h4 className="m-0">Content Distribution Center</h4>
        </div>
      </div>
      <div className="content-area">
        <div className="card">
          <div className="card-header px-0 pb-0">
            <div className="d-lg-flex justify-content-between align-items-start border-bottom pt-8 pb-16 px-24 share-tool">
              <h4 className="mb-0">Marketing Content</h4>

              <div className="search-area search-area-sm message-search-area">
                {/* <input
                  placeholder="Search content..."
                  className="form-control"
                /> */}

                <input
                  placeholder="Search content..."
                  value={valueFilter}
                  className="form-control"
                  onChange={(e) => {
                    const currValue = e.target.value;
                    setValue(currValue);
                    //const filter=filteredData.filter((f)=>f["odata.metadata"].includes("ShareFile.Api.Models.Folder"))
                    if (currValue.length > 0 && articleUrl !== "") {
                      getArticle(articleUrl + "&title__icontains=" + currValue);
                    } else if (currValue.length > 0) {
                      getArticle(
                        "article?from_cdc=1&title__icontains=" + currValue
                      );
                    } else if (currValue.length === 0 && articleUrl !== "") {
                      getArticle(articleUrl);

                      //let data = allSharedFolder.value.filter((f)=>f["odata.metadata"].includes("ShareFile.Api.Models.Folder"))
                    } else {
                      getArticle("article?from_cdc=1");
                    }
                  }}
                />

                <button className="search-btn icon-20">
                  <em className="icon-search-outline"></em>
                </button>
                {valueFilter.length > 0 && (
                  <button className="close-search-btn icon-20">
                    <em
                      className="icon-x-circle-filled"
                      onClick={() => {
                        setValue("");
                        getArticle("article?from_cdc=1");
                      }}
                    ></em>
                  </button>
                )}
              </div>
            </div>
            <div className="d-flex py-8 px-24 border-bottom" id="hidemenu">
              <span className="fw-500 me-16">Expand All Rows</span>
              <Switch onChange={onChangeCollapse} checked={onexpandAll} />
              <span className="fw-500 me-16" style={{marginLeft:'10px'}}>Show Archived Articles</span>
              <input
                className="form-check-input"
                type="checkbox"
                id="removedCheckbox"
                checked={showRemoved}
                onChange={(e) => {
                  if(e?.target?.checked){
                    filterapply({ uuid: "Archived", label: "Status:" + "Archived", type: 4 });
                    if (!filterStatus.includes("Archived")){
                      setFilterStatus(["Archived"])
                      setRtpChecked(false);
                      setPChecked(false);
                    }
                  }
                  else{
                    onShowArticleFalse();
                    // getArticle(`article?from_cdc=1&status=Ready To Publish,Published`);
                  }
                  setShowRemoved(!showRemoved)
                }}
              />
            </div>
          </div>
          <div className="filter-row d-flex justify-content-between py-8 px-24 border-bottom">
            <div className="d-flex">
              <span
                className="filt-by-client d-none text-success fw-500 cursor-pointer d-md-flex align-items-center text-nowrap"
                onClick={(e) => {
                  e.preventDefault();
                  handleFilterContentShow();
                }}
              >
                <span className="icon-20 me-8">
                  <em className="icon-adjustments-outline"></em>
                </span>
                Filter By
              </span>

              <div className="ms-md-16 d-flex align-items-center flex-wrap">
                {filterData?.map((filter, index) => (
                  <span key={filter.uuid} className="filt-badge">
                    {utility.decodeEntity(filter.label)}
                    <em
                      className="icon-x-outline cursor-pointer"
                      onClick={() => (removefilter(filter.uuid, filter.type),
                      filter.uuid?.includes("Archived") && setShowRemoved(false))}
                    ></em>
                  </span>
                ))}
              </div>
            </div>
            <div>
              {filterData.length !== 0 && (
                <a href="" className="fw-500 text-nowrap">
                  Clear All
                </a>
              )}
            </div>
          </div>
          <div className="card-body p-0">
            <div className="custom-table custom-table-cdc">
              {/* Add table here. you can take ref from "ActionRequired.js". find <div className="custom-table table-striped share-table-fav"> */}
              <Table
                locale={{
                  emptyText: (
                    <div className="task-noresult-cdc d-flex align-items-center justify-content-center text-center">
                      <div className="max-258">
                        <span className="task-nores-icon mb-16 mx-auto">
                          <em className="icon-search-outline"></em>
                        </span>
                        <p className="fs-18 lh-24 m-0">No results found</p>
                      </div>
                    </div>
                  ),
                }}
                scroll={{ y: 500 }}
                rowKey="map_id"
                dataSource={
                  articlesDataList?.length > 0 ? articlesDataList : []
                }
                columns={columns}
                pagination={false}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Sort Modal */}
      <Modal
        className="modal-486 modal-full"
        show={showFilterContent}
        onHide={handleFilterContentClose}
      >
        <Modal.Header closeButton className="border-bottom pb-md-24">
          <Modal.Title>Filter Content</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-24">
            {userDetails?.admin_type === "firm_admin" ? (
              <div className="mb-24">
                <label className="form-label">Team Name</label>
                <div className="position-relative">
                  <div
                    id="assgined_to_id"
                    className={"password-field cursor-pointer"}
                    onClick={(e) => {
                      dropdownTeamShown
                        ? setDropdownTeamShown(false)
                        : setDropdownTeamShown(true);
                    }}
                  >
                    {dropDownTeam?.length === 0 ? (
                      <div className="form-control">Any team</div>
                    ) : (
                      <div className="form-control d-flex align-items-center">
                        {dropDownTeam &&
                          dropDownTeam.filter((item,index) => index <= 2).map((filteredItem,index) =>
                            index <= 1 ? (
                              <label key={index} className="ms-8">
                                {utility.ellipsify(filteredItem.name, 20)}
                              </label>
                            ) : (
                              <label key={index} className="text-success ms-8">
                                + {dropDownTeam.length-2}
                              </label>
                            )
                          )}
                      </div>
                    )}

                    <em
                      className={
                        dropdownTeamShown
                          ? "icon-chevron-up-outline"
                          : "icon-chevron-down-outline"
                      }
                      onClick={(e) => {
                        dropdownTeamShown
                          ? setDropdownTeamShown(false)
                          : (setDropdownTeamShown(true),
                          setshowCalenderFilter(false),
                          setshowCalenderPublishedDateFilter(false),
                          setDropdownShown(false));
                      }}
                    ></em>
                  </div>
                  {dropdownTeamShown && (
                    <TeamDropdownDiv
                      teamListData={teamListData}
                      setTeamListData={setTeamListData}
                      setSelectedTeam={setSelectedTeam}
                      selectedTeam={selectedTeam}
                      setDropDownTeam={setDropDownTeam}
                      dropDownTeam={dropDownTeam}
                      setRemovedTeam={setRemovedTeam}
                    />
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}

            <div>
              <label className="text-black fs-14 lh-20 fw-500 mb-16">
                Status
              </label>
              <div className="form-check mb-12">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="rAnyone"
                  checked={rtpChecked}
                  onChange={(e) => {
                    handleFilter(e.target.checked, "Ready To Publish"),
                    setRtpChecked(!rtpChecked);
                  }}
                />
                <label className="form-check-label" htmlFor="rAnyone">
                  Ready to Publish
                </label>
              </div>
              <div className="form-check mb-12">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="rOnlyMe"
                  checked={pChecked}
                  onChange={(e) => {
                    handleFilter(e.target.checked, "Published"),
                      e.target.checked
                        ? document
                          .getElementById("publish-cal")
                          .classList.remove("disabled-div")
                        : !rChecked &&
                          (document.getElementById("publish-cal").className +=
                            " disabled-div"),
                    setPChecked(!pChecked);
                  }}
                />
                <label className="form-check-label" htmlFor="rOnlyMe">
                  Published
                </label>
              </div>
            </div>
          </div>
          <div className="mb-24">
            <label className="form-label">Topics</label>
            <div className="position-relative">
              <div
                id="assgined_to_id"
                className={"password-field cursor-pointer"}
                onClick={(e) => {
                  dropdownShown
                    ? setDropdownShown(false)
                    : setDropdownShown(true);
                }}
              >
                {dropDownTopic?.length === 0 ? (
                  <div className="form-control">Any topics</div>
                ) : (
                  <div className="form-control d-flex align-items-center">
                    {dropDownTopic &&
                      dropDownTopic.filter((item,index) => index <= 2).map((filteredItem,index) =>
                        index <= 1 ? (
                          <label key={index} className="ms-8">
                            {utility.ellipsify(filteredItem.name, 20)}
                          </label>
                        ) : (
                          <label key={index} className="text-success ms-8">
                            + {dropDownTopic.length-2}
                          </label>
                        )
                      )}
                  </div>
                )}

                <em
                  className={
                    dropdownShown
                      ? "icon-chevron-up-outline"
                      : "icon-chevron-down-outline"
                  }
                  onClick={(e) => {
                    dropdownShown
                      ? setDropdownShown(false)
                      : (setDropdownShown(true),
                      setshowCalenderFilter(false),
                      setshowCalenderPublishedDateFilter(false),
                      setDropdownTeamShown(false));
                  }}
                ></em>
              </div>
              {dropdownShown && (
                <TopicDropdownDiv
                  dropdowndata={topic}
                  setTopic={setTopic}
                  setSelected={setSelected}
                  selected={selected}
                  setDropDownTopic={setDropDownTopic}
                  dropDownTopic={dropDownTopic}
                  setRemoved={setRemoved}
                />
              )}
            </div>
          </div>

          <div className="mb-24">
            <label className="form-labe">Posted to CDC</label>
            <div className="password-field">
              <div
                onClick={(e) => {
                  showCalenderFilter
                    ? setshowCalenderFilter(false)
                    : setshowCalenderFilter(true);
                  setshowCalenderPublishedDateFilter(false);
                }}
              >
                <input
                  type="text"
                  className="form-control"
                  placeholder="Select date content was posted to CDC"
                  name="due_date"
                  value={currentDateText}
                />
                {currentDateText ? (
                  <em className="icon-calendar-filled text-success"></em>
                ) : (
                  <em className="icon-calendar-filled"></em>
                )}
              </div>
              {showCalenderFilter && (
                <div className="dropdown-content-calendar">
                  <div className="d-flex align-items-center pb-16 mb-16 border-bottom">
                    <h6 className="m-0">Date Range</h6>
                    <Switch
                      className="ms-8"
                      onChange={onChangeToggle}
                      checked={ischecked}
                    />
                  </div>

                  {showRangeCalender ? (
                    <Calendar
                      value={selectedDayRange}
                      onChange={(e) => setSelectedDayRange(e)}
                      shouldHighlightWeekends
                      minimumDate={selectedDayRange.from}
                      locale={DashboardCalendarLocale}
                      calendarSelectedDayClassName="bg-success text-white"
                    />
                  ) : (
                    <Calendar
                      value={selectedDaySingle}
                      onChange={(e) => {
                        handleSelectedDaySingle(e);
                      }}
                      shouldHighlightWeekends
                      minimumDate={selectedDaySingle}
                      locale={DashboardCalendarLocale}
                      calendarSelectedDayClassName="bg-success text-white"
                    />
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="mb-24">
            <label className="form-labe">Published Date</label>
            <div className="password-field" id="publish-cal">
              <div
                onClick={(e) => {
                  showCalenderPublishedDateFilter
                    ? setshowCalenderPublishedDateFilter(false)
                    : (setshowCalenderPublishedDateFilter(true),
                    setshowCalenderFilter(false));
                }}
              >
                <input
                  type="text"
                  className="form-control"
                  placeholder="Select date content was published"
                  name="due_date"
                  value={currentDatePublishedDateText}
                />
                {currentDatePublishedDateText ? (
                  <em className="icon-calendar-filled text-success"></em>
                ) : (
                  <em className="icon-calendar-filled"></em>
                )}
              </div>
              {showCalenderPublishedDateFilter && (
                <div className="dropdown-content-calendar">
                  <div className="d-flex align-items-center pb-16 mb-16 border-bottom">
                    <h6 className="m-0">Date Range</h6>
                    <Switch
                      className="ms-8"
                      onChange={onChangePublishedDateToggle}
                      checked={isPublishedDatechecked}
                    />
                  </div>
                  {showRangePublishedDateCalender ? (
                    <Calendar
                      value={selectedDayPublishedDateRange}
                      onChange={(e) => setSelectedDayPublishedDateRange(e)}
                      shouldHighlightWeekends
                      minimumDate={selectedDayPublishedDateRange.from}
                      locale={DashboardCalendarLocale}
                      calendarSelectedDayClassName="bg-success text-white adjust-calendar-inside-text"
                    />
                  ) : (
                    <Calendar
                      value={selectedDayPublishedDateSingle}
                      onChange={(e) => {
                        handleSelectedDayPublishedDateSingle(e);
                      }}
                      shouldHighlightWeekends
                      minimumDate={selectedDayPublishedDateSingle}
                      locale={DashboardCalendarLocale}
                      calendarSelectedDayClassName="bg-success text-white adjust-calendar-inside-text"
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-between border-top pt-24 flex-md-row-reverse">
          <div className="d-md-flex align-items-center">
            <button
              className="btn btn-outline-secondary d-none d-md-block"
              onClick={() => setFilterContentShow(false)}
            >
              Cancel
            </button>
            <button
              className="btn btn-success mt-0"
              onClick={(e) => {
                e.preventDefault();
                // setShowRemoved(false);
                filterapply();
              }}
            >
              Apply
            </button>
          </div>
          <div className="text-center pt-16 pt-md-0 text-md-start">
            <a
              href="#"
              className={`fw-500 ${
                dropDownTopic?.length === 0 &&
                dropDownTeam.length === 0 &&
                rtpChecked === true &&
                pChecked === true &&
                rChecked === true
                  ? "text-AAB1B7"
                  : ""
              }`}
              onClick={() => resetandclosefilter(true)}
            >
              Reset
            </a>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        className="modal-486 modal-full"
        show={showPublishModal}
        onHide={handlePublishModalClose}
      >
        <Modal.Header closeButton className="pb-md-24">
          <Modal.Title>{handlePublish.modal_title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-black fs-18 lh-32 mb-20">
            {handlePublish.modal_desc}
          </p>
          <p className="text-black lh-20 fw-500">“{handlePublish.title}”</p>
          {/* <div className="form-check mb-24">
            <input
              className="form-check-input"
              type="checkbox"
              id="featureArticle"
              onChange={(e) => {
                setIsFeatured(e.target.checked), setShowWarning(!showWarning);
              }}
            />
            <label className="form-check-label" htmlFor="featureArticle">
              Publish as a Featured Article
            </label>
          </div> */}
          <label className="text-black fs-14 lh-20 fw-500 mb-16">
            Publish To
          </label>
          <div className="form-check mb-16">
            <input
              className="form-check-input"
              type="radio"
              name="Team_member"
              id="Team_member"
              value="1"
              onChange={(e) => {
                setCurrentValue(e.target.value),
                setIsActive(false),
                setDropDownClient([]),
                setDropDownClientnew([]),
                setClientSelected([]);
                setDropdownClientShown(false);
                // resetclientdropdown();
                setDisableFeaturedCheckbox(false);
                setShowClientDanger(false); // resethouseholddropdown();
              }}
              checked={currentValue === "1"}
            />
            <label className="form-check-label" htmlFor="Anyone">
              All of My Team's Clients
            </label>
            <div className="form-check mb-24">
              <input
                className="form-check-input"
                type="checkbox"
                id="featureArticle"
                disabled={disableFeaturedCheckbox}
                checked={featuredChecked}
                onChange={(e) => {
                  setIsFeatured(e.target.checked),
                  setShowWarning(!showWarning),
                  setFeaturedChecked(e.target.checked);
                }}
              />
              <label className="form-check-label" htmlFor="featureArticle">
                Publish as a Featured Article
              </label>
              <label className="user-email" htmlFor="featureArticle">
                Only available when publishing to all clients
              </label>
            </div>
            {showWarning && featuredData?.title && (
              <div className="alert alert-warning d-block">
                <p className="m-0">
                  The previously featured article{" "}
                  <span className="fw-500">“{featuredData?.title}”</span> will
                  be moved to the chronological blog feed.
                </p>
              </div>
            )}
          </div>
          <div className="form-check mb-16">
            <input
              className="form-check-input"
              type="radio"
              name="SelectedMember"
              id="SelectedMember"
              value="2"
              checked={currentValue === "2"}
              onChange={(e) => {
                setCurrentValue(e.target.value),
                setIsActive(true),
                setDisableFeaturedCheckbox(true);
                setShowClientDanger(true);
                setFeaturedChecked(false);
                setIsFeatured(false), setAllSelect("");
                setShowWarning(false);
                setIsFeatured(false);

                // const newState = clientData.map(obj => {
                //   if (obj.is_selected === true) {
                //     return {...obj, is_selected: false};
                //   }
                //   return obj;
                // })

                const selected_dd_client_data = prevClientData
                  .filter((obj) => obj.is_selected === true)
                  .map((filteeddata) => {
                    return {
                      name:
                        (filteeddata?.last_name !== null
                          ? filteeddata?.last_name + ", "
                          : "") +
                        " " +
                        filteeddata?.first_name,
                      image: filteeddata?.profile_image,
                      uuid: filteeddata?.user_uuid,
                    };
                  });
                const selected_client_data = prevClientData
                  .filter((obj) => obj.is_selected === true)
                  .map((filteeddata) => {
                    return {
                      value: filteeddata?.user_uuid,
                      label:
                        (filteeddata?.last_name !== null
                          ? filteeddata?.last_name + ", "
                          : "") +
                        " " +
                        filteeddata?.first_name,
                    };
                  });

                const newState = prevClientData.map((obj) => {
                  return obj;
                });
                setClientData(newState);

                setDropDownClient(selected_dd_client_data);
                setClientSelected(selected_client_data);
                setFromAllTeam(true);
              }}
            />
            <label
              className="form-check-label fs-16 lh-20 fw-normal mb-16"
              htmlFor="SelectedMember"
            >
              Specific Clients
            </label>
            <div className="position-relative">
              <div
                id="assgined_to_id"
                className={
                  isActive
                    ? "dropdown-img cursor-pointer"
                    : "dropdown-img cursor-pointer disabled-div"
                }
                onClick={(e) => {
                  dropdownClientShown
                    ? setDropdownClientShown(false)
                    : setDropdownClientShown(true);
                  // setDropdownRelatedShown(false);
                  // setshowCalenderFilter(false);
                }}
              >
                {dropDownClientnew.length === 0 ? (
                  <div className="form-control">Select clients</div>
                ) : (
                  <div className="form-control d-flex align-items-center">
                    {dropDownClientnew &&
                      dropDownClientnew.map((option, index) =>
                        index <= 1 ? (
                          <React.Fragment key={index}>
                            <div className="user-photo icon-32 mx-8">
                              {option?.image !== "" &&
                              option?.image !== null ? (
                                <img src={option?.image} alt="" />
                              ) : (
                                <Avatar
                                  name={
                                    (option?.name.split(", ")[1] !== undefined
                                      ? option?.name.split(", ")[1]
                                      : "") +
                                    " " +
                                    option?.name.split(", ")[0]
                                  }
                                  color="#d3d6de"
                                  size={32}
                                />
                              )}
                            </div>
                            <label>{utility.ellipsify(option?.name, 20)}</label>
                          </React.Fragment>
                        ) : (
                          <label className="text-success ms-8" key={index}>
                            {utility.ellipsify(option, 20)}
                          </label>
                        )
                      )}
                  </div>
                )}
                <em
                  className={
                    dropdownClientShown
                      ? "icon-chevron-up-outline"
                      : "icon-chevron-down-outline"
                  }
                ></em>
              </div>
              {dropdownClientShown && (
                <>
                  <PublishDropDown
                    dropdownClientdata={clientData}
                    prevClientData={prevClientData}
                    setPrevClientData={setPrevClientData}
                    setClientData={setClientData}
                    setClientSelected={setClientSelected}
                    clientSelected={clientSelected}
                    setDropDownClient={setDropDownClient}
                    dropDownClient={dropDownClient}
                    dropdownClientShown={dropdownClientShown}
                    setRemoved={setRemoved}
                    allSelect={allSelect}
                    setAllSelect={setAllSelect}
                    fromAllTeam={fromAllTeam}
                    setFromAllTeam={setFromAllTeam}
                    alreadySelectedLength={alreadySelectedLength}
                  />
                </>
              )}
            </div>
          </div>
          {showClientDanger && dropDownClient.length === 0 && (
            <div className="alert alert-danger d-flex align-items-center mt-16 mb-10">
              <em className="icon icon-exclamation-circle-filled text-danger lh-24"></em>
              <div className="flex-fill">
                <h6 className="mb-0 text-danger">
                  Please select at least one client to apply this option.
                </h6>
              </div>
            </div>
          )}
          <div className="form-check mb-24">
            <input
              className="form-check-input"
              type="checkbox"
              id="archievedArticle"
              checked={isArchievedArticle}
              onChange={(e) => {
                setIsArchievedArticle(!isArchievedArticle)
              }}
            />
            <label className="form-check-label" htmlFor="featureArticle">
            Article Archiving
            </label>
            <div>
              <label className="user-email" htmlFor="featureArticle">
                Automatically archive this article after {numberofDays} Days
              </label>
            </div>
            <div className={isArchievedArticle?"d-flex align-items-start mr-10 ml-25":"d-flex align-items-start mr-10 ml-25 disabled-div"}>
              <div className="d-flex">
                <div className="xs-control plr-10">
                  <div className="pos-relative">
                    <input
                      className="form-control"
                      placeholder=""
                      name="numberofDays"
                      type="number"
                      step="any"
                      disabled={!isArchievedArticle}
                      onKeyDown={e => {
                        // not allow +/-/. while typing
                        if (
                          e.keyCode === 187 ||
                          e.keyCode === 189 ||
                          e.keyCode === 110 ||
                          e.keyCode === 109 ||
                          e.keyCode === 107 ||
                          e.key === 'e' ||
                          e.key === 'E' ||
                          e.key === '.'
                        ) {
                          e.preventDefault();
                        }
                        handleEnter(e);
                      }}
                      onChange={e => {
                        const regexMaxAmount = /^\d{1,4}$/;
                        if (
                          regexMaxAmount.test(e.target.value) &&
                          Number(e.target.value) !== 0
                        ) {
                          setNumberofDays(e.target.value)
                        } else if (e.target.value === '') {
                          setNumberofDays();
                        }
                      }}
                      value={numberofDays}
                      // format="n2"
                      min={0}
                      max={999}
                      // onWheel={(e: React.WheelEvent<HTMLDivElement>) =>
                      //   (e.target as HTMLElement).blur()
                      // }
                    />
                    <span className="k-select sort-arrows">
                      <span
                        className="k-link k-link-increase"
                        aria-label="Increase value"
                        title="Increase value"
                      >
                        <span
                          className="k-icon k-i-arrow-n"
                          onClick={() => {
                            // not allow more then 5 digit
                            if (parseInt(numberofDays, 10) + 1 <= 9999) {
                              setNumberofDays(
                                parseInt(numberofDays, 10) + 1,
                                'numberofDays'
                              );
                            }
                          }}
                        ></span>
                      </span>
                      <span
                        className="k-link k-link-decrease"
                        aria-label="Decrease value"
                        title="Decrease value"
                      >
                        <span
                          className="k-icon k-i-caret-alt-down"
                          onClick={
                            // not allow minus values
                            () => {
                              if (parseInt(numberofDays, 10) - 1 > 0) {
                                setNumberofDays(
                                  parseInt(numberofDays, 10) - 1,
                                  'numberofDays'
                                );
                              }
                            }
                          }
                        ></span>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
   
        </Modal.Body>
        <Modal.Footer className="justify-content-between border-top pt-24 flex-md-row-reverse">
          <div className="d-md-flex align-items-center">
            <button
              className="btn btn-outline-secondary d-none d-md-block"
              onClick={() => {setNumberofDays(90);
                setIsArchievedArticle(true),handlePublishModalClose()}}
            >
              Cancel
            </button>
            <button
              className={
                ((showClientDanger && dropDownClient.length === 0) ||
                  (isArchievedArticle && !numberofDays))
                  ? "btn btn-success mt-0 disabled-div"
                  : "btn btn-success mt-0"
              }
              onClick={() =>
                updatestatus(
                  handlePublish.uuid,
                  handlePublish.map_id,
                  handlePublish.publish_type,
                  handlePublish.team_id,
                  handlePublish.button_name
                )
              }
            >
              {handlePublish.button_name}
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Remove Article Modal */}
      <Modal
        className="modal-464 modal-full"
        show={showRemoveArticle}
        onHide={handleRemoveArticleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Archive Article</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-black fs-18 lh-32 mb-20">
            Are you sure you want to archive this article from the Moneta client
            portal and mobile app?
          </p>
          <p className="text-black lh-20 fw-500">
            “{singleArticleData && singleArticleData.title}“
          </p>
          {singleArticleData?.is_featured && (
            <div className="alert alert-danger">
              Please select a new article to feature first.
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-secondary"
            onClick={handleRemoveArticleClose}
          >
            Cancel
          </button>
          {singleArticleData?.is_featured ? (
            <button disabled className="btn btn-danger">
              Archive
            </button>
          ) : (
            <button
              onClick={() =>
                removeArticle(
                  singleArticleData?.uuid,
                  singleArticleData?.map_id,
                  singleArticleData?.team_id
                )
              }
              className="btn btn-danger"
            >
              Archive
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CDC;
