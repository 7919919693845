import {React, useState} from 'react';
import Loading from '../../components/Loader'
import TaskDashboard from './TaskDashboard';
import ArticleDashboard from './ArticleDashboard';

const Dashboard = () => {
  const [loader, setLoader] = useState(false);
  const [userDetails, setUserDetails] = useState(
    JSON.parse(localStorage.getItem("userDetails"))
  );


  return (
    <div>
      {loader && <Loading active={loader} />}
      {userDetails?.user_type === "advisors" && <TaskDashboard userDetails={userDetails}/>}
      {userDetails?.user_type !== "advisors" && <ArticleDashboard />}
    </div>
  );
};

export default Dashboard;
