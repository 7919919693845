import React, { useEffect, useState } from "react";
import { calendar } from "../../redux/actions/Calendar/Calendar";
import { useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";
import {  notification } from "antd";
import Loading from "../../components/Loader";
import CalendarCloseIcon from "../../Assets/Images/calendar-close-green.svg";

const DeclineMetting = (props) => {
  const dispatch = useDispatch();
  const [declineMetting, setDeclineMetting] = useState(false);
  const handleDeclineMettingClose = () => setDeclineMetting(false);
  const handleDeclineMettingShow = () => setDeclineMetting(true);
  const [loader, setLoader] = useState(false);
  const [comment, setComment] = useState("");

  const Notify = (type, mesage, icons) => {
    notification[type]({
      description: mesage,
      icon: icons,
      top: 65,
    });
  };

  useEffect(() => {
    const { visible, closeModal } = props;
    setDeclineMetting(visible);
  }, [props]);

  function acceptDeclineMeeting() {
    let param = {
      status: "reject",
      comment: comment,
    };
    setLoader(true);
    dispatch(calendar.accpetOrDeclineMeeting(param, props?.event?.uuid)).then(
      (response) => {
        setLoader(false);
        if (response.type === "ACCEPT_DECLINE_SUCCESS") {
          Notify(
            "success",
            `Meeting Declined Successfully`,
            <img src={CalendarCloseIcon} alt="" />
          );
          const { successCloseModal } = props;
          successCloseModal();
        } else {
          setLoader(false);
        }
      }
    );
  }

  return (
    <>
      {loader && <Loading active={loader} />}
      <Modal
        size="md"
        className="confirm-modal"
        show={declineMetting}
        onHide={() => {
          handleDeclineMettingClose;
          const { closeModal } = props;
          closeModal();
        }}
      >
        {!props?.isAcceptRequest && (
          <Modal.Header closeButton>
            <Modal.Title>Decline Request?</Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body>
          {!props?.isAcceptRequest && (
            <p className="mb-8 fs-md-18 lh-md-32">
              Are you sure you want to decline this request? All attendees will
              be notified.
            </p>
          )}
          {props?.isAcceptRequest && (
            <p className="mb-8 fs-md-18 lh-md-32 fw-500">
              Would you like to send an update to existing attendees?
            </p>
          )}

          <div>
            <label className="form-label">
              {props?.isAcceptRequest ? "Message" : "Reason"}
              <sup>*</sup>
            </label>
            <textarea
              className="form-control textarea-156 resize-none"
              placeholder={
                props?.isAcceptRequest
                  ? "Enter an optional message"
                  : "Provide a reason"
              }
              value={comment}
              onChange={(e) => {
               
                
                setComment(e.target.value.trimStart());
              }}
            ></textarea>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-secondary"
            variant="secondary"
            onClick={() => {
              handleDeclineMettingClose;
              const { closeModal } = props;
              closeModal();
            }}
          >
            Cancel
          </button>
          {!props?.isAcceptRequest && (
            <button
              className="btn btn-danger"
              disabled={!comment.length}
              onClick={(e) => {
                acceptDeclineMeeting();
              }}
            >
              Decline
            </button>
          )}
          {props?.isAcceptRequest && (
            <button className="btn btn-success" onClick={(e) => {}}>
              Send
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeclineMetting;
