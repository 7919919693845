import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { notification } from "antd";
import Modal from "react-bootstrap/Modal";
import { clientUser } from "../../redux/actions/ClientUser/ClientUser";

const ClientResendInvite = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showReactivateUser, setReactivateUserShow] = useState(false);
  const handleReactivateUserClose = () => {
    setReactivateUserShow(false);
  };
  const handleReactivateUserShow = () => setReactivateUserShow(true);

  useEffect(() => {
    const { visible, closeModal, isReschedule } = props;

    setReactivateUserShow(visible);
    console.log(props);
  }, []);

  const Notify = (type, mesage, icons) => {
    notification[type]({
      description: mesage,
      icon: icons,
      top: 65,
    });
  };

  function resendUserLink() {
    let param = {
      user_id: props?.client?.uuid,
    };
    dispatch(clientUser.resendUserLink(param)).then((response) => {
      if (response.type === "RESEND_LINK_USER_SUCCESS") {
        //setHouseHoldDetails(response?.data);
        const { closeModal } = props;
        closeModal();
        Notify(
          "success",
          `A new invite link has been sent to ${props?.client?.first_name+" "+props?.client?.last_name} at ${props?.client?.email}. `,
          <em className="icon-check-outline"></em>
        );
      }
    });
  }

  return (
    <>
      <Modal
        className="modal-800"
        show={showReactivateUser}
        onHide={() => {
          handleReactivateUserClose;
          const { closeModal } = props;
          closeModal();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Resend Invite Link</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-md-24">
          <div className="fs-18 lh-32">
            <p className="mb-12">
              Are you sure you want to resend an invite link to {" "}
              <b className="fw-500">{props?.client?.first_name+" "+props?.client?.last_name}?</b>
            </p>
            <p>
            They will receive an email at <b className="fw-500">{props?.client?.email}</b> with a new link 
            and temporary password to use for logging in.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-secondary"
            onClick={(e) => {
              handleReactivateUserClose();
              const { closeModal } = props;
              closeModal();
            }}
          >
            Cancel
          </button>
          <button
            className="btn btn-success"
            onClick={(e) => {
              resendUserLink();
            }}
          >
            Send Link
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ClientResendInvite;
