import React, { useEffect, useState } from "react";

import Avatar from "../../Assets/Images/Avatar.png";
import ClientUserDetails from "./ClientUserDetails";
import ClientUserFilter from "./ClientUserFilter";
import CreateUser from "./CreateUser";
import Loading from "../../components/Loader";
import { clientUser } from "../../redux/actions/ClientUser/ClientUser";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { utility } from "../../components/Utility";

const ClientUserBase = (props) => {
  const { isteam, setselectedTeam } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isSortAsc, setisSortAsc] = useState(isteam === "1" ? true : null);
  const [isTeamSortAsc, setisTeamSortAsc] = useState(
    isteam === "1" ? null : true
  );
  const [loader, setLoader] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [relationshipLeads, setRelationshipLeads] = useState([]);
  const [team_members, setTeamMembers] = useState([]);
  const [userDetails, setUserDetails] = useState(
    JSON.parse(localStorage.getItem("userDetails"))
  );
  const [refresh, setRefresh] = useState(false);
  const [selectedClient, setSelectedClient] = useState();
  const [filterApplied, setfilterApplied] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [FilterSelectedTeamMemberType, setFilterSelectedTeamMemberType] =
    useState("1");
  const [FilterSelectedTeamLeadType, setFilterSelectedTeamLeadType] =
    useState("1");
  const [next, setnext] = useState(null);

  const [UserStatus, setUserStatus] = useState({
    active: null,
    invite_sent: null,
    deactivated: null,
    invite_expired: null,
  });

  const [showUpdateDefaultSetting, setUpdateDefaultSettingShow] =
    useState(false);
  const handleUpdateDefaultSettingClose = () => {
    setUpdateDefaultSettingShow(false);
  };
  const handleUpdateDefaultSettingShow = () =>
    setUpdateDefaultSettingShow(true);

  const [showCreateUser, setCreateUserShow] = useState(false);
  const handleCreateUserClose = () => {
    setCreateUserShow(false);
  };
  const handleCreateUserShow = () => setCreateUserShow(true);

  const [showFilterClientUser, setFilterClientUserShow] = useState(false);
  const handleFilterClientUserClose = () => {
    setFilterClientUserShow(false);
  };
  const handleFilterClientUserShow = () => setFilterClientUserShow(true);

  const [showClientUserDetail, setClientUserDetailShow] = useState(false);
  const handleClientUserDetailClose = () => {
    setClientUserDetailShow(false);
  };
  const handleClientUserDetailShow = () => setClientUserDetailShow(true);

  const [isSortLastLoginAsc, setIsSortLastLoginAsc] = useState(null);
  const [isSortClientNameAsc, setisSortClientNameAsc] = useState(null);
  const [isSortRelationshipLead, setisSortRelationshipLead] = useState(null);

  // dates for today and yesterday
  var TODAY = moment().clone().startOf("day");
  var YESTERDAY = moment().clone().subtract(1, "days").startOf("day");
  let loadingMoreItems = false;

  //To get Client user list
  useEffect(() => {
    loadingMoreItems = true;

    let querryParameters = "";
    if (filterApplied) {
      querryParameters = "?";
      if (FilterSelectedTeamLeadType === "2") {
        let relationshipLeadsString = relationshipLeads
          .filter((r) => r.isSelected === true)
          .map((r) => r.uuid)
          .join(",");
        querryParameters += relationshipLeadsString
          ? "relationship_lead=" + relationshipLeadsString
          : "";
      }
      if (FilterSelectedTeamMemberType === "4") {
        let team_membersString = team_members
          .filter((r) => r.isSelected === true)
          .map((r) => r.uuid)
          .join(",");
        querryParameters += team_membersString
          ? (userDetails?.admin_type === "firm_admin"
            ? "&team="
            : "&relationship_lead=") + team_membersString
          : "";
      } else if (FilterSelectedTeamMemberType === "2") {
        querryParameters += "relationship_lead=" + userDetails?.uuid;
      }
      let userStatus = "";
      Object.keys(UserStatus).map((key, indx) => {
        if (UserStatus[key] === true) {
          userStatus += userStatus ? "," + key : key;
        }
      });
      if (userStatus) {
        querryParameters += querryParameters
          ? "&user_status=" + userStatus
          : "user_status=" + userStatus;
      }
    } else if (FilterSelectedTeamMemberType === "2") {
      querryParameters = "?";
      querryParameters += "relationship_lead=" + userDetails?.uuid;
    }
    if (searchValue) {
      querryParameters +=
        (querryParameters ? "&" : "?") + "search=" + searchValue;
    }
    querryParameters +=
      (querryParameters ? "&" : "?") + "limit=10&offset=0&ordering=";
    if (isSortAsc !== null) {
      querryParameters += (isSortAsc ? "" : "-") + "household_name";
    }
    else if (isTeamSortAsc !== null) {
      querryParameters += (isTeamSortAsc ? "" : "-") + "team__name";
    }
    if (isSortLastLoginAsc !== null) {
      querryParameters +=
        "&order_by=" +
        (isSortLastLoginAsc ? "" : "-") +
        "last_login";
    }
    if (isSortClientNameAsc !== null) {
      querryParameters +=
        "&order_by=" +
        (isSortClientNameAsc ? "" : "-") +
        "last_name";
    }
    if (isSortRelationshipLead !== null) {
      querryParameters +=
        "&order_by=" +
        (isSortRelationshipLead ? "" : "-") +
        "client_household__sf_relationship_lead__first_name";
    }

    if (isteam === "1") {
      dispatch(clientUser.getClientList(querryParameters)).then((response) => {
        setLoader(false);
        if (response.type === "GET_CLIENT_USER_LIST_SUCCESS") {
          setnext(response?.data?.next);
          setClientList(response?.data?.results);
          console.log(response?.data?.results)
          setLoader(false);
        } else {
          setLoader(false);
        }
        loadingMoreItems = false;
      });
      dispatch(clientUser.getFilterData()).then((response) => {
        setLoader(false);
        if (response.type === "GET_FILTER_LIST_SUCCESS") {
          if (relationshipLeads.length === 0) {
            let list = response.data?.relationship_leads;
            list.map((r) => (r.isSelected = false));
            setRelationshipLeads(list);
            if (userDetails?.admin_type !== "firm_admin") {
              setTeamMembers(list);
            }
          }
          if (
            relationshipLeads.length === 0 &&
            userDetails?.admin_type === "firm_admin"
          ) {
            let list = response.data?.teams;
            list.map((r) => {
              (r.uuid = r.id),
                (r.first_name = r.name),
                (r.last_name = ""),
                (r.isSelected = false);
            });
            setTeamMembers(list);
          }

          setLoader(false);
        } else {
          setLoader(false);
        }
      });
    } else {
      setLoader(false)
      getTeamList();
    }



  }, [refresh]);

  function getNextClientList() {
    if (next && !loadingMoreItems) {
      loadingMoreItems = true;
      setLoader(true);
      dispatch(clientUser.getNextClientList(next)).then((response) => {
        if (response.type === "GET_CLIENT_USER_LIST_SUCCESS") {
          setnext(response?.data?.next);
          // this gives an object with dates as keys
          let list = [...clientList, ...response.data.results];
          setClientList(list);
        }
        loadingMoreItems = false;
        setLoader(false);
      });
    }
  }

  function getTeamList() {
    setLoader(true);
    dispatch(clientUser.teamList()).then((response) => {
      if (response.type === "GET_TEAM_LIST_SUCCESS") {
        setLoader(false);
        console.log(response);
        setClientList(response?.data);
      }

    });

  }

  const handleScroll = (e) => {
    // Get it's position in the viewport
    const bottom =
      parseInt(
        e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight
      ) < 2;
    if (bottom === true && next !== null && !loadingMoreItems) {
      getNextClientList();
      return;
    }
  };


  //To get Last Login Day
  function getDay(loginDate) {
    return loginDate.isSame(TODAY, "d")
      ? "Today"
      : loginDate.isSame(YESTERDAY, "d")
        ? "Yesterday"
        : loginDate.format("ll");
  }

  //To highlight row on hover
  window.addEventListener("mouseover", (event) => {
    if (event.target.classList.contains(".clientrow")) {
      event.target.classList.add("has-open");
    }
  });

  //To remove highlight row on mouseout
  window.addEventListener("mouseout", (event) => {
    if (event.target.classList.contains("clientrow")) {
      event.target.classList.remove("has-open");
    }
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (relationshipLeads.length > 0) {
        setRefresh(!refresh);
      }
    }, 600);
    return () => {
      clearTimeout(timeout);
    };
  }, [searchValue]);
  return (
    <>
      {loader && <Loading active={loader} />}
      <div className="card client-user-card">
        <div className="card-header">
          <div className="d-lg-flex justify-content-between align-items-start mb-md-16 share-tool">
            <div className="client-user-left">
              <h4 className="mb-0">
                {isteam !== "1" ? "Client User Settings" : "Client Users"}
              </h4>
            </div>
            <div className="client-user-right d-md-flex">
              <div className="search-area search-area-sm message-search-area me-16">
                <input
                  placeholder={
                    isteam === "1"
                      ? "Search client or household name..."
                      : "Search for a team"
                  }
                  className="form-control"
                  value={searchValue}
                  onChange={(e) => {
                    e.preventDefault();
                    setSearchValue(e.target.value);
                    setisSortClientNameAsc(null);
                    setisSortRelationshipLead(null);
                    setIsSortLastLoginAsc(null);
                  }}
                />
                <button className="search-btn icon-20">
                  <em className="icon-search-outline"></em>
                </button>
                <button
                  className="close-search-btn icon-20"
                  onClick={(e) => {
                    e.preventDefault();
                    setSearchValue("");
                    setRefresh(!refresh);
                  }}
                >
                  {searchValue && <em className={"icon-x-circle-outline"}></em>}
                </button>
              </div>
              {isteam === "1" && (
                <button
                  type="button"
                  className="btn btn-success btn-xsm"
                  onClick={(e) => {
                    e.preventDefault();
                    handleCreateUserShow();
                  }}
                >
                  <em className="icon icon-add-outline me-12"></em>
                  Create User
                </button>
              )}
            </div>
          </div>
          <div className="d-flex">
            {isteam === "1" &&
              <span
                className="filt-by-client d-none text-success fw-500 cursor-pointer d-md-flex align-items-center text-nowrap"
                onClick={(e) => {
                  e.preventDefault();
                  handleFilterClientUserShow();
                }}
              >
                <span className="icon-20 me-8">
                  <em className="icon-adjustments-outline"></em>
                </span>
                Filter By
              </span>
            }
            <div className="ms-md-16 d-flex align-items-center flex-wrap">
              {FilterSelectedTeamLeadType === "2" &&
                relationshipLeads?.map((client, indx) => (
                  <>
                    {client?.isSelected === true && (
                      <span
                        key={indx + client?.first_name}
                        className="filt-badge"
                      >
                        Relationship Lead:{" "}
                        {client?.first_name + " " + client?.last_name}
                        <em
                          className="icon-x-outline cursor-pointer"
                          onClick={(e) => {
                            relationshipLeads[indx].isSelected = false;
                            if (
                              !relationshipLeads.some(
                                (r) => r.isSelected === true
                              )
                            ) {
                              setFilterSelectedTeamLeadType("1");
                            }
                            setRelationshipLeads([...relationshipLeads]);
                            setRefresh(!refresh);
                          }}
                        ></em>
                      </span>
                    )}
                  </>
                ))}

              {FilterSelectedTeamMemberType === "2" && (
                <>
                  <span className="filt-badge">
                    Relationship Lead:{" "}
                    {userDetails?.first_name + " " + userDetails?.last_name}
                    <em
                      className="icon-x-outline cursor-pointer"
                      onClick={(e) => {
                        setFilterSelectedTeamMemberType("1");
                        setRefresh(!refresh);
                      }}
                    ></em>
                  </span>
                </>
              )}
              {team_members?.map((client, indx) => (
                <>
                  {client?.isSelected === true && (
                    <span
                      key={indx + client?.first_name}
                      className="filt-badge"
                    >
                      {userDetails?.admin_type === "firm_admin"
                        ? "Team: "
                        : "Relationship Lead: "}
                      {client?.first_name + " " + client?.last_name}
                      <em
                        className="icon-x-outline cursor-pointer"
                        onClick={(e) => {
                          team_members[indx].isSelected = false;
                          if (
                            !team_members.some((r) => r.isSelected === true)
                          ) {
                            setFilterSelectedTeamMemberType("1");
                          }
                          setTeamMembers([...team_members]);
                          setRefresh(!refresh);
                        }}
                      ></em>
                    </span>
                  )}
                </>
              ))}
              {Object.keys(UserStatus).map((key, indx) => (
                <>
                  {UserStatus[key] === true && (
                    <span key={key} className="filt-badge">
                      User Status:{" "}
                      {key === "active"
                        ? "Active"
                        : key === "invite_sent"
                          ? "Invite Sent"
                          : key === "invite_expired"
                            ? "Invite Expired" :
                            "Deactivated"}
                      <em
                        className="icon-x-outline cursor-pointer"
                        onClick={(e) => {
                          e.preventDefault();
                          UserStatus[key] = false;
                          setUserStatus(JSON.parse(JSON.stringify(UserStatus)));
                          setRefresh(!refresh);
                        }}
                      ></em>
                    </span>
                  )}
                </>
              ))}
            </div>
          </div>
        </div>
        <div className="card-body p-0">
          <div className="cu-responsive">
            <div className="cu-table">
              <div className="cu-head d-none d-md-flex">
                {isteam !== "1" && (
                  <div className="cu-th cu-column-1 d-flex align-items-center">
                    Team Name
                    <em
                      className={`icon icon-sort-${isTeamSortAsc !== null
                        ? isTeamSortAsc
                          ? "ascending-outline text-success"
                          : "descending-outline text-success"
                        : "descending-outline"
                        }  ms-8 cursor-pointer`}
                      onClick={(e) => {
                        e.preventDefault();
                        setisTeamSortAsc(!isTeamSortAsc);
                        setisSortAsc(null);
                        setIsSortLastLoginAsc(null);
                        setisSortClientNameAsc(null);
                        setisSortRelationshipLead(null);
                        //setRefresh(!refresh);
                        let list = clientList?.sort()?.reverse();
                        setClientList(list);
                      }}
                    ></em>
                  </div>
                )}
                {isteam === "1" && (
                  <div className="cu-th cu-column-1 d-flex align-items-center">
                    Household Name
                    <em
                      className={`icon icon-sort-${isSortAsc !== null
                        ? isSortAsc
                          ? "ascending-outline text-success"
                          : "descending-outline text-success"
                        : "descending-outline"
                        }  ms-8 cursor-pointer`}
                      onClick={(e) => {
                        e.preventDefault();
                        setisSortAsc(!isSortAsc);
                        setisTeamSortAsc(null);
                        setRefresh(!refresh);
                        setIsSortLastLoginAsc(null);
                        setisSortClientNameAsc(null);
                        setisSortRelationshipLead(null);
                      }}
                    ></em>
                  </div>
                )}
                <div className="d-flex flex-fill w-100">
                  {isteam === "1" && (
                    <div className="cu-th cu-column-2">Client Name
                      <em
                        className={`icon icon-sort-${isSortClientNameAsc !== null
                          ? isSortClientNameAsc
                            ? "ascending-outline text-success"
                            : "descending-outline text-success"
                          : "descending-outline"
                          }  ms-8 cursor-pointer`}
                        onClick={(e) => {
                          e.preventDefault();
                          setisSortClientNameAsc(!isSortClientNameAsc);
                          setisSortRelationshipLead(null);
                          setIsSortLastLoginAsc(null);
                          setisSortAsc(null);
                          setSearchValue("");
                          setisTeamSortAsc(null);
                          setRefresh(!refresh);
                        }}
                      ></em></div>
                  )}
                  {isteam === "1" && (
                    <div className="cu-th cu-column-3">User Status</div>
                  )}
                  {isteam === "1" && (
                    <div className="cu-th cu-column-4">Relationship Lead
                      <em
                        className={`icon icon-sort-${isSortRelationshipLead !== null
                          ? isSortRelationshipLead
                            ? "ascending-outline text-success"
                            : "descending-outline text-success"
                          : "descending-outline"
                          }  ms-8 cursor-pointer`}
                        onClick={(e) => {
                          e.preventDefault();
                          setisSortRelationshipLead(!isSortRelationshipLead);
                          setisSortClientNameAsc(null);
                          setIsSortLastLoginAsc(null);
                          setisSortAsc(null);
                          setisTeamSortAsc(null);
                          setRefresh(!refresh);
                          setSearchValue("");
                        }}
                      ></em></div>
                  )}
                  {isteam === "1" && (
                    <div className="cu-th cu-column-5 text-end">Last Login
                      <em
                        className={`icon icon-sort-${isSortLastLoginAsc !== null
                          ? isSortLastLoginAsc
                            ? "ascending-outline text-success"
                            : "descending-outline text-success"
                          : "descending-outline"
                          }  ms-8 cursor-pointer`}
                        onClick={(e) => {
                          e.preventDefault();
                          setIsSortLastLoginAsc(!isSortLastLoginAsc);
                          setisSortClientNameAsc(null);
                          setisSortRelationshipLead(null);
                          setisSortAsc(null);
                          setisTeamSortAsc(null);
                          setRefresh(!refresh);
                          setSearchValue("");
                        }}
                      ></em>
                    </div>
                  )}
                </div>
              </div>
              <div
                className="cu-body custom-style-scroll"
                onScroll={handleScroll}
              >
                {clientList?.filter(
                  (c) => (isteam === "2" ? (
                    searchValue === "" ||
                    (
                      c.name?.toLowerCase() +
                      ", " +
                      c.name?.toLowerCase()
                    ).includes(searchValue?.toLowerCase())
                  ) : c))?.map((household, index) => (
                    <div
                      key={index}
                      className="cu-body-tr d-flex cursor-pointer"
                      onClick={(e) => {
                        e.preventDefault();
                        if (isteam === "2") {
                          setselectedTeam({
                            team_name: household?.name,
                            team_id: household?.id,
                          });
                        }
                      }}
                    >
                      {isteam !== "1" && (
                        <div className="cu-td cu-column-1">
                          <span className="fw-500 d-block ps-8">
                            {searchValue !== "" ? utility.getHighlightedText(household?.name, searchValue) : household?.name}
                          </span>
                        </div>
                      )}
                      <div className="cu-td cu-column-1">
                        <span className="fw-500 d-block ps-8">
                          {searchValue !== "" ? utility.getHighlightedText(household?.household_name, searchValue) : household?.household_name}
                        </span>
                      </div>
                      <div className="flex-fill w-100">
                        <div className="d-none d-md-block">
                          {household?.users && (
                            household?.users?.map((client, clientIndex) => (
                              <div
                                key={clientIndex}
                                className="d-flex align-items-center cu-row clientrow"
                              >
                                <div
                                  className="cu-td cu-column-2"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (isteam === "1") {
                                      setSelectedClient(client);
                                      handleClientUserDetailShow();
                                    }
                                  }}
                                >
                                  <div className="d-flex align-items-center lh-20">
                                    <span className="cu-photo me-8">
                                      {utility.getProfileImage(
                                        client?.profile_image,
                                        client?.first_name?.charAt(0),
                                        client?.last_name?.charAt(0)
                                      )}
                                    </span>
                                    {searchValue !== "" ? utility.getHighlightedText(`${client?.last_name}, ${client?.first_name}`, searchValue) : `${client?.last_name}, ${client?.first_name}`}
                                  </div>
                                </div>
                                <div className="cu-td cu-column-3">
                                  {client?.is_deleted === false &&
                                    client?.is_active === true && (
                                      <span className="badge badge-outline-success">
                                        <em className="icon-check-circle-outline me-4"></em>{" "}
                                        Active
                                      </span>
                                    )}

                                  {client?.is_deleted === false &&
                                    client?.is_active !== true && client?.invite_token !== "expired" && (
                                      <span className="badge badge-outline-secondary">
                                        <em className="icon-clock-outline me-4"></em>
                                        Invite Sent
                                      </span>
                                    )}
                                  {client?.is_deleted === false &&
                                    client?.is_active !== true && client?.invite_token === "expired" && (
                                      <span className="badge badge-outline-secondary alert-danger">
                                        <em className="icon-clock-outline me-4"></em>
                                        Invite Expired
                                      </span>)}
                                  {client?.is_deleted === true && (
                                    <span className="badge badge-outline-secondary">
                                      <em className="icon-x-circle-outline me-4"></em>
                                      Deactivated
                                    </span>
                                  )}
                                </div>
                                {isteam === "1" && (
                                  <>
                                    <div className="cu-td cu-column-4">
                                      <div className="d-flex align-items-center lh-20 text-muted">
                                        {household?.relationship_lead &&
                                          <span className="cu-photo me-8">
                                            {utility.getProfileImage(
                                              household?.relationship_lead
                                                ?.profile_image,
                                              household?.relationship_lead?.first_name?.charAt(
                                                0
                                              ),
                                              household?.relationship_lead?.last_name?.charAt(
                                                0
                                              )
                                            )}
                                          </span>
                                        }
                                        {household?.relationship_lead ? `${household?.relationship_lead?.first_name} ${household?.relationship_lead?.last_name}` : "-"}
                                      </div>
                                    </div>
                                    <div className="cu-td cu-column-5 text-end">
                                      <span className="text-muted">
                                        {client?.last_login !== null
                                          ? getDay(moment(client?.last_login))
                                          : "-"}
                                      </span>
                                    </div>
                                  </>
                                )}
                              </div>
                            )))}
                          {!household?.users && isteam === '1' && (
                            <div
                              className="d-flex align-items-center cu-row clientrow"
                            >
                              <div
                                className="cu-td cu-column-2"
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (isteam === "1") {
                                    setSelectedClient(household);
                                    handleClientUserDetailShow();
                                  }
                                }}
                              >
                                <div className="d-flex align-items-center lh-20">
                                  <span className="cu-photo me-8">
                                    {utility.getProfileImage(
                                      household?.profile_image,
                                      household?.first_name?.charAt(0),
                                      household?.last_name?.charAt(0)
                                    )}
                                  </span>
                                  {searchValue !== "" ? utility.getHighlightedText(`${household?.last_name}, ${household?.first_name}`, searchValue) : `${household?.last_name}, ${household?.first_name}`}
                                </div>
                              </div>
                              <div className="cu-td cu-column-3">
                                {household?.is_deleted === false &&
                                  household?.is_active === true && (
                                    <span className="badge badge-outline-success">
                                      <em className="icon-check-circle-outline me-4"></em>{" "}
                                      Active
                                    </span>
                                  )}

                                {household?.is_deleted === false &&
                                  household?.is_active !== true && household?.invite_token !== "expired" && (
                                    <span className="badge badge-outline-secondary">
                                      <em className="icon-clock-outline me-4"></em>
                                      Invite Sent
                                    </span>
                                  )}
                                {household?.is_deleted === false &&
                                  household?.is_active !== true && household?.invite_token === "expired" && (
                                    <span className="badge badge-outline-secondary alert-danger">
                                      <em className="icon-clock-outline me-4"></em>
                                      Invite Expired
                                    </span>)}

                                {household?.is_deleted === true && (
                                  <span className="badge badge-outline-secondary">
                                    <em className="icon-x-circle-outline me-4"></em>
                                    Deactivated
                                  </span>
                                )}
                              </div>
                              {isteam === "1" && (
                                <>
                                  <div className="cu-td cu-column-4">
                                    <div className="d-flex align-items-center lh-20 text-muted">
                                      {household?.relationship_lead_first_name &&
                                        <span className="cu-photo me-8">
                                          {utility.getProfileImage(
                                            household?.relationship_lead_profile_image,
                                            household?.relationship_lead_first_name?.charAt(
                                              0
                                            ),
                                            household?.relationship_lead_last_name?.charAt(
                                              0
                                            )
                                          )}
                                        </span>
                                      }
                                      {household?.relationship_lead_first_name ? `${household?.relationship_lead_first_name} ${household?.relationship_lead_last_name}` : "-"}
                                    </div>
                                  </div>
                                  <div className="cu-td cu-column-5 text-end">
                                    <span className="text-muted">
                                      {household?.last_login !== null
                                        ? getDay(moment(household?.last_login))
                                        : "-"}
                                    </span>
                                  </div>
                                </>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                {next && <div className="cu-body-tr cu-center cursor-pointer" onClick={() => getNextClientList()}>
                  More
                </div>}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showFilterClientUser && (
        <ClientUserFilter
          UserStatus={UserStatus}
          FilterSelectedTeamMemberType={FilterSelectedTeamMemberType}
          FilterSelectedTeamLeadType={FilterSelectedTeamLeadType}
          relationshipLeadList={relationshipLeads}
          team_members={team_members}
          isteam={isteam}
          closeModal={() => {
            setFilterClientUserShow(false);
          }}
          applyFilter={(
            teammembers,
            relationshipLeads,
            currentValue,
            currentTeamLeadValue,
            currentUserStatus
          ) => {
            setTeamMembers(teammembers);
            if (userDetails?.admin_type === "firm_admin") {
              setRelationshipLeads(relationshipLeads);
            }
            setFilterSelectedTeamMemberType(currentValue);
            setFilterSelectedTeamLeadType(currentTeamLeadValue);
            setUserStatus(currentUserStatus);
            setFilterClientUserShow(false);
            setfilterApplied(true);
            setRefresh(!refresh);
          }}
          resetFilter={(
            teammembers,
            relationshipLeads,
            currentValue,
            currentTeamLeadValue,
            currentUserStatus
          ) => {
            setTeamMembers(teammembers);
            if (userDetails?.admin_type === "firm_admin") {
              setRelationshipLeads(relationshipLeads);
            }
            setFilterSelectedTeamMemberType(currentValue);
            setFilterSelectedTeamLeadType(currentTeamLeadValue);
            setUserStatus(currentUserStatus);
            setfilterApplied(true);
            setRefresh(!refresh);
          }}
        ></ClientUserFilter>
      )}

      {showCreateUser && (
        <CreateUser
          visible={showCreateUser}
          closeModal={() => {
            setCreateUserShow(false);
            setRefresh(!refresh);
          }}
        ></CreateUser>
      )}

      {showClientUserDetail && (
        <ClientUserDetails
          visible={showClientUserDetail}
          client={selectedClient}
          closeModal={() => {
            setClientUserDetailShow(false);
            setRefresh(!refresh);

          }}
        ></ClientUserDetails>
      )}
    </>
  );
};

export default ClientUserBase;
