import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  useLocation,
  Navigate,
} from "react-router-dom";
import { Switch, Steps } from "antd";
import Modal from "react-bootstrap/Modal";
import axiosInstance from "../../config/axios";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Avatar from "../../Assets/Images/Avatar.png";
import salesForce from "../../Assets/Images/salesforce.svg";
import outlook from "../../Assets/Images/Outlook.svg";
import ClientUserSetting from "./ClientUserSetting";
import ClientUserBase from "./ClientUserBase";
import { useDispatch } from "react-redux";
import { clientUser } from "../../redux/actions/ClientUser/ClientUser";

const ClientUser = () => {
  const dispatch = useDispatch();

  const [key, setKey] = useState("1");
  const [selectedTeam, setselectedTeam] = useState("");
  const [userDetails, setUserDetails] = useState(
    JSON.parse(localStorage.getItem("userDetails"))
  );

  const [showClientUserDetail, setClientUserDetailShow] = useState(false);
  const handleClientUserDetailClose = () => {
    setClientUserDetailShow(false);
  };
  const handleClientUserDetailShow = () => setClientUserDetailShow(true);

  const [showSendResetPasswordLink, setSendResetPasswordLinkShow] =
    useState(false);
  const handleSendResetPasswordLinkClose = () => {
    setSendResetPasswordLinkShow(false);
  };
  const handleSendResetPasswordLinkShow = () =>
    setSendResetPasswordLinkShow(true);

  const [showDeactivateUser, setDeactivateUserShow] = useState(false);
  const handleDeactivateUserClose = () => {
    setDeactivateUserShow(false);
  };
  const handleDeactivateUserShow = () => setDeactivateUserShow(true);

  const [showReactivateUser, setReactivateUserShow] = useState(false);
  const handleReactivateUserClose = () => {
    setReactivateUserShow(false);
  };
  const handleReactivateUserShow = () => setReactivateUserShow(true);

  const [showUpdateDefaultSetting, setUpdateDefaultSettingShow] =
    useState(false);
  const handleUpdateDefaultSettingClose = () => {
    setUpdateDefaultSettingShow(false);
  };
  const handleUpdateDefaultSettingShow = () =>
    setUpdateDefaultSettingShow(true);

  function get_team_details() {
    dispatch(clientUser.get_team_details()).then((response) => {
      if (response.type === "GET_TEAM_DETAILS_SUCCESS") {
        let value = JSON.parse(JSON.stringify(response?.data));
        setselectedTeam(response?.data);
      }
    });
  }

  return (
    <>
      <div className="heading-wrap">
        <div className="d-flex align-items-center">
          <span className="head-icon me-16">
            <em className="icon-user-circle-filled"></em>
          </span>
          <h4 className="m-0">Client Users</h4>
        </div>
      </div>
      <div className="tab-wrap">
        {/* Tabs */}
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          className=""
          onSelect={(k) => {
            setKey(k);
            if (userDetails.admin_type !== "firm_admin" && k === "2") {
              get_team_details();
            } else {
              setselectedTeam("");
            }
          }}
        >
          <Tab
            eventKey={1}
            title={
              <span className="d-md-flex align-items-center justify-content-center">
                <span className="tab-icon">
                  <em className="icon-user-circle-filled"></em>
                </span>
                <span className="tab-text">Manage Client Users</span>
              </span>
            }
          ></Tab>
          {(userDetails.admin_type === "firm_admin" ||userDetails.admin_type === "team_admin") && <Tab
            eventKey={2}
            title={
              <span className="d-md-flex align-items-center justify-content-center">
                <span className="tab-icon">
                  <em className="icon-cog-filled"></em>
                </span>
                <span className="tab-text">Team Default Settings</span>
              </span>
            }
          ></Tab>}
        </Tabs>
      </div>
      <div className="content-area">
        <div className="tab-content">
          {key === "1" && <ClientUserBase isteam="1"></ClientUserBase>}
          {key === "2" &&
            selectedTeam && (
            <ClientUserSetting
              selectedTeam={selectedTeam}
              setselectedTeam={(team) => {
                setselectedTeam("");
              }}
            ></ClientUserSetting>
          )}
          {key === "2" &&
            userDetails.admin_type === "firm_admin" &&
            !selectedTeam && (
            <ClientUserBase
              isteam="2"
              setselectedTeam={(team) => {
                setselectedTeam(team);
              }}
            ></ClientUserBase>
          )}
        </div>
      </div>
      {/* Sort Modal Client User Filter used in Client User Base */}

      {/* User Modal  User Details Modal*/}

      {/* Send Reset Password Link Modal ClientResetPassword*/}

      {/* Deactivate User Modal ClientDeactivate */}

      {/* Reactivate User Modal */}

      {/* Create User Modal Added in user Base */}

      {/* Update Default Setting Modal */}
    </>
  );
};

export default ClientUser;
