import React , { useEffect, useState } from 'react'
import {  Outlet } from "react-router-dom";
import Header from './Header';
import Footer from './Footer';
import SideBar from './SideBar';
import { useLocation } from 'react-router-dom';

const Mainlayout = () => {
  const [toggleMobileMenu, settoggleMobileMenu] = useState(false);
  const togglebtn = () => {
    settoggleMobileMenu(!toggleMobileMenu);
  }
  const location = useLocation ();
  return (
    <>
      <Header togglebtn={togglebtn}/>
      <div className='d-flex content-wrap'>
        <SideBar toggleMobileMenu={toggleMobileMenu} togglebtn={togglebtn}/>
        <div className='flex-fill main-content-bg content-right'>
          <Outlet />
        </div>
      </div>
      {/* <Footer /> */}
    </>
  )
}

export default Mainlayout;