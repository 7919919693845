import { React, useState } from "react";
import Avatar from "react-avatar";
import { utility } from "../../components/Utility";

const PublishDropDown = ({
  dropdownClientdata,
  prevClientData,
  setPrevClientData,
  setClientData,
  setClientSelected,
  clientSelected,
  dropDownClient,
  setDropDownClient,
  setRemoved,
  allSelect,
  setAllSelect,
  fromAllTeam,
  setFromAllTeam,
  alreadySelectedLength
}) => {
  const [state, setState] = useState({
    searchText: "",
    filterList: dropdownClientdata
  });
  const { searchText, filterList } = state;


  const handleClick = () => {
    const newStatePrevData = prevClientData?.map((obj, index) => {
      if (
        index + 1 <= alreadySelectedLength
      ) {
        return { ...obj, is_selected: true };
      }
      return { ...obj, is_selected: false };
    });
    setPrevClientData(newStatePrevData);
    const newState = state.filterList.map(obj => {
      if (obj.is_selected === true && allSelect) {
        return { ...obj, is_selected: false };
      }
      else {
        return { ...obj, is_selected: true };
      }
    })
    // console.log(newState,"nnnnnnnnnnnnnnnnnnn")
    if (allSelect) {
      setDropDownClient([])
      setClientSelected([])
      document.getElementsByName("publish-checkbox").checked = false
      dropdownClientdata.map(obj => {
        obj.is_selected = false
      })
    }
    else {
      document.getElementsByName("publish-checkbox").checked = true
      // dropdownClientdata[indx].is_selected = true
      dropdownClientdata.map(obj => {
        obj.is_selected = true
      })
      const newState = dropdownClientdata.map(obj => {
        return {
          "name": (obj?.last_name !== null ? obj?.last_name + ", " : "") +
            " " +
            obj?.first_name, "image": obj?.profile_image, "uuid": obj?.user_uuid
        }
      })
      setDropDownClient(newState);
      const newStateSelected = dropdownClientdata.map(obj => {
        return {
          value: obj?.user_uuid,
          label:
            (obj?.last_name !== null ? obj?.last_name + ", " : "") +
            " " +
            obj?.first_name,
        }
      })
      setClientSelected(newStateSelected)
      setDropDownClient(newState);
      setFromAllTeam(false)
    }
    // setClientData(newState);
    setState((s) => ({
      ...s,
      searchText: "",
      filterList: newState,
    }));

    // const newStatePrevData = prevClientData.map((obj,indx) => {
    //   if (
    //     indx + 1 < alreadySelectedLength
    //   ) {
    //     return { ...obj, is_selected: true };
    //   }
    //   return obj;
    // });
    // setPrevClientData(newStatePrevData);
    // const { id, checked } = e.target;
    // setIsCheck([...isCheck, id]);
    // if (!checked) {
    //   setIsCheck(isCheck.filter(item => item !== id));
    // }
  };

  return (
    <div className="dropdown-content mt-4">
      <div className="px-24 py-md-8 py-12 border-mt-top">
        <div className="search-area">
          <input
            className="form-control"
            value={searchText}
            onChange={(e) => {
              let lst = dropdownClientdata?.filter(
                (c) =>
                  e.target.value === "" ||
                  (
                    c.last_name?.toLowerCase() +
                    ", " +
                    c.first_name?.toLowerCase()
                  ).includes(e.target.value?.toLowerCase())
              );
              setState((s) => ({
                ...s,
                searchText: e.target.value,
                filterList: lst,
              }));
            }}
          />
          {searchText === "" && (
            <button className="search-btn icon-20">
              <em className="icon-search-outline"></em>
            </button>
          )}
          {searchText !== "" && (
            <button
              className="close-search-btn icon-20"
              onClick={(event) => {
                let lst = dropdownClientdata;
                setState((s) => ({
                  ...s,
                  searchText: "",
                  filterList: lst,
                }));
              }}
            >
              <em className="icon-x-circle-filled"></em>
            </button>
          )}
          <span className="cdc-span fs-14 .lh-24">
            {dropDownClient.length} selected
          </span>
          {searchText === ""?
          !allSelect ? (
            <a
              href="#"
              className="mb-4 fs-14 d-inline-block"
              onClick={() => (handleClick(), setAllSelect(true))}
            >
              Select All
            </a>
          ) : (
            <a
              href="#"
              className="mb-4 fs-14 d-inline-block"
              onClick={() => (handleClick(), setAllSelect(false))}
            >
              Deselect All
            </a>
          ):<></>}
        </div>
      </div>
      <ul className="check-list list-unstyled custom-style-scroll">
        {filterList &&
          filterList?.map((client, indx) => (
            <li
              key={indx + client?.last_name}
              name="publish-checkbox"
              className={
                allSelect === ""?
                (prevClientData[indx]?.is_selected
                  ? "already-selected"
                  : client.is_selected
                  ? "selected"
                  : ""):
                  allSelect?prevClientData[indx]?.is_selected?"already-selected":"selected":""
              }
              //   className={`${client.is_selected ? "selected" : ""}`}
            >
              <div className="form-check mb-0 d-flex align-items-center">
                <input
                  className="form-check-input"
                  type="Checkbox"
                  name="client_checkbox"
                  checked={
                    // allSelect === ""
                       fromAllTeam
                        ? prevClientData[indx]?.is_selected
                        : client.is_selected
                        // : allSelect
                        // ? true
                        // : false
                  }
                  id={client.last_name}
                  onChange={(e) => {
                    setAllSelect("")
                    let indx = dropdownClientdata?.findIndex(
                      (c) => c.user_uuid === client.user_uuid
                    );
                    dropdownClientdata[indx].is_selected = e?.target?.checked;
                    // setPrevClientData(...prevClientData,)
                    const newStatePrevData = prevClientData?.map((obj, index) => {
                      if (
                        index + 1 <= alreadySelectedLength
                      ) {
                        return { ...obj, is_selected: true };
                      }
                      return { ...obj, is_selected: false };
                    });
                    setPrevClientData(newStatePrevData);
                    setFromAllTeam(false);
                    setClientData(dropdownClientdata);
                    if (dropdownClientdata[indx].is_selected === true) {
                      setDropDownClient([
                        ...dropDownClient,
                        {
                          name:
                            (dropdownClientdata[indx]?.last_name !== null
                              ? dropdownClientdata[indx]?.last_name + ", "
                              : "") +
                            " " +
                            dropdownClientdata[indx]?.first_name,
                          image: dropdownClientdata[indx]?.profile_image,
                          uuid: dropdownClientdata[indx]?.user_uuid,
                        },
                      ]);
                      setClientSelected([
                        ...clientSelected,
                        {
                          value: dropdownClientdata[indx]?.user_uuid,
                          label:
                            (dropdownClientdata[indx]?.last_name !== null
                              ? dropdownClientdata[indx]?.last_name + ", "
                              : "") +
                            " " +
                            dropdownClientdata[indx]?.first_name,
                        },
                      ]);
                      setRemoved("");
                    } else {
                      const newStatePrevData = prevClientData?.map((obj,index) => {
                        if (
                          index + 1 <= alreadySelectedLength
                        ) {
                          return { ...obj, is_selected: true };
                        }
                        return { ...obj, is_selected: false };
                      });
                      setPrevClientData(newStatePrevData);
                      var toRemove =
                        (dropdownClientdata[indx]?.last_name !== null
                          ? dropdownClientdata[indx]?.last_name + ", "
                          : "") +
                        " " +
                        dropdownClientdata[indx]?.first_name;
                      // var index = dropDownAssignedto.indexOf(toRemove);
                      var index = dropDownClient.findIndex(
                        (item) => item.name === toRemove
                      );
                      if (index > -1) {
                        //Make sure item is present in the array, without if condition, -n indexes will be considered from the end of the array.
                        const removed = dropDownClient.splice(index, 1);
                        setRemoved(removed[0]);
                        setDropDownClient([...dropDownClient]);
                      }
                      const filtered = clientSelected.filter((filter) => {
                        return (
                          filter.value !== dropdownClientdata[indx].user_uuid
                        );
                      });
                      setClientSelected(filtered);
                    }
                    indx = filterList?.findIndex(
                      (c) => c.user_uuid === client.user_uuid
                    );
                    filterList[indx].is_selected = e?.target?.checked;
                    setState((s) => ({
                      ...s,
                      filterList: filterList,
                    }));
                  }}
                />
                <div className="user-photo icon-32 mx-8">
                  {client.profile_image !== "" &&
                  client.profile_image !== null ? (
                    <img src={client.profile_image} alt="" />
                  ) : (
                    <Avatar
                      color="lightgrey"
                      name={
                        client?.first_name +
                        " " +
                        (client?.last_name !== null ? client?.last_name : "")
                      }
                      size={40}
                    />
                  )}
                </div>
                <label className="form-check-label" htmlFor="filtclient2">
                  {searchText
                    ? utility.getHighlightedText(
                      (client?.last_name !== null
                          ? client?.last_name + ", "
                          : "") +
                          " " +
                          client?.first_name,
                      searchText
                    )
                    : (client?.last_name !== null
                        ? client?.last_name + ", "
                        : "") +
                      " " +
                      client?.first_name}
                </label>
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default PublishDropDown;
