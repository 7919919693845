import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
const Loader = (props) => {
  const [isActive, setIsActive] = useState(false);
  const antIcon = <LoadingOutlined style={{ fontSize: 75 }} spin />;

  useEffect(() => {
    let activeTimeout;
    let active = props.active;
    if (!active) {
      if (activeTimeout) {
        clearTimeout(this.activeTimeout);
      }
      setIsActive(false);
    } else {
      activeTimeout = setTimeout(() => {
        setIsActive(active);
      }, props?.startActiveTime? props?.startActiveTime:3000);
    }
  }, [props.active]);

  useEffect(()=>{
    setIsActive(props?.noTimeOut)
  },[props.noTimeOut])

  return (
    <>
      {isActive && (
        <div style={{position:props?.previewiframe?'absolute':props?.iframe?'inherit':'fixed'}} className="loader_wrapper">
          <div className="loader07">
            <Spin indicator={antIcon} />
          </div>
        </div>
      )}
    </>
  );
};

export default Loader;
