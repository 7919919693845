import React, { useEffect, useState } from "react";
import { isMobile, isSafari } from 'react-device-detect';

import Icon from "../../Assets/Images/exclamation.svg";
import Loading from "../../components/Loader";
import { financial } from '../../redux/actions/Financial/Investment';
import moment from "moment";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

const Investment = () => {
  const [financialUrl, setFinancialUrl] = useState(localStorage.getItem("financialUrl"));
  const [showFinancialFeature, setShowFinancialFeature] = useState(true)
  const [loader, setLoader] = useState(true);
  const [errorMessage, setErrorMessage] = useState(true);
  const [iframeLoadTime, setIframeLoadTime] = useState(moment(new Date()))
  const [showTimeOutError, setShowTimeOutError] = useState(false)
  const [postApiCall, setPostAPICall] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();



  function iframeLogin(status) {
    const data = {
      system: "website",
      status: status,
      orion_household_id: location?.state?.orion_hh_id,
      orion_household_name: location?.state?.orion_hh_name
    }
    if (!postApiCall) {
      setPostAPICall(true)
      dispatch(financial.postIframeLogin(data)).then((response) => {
        if (response.type === "POST_FINANCIALLOGINIFRAMEURL_SUCCESS") {
          setPostAPICall(true)
        }
      });
    }
  }


  useEffect(() => {
    let nIntervId
    setLoader(true);
    let start = 0;
    {
      !financialUrl && (
        nIntervId = setInterval(() => {
          if (start > 30) {
            setLoader(false);
            setShowFinancialFeature(false);
            clearInterval(nIntervId);
            iframeLogin('failure')
          }
          setFinancialUrl(localStorage.getItem("financialUrl"));
          if (localStorage.getItem("financialUrl") && localStorage.getItem("financialUrl") !== "Invalid") {
            clearInterval(nIntervId);
          }
          else if (localStorage.getItem("financialUrl") === "Invalid") {
            iframeLogin('failure')
          }
          start = start + 1
        }, 500))
    }
  }, [financialUrl])


  useEffect(() => {
    if (document.getElementsByTagName("header")[0]) {
      let header = document.getElementsByTagName("header")[0].offsetHeight;
      let windowHeight = window.innerHeight;
      let height = windowHeight - (header + 12) + "px";
      const error_div = document.getElementById("error-div")
      if (error_div) {
        error_div.style.height = height;
      }
    }
    if (isSafari) {
      setErrorMessage("This integration is not supported by the Safari browser. Please try again using a different browser.")
    }
    else if (isMobile) {
      setErrorMessage("This integration is not currently supported on mobile devices, but we’re working on it and it’s coming soon!")
    }
    else if (showTimeOutError) {
      setErrorMessage("Your session has timed out. Please click refresh.")
    }
    else {
      setErrorMessage("We encountered an error while loading your investments data. Please refresh the page, and if the issue persists, contact your advisor for assistance.")
    }
  }, [showTimeOutError])


  return (
    <>
      {financialUrl && financialUrl !== "Invalid" && showFinancialFeature && !isSafari && !isMobile && !showTimeOutError ?
        <>
          {loader && <Loading iframe={true} active={loader} />}
          <iframe
            id="iframe-view-doc"
            src={financialUrl}
            height='100%'
            width='100%'
            className="responsive-iframe"
            onLoad={(event) => {
              const today_date = moment(new Date())
              const duration = moment.duration(today_date.diff(iframeLoadTime));
              if (parseInt(duration.asMinutes()) > 30) {
                setShowTimeOutError(true)
              }
              iframeLogin('success')
              setIframeLoadTime(moment(new Date()))
              setTimeout(() => {
                setLoader(false)
              }, 8000)
            }
            }
          ></iframe>
        </>
        : <div id="error-div" className="task-noresult-cdc d-flex align-items-center justify-content-center text-center ">
          <div className="max-350">
            <img src={Icon} alt="" className="mb-16" />
            <p className="fs-18 lh-24 m-0 mb-16">{errorMessage}</p>
            {!isSafari && !isMobile &&
              <button
                className="btn btn-outline-secondary  d-inline-flex align-items-center" style={{ color: 'white', backgroundColor: 'black' }}
                onClick={() => window.location.reload()}
              >
                <span className="icon-24 me-8">
                  <em className="icon-refresh-outline"></em>
                </span>
                Refresh
              </button>
            }
          </div>
        </div>}
    </>
  );
};

export default Investment;