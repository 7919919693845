import "sendbird-uikit/dist/index.css";

import {
  Channel,
  ChannelList,
  MessageSearch,
  SendBirdProvider,
  sendBirdSelectors,
  withSendBird,
} from "sendbird-uikit";
import { Dropdown, Menu } from "antd";
import React, { useCallback, useEffect, useState } from "react";

import GlobalSearch from "./GlobalSearch";
import Loading from "../../components/Loader";
import Modal from "react-bootstrap/Modal";
import axiosInstance from "../../config/axios";
import calendarClose from "../../Assets/Images/calendar-close.svg";
import { message } from "../../redux/actions/Message/Message";
import moment from "moment";
import { notificationService } from "../../redux/actions/Notification/Notification";
import salesForce from "../../Assets/Images/salesforce.svg";
import { useDispatch } from "react-redux";
import { utility } from "../../components/Utility";

/* eslint-disable indent */

const myColorSet = {
  "--sendbird-light-primary-500": "#10B981",
  "--sendbird-light-primary-400": "#10B981",
  "--sendbird-light-primary-300": "#41BA77",
  "--sendbird-light-primary-200": "#2",
  "--sendbird-light-primary-100": "#1",
};


function getMessageTime(message) {
  return moment(message?.createdAt).format("YYYY-MM-DD") ===
    moment().format("YYYY-MM-DD")
    ? moment(message?.createdAt).format("hh:mm A")
    : moment(message?.createdAt) <= moment().subtract(1, "weeks")
      ? moment(message?.createdAt).format("ll")
      : moment(message?.createdAt).format("dddd");
}


function getChannelMessageTime(channel) {
  return channel?.lastMessage?.createdAt
    ? moment(channel?.lastMessage?.createdAt).format(
      "YYYY-MM-DD"
    ) === moment().format("YYYY-MM-DD")
      ? moment(channel?.lastMessage?.createdAt).format("hh:mm A")
      : moment(channel?.lastMessage?.createdAt) <=
        moment().subtract(1, "weeks")
        ? moment(channel?.lastMessage?.createdAt).format("ll")
        : moment(channel?.lastMessage?.createdAt).format("dddd")
    : moment(channel?.createdAt).format("YYYY-MM-DD") ===
      moment().format("YYYY-MM-DD")
      ? moment(channel?.createdAt).format("hh:mm A")
      : moment(channel?.createdAt) <= moment().subtract(1, "weeks")
        ? moment(channel?.createdAt).format("ll")
        : moment(channel?.createdAt).format("dddd");
}



const sendbirdAppId =
  window.location.host.includes("localhost") ||
    window.location.host.includes("devportal.monetagroup.com")
    ? "BC8F8BA6-681A-4111-8617-DB3568C73E57"
    : window.location.host.includes("stageportal.monetagroup.com")
      ? "2D346571-32CF-45CA-B319-4DE483FC292B"
      : "9644B7B7-43C0-407C-8E27-070978440596";


const Messages = (props) => {
  const [stringSet] = useState({
    MODAL__CREATE_CHANNEL__TITLE: "New Message", // You can display these texts in other languages.
  });

  const [channelUrl, setchannelUrl] = useState("");
  const [operatorCount, setoperatorCount] = useState("");
  const [channel, setchannel] = useState("");
  const [highlightedMessage, setHighlightedMessage] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [showInviteFilter, setshowInviteFilter] = useState(false);
  const [selectedClientHouseHold, setselectedClientHouseHold] = useState(undefined);
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const [loader, setLoader] = useState(false);
  const [showChannelAction, setChannelActionShow] = useState(false);

  const [channelSearchText, setChannelSearchText] = useState("");
  const [userDetails, setUserDetails] = useState(false);
  const dispatch = useDispatch();
  const userInfo = JSON.parse(localStorage.getItem("userDetails"));

  const userDetailsUuid = userInfo?.uuid.trim();
  const userId = userInfo?.sendbird_user_id.trim();
  const accessToken = userInfo?.sendbird_user_token.trim();
  const isAdvisor = userInfo?.user_type === "advisors";
  const isClient = userInfo?.user_type === "clients";

  useEffect(() => {
    dispatch(message.GetClientList()).then((response) => {
      setLoader(false);
      if (response.type === "GET_CLIENT_LIST_SUCCESS") {
        setClientList(response.data);
      }
    });
  }, [dispatch]);

  const getClientList = () => {
    if (!loader) {
      setLoader(true);
      dispatch(message.GetClientList()).then((response) => {
        setLoader(false);
        if (response.type === "GET_CLIENT_LIST_SUCCESS") {
          setClientList(response.data);
          setShowFilter(true);
          setLoader(false);
        } else {
          setLoader(false);
        }
      });
    }
  };

  window.addEventListener("click", (event) => {
    if (
      event.target.classList.contains("sendbird-notification") ||
      event.target.classList.contains("sendbird-notification__text") ||
      event.target.classList.contains("sendbird-icon-chevron-down")
    ) {
      event.target.classList.add("d-none");
      setRefresh(!refresh);
    } else if (
      event.target.classList.contains("sendbird-message-item-menu__trigger") ||
      event.target.localName === "svg" ||
      event.target.classList.contains("icon-more_svg__fill")
    ) {
      const popup = document.getElementsByClassName("sendbird-dropdown__menu");

      popup[0].style.bottom =
        window.innerHeight - (event.target.getBoundingClientRect().top - 8) + "px";
    }
  });

  const handleFilterClose = () => {
    setShowFilter(false);
  };

  const handleInviteFilterClose = () => {
    setshowInviteFilter(false);
  };

  const getChannelName = (members) => {
    return [...members].sort((a, b) => a.nickname.localeCompare(b.nickname)).map(m => m.nickname).join(", ");
  };


  const hideChannelInfo = () => {
    setShowSearch(false);
    setShowInfo(false);
    setShowFilter(false);
    setshowInviteFilter(false);
    setShowUserDetails(false);
    setHighlightedMessage("");
    setChannelSearchText("");
  };

  function removeUserFromChannel(isfromChannelAction) {
    dispatch(message.removeUserFromChannel(channelUrl, userId)).then(
      (response) => {
        setLoader(false);
        handleChannelActionActionClose();
        if (isfromChannelAction === true) {
          setchannelUrl("");
        }
        setRefresh(!refresh);
      }
    );
  }

  function removeUserFromChannelFromAction(channel) {
    dispatch(message.removeUserFromChannel(channel.url, userId)).then(
      () => {
        setLoader(false);
        handleChannelActionActionClose();
        setchannelUrl("");
        setRefresh(!refresh);
      }
    );
  }

  function muteChannel(channel) {
    dispatch(
      message.MuteChannel(
        channel?.url,
        channel.myPushTriggerOption === "default" ? "off" : "default"
      )
    ).then(() => {
      setLoader(false);
      handleChannelActionActionClose();
      setRefresh(!refresh);
    });
  }
  const handleChannelActionActionClose = () => {
    setChannelActionShow(false);
  };
  function setChannelName() {
    setTimeout(() => {
      var eles = document.getElementsByClassName(
        "sendbird-place-holder__body--align-top__text__channel-name"
      );
      eles[0].innerHTML =
        "'" +
        (channel?.name === "Group Channel"
          ? getChannelName(channel?.members)
          : channel?.name);
    }, 100);
  }

  function checkOutOfOffice(member) {
    let afterFilter = member?.filter((c) => {
      return c?.userId !== userDetailsUuid;
    });

    let test = afterFilter?.filter((c) => {
      return c?.metaData?.ooo === "true";
    });
    if (afterFilter?.length > 1 && test?.length) {
      return `${test?.length} ${test?.length > 1 ? "members are" : "member is"}  out of office`;
    } else if (afterFilter?.length === 1 && test?.length) {
      return `Out of office until ${moment(test[0].metaData?.to_date).format(
        "MMM, D"
      )}`;
    } else if (test?.length) {
      return false;
    }
  }

  function checkReadReceipt(member) {

    let currentUser = member?.filter((c) => {
      return c?.metaData?.is_read_receipts_enabled === "true";
    });
    let element = document.getElementsByClassName("message-wrapper")[0];
    if (currentUser?.length !== member?.length) {
      element.classList.add("read-recipt");
    }
  }

  function getUnreadMessageCount() {
    dispatch(notificationService.getUnreadMessageCount()).then(
      (response) => { }
    );
  }
  const MyCustomPreview = ({ channel, onLeaveChannel }) => (
    <>
      <div
        className={`channel-block d-flex ${channelUrl === channel.url ? "current-message" : ""
          }`}
        onClick={() => {
          document.body.classList.add("chat-open");
          setchannelUrl(channel.url);
          setoperatorCount(
            channel?.members?.filter((c) => c.role === "operator")?.length
          );
          setchannel(channel);
          hideChannelInfo();
          getUnreadMessageCount();
        }}
      >
        <div className="position-relative">
          <div className="channel-list-photo">{getChannelProfile(channel)}</div>
          {checkOutOfOffice(channel?.members) && (
            <img className="calendar-close" src={calendarClose} alt="" />
          )}
        </div>
        <div className="flex-fill channel-right">
          <div className="d-flex align-items-center justify-content-between mb-4">
            <div className="d-flex align-items-center max-chann">
              <h6 className="">
                {channel?.name === "Group Channel"
                  ? getChannelName(channel?.members)
                  : channel?.name}
              </h6>
              {channel?.myPushTriggerOption !== "default" && (
                <span className="icon-20 silent-bell">
                  <em className="sb-notifications-off"></em>
                </span>
              )}
            </div>
            <div className="d-flex align-items-center">
              <span className="mesg-time">
                {getChannelMessageTime(channel)}{" "}
              </span>
              <div
                className="dropdown ms-8 d-none d-md-none"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Dropdown
                  trigger={["click"]}
                  placement="bottomRight"
                  overlay={
                    <Menu>
                      <Menu.Item
                        key="1"
                        onClick={(e) => {
                          muteChannel(channel);
                        }}
                      >
                        {channel.myPushTriggerOption === "default"
                          ? "Mute Channel"
                          : "Unmute Channel"}
                      </Menu.Item>
                      {(channel?.members?.filter((c) => c.role === "operator")
                        ?.length > 1 ||
                        !isAdvisor) && (
                          <Menu.Item
                            className="text-danger"
                            onClick={(e) => {
                              removeUserFromChannelFromAction(channel);
                            }}
                          >
                            Leave Channel
                          </Menu.Item>
                        )}
                    </Menu>
                  }
                >
                  <span className="icon-32 cursor-pointer dropdown-toggle">
                    <em className="icon-dots-vertical-outline"></em>
                  </span>
                </Dropdown>
                <ul className="dropdown-menu">
                  {(channel?.members?.filter((c) => c.role === "operator")
                    ?.length > 1 ||
                    !isAdvisor) && (
                      <li>
                        <a className="dropdown-item" href="#">
                          Leave Channel
                        </a>
                      </li>
                    )}
                  <li>
                    <a className="dropdown-item" href="#">
                      Mute Channel
                    </a>
                  </li>
                </ul>
              </div>
              <span className="icon-24 cursor-pointer d-md-none">
                <em
                  className="icon-dots-vertical-outline"
                  onClick={(e) => {
                    e.stopPropagation();
                    setChannelActionShow(true);
                  }}
                ></em>
              </span>
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <p className="text-muted">{checkOutOfOffice(channel?.members)}</p>
          </div>
          <div className="d-flex justify-content-between">
            <p className="text-muted" style={{ fontFamily: 'Segoe UI' }}>
              {channel?.lastMessage?.messageType !== "file"
                ? channel?.lastMessage?.message
                : channel?.lastMessage?.name}
            </p>
            {channel?.unreadMessageCount !== 0 && (
              <span className="noread-digit">
                {channel?.unreadMessageCount}
              </span>
            )}
          </div>
        </div>
      </div>

      <Modal
        size="sm"
        show={showChannelAction}
        onHide={handleChannelActionActionClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Actions</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <ul className="list-unstyled bulk-list action-list m-0">
            <li
              onClick={(e) => {
                muteChannel(channel);
              }}
            >
              {" "}
              {channel.myPushTriggerOption === "default"
                ? "Mute Channel"
                : "Unmute Channel"}
            </li>
            {(channel?.members?.filter((c) => c.role === "operator")?.length >
              1 ||
              !isAdvisor) && (
                <li
                  className="text-danger"
                  onClick={(e) => {
                    setchannelUrl(channel?.url);
                    removeUserFromChannel(true);
                  }}
                >
                  Leave Channel
                </li>
              )}
          </ul>
        </Modal.Body>
      </Modal>
    </>
  );

  const CustomSearchMessagePreview = ({ message }) => (
    <>
      <div
        className="channel-block d-flex"
        onClick={() => {
          setHighlightedMessage(message.messageId);
          setShowSearch(!showSearch);
        }}
      >
        <div className="channel-list-photo">
          {utility.getProfileImage(
            channel?.members?.filter(
              (m) => m.userId === message?._sender?.userId
            )[0]?.profileUrl,
            message?._sender?.nickname?.charAt(0),
            message?._sender?.nickname?.split(" ")[1]?.charAt(0)
          )}
        </div>
        <div className="flex-fill channel-right">
          <div className="d-flex align-items-center justify-content-between mb-md-8 mb-4">
            <div className="d-flex align-items-center max-chann">
              <h6 className="fw-normal">{message?._sender?.nickname}</h6>
            </div>
            <div className="d-flex align-items-center">
              <span className="mesg-time">
                {getMessageTime(message)}
              </span>
            </div>
          </div>
          <div className="d-flex text-break fs-16 lh-20">
            {message?.messageType === "file" && (
              <>
                {(message?.type.includes("video") ||
                  message?.type.includes("audio")) && (
                    <span className="icon-24">
                      <em className="icon-play-filled text-success"></em>
                    </span>
                  )}
                {message?.type.includes("image") && (
                  <span className="icon-24">
                    <em className="icon-image-outline text-success"></em>
                  </span>
                )}
                {message?.type.includes("application") && (
                  <span className="icon-24">
                    <em className="icon-document-text-filled text-success "></em>
                  </span>
                )}
              </>
            )}
            {getHighlightedText(
              message?.messageType === "file"
                ? message?.name
                : message?.message,
              channelSearchText
            )}
          </div>
        </div>
      </div>
    </>
  );

  const CustomComponent = ({ createChannel, sdk, leaveChannel }) => {
    const [state, setState] = useState({
      valueFilter: "",
      filterList: clientList?.data,
      numberSelected: 0,
      selectedHousehold: 0,
      isMandatoryMemberSelected: false,
    });
    const {
      valueFilter,
      filterList,
      numberSelected,
      selectedHousehold,
      isMandatoryMemberSelected,
    } = state;

    function selectMember(checked, client) {
      let indx = clientList?.data?.findIndex((c) => c.uuid === client.uuid);
      clientList.data[indx].isSelected = checked;
      if (isAdvisor) {
        const isClientSelected = clientList?.data?.some(
          (c) => c.user_type === "clients" && c.isSelected
        );
        if (!isClientSelected) {
          clientList?.data
            ?.filter((c) => c.user_type !== "clients")
            .map((c) => (c.isSelected = false));
        }
      }
      setClientList(clientList);
      const numberOfSelectedClients = clientList?.data?.filter((c) => c.isSelected);

      const temp = clientList?.data?.some(
        (c) =>
          (isAdvisor
            ? c.user_type === "clients"
            : c.user_type !== "clients") && c.isSelected
      );
      if (client?.user_type !== "advisors") {
        setState((s) => ({
          ...s,
          selectedHousehold: temp ? client.household : 0,
          isMandatoryMemberSelected: temp,
          numberSelected: numberOfSelectedClients?.length,
        }));
      } else {
        setState((s) => ({
          ...s,
          isMandatoryMemberSelected: temp,
          numberSelected: numberOfSelectedClients?.length,
        }));
      }
    }
    return (
      <>
        <div className="msg-channel-header d-none d-md-flex align-items-center justify-content-between">
          <h5 className="m-0">Channels</h5>
          <button
            className="create-channel-btn icon-32"
            onClick={() => {
              getClientList();
            }}
          >
            {" "}
          </button>
        </div>
        <Modal
          className="modal-full filtbyclient-modal"
          show={showFilter}
          onHide={handleFilterClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>New Message</Modal.Title>
          </Modal.Header>
          <Modal.Body className="ps-0 pe-0 pt-0 pt-md-16">
            <div className="px-24 py-12 border-md-top">
              <div className="search-area">
                <input
                  placeholder="Search Users..."
                  className="form-control"
                  value={valueFilter}
                  onChange={(e) => {
                    let lst = clientList?.data?.filter(
                      (c) =>
                        e.target.value === "" ||
                        (
                          c.first_name?.toLowerCase() +
                          " " +
                          c.last_name?.toLowerCase()
                        ).includes(e.target.value?.toLowerCase())
                    );
                    setState((s) => ({
                      ...s,
                      valueFilter: e.target.value,
                      filterList: lst,
                    }));
                  }}
                />
                <button className="search-btn icon-20">
                  <em className="icon-search-outline"></em>
                </button>
                {valueFilter !== "" && (
                  <button
                    className="close-search-btn icon-20"
                    onClick={(event) => {
                      const value = "";
                      const lst = clientList?.data?.filter(
                        (c) =>
                          value === "" ||
                          (
                            c.first_name?.toLowerCase() +
                            " " +
                            c.last_name?.toLowerCase()
                          ).includes(value?.toLowerCase())
                      );
                      setState((s) => ({
                        ...s,
                        valueFilter: value,
                        filterList: lst,
                      }));
                    }}
                  >
                    <em className="icon-x-circle-filled"></em>
                  </button>
                )}
              </div>
            </div>
            <div className="selected-client fw-500 text-success border-top">
              {numberSelected} selected
            </div>
            <div className="message-check-main custom-style-scroll pr-8">
              <div className="ps-24 pe-8">
                <div>
                  <h6 className="fs-14 lh-20 text-uppercase mb-4 ">
                    {clientList?.data?.filter((x) => x.user_type === "clients")?.length > 0 ? (isAdvisor
                      ? "MY CLIENTS"
                      : "MY HOUSEHOLD") : ""}
                  </h6>
                  <ul className="message-check-list list-unstyled mb-8">
                    {filterList
                      ?.filter(
                        (x) =>
                          (selectedHousehold === 0 ||
                            x.household === selectedHousehold) &&
                          x.user_type === "clients"
                      )
                      ?.map((client, index) => (
                        <li key={index}>
                          <div className="d-flex align-items-center">
                            <span className="m-client-photo icon-32">
                              {utility.getProfileImage(
                                client?.profile_image,
                                client?.first_name?.charAt(0),
                                client?.last_name?.charAt(0)
                              )}
                            </span>
                            <label
                              className="form-check-label fs-16 lh-24"
                              htmlFor="filtclient2"
                            >
                              {client.first_name + " " + client.last_name}
                            </label>
                          </div>
                          <div className="form-check mb-0 no-text-form-check">
                            {client?.isSelected && (
                              <input
                                className="form-check-input"
                                type="Checkbox"
                                id={client.first_name}
                                checked={true}
                                onChange={(e) => {
                                  selectMember(e?.target?.checked, client);
                                }}
                              />
                            )}
                            {!client?.isSelected && (
                              <input
                                className="form-check-input"
                                type="Checkbox"
                                id={client.first_name}
                                checked={false}
                                onChange={(e) => {
                                  selectMember(e?.target?.checked, client);
                                }}
                              />
                            )}
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
                {(isMandatoryMemberSelected ||
                  !isAdvisor) && (
                    <div>
                      <h6 className="fs-14 lh-20 text-uppercase mb-4 ">
                        {clientList?.data?.filter((x) => x.user_type !== "clients")?.length > 0 ? (isAdvisor
                          ? "MY TEAM"
                          : "MY ADVISORY TEAM") : ""}
                      </h6>
                      <ul className="message-check-list list-unstyled mb-8">
                        {filterList
                          ?.filter((x) => x.user_type !== "clients")
                          ?.map((client, index) => (
                            <li key={index}>
                              <div className="d-flex align-items-center">
                                <span className="m-client-photo icon-32">
                                  {utility.getProfileImage(
                                    client?.profile_image,
                                    client?.first_name?.charAt(0),
                                    client?.last_name?.charAt(0)
                                  )}
                                </span>
                                <label
                                  className="form-check-label fs-16 lh-24"
                                  htmlFor="filtclient2"
                                >
                                  {client.first_name + " " + client.last_name}
                                </label>
                              </div>
                              <div className="form-check mb-0 no-text-form-check">
                                {client?.isSelected && (
                                  <input
                                    className="form-check-input"
                                    type="Checkbox"
                                    id={client.first_name}
                                    checked={true}
                                    onChange={(e) => {
                                      selectMember(e?.target?.checked, client);
                                    }}
                                  />
                                )}
                                {!client?.isSelected && (
                                  <input
                                    className="form-check-input"
                                    type="Checkbox"
                                    id={client.first_name}
                                    checked={false}
                                    onChange={(e) => {
                                      selectMember(e?.target?.checked, client);
                                    }}
                                  />
                                )}
                              </div>
                            </li>
                          ))}
                      </ul>
                    </div>
                  )}
              </div>
            </div>
            {!isMandatoryMemberSelected && (
              <div className="px-24">
                <div className="alert alert-warning d-flex align-items-center justify-content-center text-center mt-16 mb-0">
                  <div className="flex-fill">
                    <h6 className="mb-0">
                      {isAdvisor
                        ? "Please add at least one client to proceed"
                        : "Please add at least one advisory team member to proceed"}
                    </h6>
                  </div>
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div className="d-md-flex align-items-center">
              <button
                className="btn btn-outline-secondary"
                variant="secondary"
                onClick={(e) => {
                  e.preventDefault();
                  handleFilterClose();
                }}
              >
                Cancel
              </button>
              <button
                className="btn btn-success"
                variant="primary"
                disabled={!isMandatoryMemberSelected}
                onClick={() => {
                  if (isMandatoryMemberSelected) {
                    setShowFilter(true);
                    let params = new sdk.GroupChannelParams();
                    params.isPublic = false;
                    params.isEphemeral = false;
                    params.isDistinct = true;
                    let userIds = clientList?.data
                      ?.filter(
                        (c) => c.user_type === "advisors" && c.isSelected
                      )
                      .map((item) => item.sendbird_user_id);
                    if (isAdvisor) {
                      userIds.push(userId);
                    }
                    params.operatorUserIds = userIds;
                    params.addUserIds(
                      clientList.data
                        .filter((c) => c.isSelected === true)
                        .map((item) => item.sendbird_user_id)
                    );
                    params.name = "Group Channel";
                    createChannel(params)
                      .then((c) => {
                        setchannelUrl(c.url);
                        setchannel(c);
                        setShowFilter(false);
                        setoperatorCount(
                          c?.members?.filter(
                            (item) => item.role === "operator"
                          )?.length
                        );
                        clientList.data.map(
                          (item) => (item.isSelected = false)
                        );
                        setClientList(clientList);
                      })
                      .catch((c) => {
                        console.warn(c);
                        setShowFilter(false);
                      });
                  }
                }}
              >
                Create
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  };
  const CustomChannelComponent = ({ createChannel, sdk, leaveChannel }) => {
    return (
      <>
        <Channel
          useMessageGrouping={false}
          renderChatHeader={CustomChatHeader}
          replyType="QUOTE_REPLY"
          channelUrl={channelUrl}
          highlightedMessage={highlightedMessage}
          onBeforeUpdateUserMessage={(text) => {
            if (text) {
              const params = new sdk.UserMessageParams();
              params.message = text;
              return params;
            }
          }}
        />
      </>
    );
  };
  const CustomChannelInfoComponent = ({ sdk, leaveChannel }) => {
    const [currentMember, setcurrentMember] = useState({});
    const [editName, setEditName] = useState(false);
    const [img, setImg] = useState();
    const [channelLoader, setChannelLoader] = useState(false);
    const [ShowMembers, setShowMembers] = useState(false);
    const [ShowRemovePhotoOption, setShowRemovePhotoOption] = useState(false);
    const [showMemberAction, setMemberActionShow] = useState(false);
    const [state, setState] = useState({
      valueFilter: "",
      filterList: clientList?.data,
      numberSelected: 0,
      tempChannelName: channel.name,
      file: "",
    });
    const handleMemberActionActionClose = () => {
      setMemberActionShow(false);
    };

    const { valueFilter, filterList, numberSelected, tempChannelName } = state;
    const handleEditClose = () => {
      setEditName(false);
      setImg("");
      setState((s) => ({
        ...s,
        file: "",
      }));
    };
    const onImageChange = (e) => {
      setShowRemovePhotoOption(true);
      setState((s) => ({
        ...s,
        file: e.target.files[0],
      }));
      setImg(URL.createObjectURL(e.target.files[0]));
    };
    const menu = (
      <Menu>
        <Menu.Item
          key="1"
          onClick={(e) => {
            getUserDetails();
          }}
        >
          View Info
        </Menu.Item>
        {!isClient &&
          currentMember.userId !== userId &&
          (currentMember.role !== "operator" || operatorCount >= 2) && (
            <Menu.Item
              className="text-danger"
              key="2"
              onClick={(e) => {
                removeUserFromChannel();
              }}
            >
              Remove
            </Menu.Item>
          )}
      </Menu>
    );

    function getUserDetails() {
      dispatch(message.getUserDetails(currentMember?.userId)).then(
        (response) => {
          setLoader(false);
          if (response.type === "GET_USER_DETAILS_SUCCESS") {
            setUserDetails(response.data);
            setShowUserDetails(true);
            setShowInfo(false);
          }
        }
      );
    }

    function removeUserFromChannel() {
      dispatch(
        message.removeUserFromChannel(channelUrl, currentMember?.userId)
      ).then(() => {
        setLoader(false);
        setRefresh(!refresh);
      });
    }

    function InviteUserToChannel() {
      dispatch(
        message.InviteUserToChannel(
          channelUrl,
          clientList.data
            .filter((c) => c.isSelected === true)
            .map((item) => item.sendbird_user_id)
        )
      ).then(() => {
        setLoader(false);
        handleInviteFilterClose();
      });
    }

    function updateChannel(tempChannelName) {
      setChannelLoader(true);
      dispatch(message.updateChannel(channelUrl, tempChannelName)).then(
        () => {
          setChannelLoader(false);
          hideChannelInfo();
        }
      );
    }

    function updateChannelCover(file) {
      setChannelLoader(true);
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "token " + localStorage.getItem("token")
      );

      var formdata = new FormData();
      if (file !== null) {
        formdata.append("cover_file", file, "images.jpeg");
      }
      formdata.append("channel_url", channel.url);
      axiosInstance
        .put(`/message/update_channel_cover`, formdata)
        .then((response) => {
          hideChannelInfo();
          setChannelLoader(false);
        })
        .catch((error) => {
          setChannelLoader(false);
        });
    }
    return (
      <>
        {channelLoader && <Loading active={channelLoader} />}
        {!ShowMembers && (
          <div className="channel-search-sidebar d-flex flex-column">
            <div className="channel-search-header d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <span
                  className="icon-32 cursor-pointer d-md-none me-12"
                  onClick={(e) => {
                    setShowInfo(!showInfo);
                  }}
                >
                  <em className="icon-chevron-left-outline text-success"></em>
                </span>
                <h5 className="m-0 lh-24 fs-18">Channel information</h5>
              </div>
              <span
                className="icon-24 cursor-pointer d-none d-md-block"
                onClick={(e) => {
                  setShowInfo(!showInfo);
                }}
              >
                <em className="icon-x-outline"></em>
              </span>
              <a
                href="javascript:void(0);"
                className="text-success fw-500 cursor-pointer d-md-none"
                onClick={(e) => {
                  setEditName(true);
                  setState((s) => ({
                    ...s,
                    tempChannelName:
                      channel?.name === "Group Channel"
                        ? getChannelName(channel?.members)
                        : channel?.name,
                  }));
                }}
              >
                Edit
              </a>
            </div>
            <div className="custom-style-scroll">
              {" "}
              <div className="member-photo-prof text-center position-relative">
                <a
                  href="javascript:void(0);"
                  className="channel-edit fw-500 d-none d-md-block fs-14 lh-20"
                  onClick={(e) => {
                    if (
                      channel.coverUrl.includes("/sample") ||
                      channel.coverUrl === ""
                    ) {
                      setShowRemovePhotoOption(false);
                    } else {
                      setShowRemovePhotoOption(true);
                    }
                    setEditName(true);
                    setState((s) => ({
                      ...s,
                      tempChannelName:
                        channel?.name === "Group Channel"
                          ? getChannelName(channel?.members)
                          : channel?.name,
                    }));
                  }}
                >
                  Edit
                </a>
                <div className="channel-user-photo mx-auto mb-8">
                  {getChannelProfile(channel)}
                </div>
                <h6 className="mb-0 lh-20 text-ellipsis">
                  {channel?.name === "Group Channel"
                    ? getChannelName(channel?.members)
                    : channel?.name}
                </h6>
              </div>
              <div className="member-dropmenu d-none d-md-block">
                <Menu
                  defaultSelectedKeys={["1"]}
                  defaultOpenKeys={["sub1"]}
                  mode="inline"
                >
                  <Menu.SubMenu
                    key="sub4"
                    icon={
                      <span className="icon-24 text-success">
                        <em className="sb-members"></em>
                      </span>
                    }
                    title={
                      <div className="d-flex align-items-center justify-content-between">
                        Members{" "}
                        <span className="digit-circle icon-24">
                          {channel?.members?.length}
                        </span>
                      </div>
                    }
                  >
                    {channel?.members?.map((member, index) => (
                      <Menu.Item key={index}>
                        <span className="icon-24 digit-circle">
                          {utility.getProfileImage(
                            member?.profileUrl,
                            member?.nickname?.charAt(0),
                            member?.nickname?.split(" ")[1]?.charAt(0)
                          )}
                        </span>
                        <span>
                          {member.userId !== userId
                            ? member.nickname
                            : member.nickname + " (You)"}{" "}
                        </span>
                        {member.userId !== userId && (
                          <Dropdown
                            placement="bottomRight"
                            trigger={["click"]}
                            overlay={menu}
                            onClick={(e) => {
                              setcurrentMember(member);
                            }}
                          >
                            <spna className="icon-32">
                              <em className="icon-dots-vertical-outline"></em>
                            </spna>
                          </Dropdown>
                        )}
                      </Menu.Item>
                    ))}
                    {!isClient && (
                      <Menu.Item className="invite-member-btn">
                        <div className="w-100">
                          <button
                            type="button"
                            className="btn btn-success w-100"
                            onClick={() => {
                              setState((s) => ({
                                ...s,
                                filterList: clientList?.data.map(c => c.isSelected = false),
                              }));
                              let userId = channel?.members.find(c => c.role !== "operator")?.userId;
                              setselectedClientHouseHold(clientList?.data?.find(c => c.sendbird_user_id === userId)?.household)
                              setshowInviteFilter(true);
                            }}
                          >
                            Invite Members
                          </button>
                        </div>
                      </Menu.Item>
                    )}
                  </Menu.SubMenu>
                </Menu>
              </div>
              <div
                className="member-dropmenu d-md-none"
                onClick={(e) => {
                  setShowMembers(true);
                }}
              >
                <Menu
                  defaultSelectedKeys={["1"]}
                  defaultOpenKeys={["sub1"]}
                  mode="inline"
                >
                  <Menu.SubMenu
                    key="sub4"
                    icon={
                      <span className="icon-24 text-success">
                        <em className="sb-members"></em>
                      </span>
                    }
                    title={
                      <div className="d-flex align-items-center justify-content-between">
                        Members{" "}
                        <span className="digit-circle icon-24">
                          {channel?.members?.length}
                        </span>
                      </div>
                    }
                  ></Menu.SubMenu>
                </Menu>
              </div>
              {(channel?.members?.filter((c) => c.role === "operator")?.length >
                1 ||
                !isAdvisor) && (
                  <div className="cursor-pointer">
                    <button
                      type="button"
                      className="leave-channel-btn d-flex align-items-center"
                      onClick={() => {
                        leaveChannel(channelUrl)
                          .then((c) => {
                            setchannelUrl("");
                            setchannel("");
                          })
                          .catch((c) => console.warn(c));
                      }}
                    >
                      <span className="icon-24 fs-20 text-danger me-16">
                        <em className="sb-leave"></em>
                      </span>
                      Leave channel
                    </button>
                  </div>
                )}
            </div>
          </div>
        )}

        {ShowMembers && (
          <div className="channel-search-sidebar d-flex d-md-none flex-column">
            <div className="channel-search-header d-flex align-items-center justify-content-between mb-16">
              <div className="d-flex align-items-center">
                <span
                  className="icon-32 cursor-pointer d-md-none me-12"
                  onClick={(e) => {
                    setShowMembers(false);
                  }}
                >
                  <em className="icon-chevron-left-outline text-success"></em>
                </span>
                <h5 className="m-0 lh-24 fs-18">Members</h5>
              </div>
            </div>
            <div className="channel-search-body flex-fill">
              {channel?.members.map((member, index) => (
                <div
                  className={`mem-channel-block d-flex align-items-center`}
                  key={index}
                >
                  {/* {channel} */}
                  <div className="channel-mem-photo">
                    {utility.getProfileImage(
                      member?.profileUrl,
                      member?.nickname?.charAt(0),
                      member?.nickname?.split(" ")[1]?.charAt(0)
                    )}
                  </div>
                  <div className="flex-fill channel-right">
                    <div className="d-flex align-items-center justify-content-between mb-md-8 mb-4">
                      <div className="d-flex align-items-center max-chann">
                        <h6 className="fw-normal">{member.nickname}</h6>
                      </div>
                      <div className="d-flex align-items-center">
                        <button
                          type="button"
                          className="btn btn-outline-secondary btn-icon-only"
                        >
                          <em
                            className="icon-dots-vertical-outline"
                            onClick={(e) => {
                              e.stopPropagation();
                              setoperatorCount(
                                channel?.members?.filter(
                                  (c) => c.role === "operator"
                                )?.length
                              );
                              setMemberActionShow(true);
                              setcurrentMember(member);
                            }}
                          ></em>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {!isClient && (
              <div className="channel-search-footer py-16 px-24 border-top">
                <button
                  type="button"
                  className="btn btn-success w-100"
                  onClick={() => {
                    setState((s) => ({
                      ...s,
                      filterList: clientList?.data,
                    }));

                    setshowInviteFilter(true);
                  }}
                >
                  Invite Members
                </button>
              </div>
            )}
          </div>
        )}

        <Modal
          size="sm"
          show={showMemberAction}
          onHide={handleMemberActionActionClose}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Actions</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0">
            <ul className="list-unstyled bulk-list action-list m-0">
              <li
                onClick={(e) => {
                  e.preventDefault();
                  getUserDetails();
                }}
              >
                View Info
              </li>
              {!isClient &&
                currentMember.userId !== userId &&
                (currentMember.role !== "operator" || operatorCount >= 2) && (
                  <li
                    className="text-danger"
                    onClick={(e) => {
                      e.preventDefault();
                      removeUserFromChannel();
                    }}
                  >
                    Remove
                  </li>
                )}
            </ul>
          </Modal.Body>
        </Modal>

        <Modal className="modal-full" show={editName} onHide={handleEditClose}>
          <Modal.Header closeButton>
            <Modal.Title>Edit channel information</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-24">
              <label className="d-block mb-16 d-none d-md-block">
                Channel image
              </label>
              <div className="d-flex align-items-center">
                <div className="channel-user-photo">
                  {!img && img !== null && getChannelProfile(channel)}
                  {img === null && resetDefaultProfile(channel)}
                  {img && <img src={img} alt="" />}
                </div>
                <div className="d-flex ps-16">
                  <div className="upload-chann">
                    <input
                      type="file"
                      id="profile"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={onImageChange}
                    />
                    <span className="upld-txt">Change Photo</span>
                  </div>
                  {ShowRemovePhotoOption && (
                    <span
                      className="rmv-txt"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowRemovePhotoOption(false);
                        document.getElementById("profile").value = null;
                        setImg(null);
                        setState((s) => ({
                          ...s,
                          file: null,
                        }));
                      }}
                    >
                      Remove Photo
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div>
              <label className="d-block fs-14 lh-24">Channel name</label>
              <input
                name="Channel Name"
                type="text"
                value={tempChannelName}
                className="form-control"
                placeholder="Group Channel"
                onChange={(e) => {
                  setState((s) => ({
                    ...s,
                    tempChannelName: e.target.value,
                  }));
                }}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-md-flex align-items-center">
              <button
                className="btn btn-outline-secondary"
                variant="secondary"
                onClick={(e) => {
                  e.preventDefault();
                  handleEditClose();
                  setImg("");
                  setState((s) => ({
                    ...s,
                    file: "",
                  }));
                }}
              >
                Cancel
              </button>
              <button
                className="btn btn-success"
                variant="primary"
                disabled={
                  tempChannelName ===
                  (channel?.name === "Group Channel"
                    ? getChannelName(channel?.members)
                    : channel?.name) && state?.file === ""
                }
                onClick={() => {
                  if (state?.file !== "") {
                    updateChannelCover(state?.file);
                  }
                  if (channel.name !== tempChannelName) {
                    updateChannel(tempChannelName);
                  }
                }}
              >
                Save
              </button>
            </div>
          </Modal.Footer>
        </Modal>
        <Modal
          className="modal-full filtbyclient-modal"
          show={showInviteFilter}
          onHide={handleInviteFilterClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Invite Members</Modal.Title>
          </Modal.Header>
          <Modal.Body className="ps-0 pe-0 pt-0 pt-md-16">
            <div className="px-24 py-12 border-md-top">
              <div className="search-area">
                <input
                  placeholder="Search Users..."
                  className="form-control"
                  value={valueFilter}
                  onChange={(e) => {
                    let lst = clientList?.data?.filter(
                      (c) =>
                        e.target.value === "" ||
                        (
                          c.first_name?.toLowerCase() +
                          " " +
                          c.last_name?.toLowerCase()
                        ).includes(e.target.value?.toLowerCase())
                    );
                    setState((s) => ({
                      ...s,
                      valueFilter: e.target.value,
                      filterList: lst,
                    }));
                  }}
                />
                <button className="search-btn icon-20">
                  <em className="icon-search-outline"></em>
                </button>
                {valueFilter !== "" && (
                  <button
                    className="close-search-btn icon-20"
                    onClick={(event) => {
                      const value = "";
                      let lst = clientList?.data?.filter(
                        (c) =>
                          value === "" ||
                          (
                            c.first_name?.toLowerCase() +
                            " " +
                            c.last_name?.toLowerCase()
                          ).includes(value?.toLowerCase())
                      );
                      setState((s) => ({
                        ...s,
                        valueFilter: value,
                        filterList: lst,
                      }));
                    }}
                  >
                    <em className="icon-x-circle-filled"></em>
                  </button>
                )}
              </div>
            </div>
            <div className="selected-client fw-500 text-success border-top">
              {numberSelected} selected
            </div>
            <div className="message-check-main custom-style-scroll pr-8">
              <div className="ps-24 pe-8">
                <div>
                  <h6 className="fs-14 lh-20 text-uppercase mb-4 ">
                    {clientList?.data?.filter((x) => x.user_type === "clients")?.length > 0 ? (isAdvisor
                      ? "MY CLIENTS"
                      : "MY HOUSEHOLD") : ""}
                  </h6>

                  <ul className="message-check-list list-unstyled mb-8">
                    {filterList
                      ?.filter((x) => (selectedClientHouseHold === undefined || x.household === selectedClientHouseHold) && x.user_type === "clients")
                      ?.map((client, index) => {
                        const existingUser = channel?.members
                          .map((f) => f.userId)
                          .includes(client.uuid);
                        return (
                          <>
                            {
                              <li key={index}>
                                <div className="d-flex align-items-center">
                                  <span className="m-client-photo icon-32">
                                    {utility.getProfileImage(
                                      client?.profile_image,
                                      client?.first_name?.charAt(0),
                                      client?.last_name?.charAt(0)
                                    )}
                                  </span>
                                  <label
                                    className="form-check-label fs-16 lh-24"
                                    htmlFor="filtclient2"
                                  >
                                    {client.first_name + " " + client.last_name}
                                  </label>
                                </div>
                                <div className="form-check mb-0 no-text-form-check">
                                  {(client?.isSelected || existingUser) && (
                                    <input
                                      disabled={existingUser}
                                      className="form-check-input"
                                      type="Checkbox"
                                      id={client.first_name}
                                      checked={true}
                                      onChange={(e) => {
                                        let indx = clientList?.data?.findIndex(
                                          (c) => c.uuid === client.uuid
                                        );
                                        clientList.data[indx].isSelected =
                                          e?.target?.checked;
                                        setClientList(clientList);
                                        let household = clientList?.data?.find(c => c.isSelected === true && c.user_type === "clients")?.household;
                                        if (household !== undefined) {
                                          setselectedClientHouseHold(clientList?.data?.find(c => c.isSelected === true && c.user_type === "clients")?.household)
                                        }
                                        else {
                                          let userId = channel?.members.find(c => c.role !== "operator")?.userId;
                                          setselectedClientHouseHold(clientList?.data?.find(c => c.sendbird_user_id === userId)?.household)
                                        }
                                        setState((s) => ({
                                          ...s,
                                          numberSelected: clientList?.data?.filter(
                                            (c) => c.isSelected
                                          )?.length,
                                        }));
                                      }}
                                    />
                                  )}
                                  {!client?.isSelected && !existingUser && (
                                    <input
                                      disabled={existingUser}
                                      className="form-check-input"
                                      type="Checkbox"
                                      id={client.first_name}
                                      checked={false}
                                      onChange={(e) => {
                                        let indx = clientList?.data?.findIndex(
                                          (c) => c.uuid === client.uuid
                                        );
                                        clientList.data[indx].isSelected =
                                          e?.target?.checked;
                                        setClientList(clientList);
                                        let household = clientList?.data?.find(c => c.isSelected === true && c.user_type === "clients")?.household;
                                        if (household !== undefined) {
                                          setselectedClientHouseHold(clientList?.data?.find(c => c.isSelected === true && c.user_type === "clients")?.household)
                                        }
                                        else {
                                          let userId = channel?.members.find(c => c.role !== "operator")?.userId;
                                          setselectedClientHouseHold(clientList?.data?.find(c => c.sendbird_user_id === userId)?.household)
                                        }
                                        setState((s) => ({
                                          ...s,
                                          numberSelected: clientList?.data?.filter(
                                            (c) => c.isSelected
                                          )?.length,
                                        }));
                                      }}
                                    />
                                  )}
                                </div>
                              </li>
                            }
                          </>
                        );
                      })}
                  </ul>
                </div>
                <div>
                  <h6 className="fs-14 lh-20 text-uppercase mb-4 ">
                    {clientList?.data?.filter((x) => x.user_type !== "clients")?.length > 0 ? (isAdvisor
                      ? "MY TEAM"
                      : "MY ADVISORY TEAM") : ""}
                  </h6>
                  <ul className="message-check-list list-unstyled mb-8">
                    {filterList
                      ?.filter((x) => x.user_type !== "clients")
                      ?.map((client, index) => {
                        const existingUser = channel?.members
                          .map((f) => f.userId)
                          .includes(client.uuid);
                        return (
                          <>
                            {
                              <li key={index}>
                                <div className="d-flex align-items-center">
                                  <span className="m-client-photo icon-32">
                                    {utility.getProfileImage(
                                      client?.profile_image,
                                      client?.first_name?.charAt(0),
                                      client?.last_name?.charAt(0)
                                    )}
                                  </span>
                                  <label
                                    className="form-check-label fs-16 lh-24"
                                    htmlFor="filtclient2"
                                  >
                                    {client.first_name + " " + client.last_name}
                                  </label>
                                </div>
                                <div className="form-check mb-0 no-text-form-check">
                                  {(client?.isSelected || existingUser) && (
                                    <input
                                      disabled={existingUser}
                                      className="form-check-input"
                                      type="Checkbox"
                                      id={client.first_name}
                                      checked={true}
                                      onChange={(e) => {
                                        let indx = clientList?.data?.findIndex(
                                          (c) => c.uuid === client.uuid
                                        );
                                        clientList.data[indx].isSelected =
                                          e?.target?.checked;
                                        setClientList(clientList);
                                        setState((s) => ({
                                          ...s,
                                          numberSelected: clientList?.data?.filter(
                                            (c) => c.isSelected
                                          )?.length,
                                        }));
                                      }}
                                    />
                                  )}
                                  {!client?.isSelected && !existingUser && (
                                    <input
                                      disabled={existingUser}
                                      className="form-check-input"
                                      type="Checkbox"
                                      id={client.first_name}
                                      checked={false}
                                      onChange={(e) => {
                                        let indx = clientList?.data?.findIndex(
                                          (c) => c.uuid === client.uuid
                                        );
                                        clientList.data[indx].isSelected =
                                          e?.target?.checked;
                                        setClientList(clientList);
                                        setState((s) => ({
                                          ...s,
                                          numberSelected: clientList?.data?.filter(
                                            (c) => c.isSelected
                                          )?.length,
                                        }));
                                      }}
                                    />
                                  )}
                                </div>
                              </li>
                            }
                          </>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-md-flex align-items-center">
              <button
                className="btn btn-outline-secondary"
                variant="secondary"
                onClick={(e) => {
                  e.preventDefault();
                  handleInviteFilterClose();
                }}
              >
                Cancel
              </button>
              <button
                className="btn btn-success"
                variant="primary"
                disabled={clientList?.data?.filter(c => c.isSelected === true)?.length === 0}
                onClick={() => {
                  InviteUserToChannel();
                }}
              >
                Invite
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  const getHighlightedText = (text, highlight) => {
    if (!highlight.trim()) {
      return text;
    }
    // Split on highlight term and include term into parts, ignore case
    const parts = text?.split(new RegExp(`(${highlight})`, "gi"));
    return (
      <span>
        {" "}
        {parts?.map((part, i) => (
          <span
            key={i}
            className={
              part.toLowerCase() === highlight.toLowerCase() ? "mark" : ""
            }
          >
            {part}
          </span>
        ))}{" "}
      </span>
    );
  };
  const CustomComponentWithSendBird = withSendBird(CustomComponent, (state) => {
    const createChannel = sendBirdSelectors.getCreateChannel(state);
    const leaveChannel = sendBirdSelectors.getLeaveChannel(state);
    const sdk = sendBirdSelectors.getSdk(state);
    return { createChannel, sdk, leaveChannel };
  });
  const CustomChannelWithSendBird = withSendBird(
    CustomChannelComponent,
    (state) => {
      const createChannel = sendBirdSelectors.getCreateChannel(state);
      const leaveChannel = sendBirdSelectors.getLeaveChannel(state);
      const sdk = sendBirdSelectors.getSdk(state);
      return { createChannel, sdk, leaveChannel };
    }
  );

  const CustomchannelInfoWithSendBird = withSendBird(
    CustomChannelInfoComponent,
    (state) => {
      const leaveChannel = sendBirdSelectors.getLeaveChannel(state);
      const sdk = sendBirdSelectors.getSdk(state);
      return { sdk, leaveChannel };
    }
  );
  const resetDefaultProfile = (channel) => {
    const members = channel?.members.filter((m) => m.userId !== userId);
    return (
      <>
        {members?.length === 1 &&
          utility.getProfileImage(
            members[0]?.profileUrl,
            members[0]?.nickname?.charAt(0),
            members[0]?.nickname?.split(" ")[1]?.charAt(0)
          )}
        {members?.length > 1 && (
          <div className="d-flex flex-fill">
            <div className="prof-ileft d-flex align-items-center">
              {utility.getProfileImage(
                members[0]?.profileUrl,
                members[0]?.nickname?.charAt(0),
                members[0]?.nickname?.split(" ")[1]?.charAt(0)
              )}
            </div>
            <div className="prof-iright d-flex align-items-center">
              {utility.getProfileImage(
                members[1]?.profileUrl,
                members[1]?.nickname?.charAt(0),
                members[1]?.nickname?.split(" ")[1]?.charAt(0)
              )}
            </div>
          </div>
        )}
      </>
    );
  };
  const getChannelProfile = (channel) => {
    let members = channel?.members?.filter((m) => m.userId !== userId);
    if (members?.length === 0) {
      members = channel?.members;
    }
    return (
      <>
        {!channel?.coverUrl?.includes("/sample") && (
          <img src={channel?.coverUrl} alt="" />
        )}
        {channel?.coverUrl?.includes("/sample") &&
          members?.length === 1 &&
          utility.getProfileImage(
            members[0]?.profileUrl,
            members[0]?.nickname?.charAt(0),
            members[0]?.nickname?.split(" ")[1]?.charAt(0)
          )}
        {channel?.coverUrl?.includes("/sample") && members?.length > 1 && (
          <div className="d-flex flex-fill">
            <div className="prof-ileft d-flex align-items-center">
              {utility.getProfileImage(
                members[0]?.profileUrl,
                members[0]?.nickname?.charAt(0),
                members[0]?.nickname?.split(" ")[1]?.charAt(0)
              )}
            </div>
            <div className="prof-iright d-flex align-items-center">
              {utility.getProfileImage(
                members[1]?.profileUrl,
                members[1]?.nickname?.charAt(0),
                members[1]?.nickname?.split(" ")[1]?.charAt(0)
              )}
            </div>
          </div>
        )}

      </>
    );
  };
  const CustomChatHeader = () => (
    <div className="channel-toolbar d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center">
        <span className="d-md-none icon-32 me-12">
          <em
            className="sb-back fs-24 text-success"
            onClick={(event) => {
              document.body.classList.remove("chat-open");
            }}
          ></em>
        </span>
        <div className="channel-list-photo me-8">
          {getChannelProfile(channel)}
          {checkReadReceipt(channel?.members)}
        </div>{" "}
        <div>
          <h5 className="fw-600 channel-name m-0">
            {channel?.name === "Group Channel"
              ? getChannelName(channel?.members)
              : channel?.name}
          </h5>

          <p className="text-muted m-0">
            <i>{checkOutOfOffice(channel?.members)}</i>
          </p>
        </div>
      </div>
      <div className="d-flex align-items-center">
        <span className="icon-32 cursor-pointer me-md-16 me-8">
          <em
            className="sb-search fs-24"
            onClick={(event) => {
              setChannelName();
              setShowSearch(!showSearch);
              setShowInfo(false);
              setShowUserDetails(false);
              setChannelSearchText("")
            }}
          ></em>
        </span>
        <span className="icon-32 cursor-pointer">
          <em
            className="sb-info text-success fs-24"
            onClick={(event) => {
              setShowInfo(!showInfo);
              setShowSearch(false);
              setShowUserDetails(false);
            }}
          ></em>
        </span>
      </div>
    </div>
  );
  const channelSort = useCallback((channels) => {
    if (channels.length === 0) {
      return channels;
    }
    let url = localStorage.getItem("channelUrl");
    if (url && channelUrl === "") {
      let channel = channels.filter((m) => m.url === url);
      if (channel.length !== 0) {
        setchannelUrl(url);
        setchannel(channel[0]);
      }
      localStorage.removeItem("channelUrl");
    }
    return channels.sort(function (a, b) {
      if (
        (a?.lastMessage ? a?.lastMessage?.createdAt : a?.createdAt) >
        (b?.lastMessage ? b?.lastMessage?.createdAt : b?.createdAt)
      ) {
        return -1;
      }
      if (
        (a?.lastMessage ? a?.lastMessage?.createdAt : a?.createdAt) <
        (b?.lastMessage ? b?.lastMessage?.createdAt : b?.createdAt)
      ) {
        return 1;
      }
      return 0;
    });
  }, []);
  return (
    <>
      {loader && <Loading noTimeOut={loader} />}
      <div className="heading-wrap d-flex align-items-center justify-content-between px-16 px-md-24">
        <div className="d-flex align-items-center">
          <span className="head-icon me-16">
            <em className="icon-chat-alt-2-filled"></em>
          </span>
          <h4 className="m-0">Messages</h4>
        </div>
        <GlobalSearch
          userId={userId}
          redirectToMessage={(channel, message_id) => {
            setchannel(channel);
            setchannelUrl(channel?.channel_url);
            setHighlightedMessage(message_id);
          }}
        />
        <div className="d-flex d-md-none">
          <span className="icon-32">
            <em className="icon-search-outline fs-md-20"></em>
          </span>
          <span
            className="icon-32 ms-12"
            onClick={() => {
              getClientList();
            }}
          >
            <em className="sb-create text-success fs-20"></em>
          </span>
        </div>
      </div>

      <div className="message-wrapper">
        <SendBirdProvider
          appId={sendbirdAppId}
          userId={userId}
          accessToken={accessToken}
          stringSet={stringSet}
          allowProfileEdit={false}
          disableUserProfile={true}
          colorSet={myColorSet}
          includeReactions={false}
          config={{ logLevel: 'all' }}
        >
          <CustomComponentWithSendBird />
          <ChannelList
            sortChannelList={channelSort}
            disableAutoSelect={false}
            renderChannelPreview={MyCustomPreview}
            queries={{
              channelListQuery: {
                order: "latest_last_message",
                includeEmpty: true,
                limit: 100,
              },
              applicationUserListQuery: {
                userIdsFilter: clientList?.data?.map((c) => c.sendbird_user_id),
              },
            }}
          />
          <CustomChannelWithSendBird />

          {channel && showInfo && <CustomchannelInfoWithSendBird />}
          {channel && showSearch && (
            <>
              <div className="channel-search-sidebar d-flex flex-column">
                <div className="channel-search-header d-none d-md-flex align-items-center justify-content-between">
                  <h5 className="m-0 lh-24 fs-18">Search in channel</h5>
                  <span
                    className="icon-24 cursor-pointer"
                    onClick={(e) => {
                      setShowSearch(!showSearch);
                    }}
                  >
                    <em className="icon-x-outline"></em>
                  </span>
                </div>
                <div className="channel-search-header d-flex d-md-none align-items-center justify-content-between">
                  <div className="search-area message-search-area mobile-search">
                    <input
                      placeholder="Search messages..."
                      className="form-control"
                      value={channelSearchText}
                      onChange={(event) => {
                        setChannelSearchText(event.target.value);
                      }}
                    />
                    <button className="search-btn icon-20">
                      <em className="icon-search-outline"></em>
                    </button>
                    {channelSearchText !== "" && (
                      <button
                        className="close-search-btn icon-20"
                        onClick={(event) => {
                          setChannelSearchText("")
                          setChannelName();
                        }}
                      >
                        <em className="icon-x-circle-filled"></em>
                      </button>
                    )}
                  </div>
                  <a href="#" className="fw-500 text-success ms-20">
                    Search
                  </a>
                </div>
                <div className="custom-style-scroll channel-search-body d-flex flex-column">
                  {" "}
                  <div className="p-16 d-none d-md-block">
                    <div className="search-area message-search-area mb-16">
                      <input
                        placeholder="Search messages..."
                        className="form-control"
                        value={channelSearchText}
                        onChange={(event) => {
                          setChannelSearchText(event.target.value);
                        }}
                      />
                      <button className="search-btn icon-20">
                        <em className="icon-search-outline"></em>
                      </button>
                      {channelSearchText !== "" && (
                        <button
                          className="close-search-btn icon-20"
                          onClick={(event) => {
                            setChannelSearchText("")
                            setChannelName();
                          }}
                        >
                          <em className="icon-x-circle-filled"></em>
                        </button>
                      )}
                    </div>
                  </div>
                  <MessageSearch
                    renderSearchItem={CustomSearchMessagePreview}
                    messageSearchQuery={{
                      exactMatch: false,
                      channelUrl: { channelUrl },
                      advancedQuery: true,
                      limit: 20,
                    }}
                    channelUrl={channelUrl}
                    searchString={
                      channelSearchText ? "*" + channelSearchText + "*" : ""
                    }
                    onResultLoaded={(message, error) => { }}
                    onResultClick={(message) => {
                      setHighlightedMessage(message.messageId);
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </SendBirdProvider>
        {channel && showUserDetails && (
          <div className="member-info d-flex flex-column">
            <div className="member-info-header d-flex align-items-center justify-content-between">
              <span
                className="icon-24 cursor-pointer"
                onClick={(e) => {
                  setShowUserDetails(false);
                  setShowInfo(true);
                }}
              >
                <em className="icon-chevron-left-outline"></em>
              </span>
              <h5 className="m-0 lh-24 fs-18">Member Info</h5>
              <span
                className="icon-24 cursor-pointer visibility-mob-0"
                onClick={(e) => {
                  setShowUserDetails(false);
                  setShowInfo(true);
                }}
              >
                <em className="icon-x-outline"></em>
              </span>
            </div>
            <div className="member-scroll custom-style-scroll">
              <div className="member-photo-prof text-center">
                <div className="user-photo mx-auto mb-8">
                  {utility.getProfileImage(
                    userDetails?.profile_image,
                    userDetails?.first_name?.charAt(0),
                    userDetails?.last_name?.charAt(0)
                  )}
                </div>
                <h5 className="mb-8 fs-18 lh-24">
                  {userDetails.first_name + " " + userDetails.last_name}
                </h5>
                <p className="mb-16">{userDetails?.household_role}</p>
                <div className="d-flex justify-content-center">
                  {userDetails?.phone_number && (isAdvisor && userDetails?.user_type === "clients") && (
                    <a href={`tel:${userDetails?.phone_number}`}>
                      <span className="icon-32 me-16 cursor-pointer">
                        <em className="icon-phone-filled"></em>
                      </span>
                    </a>
                  )}

                  {userDetails?.email && (
                    <span className="icon-32 cursor-pointer">
                      <a href={`mailto:${userDetails?.email}`}>
                        <em className="icon-mail-filled"></em>
                      </a>
                    </span>
                  )}
                </div>
              </div>
              {isAdvisor && userDetails?.user_type === "clients" && (
                <a
                  href={userDetails?.sf_account_link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="d-flex align-items-center lh-20 px-24 py-20 border-bottom"
                >
                  <img width="29px" src={salesForce} alt="" className="me-8" />
                  <span className="text-underline">View Household Details</span>
                  <em className="icon icon-arrow-sm-right-outline ms-auto text-base"></em>
                </a>
              )}
              {isAdvisor && userDetails?.user_type === "clients" && (
                <a
                  href={userDetails?.sf_contact_link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="d-flex align-items-center lh-20 px-24 py-20 border-bottom"
                >
                  <img width="29px" src={salesForce} alt="" className="me-8" />
                  <span className="text-underline">View Contact Details</span>
                  <em className="icon icon-arrow-sm-right-outline ms-auto text-base"></em>
                </a>
              )}
              <div className="member-cont-detail">
                {userDetails?.home_number && (
                  <div className="text-black">
                    <label className="mb-8 fs-14 fw-500">Home Number</label>
                    <p className="m-0">{userDetails?.home_number}</p>
                  </div>
                )}

                {userDetails?.phone_number && (isAdvisor && userDetails?.user_type === "clients") && (
                  <div className="text-black">
                    <label className="mb-8 fs-14 fw-500">Cell Number</label>
                    <p className="m-0">{userDetails?.phone_number}</p>
                  </div>
                )}
                {userDetails?.email && (
                  <div className="text-black">
                    <label className="mb-8 fs-14 fw-500">Email Address</label>
                    <p className="m-0">{userDetails?.email}</p>
                  </div>
                )}
                {userDetails?.advisory_team && (
                  <div className="text-black">
                    <label className="mb-8 fs-14 fw-500">Advisory Team</label>
                    <p className="m-0">{userDetails?.advisory_team?.name}</p>
                  </div>
                )}
                {userDetails?.advisory_team?.relationship_lead && (
                  <div className="text-black">
                    <label className="mb-8 fs-14 fw-500">
                      Relationship Lead
                    </label>
                    <p className="m-0">
                      {userDetails?.advisory_team?.relationship_lead
                        ?.first_name +
                        " " +
                        userDetails?.advisory_team?.relationship_lead
                          ?.last_name}
                    </p>
                  </div>
                )}
                {userDetails?.advisory_team?.relationship_lead && (
                  <div className="text-black">
                    <label className="mb-8 fs-14 fw-500">Advisor</label>
                    <p className="m-0">
                      {userDetails?.advisory_team?.advisor?.first_name +
                        " " +
                        userDetails?.advisory_team?.advisor?.last_name}{" "}
                    </p>
                  </div>
                )}
                {userDetails?.advisory_team?.csm && (
                  <div className="text-black">
                    <label className="mb-8 fs-14 fw-500">CSM</label>
                    <p className="m-0">
                      {" "}
                      {userDetails?.advisory_team?.csm?.first_name +
                        " " +
                        userDetails?.advisory_team?.csm?.last_name}{" "}
                    </p>
                  </div>
                )}
                {userDetails?.advisory_team?.partner && (
                  <div className="text-black">
                    <label className="mb-8 fs-14 fw-500">Partner</label>
                    <p className="m-0">
                      {" "}
                      {userDetails?.advisory_team?.partner?.first_name +
                        " " +
                        userDetails?.advisory_team?.partner?.last_name}{" "}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Messages;