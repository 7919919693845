import { React, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { message } from "../../redux/actions/Message/Message";
import { Switch, Tooltip } from "antd";
import { useNavigate, useLocation } from "react-router";
import Avatar from "react-avatar";
import { utility } from "../../components/Utility";
import moment from "moment";

const MessageDashboard = ({messageRefreshTime,getRefreshTime}) => {
  const [loader, setLoader] = useState(false);
  const navaigate = useNavigate();
  const dispatch = useDispatch();
  const [unreadMessagesToggle, setUnreadMessagesToogle] = useState(true);
  const [recentMessageList, setRecentMessageList] = useState([]);
  const [unreadMessagesList, setUnreadMessagesList] = useState([]);
  const [listtype, setListType] = useState([]);
  const [unread, setUnread] = useState([]);
  const [recentNext, setRecentNext] = useState([]);
  const [unreadNext, setUnreadNext] = useState([]);
  const [rotate, setRotate] = useState(0);
  const { pathname } = useLocation();
  const [userDetails, setUserDetails] = useState(
    JSON.parse(localStorage.getItem("userDetails"))
  );
  // Message Related
  function onChangeToggle(checked) {
    if (checked) {
      setUnreadMessagesToogle(true);
      setListType(1);
    } else {
      setUnreadMessagesToogle(false);
      getRecentMessagesList(
        "message/get_recent_message_list?refresh=1",
        0
      );
      setListType(0);
    }
  }

  useEffect(() => {
    getRecentMessagesList("message/get_recent_message_list?refresh=1&unread_filter=unread_message", 1);
  }, []);

  const navigateToMessage = (channel) => {
    localStorage.setItem("channelUrl", channel?.channel_url);
    navaigate("/dashboard/Messages");
    // navaigate("/dashboard/Messages", {
    //   state: { from: pathname, channel: channel },
    // });
  };

  const onscrollmessage = (e) => {
    if (
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight &&
      (listtype ? unreadNext : recentNext)
    ) {
      var message_url = "";
      listtype
        ? (message_url =
          "message/get_recent_message_list?unread_filter=unread_message&token=" +
          unreadNext)
        : (message_url = "message/get_recent_message_list?token=" + recentNext);
      dispatch(message.getRecentMessages(message_url)).then((response) => {
        if (response.type === "GET_RECENT_MESSAGES_SUCCESS") {
          setLoader(false);
          {
            listtype
              ? (setUnreadMessagesList([
                ...unreadMessagesList,
                ...response.data.data.channels,
              ]),
              setUnreadNext(response.data.data.next))
              : (setRecentMessageList([
                ...recentMessageList,
                ...response.data.data.channels,
              ]),
              setRecentNext(response.data.data.next));
          }
        } else {
          setLoader(false);
        }
      });
    }
  };

  function getuserphoto(channel) {
    const members = channel.members.filter(
      (user) => user.user_id !== userDetails?.uuid
    );
    return (
      <>
        {!channel?.cover_url?.includes("/sample") && (
          <img src={channel?.cover_url} alt="" />
        )}
        {channel?.cover_url?.includes("/sample") &&
          members?.length === 1 &&
          utility.getProfileImage(
            members[0]?.profile_url,
            members[0]?.nickname?.charAt(0),
            members[0]?.nickname?.split(" ")[1]?.charAt(0)
          )}
        {channel?.cover_url?.includes("/sample") && members?.length > 1 && (
          <div className="d-flex flex-fill">
            <div className="prof-ileft d-flex align-items-center">
              {utility.getProfileImage(
                members[0]?.profile_url,
                members[0]?.nickname?.charAt(0),
                members[0]?.nickname?.split(" ")[1]?.charAt(0)
              )}
            </div>
            <div className="prof-iright d-flex align-items-center">
              {utility.getProfileImage(
                members[1]?.profile_url,
                members[1]?.nickname?.charAt(0),
                members[1]?.nickname?.split(" ")[1]?.charAt(0)
              )}
            </div>
          </div>
        )}
      </>
    );
  }

  function timeConverter(UNIX_timestamp) {
    var t = new Date(UNIX_timestamp);
    var today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    var withinweek = moment().subtract(1, "weeks");
    if (moment(today).format("YYYY-MM-DD") === moment(t).format("YYYY-MM-DD")) {
      var time = "Today";
    } else if (
      moment(yesterday).format("YYYY-MM-DD") === moment(t).format("YYYY-MM-DD")
    ) {
      time = "Yesterday";
    } else if (
      moment(t).format("YYYY-MM-DD") >= moment(withinweek).format("YYYY-MM-DD")
    ) {
      var days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      time = days[t.getDay()];
    } else {
      time = moment(t).format("MMM DD");
    }
    return time;
  }

  // function to get recent message list
  const getRecentMessagesList = (message_url, type) => {
    setLoader(true);
    dispatch(message.getRecentMessages(message_url)).then((response) => {
      if (response.type === "GET_RECENT_MESSAGES_SUCCESS") {
        setLoader(false);
        setListType(type);
        {
          type
            ? (setUnreadMessagesList(response.data.data.channels),
            setUnreadNext(response.data.data.next))
            : (setRecentMessageList(response.data.data.channels),
            setRecentNext(response.data.data.next));
        }
      } else {
        setLoader(false);
      }
    });
  };

  return (
    <div className="card h-100 mb-xl-0">
      <div className="card-header p-0">
        <div className="d-flex">
          <h4 className="py-20 px-24">Unread Messages</h4>

          <Tooltip placement="bottomLeft" title={messageRefreshTime}>
            {/* <button className="btn btn-outline-secondary "
        type="button" >Refresh</button> */}
            <span
              className="icon-24 cursor-pointer refresh-span"
              onClick={() =>
                unreadMessagesToggle
                  ? (setRotate(1),getRecentMessagesList(
                    "message/get_recent_message_list?unread_filter=unread_message&refresh=1",
                    1
                  ),
                  setTimeout(() => {
                    getRefreshTime();
                    setRotate(0)
                  }, 1000))
                  : (setRotate(1),
                  getRecentMessagesList(
                    "message/get_recent_message_list?refresh=1",
                    0
                  ),
                  setTimeout(() => {
                    getRefreshTime();
                    setRotate(0);
                  }, 1000))
              }
            >
              {rotate?
              <em className="icon-refresh-outline rotate down"></em>:
              <em className="icon-refresh-outline"></em>
              }
            </span>
          </Tooltip>
        </div>
        <div className="ctb-top d-flex align-items-center justify-content-between border-top">
          <div className="d-flex align-items-center">
            <span className="fw-500 me-16">Only Unread Messages</span>
            <Switch checked={unreadMessagesToggle} onChange={onChangeToggle} />
          </div>
        </div>
      </div>
      {unreadMessagesToggle ? (
        <div className="card-body ps-0 pe-8 pb-0">
          {unreadMessagesList && unreadMessagesList.length === 0 ? (
            <div className="msg-nores d-flex align-items-center justify-content-center text-center">
              <div className="max-258">
                <span className="task-nores-icon msg-nores-icon mb-16 mx-auto">
                  <em className="icon-check-circle-filled"></em>
                </span>
                <p className="fs-18 lh-24 m-0">
                  You’re all caught up on messages!
                </p>
              </div>
            </div>
          ) : (
            <div
              className="msg-list custom-style-scroll"
              onScroll={onscrollmessage}
            >
              <div>
                {unreadMessagesList &&
                  unreadMessagesList.map(
                    (item, index) =>
                      item?.unread_message_count > 0 && (
                        <div
                          className="msg-item"
                          key={index}
                          onClick={() => navigateToMessage(item)}
                        >
                          <div className="channel-list-photo">
                            {item?.channel?.member_count===1?
                             utility.getProfileImage(
                               userDetails?.profile_image,
                               userDetails?.first_name?.charAt(0),
                               userDetails?.last_name !== null?
                               userDetails?.last_name?.charAt(0):""
                             ):getuserphoto(item)}
                          </div>
                          <div className="msg-caption">
                            <div className="msg-detail cursor-pointer">
                              <h6>
                                {item.channel?.name === "" ||
                                item.channel?.name === "Group Channel"
                                ? item.members?.map(
                                  (user, i) =>
                                    user?.nickname +
                                    (item.members?.length - 1 === i
                                        ? ""
                                        : ", ")
                                )
                                  : item.channel?.name}
                              </h6>
                              <p>{item.last_message?.message}</p>
                            </div>
                            <div className="msg-date">
                              <span className="fs-14 lh-20 mb-4 fw-500">
                                {timeConverter(item.last_message?.created_at)}
                              </span>
                              <strong>{item?.unread_message_count}</strong>
                            </div>
                          </div>
                        </div>
                      )
                  )}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="card-body ps-0 pe-8 pb-0">
          <div
            className="msg-list custom-style-scroll"
            onScroll={onscrollmessage}
          >
            <div>
              {recentMessageList &&
                recentMessageList?.map((item, index) => (
                  <div
                    className="msg-item"
                    key={index}
                    onClick={() => navigateToMessage(item)}
                  >
                    <div className="channel-list-photo">
                      {item?.channel?.member_count===1?
                             utility.getProfileImage(
                               userDetails?.profile_image,
                               userDetails?.first_name?.charAt(0),
                               userDetails?.last_name !== null?
                               userDetails?.last_name?.charAt(0):""
                             ):getuserphoto(item)}
                    </div>
                    <div className="msg-caption">
                      <div className="msg-detail cursor-pointer">
                        <h6>
                          {item.channel?.name === "" ||
                          item.channel?.name === "Group Channel"
                            ? item.members?.map(
                              (user, i) =>
                                user?.nickname +
                                (item.members?.length - 1 === i ? "" : ", ")
                            )
                            : item.channel?.name}
                        </h6>
                        <p>{item.last_message?.message}</p>
                      </div>
                      <div className="msg-date">
                        <span className="fs-14 lh-20 mb-4 fw-500">
                          {timeConverter(item.last_message?.created_at)}
                        </span>
                        {item?.unread_message_count > 0 && (
                          <strong>{item?.unread_message_count}</strong>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MessageDashboard;
