/* eslint-disable indent */
import React, { useEffect, useState, useRef } from "react";
import axiosInstance from "../../config/axios";
import { useDispatch, useSelector } from "react-redux";
import { docuSign } from "../../redux/actions/DocuSign/DocuSign";
import { notificationService } from "../../redux/actions/Notification/Notification";
import { calendar } from "../../redux/actions/Calendar/Calendar";
import { notification } from "antd";
import DeclineMetting from "../Calendar/DeclineMetting";
import CalendarIcon from "../../Assets/Images/calendar-right-arrow.svg";
import CalendarCloseIcon from "../../Assets/Images/calendar-close-green.svg";
import CalendarCheckIcon from "../../Assets/Images/calendar-check-green.svg";
import { auth } from "../../redux/actions/Auth/Auth";

import Loading from "../../components/Loader";
import moment from "moment";
import { useNavigate } from "react-router";
import { Menu, Dropdown, Empty } from "antd";

const Notification = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [users, setUser] = useState("");
  const [notificationType, setNotificationType] = useState("All Notifications");
  const [notifications, setNotifications] = useState([]);
  const [EmbededUrl, setEmbededUrl] = useState("");
  const [currentNotification, setcurrentNotification] = useState("");
  const [showView, showViewFile] = useState(false);
  const [loader, setLoader] = useState(false);
  const [next, setnext] = useState(null);
  const [refresh, setrefresh] = useState(false);
  const [userDetails, setUserDetails] = useState(
    JSON.parse(localStorage.getItem("userDetails"))
  );
  const [declineMetting, setDeclineMetting] = useState(false);
  const [selectedCalenderEvent, setselectedCalenderEvent] = useState("");
  // const [calendarPreference, setCalendarPreference] = useState(false);
  const [docusignPreference, setDocusignPreference] = useState(false);
  const [filePreference, setFilePreference] = useState(false);
  const [callAPI, setCallAPI] = useState(false);

  useEffect(() => {
    if (notificationType !== "All Notifications"){
      getNotification()
    }
  }, [notificationType, refresh]);

  useEffect(() => {
    getGlobalNavPreferences();
    // getNotification();
    // setTimeout(() => {
      
    // }, 5000);
  }, [])


  function getGlobalNavPreferences()
  {
    dispatch(auth.globalNavPreferences()).then((response) => {
      if (response.type === "GLOBAL_NAVIGATION_SUCCESS") {
        var string = "article"
        response.data.forEach((element) => {
          // if (element.preference_type === "calendar" && element.value) {
          //   string = string.concat(",calendar");
          //   setCalendarPreference(element.value);
          // } else 
          if (element.preference_type === "docusign" && element.value) {
            string = string.concat(",docusign");
            setDocusignPreference(element.value);
          } 
          else if (element.preference_type === "files" && element.value){
            string = string.concat(",files");
            setFilePreference(element.value);
          }
          setCallAPI(true);
        });
        setNotificationType(string)
      }
    });
  }

  function getNotification() {
    setLoader(true);
    dispatch(notificationService.notifications(notificationType)).then(
      (response) => {
        setLoader(false);
        if (response.type === "NOTIFICATION_LIST_SUCCESS") {
          setNotifications(response.data.results);
          setnext(response.data.next);
        }
      }
    );
  }

  function getNextNotification(next) {
    setLoader(true);
    dispatch(notificationService.nextNotifications(next)).then((response) => {
      setLoader(false);
      if (response.type === "NOTIFICATION_LIST_SUCCESS") {
        setNotifications([...notifications, ...response.data.results]);
        setnext(response.data.next);
      }
    });
  }

  function getEmbededSignature(envelope_id, uuid) {
    setLoader(true);
    dispatch(docuSign.getEmbededSignUrl(envelope_id)).then((response) => {
      setLoader(false);
      if (response.type === "GET_EMBEDED_SIGN_SUCCESS") {
        showViewFile(true);
        window.document
          .getElementById("documentviewer")
          .classList.remove("d-none");
        window.document
          .getElementById("documentviewer")
          .classList.add("d-block");
        setTimeout(() => {
          let windowHeight = window.innerHeight;
          let element = document.getElementsByClassName("view-doc-body")[0];
          let headHeight =
            document.getElementsByClassName("view-doc-head")[0].offsetHeight;
          let bottomHeight =
            document.getElementsByClassName("view-doc-footer")[0].offsetHeight;
          console.log(element.offsetHeight, headHeight, bottomHeight);
          element.style.height =
            windowHeight - (headHeight + bottomHeight) + "px";
        }, 100);
        setEmbededUrl(response?.data?.url);
      }else{
        if (response?.error?.message){
          Notify(
            "error",
            response?.error?.message
          );
        }
        else{
          navigate("/dashboard/docu-sign");
        }
      }
    });
  }

  function markAllRead() {
    setLoader(true);
    dispatch(notificationService.markAllRead()).then((response) => {
      setLoader(false);
      if (response.type === "NOTIFICATION_SUCCESS") {
        setrefresh(!refresh);
      }
    });
  }

  function markSingleAsRead(uuid) {
    setLoader(true);
    dispatch(notificationService.markSingleAsRead(uuid)).then((response) => {
      setLoader(false);
      if (response.type === "NOTIFICATION_SUCCESS") {
        let index = notifications.findIndex((el) => el.uuid === uuid);
        notifications[index].is_read = true;
        setNotifications([...notifications]);
        unreadNotificationsCount();
      }
    });
  }

  function unreadNotificationsCount() {
    dispatch(notificationService.unreadNotificationsCount()).then(
      (response) => {}
    );
  }

  function delete_all() {
    setLoader(true);
    dispatch(notificationService.delete_all()).then((response) => {
      setLoader(false);
      if (response.type === "NOTIFICATION_SUCCESS") {
        setrefresh(!refresh);
      }
    });
  }
  function remove_single(uuid) {
    setLoader(true);
    dispatch(notificationService.delete_single(uuid, true)).then((response) => {
      setLoader(false);
      if (response.type === "NOTIFICATION_SUCCESS") {
        let index = notifications.findIndex((el) => el.uuid === uuid);
        notifications.splice(index, 1);
        setNotifications([...notifications]);
      }
    });
  }
  function mark_completed() {
    let index = notifications.findIndex(
      (el) => el.uuid === currentNotification?.uuid
    );
    notifications[index].is_action_completed = true;
    setNotifications([...notifications]);
    setcurrentNotification("");
  }

  function complate_docusign_action() {
    setLoader(true);
    dispatch(
      notificationService.complate_docusign_action(
        currentNotification?.file_reference,
        currentNotification?.extra_content?.signer_user,
        userDetails?.first_name + " " + userDetails?.last_name,
        currentNotification?.content?.split("document")[1]
      )
    ).then((response) => {
      setLoader(false);
      if (response.type === "NOTIFICATION_SUCCESS") {
        let index = notifications.findIndex(
          (el) => el.uuid === currentNotification?.uuid
        );
        notifications[index].is_action_completed = true;
        setNotifications([...notifications]);
        setcurrentNotification("");
        getNotification()
      }
    });
  }
  function delete_single(uuid, isdelete = true) {
    setLoader(true);
    dispatch(notificationService.delete_single(uuid, isdelete)).then(
      (response) => {
        setLoader(false);
        if (response.type === "NOTIFICATION_SUCCESS") {
          let index = notifications.findIndex((el) => el.uuid === uuid);
          notifications[index].is_deleted = isdelete;
          setNotifications([...notifications]);
        }
      }
    );
  }

  function TurnedOffNotification(notification_filter, uuid) {
    setLoader(true);
    dispatch(
      notificationService.notification_preferences_set(notification_filter)
    ).then((response) => {
      setLoader(false);
      if (response.type === "NOTIFICATION_SUCCESS") {
        let index = notifications.findIndex((el) => el.uuid === uuid);
        notifications[index].TurnedOffItem = true;
        setNotifications([...notifications]);
      }
    });
  }
  function redirectToMessage(message) {
    localStorage.setItem("channelUrl", message?.extra_content?.channel_url);
    //localStorage.setItem("notifyContent", message?.content);

    navigate("/dashboard/Messages");
  }

  function redirectToArticle(article) {
    // localStorage.setItem("channelUrl", message?.extra_content?.channel_url);
    //localStorage.setItem("notifyContent", message?.content);
    localStorage.setItem("article_uuid", article);

    navigate("/dashboard");
  }

  const setContentRef = useRef();
  useEffect(() => {
    if (setContentRef.current.src.includes("/notification?state=1")) {
      if (setContentRef.current.src.includes("event=signing_complete")) {
        window.parent.document.getElementById("btnActionCompleted").click();
      } else {
        window.parent.document.getElementById("btnReLodaData").click();
      }
    }
  }, [setContentRef]);

  function CloseDocument() {
    window.parent.document
      .getElementById("documentviewer")
      .classList.add("d-none");
    setcurrentNotification("");
  }
  function ActionCompleted() {
    complate_docusign_action();
    window.parent.document
      .getElementById("documentviewer")
      .classList.add("d-none");
  }
  const handleScroll = (e) => {
    const bottom =
    parseInt(
      e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight
    ) < 2;
    if (bottom === true && next !== null) {
      getNextNotification(next);
    }
  };

  const ViewRequest = (eventid, notificationid) => {
    setLoader(true);
    dispatch(notificationService.markSingleAsRead(notificationid)).then(
      (response) => {
        setLoader(false);
        if (response.type === "NOTIFICATION_SUCCESS") {
          navigate("/dashboard/calendar?meeting=" + eventid);
        }
      }
    );
  };

  const formatNotificationTime = (time) => {
    if (time === "an hour ago") {
      return `1h`;
    }
    if (time.startsWith("a few")) {
      return `1s`;
    } else if (time.startsWith("a")) {
      if (time.charAt(3) === 'o'){
        return `1${time.charAt(2)}${time.charAt(3)}`;
      }
      else{
        return `1${time.charAt(2)}`;
      }
    }
    return time
      .replace(" seconds ago", "s")
      .replace(" minutes ago", "m")
      .replace(" hours ago", "h")
      .replace(" days ago", "d")
      .replace(" months ago", "mo")
      .replace(" years ago", "y");
  };
  const Notify = (type, mesage, icons) => {
    notification[type]({
      description: mesage,
      icon: icons,
      top: 65,
    });
  };
  function eventDetails(uuid, notificationId) {
    setLoader(true);
    dispatch(calendar.eventDetails(uuid)).then((response) => {
      if (response.type === "GET_EVENT_SUCCESS") {
        if (response.data?.event_type === "in_person_meeting") {
          navigate("/dashboard/calendar?AcceptMeeting=" + response.data?.uuid);
        } else {
          acceptDeclineMeeting(response.data, notificationId);
        }
      }
      setLoader(false);
    });
  }
  function acceptDeclineMeeting(event, notificationId) {
    let param = {
      status: "accept",
      comment: "Accepted",
    };
    setLoader(true);
    dispatch(calendar.accpetOrDeclineMeeting(param, event?.uuid)).then(
      (response) => {
        setLoader(false);
        if (response.type === "ACCEPT_DECLINE_SUCCESS") {
          Notify(
            "success",
            `${event?.title.includes("_")  ? event?.title.substring(0, event?.title.indexOf("_") ): event?.title}” was scheduled on ${moment(
              event?.start_time
            ).format("MMM d LT")}`,
            <img src={CalendarCheckIcon} alt="" />
          );
          let index = notifications.findIndex(
            (el) => el.uuid === notificationId
          );
          notifications[index].is_action_completed = true;
          setNotifications([...notifications]);
          setcurrentNotification("");
        }
      }
    );
  }
  return (
    <>
      {loader && <Loading active={loader} />}
      <button id="btnReLodaData" hidden onClick={CloseDocument}></button>
      <button id="btnActionCompleted" hidden onClick={ActionCompleted}></button>
      <div>
        <div className="heading-wrap">
          <div className="d-flex align-items-center justify-content-between mb-16">
            <div className="d-flex align-items-center">
              <span className="head-icon me-16">
                <em className="icon-bell-filled"></em>
              </span>
              <h4 className="m-0">Notifications</h4>
            </div>
            <Dropdown
              trigger={["click"]}
              placement="bottomRight"
              overlay={
                <Menu>
                  <Menu.Item
                    key="1"
                    onClick={(e) => {
                      markAllRead();
                    }}
                  >
                    Mark all as read
                  </Menu.Item>

                  <Menu.Item
                    onClick={(e) => {
                      delete_all();
                    }}
                  >
                    Delete all
                  </Menu.Item>

                  <Menu.Item
                    onClick={(e) => {
                      navigate("/dashboard/profile");
                    }}
                  >
                    Go to notification settings
                  </Menu.Item>
                </Menu>
              }
            >
              <span className="btn btn-outline-secondary btn-icon-only">
                <em className="icon-dots-vertical-outline"></em>
              </span>
            </Dropdown>
          </div>
          <div className="notific-tabs d-none d-md-flex">
            <span
              className={
                notificationType.includes("article")
                  ? "badge badge-success cursor-pointer"
                  : "badge badge-secondary cursor-pointer"
              }
              onClick={(e) => {
                e.preventDefault();
                const string = "article"
                setNotificationType(string.concat(docusignPreference===true?",docusign":"",filePreference===true?",files":""));
                // const string = "article"
                // setNotificationType(string.concat(calendarPreference===true?
                //             ",calendar":"",docusignPreference===true?",docusign":"",filePreference===true?",files":""));
              }}
            >
              All Notifications
            </span>
            {/* <span
              style={{ cursor: "pointer" }}
              className={
                notificationType === "Files"
                  ? "badge badge-success"
                  : "badge badge-secondary"
              }
              onClick={(e) => {
                e.preventDefault();
                setNotificationType("Files");
              }}
            >
              Files
            </span> */}
            {docusignPreference===true && (
            <span
              className={
                notificationType === "DocuSign"
                  ? "badge badge-success cursor-pointer"
                  : "badge badge-secondary cursor-pointer"
              }
              onClick={(e) => {
                e.preventDefault();
                setNotificationType("DocuSign");
              }}
            >
              DocuSign
            </span>
            )}
            {/* {calendarPreference===true && (
            <span
              className={
                notificationType === "Calendar"
                  ? "badge badge-success cursor-pointer"
                  : "badge badge-secondary cursor-pointer"
              }
              onClick={(e) => {
                e.preventDefault();
                setNotificationType("Calendar");
              }}
            >
              Calendar
            </span>
            )} */}
            {userDetails?.user_type !== "advisors" && (
            <span
              className={
                notificationType === "Article"
                  ? "badge badge-success cursor-pointer"
                  : "badge badge-secondary cursor-pointer"
              }
              onClick={(e) => {
                e.preventDefault();
                setNotificationType("Article");
              }}
            >
              Article
            </span>
            )}
            {filePreference===true && (
            <span
              className={
                notificationType === "Files"
                  ? "badge badge-success cursor-pointer"
                  : "badge badge-secondary cursor-pointer"
              }
              onClick={(e) => {
                e.preventDefault();
                setNotificationType("Files");
              }}
            >
              Files
            </span>
            )}
          </div>
          <div className="notific-tabs d-md-none">
            <Dropdown
              trigger={["click"]}
              placement="bottomRight"
              overlay={
                <Menu>
                  <Menu.Item
                    key="1"
                    onClick={(e) => {
                      const string = "article"
                      setNotificationType(string.concat(docusignPreference===true?",docusign":"",filePreference===true?",files":""));
                      // setNotificationType(string.concat(calendarPreference===true?
                      //   ",calendar":"",docusignPreference===true?",docusign":"",filePreference===true?",files":""));
                    }}
                  >
                    All Notifications
                  </Menu.Item>
                  {/* <Menu.Item
                    onClick={(e) => {
                      setNotificationType("Files");
                    }}
                  >
                    Files
                  </Menu.Item> */}
                  {docusignPreference===true && (
                  <Menu.Item
                    onClick={(e) => {
                      setNotificationType("DocuSign");
                    }}
                  >
                    DocuSign
                  </Menu.Item>
                  )}
                  {/* {calendarPreference===true && (
                  <Menu.Item
                    onClick={(e) => {
                      setNotificationType("Calendar");
                    }}
                  >
                    Calendar
                  </Menu.Item>
                  )} */}
                  {filePreference===true && (
                  <Menu.Item
                    onClick={(e) => {
                      setNotificationType("Files");
                    }}
                  >
                    Files
                  </Menu.Item>
                  )}
                </Menu>
              }
            >
              <button
                className="notification-filter-btn dropdown-toggle no-caret min-width"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {notificationType}
              </button>
            </Dropdown>
          </div>
        </div>

        <div className="notific-wrapper" onScroll={handleScroll}>
          {notifications?.map((notificaion, index) => (
            <>
              {(notificaion.notification_type === "envelope-resent" ||
                notificaion.notification_type === "envelope-sent" || 
                notificaion.notification_type === "envelope-cc") &&
                notificaion?.is_deleted !== true &&
                notificaion?.TurnedOffItem !== true && (
                <div
                  className={`notific-block notific-new-block notific-block-${
                      notificaion?.is_read === true ? "white" : "green"
                    } d-flex justify-content-between`}
                >
                  <div className="flex-fill d-flex">
                    {notificaion.notification_type !== "envelope-cc" ?
                    <span className="icon-24">
                      {notificaion?.is_action_completed === true ? (
                          <em className="icon icon-check-circle-filled text-success fs-20"></em>
                        ) : (
                          <em className="icon icon-exclamation-circle-filled text-info fs-20"></em>
                        )}
                    </span>
                    : 
                    <span className="icon-24">
                  
                      <em className="icon icon-check-circle-filled text-success fs-20"></em>

                    </span>
                    
                    }
                    <div className="flex-fill ps-16">
                      <div className="d-flex mb-8">
                        <h6
                          className={`m-0 lh-20${
                              notificaion?.is_read === true ? " text-muted" : ""
                            }`}
                        >
                          {notificaion?.is_action_completed !== true
                              ? notificaion?.title
                              : "Document Signed"}
                        </h6>
                        <span className="text-muted ms-8 fs-14 lh-20">
                          {formatNotificationTime(
                            moment(notificaion?.created_at).fromNow()
                          )}
                        </span>
                      </div>
                      <p
                        className={`mb-md-32 mb-12 lh-20${
                            notificaion?.is_read === true ? " text-7E8C9A" : ""
                          }`}
                      >
                        {notificaion?.is_action_completed !== true
                            ? notificaion?.content
                            : userDetails?.first_name +
                              " " +
                              userDetails?.last_name +
                              " provided a signature on document " +
                              notificaion?.content?.split("document")[1]}
                      </p>
                      {notificaion.notification_type !== "envelope-cc" ?
                      <button
                        type="button"
                        className="btn btn-secondary btn-xsm fw-500"
                        onClick={(e) => {
                          e.preventDefault();
                          markSingleAsRead(notificaion?.uuid);
                          setcurrentNotification(notificaion);
                          getEmbededSignature(
                            notificaion?.file_reference,
                            notificaion?.uuid
                          );
                        }}
                      >
                        {notificaion?.is_action_completed === true
                            ? "View Document"
                            : "Sign Document"}
                      </button> :
                      <button
                        type="button"
                        className="btn btn-secondary btn-xsm fw-500"
                        onClick={(e) => {
                          e.preventDefault();
                          markSingleAsRead(notificaion?.uuid);
                          setcurrentNotification(notificaion);
                          getEmbededSignature(
                            notificaion?.file_reference,
                            notificaion?.uuid
                          );
                        }}
                      >
                                            
                      View Document 
                      </button>
                      }
                    </div>
                  </div>
                  <div>
                    {notificaion?.is_read === false && (
                      <span className="d-block ms-auto red-dot mb-12 me-8"></span>
                    )}
                    <Dropdown
                      trigger={["click"]}
                      placement="bottomRight"
                      overlay={
                        <Menu>
                          {notificaion?.is_read === false && (
                            <Menu.Item
                              key="1"
                              onClick={(e) => {
                                markSingleAsRead(notificaion?.uuid);
                              }}
                            >
                              <span className="fw-500 d-block">
                                  Mark as read
                              </span>
                            </Menu.Item>
                          )}
                          {/* {userDetails?.user_type !== "clients" && (
                            <Menu.Item
                              onClick={(e) => {
                                markSingleAsRead(notificaion?.uuid);
                                TurnedOffNotification(notificaion?.notification_filter,notificaion?.uuid);
                              }}
                            >
                              <span className="fw-500 d-block">Turn off</span>
                              <span className="d-block fs-14">
                                  Stop receiving notifications like this
                              </span>
                            </Menu.Item>
                          )} */}
                          <Menu.Item
                            onClick={(e) => {
                              markSingleAsRead(notificaion?.uuid);
                              delete_single(notificaion?.uuid);
                            }}
                          >
                            <span className="fw-500 d-block">Delete</span>
                            <span className="d-block fs-14">
                                Delete this notification
                            </span>
                          </Menu.Item>
                        </Menu>
                      }
                    >
                      <span className="btn btn-outline-secondary btn-icon-only">
                        <em className="icon-dots-vertical-outline"></em>
                      </span>
                    </Dropdown>
                  </div>
                </div>
              )}
              {notificaion.notification_type === "recipient-completed" &&
                notificaion?.is_deleted !== true &&
                notificaion?.TurnedOffItem !== true && (
                <div
                  className={`notific-block notific-new-block notific-block-${
                      notificaion?.is_read === true ? "white" : "green"
                    } d-flex justify-content-between`}
                >
                  <div className="flex-fill d-flex">
                    <span className="icon-24">
                      <em className="icon icon-check-circle-filled text-success fs-20"></em>
                    </span>
                    <div className="flex-fill ps-16">
                      <div className="d-flex mb-8">
                        <h6
                          className={`m-0 lh-20${
                              notificaion?.is_read === true ? " text-muted" : ""
                            }`}
                        >
                          {notificaion?.title}
                        </h6>
                        <span className="text-muted ms-8 fs-14 lh-20">
                          {formatNotificationTime(
                            moment(notificaion?.created_at).fromNow()
                          )}
                        </span>
                      </div>
                      <p className="mb-md-32 mb-12 lh-20">
                        {notificaion?.content}
                      </p>
                      <button
                        type="button"
                        className="btn btn-secondary btn-xsm fw-500"
                        onClick={(e) => {
                          e.preventDefault();
                          markSingleAsRead(notificaion?.uuid);
                          getEmbededSignature(
                            notificaion?.file_reference,
                            notificaion?.uuid
                          );
                        }}
                      >
                          View Document
                      </button>
                    </div>
                  </div>
                  <div>
                    {notificaion?.is_read === false && (
                      <span className="d-block ms-auto red-dot mb-12 me-8"></span>
                    )}
                    <Dropdown
                      trigger={["click"]}
                      placement="bottomRight"
                      overlay={
                        <Menu>
                          {notificaion?.is_read === false && (
                            <Menu.Item
                              key="1"
                              onClick={(e) => {
                                markSingleAsRead(notificaion?.uuid);
                              }}
                            >
                              <span className="fw-500 d-block">
                                  Mark as read
                              </span>
                            </Menu.Item>
                          )}
                          {/* {userDetails?.user_type !== "clients" && (
                            <Menu.Item
                              onClick={(e) => {
                                markSingleAsRead(notificaion?.uuid);
                                TurnedOffNotification(notificaion?.notification_filter,notificaion?.uuid);
                              }}
                            >
                              <span className="fw-500 d-block">Turn off</span>
                              <span className="d-block fs-14">
                                  Stop receiving notifications like this
                              </span>
                            </Menu.Item>
                          )} */}
                          <Menu.Item
                            onClick={(e) => {
                              markSingleAsRead(notificaion?.uuid);
                              delete_single(notificaion?.uuid);
                            }}
                          >
                            <span className="fw-500 d-block">Delete</span>
                            <span className="d-block fs-14">
                                Delete this notification
                            </span>
                          </Menu.Item>
                        </Menu>
                      }
                    >
                      <span className="btn btn-outline-secondary btn-icon-only">
                        <em className="icon-dots-vertical-outline"></em>
                      </span>
                    </Dropdown>
                  </div>
                </div>
              )}

              {(notificaion.notification_type === "event_create_owner" ||
                notificaion.notification_type === "event_create_participant" ||
                notificaion.notification_type === "event_accept" ||
                notificaion.notification_type === "event_declined" ||
                notificaion.notification_type === "event_reschedule_owner" ||
                notificaion.notification_type === "locationtype_changed_owner" ||
                notificaion.notification_type === "locationtype_changed_participant" ||
                notificaion.notification_type ===
                  "event_reschedule_participant" ||
                notificaion.notification_type === "event_accept_with_edit" ||
                notificaion.notification_type === "event_canceled" ||
                notificaion.notification_type === "outlook_client_participant") &&
                notificaion?.is_deleted !== true &&
                notificaion?.TurnedOffItem !== true && (
                <div
                  className={`notific-block notific-new-block notific-block-${
                      notificaion?.is_read === true ? "white" : "green"
                    } d-flex justify-content-between`}
                >
                  <div className="flex-fill d-flex">
                    <span className="icon-24">
                      {(notificaion.notification_type === "event_accept" ||
                          notificaion.notification_type ===
                            "event_accept_with_edit") && (
                        <img src={CalendarCheckIcon} alt="" />
                      )}
                      {(notificaion.notification_type === "event_declined" ||
                       notificaion.notification_type === "event_canceled") && (
                        <img src={CalendarCloseIcon} alt="" />
                      )}
                      {notificaion.notification_type !== "event_accept" &&
                          notificaion.notification_type !==
                            "event_accept_with_edit" &&
                          notificaion.notification_type !==
                            "event_declined" && notificaion.notification_type !== "event_canceled"&& (
                        <img src={CalendarIcon} alt="" />
                      )}
                    </span>
                    <div className="flex-fill ps-16">
                      <div className="d-flex mb-8">
                        <h6
                          className={`m-0 lh-20${
                              notificaion?.is_read === true ? " text-muted" : ""
                            }`}
                        >
                          {notificaion?.title}
                        </h6>
                        <span className="text-muted ms-8 fs-14 lh-20">
                          {formatNotificationTime(
                            moment(notificaion?.created_at).fromNow()
                          )}
                        </span>
                      </div>
                      <p className="mb-md-32 mb-12 lh-20">
                        {notificaion?.content}
                      </p>
                      {notificaion?.extra_content?.is_event_deleted !== "true" &&
                      <button
                        type="button"
                        className="btn btn-secondary btn-xsm fw-500 me-16"
                        onClick={(e) => {
                          e.preventDefault();
                          markSingleAsRead(notificaion?.uuid);
                          ViewRequest(
                            notificaion?.extra_content?.event_uuid,
                            notificaion?.uuid
                          );
                        }}
                      >
                          View Request
                      </button>
                      }
                      {(notificaion.notification_type ===
                          "event_reschedule_participant" ||
                          notificaion.notification_type ===
                            "event_create_participant") &&
                          notificaion?.is_action_completed !== true &&
                          userDetails?.user_type === "advisors" && (notificaion?.extra_content?.is_event_deleted !== "true")  && notificaion?.extra_content?.event_host !== userDetails?.uuid && (
                        <>
                          {" "}
                          <a
                            href="#"
                            className="fw-500 me-16"
                            onClick={(e) => {
                              e.preventDefault();
                              markSingleAsRead(notificaion?.uuid);
                              setcurrentNotification(notificaion);
                              eventDetails(
                                notificaion?.extra_content?.event_uuid,
                                notificaion?.uuid
                              );
                            }}
                          >
                                Accept
                          </a>
                          <a
                            href="#"
                            className="fw-500 me-16"
                            onClick={(e) => {
                              e.preventDefault();
                              //setDeclineMetting(true);
                              markSingleAsRead(notificaion?.uuid);
                              // setcurrentNotification(notificaion);
                              // setselectedCalenderEvent(
                              //   notificaion?.extra_content?.event_uuid
                              // );
                              navigate(
                                "/dashboard/calendar?DeclineMeeting=" +
                                      notificaion?.extra_content?.event_uuid
                              );
                            }}
                          >
                                Decline
                          </a>
                        </>
                      )}
                    </div>
                  </div>
                  <div>
                    {notificaion?.is_read === false && (
                      <span className="d-block ms-auto red-dot mb-12 me-8"></span>
                    )}
                    <Dropdown
                      trigger={["click"]}
                      placement="bottomRight"
                      overlay={
                        <Menu>
                          {notificaion?.is_read === false && (
                            <Menu.Item
                              key="1"
                              onClick={(e) => {
                                markSingleAsRead(notificaion?.uuid);
                              }}
                            >
                              <span className="fw-500 d-block">
                                  Mark as read
                              </span>
                            </Menu.Item>
                          )}
                          {/* {userDetails?.user_type !== "clients" && (
                            <Menu.Item
                              onClick={(e) => {
                                markSingleAsRead(notificaion?.uuid);
                                TurnedOffNotification(notificaion?.notification_filter,notificaion?.uuid);
                              }}
                            >
                              <span className="fw-500 d-block">Turn off</span>
                              <span className="d-block fs-14">
                                  Stop receiving notifications like this
                              </span>
                            </Menu.Item>
                          )} */}
                          <Menu.Item
                            onClick={(e) => {
                              markSingleAsRead(notificaion?.uuid);
                              delete_single(notificaion?.uuid);
                            }}
                          >
                            <span className="fw-500 d-block">Delete</span>
                            <span className="d-block fs-14">
                                Delete this notification
                            </span>
                          </Menu.Item>
                        </Menu>
                      }
                    >
                      <span className="btn btn-outline-secondary btn-icon-only">
                        <em className="icon-dots-vertical-outline"></em>
                      </span>
                    </Dropdown>
                  </div>
                </div>
              )}
              {declineMetting && (
                <DeclineMetting
                  visible={declineMetting}
                  isAcceptRequest={false}
                  event={{ uuid: selectedCalenderEvent }}
                  closeModal={() => {
                    setDeclineMetting(false);
                    setcurrentNotification("");
                  }}
                  successCloseModal={() => {
                    mark_completed();
                    setDeclineMetting(false);
                  }}
                ></DeclineMetting>
              )}
              {notificaion?.TurnedOffItem === true && (
                <div className="notific-block notific-new-block d-flex justify-content-between">
                  <div className="flex-fill d-flex">
                    <span className="icon-24">
                      {notificaion?.notification_filter === "calendar" &&
                        notificaion.notification_type === "event_accept" && (
                        <img src={CalendarCheckIcon} alt="" />
                      )}
                      {notificaion?.notification_filter === "calendar" &&
                        notificaion.notification_type === "event_declined" && (
                        <img src={CalendarCloseIcon} alt="" />
                      )}
                      {notificaion?.notification_filter === "calendar" &&
                        notificaion.notification_type !== "event_accept" &&
                        notificaion.notification_type !== "event_declined" && (
                        <img src={CalendarIcon} alt="" />
                      )}
                      {notificaion?.notification_filter !== "calendar" && (
                        <em className="icon icon-check-circle-filled text-success fs-20"></em>
                      )}
                    </span>
                    <div className="flex-fill ps-16">
                      <p className="mb-16 lh-20">
                        You will no longer receive notifications like these. You
                        can re-enable them in your notification settings.
                      </p>
                      <div className="d-flex">
                        <a
                          href="#"
                          className="fw-500 me-16"
                          onClick={(e) => {
                            navigate("/dashboard/profile");
                          }}
                        >
                          Go to Notification Settings
                        </a>
                        <a
                          href="#"
                          className="fw-500 text-base"
                          onClick={(e) => {
                            e.preventDefault();
                            setrefresh(!refresh);
                          }}
                        >
                          Dismiss
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {notificaion?.is_deleted === true && (
                <div className="notific-block notific-new-block notific-block-white d-flex justify-content-between">
                  <div className="flex-fill d-flex">
                    <span className="icon-24">
                      <em className="icon icon-check-circle-filled text-success fs-20"></em>
                    </span>
                    <div className="flex-fill ps-16">
                      <div className="d-flex mb-8">
                        <h6 className="m-0 lh-20 text-muted">
                          Notification Deleted
                        </h6>
                      </div>
                      <a
                        href="#"
                        className="fw-500 me-16"
                        onClick={(e) => {
                          e.preventDefault();
                          delete_single(notificaion?.uuid, false);
                        }}
                      >
                        Undo
                      </a>
                    </div>
                  </div>
                </div>
              )}
              {notificaion.notification_type === "unread-message" &&
                notificaion?.is_deleted !== true && (
                <div className="notific-block d-flex justify-content-between">
                  <div className="d-flex align-items-center">
                    <span className="icon-24 me-16 fs-18">
                      <em className="icon-chat-alt-2-filled text-success"></em>
                    </span>
                    <div className="flex-fill">
                      <div className="d-flex align-items-center justify-content-between">
                        <h6 className="mb-0 mb-md-12 fw-normal">
                          {notificaion?.title}
                        </h6>
                        <span className="d-block d-md-none fs-14 lh-20 fw-500">
                          {formatNotificationTime(
                            moment(notificaion?.created_at).fromNow()
                          )}
                        </span>
                      </div>
                      <p className="text-muted mb-0">
                        {notificaion?.content}
                      </p>
                    </div>
                  </div>
                  <div className="d-none d-md-block">
                    <span className="d-block fs-14 lh-20 mb-12 text-end fw-500">
                      {formatNotificationTime(
                        moment(notificaion?.created_at).fromNow()
                      )}
                    </span>
                    <span
                      className="text-success d-inline-block cursor-pointer text-end"
                      onClick={(e) => {
                        e.preventDefault();
                        redirectToMessage(notificaion);
                        //getEmbededSignature(notificaion?.file_reference);
                      }}
                    >
                        View Message
                    </span>
                  </div>
                </div>
              )}

              {notificaion.notification_type === "article_published" &&
                notificaion?.is_deleted !== true && userDetails?.user_type !== "advisors" &&(

                  <div
                  className={`notific-block notific-new-block notific-block-${
                      notificaion?.is_read === true ? "white" : "green"
                    } d-flex justify-content-between`}
                >
                  <div className="flex-fill d-flex">
                    <em className="icon icon-exclamation-circle-filled text-info fs-20"></em>
                    <div className="flex-fill ps-16">
                      <div className="d-flex mb-8">
                        <h6
                          className={`m-0 lh-20${
                              notificaion?.is_read === true ? " text-muted" : ""
                            }`}
                        >
                          {notificaion?.title}
                        </h6>
                        <span className="text-muted ms-8 fs-14 lh-20">
                          {formatNotificationTime(
                            moment(notificaion?.created_at).fromNow()
                          )}
                        </span>
                      </div>
                      <p className="mb-md-32 mb-12 lh-20">
                        {notificaion?.content}
                      </p>
                      <button
                        type="button"
                        className="btn btn-secondary btn-xsm fw-500 me-16"
                        onClick={(e) => {
                          e.preventDefault();
                          markSingleAsRead(notificaion?.uuid);
                          redirectToArticle(
                            notificaion?.extra_content?.article_uuid,
                            notificaion?.uuid
                          );
                        }}
                      >
                          View Article
                      </button>
                    </div>
                  </div>
                  <div>
                    {notificaion?.is_read === false && (
                      <span className="d-block ms-auto red-dot mb-12 me-8"></span>
                    )}
                    <Dropdown
                      trigger={["click"]}
                      placement="bottomRight"
                      overlay={
                        <Menu>
                          {notificaion?.is_read === false && (
                            <Menu.Item
                              key="1"
                              onClick={(e) => {
                                markSingleAsRead(notificaion?.uuid);
                              }}
                            >
                              <span className="fw-500 d-block">
                                  Mark as read
                              </span>
                            </Menu.Item>
                          )}
                          <Menu.Item
                            onClick={(e) => {
                              markSingleAsRead(notificaion?.uuid);
                              delete_single(notificaion?.uuid);
                            }}
                          >
                            <span className="fw-500 d-block">Delete</span>
                            <span className="d-block fs-14">
                                Delete this notification
                            </span>
                          </Menu.Item>
                        </Menu>
                      }
                    >
                      <span className="btn btn-outline-secondary btn-icon-only">
                        <em className="icon-dots-vertical-outline"></em>
                      </span>
                    </Dropdown>
                  </div>
                </div>
                )}


                {(notificaion.notification_type === "FileDelete" ||
                notificaion.notification_type === "FileUpload" || 
                notificaion.notification_type === "FileDownload" ||
                notificaion.notification_type === "FileUpdate") && (
                <div
                  className={`notific-block notific-new-block notific-block-${
                      notificaion?.is_read === true ? "white" : "green"
                    } d-flex justify-content-between`}
                >
                  <div className="flex-fill d-flex">
                    {/* {notificaion.notification_type !== "envelope-cc" ? */}
                    <span className="icon-24">
                      {/* {notificaion?.is_action_completed === true ? ( */}
                        <em className="icon-chat-alt-2-filled text-success fs-20"></em>
                        {/* ) : (
                          <em className="icon-chat-alt-2-filled text-info fs-20"></em>
                        )} */}
                    </span>
                    <div className="flex-fill ps-16">
                      <div className="d-flex mb-8">
                        <h6
                          className={`m-0 lh-20${
                              notificaion?.is_read === true ? " text-muted" : ""
                            }`}
                        >
                          {notificaion?.title}
                        </h6>
                        <span className="text-muted ms-8 fs-14 lh-20">
                          {formatNotificationTime(
                            moment(notificaion?.created_at).fromNow()
                          )}
                        </span>
                      </div>
                      <p
                        className={`mb-md-32 mb-12 lh-20${
                            notificaion?.is_read === true ? " text-7E8C9A" : ""
                          }`}
                      >
                        {notificaion?.is_action_completed !== true
                            ? notificaion?.content
                            : userDetails?.first_name +
                              " " +
                              userDetails?.last_name +
                              " provided a signature on document " +
                              notificaion?.content?.split("document")[1]}
                      </p>
                      {notificaion.notification_type !== "FileDelete" && (
                      <button
                        type="button"
                        className="btn btn-secondary btn-xsm fw-500"
                        onClick={(e) => {
                          e.preventDefault();
                          markSingleAsRead(notificaion?.uuid);
                          setcurrentNotification(notificaion);
                          navigate('/dashboard/share-file',{state:{Id:notificaion?.extra_content?.Resource?.Parent?.Id,FileName:notificaion?.extra_content?.Resource?.Parent?.Name,FileCount:1}});
                        }}
                      >
                                            
                      Review File 
                      </button>)}
                      {/* } */}
                    </div>
                  </div>
                  <div>
                    {notificaion?.is_read === false && (
                      <span className="d-block ms-auto red-dot mb-12 me-8"></span>
                    )}
                    <Dropdown
                      trigger={["click"]}
                      placement="bottomRight"
                      overlay={
                        <Menu>
                          {notificaion?.is_read === false && (
                            <Menu.Item
                              key="1"
                              onClick={(e) => {
                                markSingleAsRead(notificaion?.uuid);
                              }}
                            >
                              <span className="fw-500 d-block">
                                  Mark as read
                              </span>
                            </Menu.Item>
                          )}
                          <Menu.Item
                            onClick={(e) => {
                              markSingleAsRead(notificaion?.uuid);
                              delete_single(notificaion?.uuid);
                            }}
                          >
                            <span className="fw-500 d-block">Delete</span>
                            <span className="d-block fs-14"> 
                                Delete this notification
                            </span>
                          </Menu.Item>
                        </Menu>
                      }
                    >
                      <span className="btn btn-outline-secondary btn-icon-only">
                        <em className="icon-dots-vertical-outline"></em>
                      </span>
                    </Dropdown>
                  </div>
                </div>
              )}


            </>
          ))}
        </div>
        {notifications?.length === 0 && (
          <Empty
            className="blank-notific"
            description={
              <div className="empty-table-block mx-auto">
                <>
                  <span className="upload-icon mx-auto">
                    <em className="icon-bell-filled"></em>
                  </span>
                  <span className="d-block fs-18 lh-24 mb-12 fw-500">
                    No notifications yet
                  </span>
                  {notificationType.includes("article") ? (
                    "Notifications about your activity will appear here."
                  ) : (
                    <a
                      className="fs-16 d-inline-block lh-24 fw-500"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        const string = "article"
                        setNotificationType(string.concat(docusignPreference===true?",docusign":"",filePreference===true?",files":""));
                        // setNotificationType(string.concat(calendarPreference===true?
                        // ",calendar":"",docusignPreference===true?",docusign":"",filePreference===true?",files":""));
                      }}
                    >
                      See all notifications
                    </a>
                  )}
                </>
              </div>
            }
          />
        )}
      </div>

      <div
        id="documentviewer"
        className={`view-document  align-items-stretch ${
          showView ? "d-flex" : "d-none"
        }`}
      >
        <div className="view-doc-left flex-fill">
          <div className="view-doc-head d-flex justify-content-between border-bottom align-items-center">
            <div className="d-flex align-items-center">
              <span
                className="icon-32 fs-20 me-8 me-md-16 cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  showViewFile(false);
                  // props.func(false);
                  setEmbededUrl("");
                }}
              >
                <em className="icon-chevron-left-outline"></em>
              </span>
              <div className="d-flex align-items-center">
                <div>
                  <p className="m-0 lh-20 pdf-name">
                    {currentNotification?.extra_content?.title}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="view-doc-body">
            <iframe
              id="iframe-view-doc"
              ref={setContentRef}
              className="h-100 w-100"
              src={EmbededUrl}
            ></iframe>
          </div>
          <div className="view-doc-footer border-top d-flex justify-content-between align-items-center">
            <div className="view-footer-left"></div>
          </div>
        </div>
        {/* singned popup */}
        {/* <Modal
          className="modal-356 you-sign-modal"
          show={showYouSigned}
          onHide={handleYouSignedClose}
        >
          <Modal.Body className="text-center">
            <div className="check-green text-success">
              <em className="icon-check-circle-filled"></em>
            </div>
            <Modal.Title>You’ve signed!</Modal.Title>
            <p className="fs-18 lh-32">
              Your signed documents will show under “Completed” tab once all
              parties have signed.
            </p>
            <button type="button" className="btn btn-success w-100">
              Done
            </button>
          </Modal.Body>
        </Modal> */}
      </div>
    </>
  );
};

export default Notification;
