import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import { userConstants } from '../../redux/constants/user.constant';
import Loading from '../../components/Loader';
import { auth } from '../../redux/actions/Auth/Auth'
const ForgotPasswordVerification = () => {


  const [phoneNumber, setPhoneNumber] = useState('');

  const [loader, setLoader] = useState(false);
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    localStorage.setItem("user", false);
    let contact = JSON.parse(localStorage.getItem("userDetails"))?.phone_number;
    setPhoneNumber(contact?.length > 6  ? contact?.slice(-4) : contact);
    setEmail(localStorage.getItem("Email"));
  }, []);

  function handleSubmit(e) {
    setLoader(true);
    e.preventDefault();
    let param = { email: email };
    //setNext(true);
    dispatch(auth.forgotPasswordCode(param)).then((user) => {
      setLoader(false);
      if (user.type === "FORGOT_OTP_RECEIVED_SUCCESS") {
        navigate("/forgot-password-code");
      } else {
        //navigate("/login-password")
      }

    });
  }

  return (
    <>
      { loader && <Loading active={loader}/> }
      <div>
        <form name="form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label className='form-label'>Verification Method</label>
            <input type="text" name="mobile" className='form-control' value={`Text Message - *** ***`+ phoneNumber} disabled />
          </div>
          <div className="mb-16">
            <button className="btn btn-success w-100">
                            Send Verification Code
            </button>
          </div>
          <div className='text-center mb-8'>
            <Link to="/" className="btn btn-link">Back to login ?</Link>
          </div>
        </form>
      </div>
    </>
  );
}

export default ForgotPasswordVerification
