/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { Switch, Steps, notification, Radio, Tooltip } from "antd";
import Avatar from "../../Assets/Images/Avatar.png";
import Modal from "react-bootstrap/Modal";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import salesForce from "../../Assets/Images/salesforce.svg";
import { utility } from "../../components/Utility";
import { clientUser } from "../../redux/actions/ClientUser/ClientUser";
import ClientDeactivate from "./ClientDeactivate";
import ClientReactivate from "./ClientReactivate";
import ClientResetPassword from "./ClientResetPassword";
import ClientResendInvite from "./ClientResendInvite";
import moment from "moment";
import Loading from "../../components/Loader";
import preview from "../../Assets/Images/preview.svg";
import previewDisabled from "../../Assets/Images/preview-disabled.svg";
import PreviewIframe from "./previewIframe";

const ClientUserDetails = (props) => {
  const { Step } = Steps;
  const [step, setStep] = useState(0);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [key, setKey] = useState("1");
  const [loader, setLoader] = useState(false);

  const [isEdit, setIsEdit] = useState(false);

  const [showClientUserDetail, setClientUserDetailShow] = useState(false);
  const handleClientUserDetailClose = () => {
    setClientUserDetailShow(false);
  };
  const handleClientUserDetailShow = () => setClientUserDetailShow(true);

  const [houseHoldDetails, setHouseHoldDetails] = useState();

  const [userDetails, setUserDetails] = useState();
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState();
  const [selectedEmail, setSelectedEmail] = useState();

  // const [calendarNotification, setCalendarNotification] = useState([]);
  const [messageNotification, setMessageNotification] = useState([]);
  const [docuSignNotification, setDocuSignNotification] = useState([]);
  const [fileNotification, setFileNotification] = useState([]);

  const [showMessageReset, setShowMessageReset] = useState(false);
  // const [showCalendarReset, setShowCalendarReset] = useState(false);
  const [showDocuSignReset, setShowDocuSignReset] = useState(false);
  const [showFileReset, setShowFileReset] = useState(false);

  // const [calendarDefaultNotification, setCalendarDefaultNotification] =
  //   useState([]);
  const [messageDefaultNotification, setMessageDefaultNotification] = useState(
    []
  );
  const [docuSignDefaultNotification, setDocuSignDefaultNotification] =
    useState([]);

  const [notificationUpdate, setNotificationUpdate] = useState([])

  const [fileDefaultNotification, setFileDefaultNotification] = useState([]);

  const [showMessage, setShowMessage] = useState(true);
  // const [showCalendar, setShowCalendar] = useState(true);
  const [showDocuSign, setShowDocuSign] = useState(true);
  const [showFile, setShowFile] = useState(true);

  //Handle Deactivate
  const [showDeactivateUser, setDeactivateUserShow] = useState(false);
  const handleDeactivateUserClose = () => {
    setDeactivateUserShow(false);
  };
  const handleDeactivateUserShow = () => setDeactivateUserShow(true);

  //Handle Reactivate
  const [showReactivateUser, setReactivateUserShow] = useState(false);
  const handleReactivateUserClose = () => {
    setReactivateUserShow(false);
  };
  const handleReactivateUserShow = () => setReactivateUserShow(true);

  //Handle Password
  const [showSendResetPasswordLink, setSendResetPasswordLinkShow] =
    useState(false);
  const handleSendResetPasswordLinkClose = () => {
    setSendResetPasswordLinkShow(false);
  };
  const handleSendResetPasswordLinkShow = () =>
    setSendResetPasswordLinkShow(true);

  //Handle Resend Invite Link
  const [showResendInviteLink, setResendInviteLink] = useState(false);

  const [investmentTab, setInvestmentTab] = useState([]);
  const [investmentToggle, setInvestmentToggle] = useState(false);

  const handleResendInviteLinkClose = () => {
    setResendInviteLink(false);
  };
  const handleResendInviteLinkShow = () => setResendInviteLink(true);

  const [articleNotification, setArticleNotification] = useState([]);
  const [showArticleReset, setShowArticleReset] = useState(false);
  const [showArticle, setShowArticle] = useState(true);
  const [articleDefaultNotification, setArticleDefaultNotification] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [defaultNotification,setDefaultNotification] = useState([]);
  const [showIframePreview,setShowIframePreview] = useState(false);
  const [toggleUpdate, setToggleUpdate] = useState(false);
  const [ rotate, setRotate] = useState(0);


  useEffect(() => {
    const { visible, closeModal, isReschedule } = props;

    setClientUserDetailShow(visible);
    getNotificationPreference();
    getUserDetails();
  }, []);
  const [emailErrorMsg, setEmailErrorMsg] = useState(null);

  useEffect(() => {
    if(messageNotification?.length > 0){
      testResetToDefault();
    }
  }, [
      // calendarNotification , 
      messageNotification , 
      docuSignNotification , 
      fileNotification , 
      // calendarDefaultNotification , 
      messageDefaultNotification , 
      docuSignDefaultNotification ,
      fileDefaultNotification, 
      articleNotification, 
      articleDefaultNotification]);

  function getUserDetails() {
    dispatch(clientUser.getUserDetails(props?.client?.uuid)).then(
      (response) => {
        if (response.type === "GET_USER_SUCCESS") {
          setUserDetails(response?.data);
          setSelectedEmail(response?.data?.email);
          setSelectedPhoneNumber(response?.data?.phone_number);
          getInvestmentToggleButtonPermission(response?.data?.uuid, response?.data?.client_household?.sf_external_id);
        }
      }
    );
  }



  function getInvestmentToggleButtonPermission(user_uuid, household_sf_id){
    dispatch(clientUser.getInvestmentToggleButtonPermission(household_sf_id, user_uuid)).then((response) => {
      if (response.type === "GET_INVESTMENT_TOGGLE_BUTTON_PERMISSION_SUCCESS") {
        setInvestmentToggle(response?.data?.toggle_allowed)
        setInvestmentTab(response?.data)
      }
    });
  }

  function checkEmailMobile(){
    setLoader(true);
    let paraData;
    if(userDetails?.email === selectedEmail){
      paraData = {"phone_number":selectedPhoneNumber}
    }
    else{
      paraData = {"phone_number":selectedPhoneNumber,"email": selectedEmail }
    }
    dispatch(clientUser.checkEmailMobile(paraData, userDetails?.client_household?.id)).then((response) =>{
      if (response.type === "POST_CHECK_EMAILMOBILE_SUCCESS"){
        setLoader(false);
        setEmailErrorMsg(null);
        {response?.data?.message && userDetails?.phone_number !== selectedPhoneNumber &&
        Notify(
          "warning",
           response?.data?.message,
        );
        updateUserDetails()
      }}
      else{
        setLoader(false);
        {userDetails?.email !== selectedEmail ?
        setEmailErrorMsg(response.error.error):
        updateUserDetails();
        }
      }
    });
  }


  function updateUserDetails() {
    let param = {
      user_id: props?.client?.uuid,
      email: selectedEmail,
      phone_number: selectedPhoneNumber,
    };
    dispatch(clientUser.updateUserDetails(param)).then((response) => {
      if (response.type === "UPDATE_USER_SUCCESS") {
        getUserDetails();
        setIsEdit(!isEdit);
      }
    });
  }

  function getNotificationPreference() {
    setLoader(true);
    dispatch(
      clientUser.getNotificationPreferencesById(props?.client?.uuid)
    ).then((response) => {
      setLoader(false);
      if (response.type === "GET_NOTIFICATION_SUCCESS") {
        let value = JSON.parse(JSON.stringify(response?.data));

        //data?.map((c) => (c.value = c.default_value));

        //Create Calendar Object
        // let calendar = value?.filter((c) => c?.preference_type === "calendar");
        // setCalendarNotification(calendar);

        //Create Message Object
        let message = value?.filter((c) => c?.preference_type === "message");
        setMessageNotification(message);

        //Create docusign Object
        let docuSign = value?.filter((c) => c?.preference_type === "docusign");
        setDocuSignNotification(docuSign);

        //Create docusign Object
        let file = value?.filter((c) => c?.preference_type === "files");
        setFileNotification(file);

        //Create article Object
        let article = value?.filter((c) => c?.preference_type === "articles");
        setArticleNotification(article);

        //This is setting
        setNotificationValue(value);
      }
    });
  }

  function testResetToDefault(){
    // if(JSON.stringify(calendarDefaultNotification) !== JSON.stringify(calendarNotification)){
    //   setShowCalendarReset(true);
    // }else{
    //   setShowCalendarReset(false);
    // }
    if(JSON.stringify(fileDefaultNotification) !== JSON.stringify(fileNotification)){
      setShowFileReset(true);
    }else{
      setShowFileReset(false);
    }
    if(JSON.stringify(docuSignDefaultNotification) !== JSON.stringify(docuSignNotification)){
      setShowDocuSignReset(true);
    }else{
      setShowDocuSignReset(false);
    }
    if(JSON.stringify(messageDefaultNotification) !== JSON.stringify(messageNotification)){
      setShowMessageReset(true);
    }else{
      setShowMessageReset(false);
    }
    if(JSON.stringify(articleDefaultNotification) !== JSON.stringify(articleNotification)){
      setShowArticleReset(true);
    }else{
      setShowArticleReset(false);
    }

  }

  function setNotificationValue(value) {
    let data = JSON.parse(JSON.stringify(value));

    //Setting the default value to value
    //data?.map((c) => (c.value = c.default_value));

    //Create Calendar Object
    // let calendar = data?.filter((c) => c?.preference_type === "calendar");
    // setCalendarDefaultNotification(calendar);

    //Create Message Object
    let message = data?.filter((c) => c?.preference_type === "message");
    setMessageDefaultNotification(message);

    //Create docusign Object
    let docuSign = data?.filter((c) => c?.preference_type === "docusign");
    setDocuSignDefaultNotification(docuSign);

    //Create docusign Object
    let file = data?.filter((c) => c?.preference_type === "files");
    setFileDefaultNotification(file);

    //Create article Object
    let article = data?.filter((c) => c?.preference_type === "articles");
    setArticleDefaultNotification(article);
  }

  function setUserPreference(id, value, dependent_value=null) {
    if(notificationUpdate.some(e => e?.preference_id === id && id !==9)) {
      setNotificationUpdate(notificationUpdate?.filter((val) => val?.preference_id !== id))
    }
    else{
      const notification_list = dependent_value?
        [{
          preference_id: dependent_value,
          value: value==="true"?true:false,
        },
        {
          preference_id: id,
          value: value,
        }]:
        [{
          preference_id: id,
          value: value,
        }]
      setNotificationUpdate([...notificationUpdate,...notification_list])
    }
  }



  function handleSubmit(){
    createToggleOfInvestmentTab()
    let param = {
      preferences: [...notificationUpdate, ...defaultNotification],
      user: userDetails?.uuid,
    };
    if (param?.preferences?.length > 0) {
    dispatch(clientUser.setUserPreference(param)).then((response) => {
      if (response.type === "SET_NOTIFICATION_SUCCESS") {
        props?.closeModal()
        //getNotificationPreference();
      }
    });
  }
  else{
    props?.closeModal()
  }
  }

  function setUserPreferenceReset(preferenceType,isReset=false) {
    
    let value = [];
    if (preferenceType === "msg") {
      messageDefaultNotification?.map((c) => {
        value.push({
          preference_id: c?.id,
          value: c?.default_value,
          reset_default:true
        });
      });
    } 
    // else if (preferenceType === "calendar") {
    //   calendarDefaultNotification?.map((c) => {
    //     value.push({
    //       preference_id: c?.id,
    //       value: c?.default_value,
    //       reset_default:true
    //     });

    //   });
    // } 
    else if (preferenceType === "docu") {
      docuSignDefaultNotification?.map((c) => {
        value.push({
          preference_id: c?.id,
          value: c?.default_value,
          reset_default:true
        });

      });
    } else if (preferenceType === "file") {
      fileDefaultNotification?.map((c) => {
        value.push({
          preference_id: c?.id,
          value: c?.default_value,
          reset_default:true
        });

      });
    }
    else if (preferenceType === "article") {
      articleDefaultNotification?.map((c) => {
        value.push({
          preference_id: c?.id,
          value: c?.default_value,
          reset_default:true
        })
      });
    }
    setDefaultNotification(value);
    // let param = {
    //   preferences: value,
    //   user: userDetails?.uuid,
    // };
    // dispatch(clientUser.setUserPreference(param,isReset)).then((response) => {
    //   if (response.type === "SET_NOTIFICATION_SUCCESS") {
    //     getNotificationPreference();
    //   }
    // });
  }

  const handleConfirmationModalClose = () => {
    setShowConfirmationModal(false);
  };

  function setUserPreferenceGroup(list, e) {
    let value = [];
    list?.map((c) => {
      value.push({
        preference_id: c,
        value: e,
      });
    });
    let param = {
      preferences: value,
      user: userDetails?.uuid,
    };
    dispatch(clientUser.setUserPreference(param)).then((response) => {
      if (response.type === "SET_NOTIFICATION_SUCCESS") {
        //getNotificationPreference();
      }
    });
  }

  const Notify = (type, mesage, icons) => {
    notification[type]({
      description: mesage,
      icon: icons,
      top: 65,
    });
  };

  function activateUser(uuid) {
    let param = {
      user_id: uuid,
    };
    dispatch(clientUser.reactivateUser(param)).then((response) => {
      if (response.type === "ACTIVATE_USER_SUCCESS") {
        Notify(
          "success",
          `${
            props?.client?.first_name + " " + props?.client?.last_name
          } (${props?.client?.email}) account is reactivated.`,
          <em className="icon-check-outline"></em>
        );
        getUserDetails();
      }
    });
  }

  function createToggleOfInvestmentTab(){
    let payload = {
      toggle: investmentToggle,
      user_uuid: userDetails?.uuid,
      orion_hh_id: investmentTab?.orion_hh_id,
    };
    dispatch(clientUser.createToggleOfInvestmentTab(payload)).then(
      (response) => {
        if (response.type === "CREATE_INVESTMENT_TOGGLE_ENTRY_SUCCESS") {
          console.log(response?.data)
        }
      }
    );
  }

  return (
    <>
      {loader && <Loading noTimeOut={loader} />}
      <Modal
        className="modal-800 modal-full"
        show={showClientUserDetail}
        onHide={() => (notificationUpdate?.length>0 || defaultNotification?.length>0 || toggleUpdate)?setShowConfirmationModal(true):props?.closeModal()}
        centered
      >
        <Modal.Header closeButton className="border-bottom pb-md-24">
          <Modal.Title>
            {userDetails?.first_name + " " + userDetails?.last_name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="cu-tab-wrap">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              className=""
              onSelect={(k) => {
                console.log(key);
                setKey(k);
              }}
            >
              <Tab
                eventKey={1}
                title={
                  <span className="d-md-flex align-items-center justify-content-center">
                    <span className="tab-icon">
                      <em className="icon-info-circle-filled"></em>
                    </span>
                    <span className="tab-text">User Details</span>
                  </span>
                }
              ></Tab>
              <Tab
                eventKey={2}
                title={
                  <span className="d-md-flex align-items-center justify-content-center">
                    <span className="tab-icon">
                      <em className="icon-cog-filled"></em>
                    </span>
                    <span className="tab-text">User Preferences</span>
                  </span>
                }
              ></Tab>
            </Tabs>
          </div>
          <div className="tab-content pt-24">
            {/* User Details tab content */}
            {key === "1" && (
              <div>
                <div className="d-flex align-items-start justify-content-between mb-24">
                  <div>
                    <h4 className="fs-18 lh-24 m-0">User Details</h4>
                    <span className="text-7E8C9A d-block fs-14 lh-20">
                      Last Update:{" "}
                      {moment(userDetails?.modified_at).format("ll")}
                    </span>
                  </div>
                  <div className="d-flex align-items-center">
                    {!isEdit ? (
                      <button
                        className="btn btn-outline-secondary d-flex align-items-center px-12 py-1 fw-500"
                        onClick={(e) => {
                          setIsEdit(!isEdit);
                        }}
                      >
                        <em className="icon-pencil-filled me-12 fs-12 lh-24"></em>
                        Edit
                      </button>
                    ) : (
                      <>
                        <div className="col-md-2">
                        <Tooltip placement="bottomRight" title="Refresh">
                          <span className="icon-24 cursor-pointer" onClick={()=>{setRotate(1), getUserDetails()
                            setTimeout(() => {
                              setRotate(0)
                            }, 1500); }}>
                              {rotate?
                              <em className="icon-refresh-outline rotate down"></em>:
                              <em className="icon-refresh-outline"></em>}
                          </span>
                        </Tooltip>
                        </div>
                        <button
                          type="button"
                          className="btn btn-outline-secondary me-16 btn-sm"
                          onClick={(e) => {
                            setIsEdit(!isEdit);
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className={emailErrorMsg?"btn btn-success btn-sm disabled":"btn btn-success btn-sm"}
                          onClick={(e) => {
                            {userDetails?.email === selectedEmail && userDetails?.phone_number === selectedPhoneNumber?
                              updateUserDetails():checkEmailMobile()}
                          }}
                        >
                          Save
                        </button>
                      </>
                    )}
                  </div>
                </div>
                {!isEdit && (
                  <div className="row justify-content-between">
                    <div className="col-md-4">
                      <div className="d-flex mb-20">
                        <span className="icon-24">
                          <em className="icon-info-circle-filled fs-18"></em>
                        </span>
                        <div className="ms-8 pt-2">
                          <span className="d-block lh-20 text-172935">
                            {userDetails?.salesforce_id}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex mb-20">
                        <span className="icon-24">
                          {userDetails?.is_deleted ? (
                            <em className={"icon-x-circle-filled fs-18"}></em>
                          ) : (
                            <em
                              className={
                                userDetails?.is_active
                                  ? "icon-check-circle-filled text-success fs-18"
                                  : "icon-clock-filled fs-18"
                              }
                            ></em>
                          )}
                        </span>
                        <div className="ms-8 pt-2">
                          {userDetails?.is_deleted ? (
                            <span className="d-block lh-20 text-172935">
                              {"Deactivated"}
                            </span>
                          ) : (
                            <span className="d-block lh-20 text-172935">
                              {userDetails?.is_active
                                ? "Active"
                                : props?.client?.invite_token === "expired"? "Invite Expired":"Invite Sent"}
                            </span>
                          )}
                          <span className="d-block lh-20 fs-14 text-7E8C9A mt-2">
                            {userDetails?.is_active
                              ? `Last Login: ${
                                  userDetails?.last_login
                                    ? moment(userDetails?.last_login).format(
                                        "ll"
                                      )
                                    : "--"
                                }`
                              : `Invite Link Sent: ${moment(
                                  userDetails?.modified_at
                                ).format("ll")}`}
                          </span>
                        </div>
                      </div>
                      {userDetails?.is_deleted && (
                        <div className="d-flex mb-20">
                          <span className="icon-24">
                            <img src={salesForce} alt="" />
                          </span>

                          <div className="ms-8 pt-2">
                            <span className="d-block lh-20 text-172935">
                              {"Terminated"}
                            </span>
                            <a href={userDetails?.sf_account_link}
                               target="_blank"
                                rel="noopener noreferrer" className="d-block lh-20 mt-12 text-underline">
                              View Household Details
                            </a>
                            <a href={userDetails?.sf_contact_link}
                               target="_blank"
                                rel="noopener noreferrer" className="d-block lh-20 mt-12 text-underline">
                              View Contact Details
                            </a>
                          </div>
                        </div>
                      )}
                      <div className="d-flex mb-20">
                        <span className="icon-24">
                          <em className="icon-home-filled fs-18"></em>
                        </span>
                        <div className="ms-8 pt-2">
                          <span className="d-block lh-20 text-172935">
                            {userDetails?.client_household?.household_name}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex mb-20">
                        <span className="icon-24">
                          <em className="icon-phone-filled fs-18"></em>
                        </span>
                        <div className="ms-8 pt-2">
                          <span className="d-block lh-20 text-172935">
                            {userDetails?.phone_number}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex mb-20">
                        <span className="icon-24">
                          <em className="icon-mail-filled fs-18"></em>
                        </span>
                        <div className="ms-8 pt-2">
                          <span className="d-block lh-20 text-172935">
                            {userDetails?.email}
                          </span>
                        </div>
                      </div>
                      {!userDetails?.is_deleted && (
                        <div className="d-flex">
                          <span className="icon-24">
                            <img src={salesForce} alt="" />
                          </span>
                          <div className="ms-8 pt-2">
                            <a href={userDetails?.sf_account_link}
                               target="_blank"
                                rel="noopener noreferrer" className="d-block lh-20 text-underline">
                              View Household Details
                            </a>
                            <a href={userDetails?.sf_contact_link}
                               target="_blank"
                                rel="noopener noreferrer" className="d-block lh-20 mt-12 text-underline">
                              View Contact Details
                            </a>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="col-md-6">
                      <div className="d-flex">
                        <span className="icon-24 mt-4">
                          <em className="icon-users-filled fs-18"></em>
                        </span>
                        <div className="ms-8">
                          {userDetails?.client_household?.relationship_lead && (
                            <div className="cu-user-block d-flex align-items-center">
                              <div className="cu-photo-outer me-8">
                                <span className="cu-photo">
                                  {/* <img src={Avatar} alt="" /> */}
                                  {utility.getProfileImage(
                                    userDetails?.client_household?.relationship_lead
                                      ?.profile_image,
                                    userDetails?.client_household?.relationship_lead?.first_name?.charAt(
                                      0
                                    ),
                                    userDetails?.client_household?.relationship_lead?.last_name?.charAt(
                                      0
                                    )
                                  )}
                                </span>
                              </div>
                              <div className="lh-20">
                                <span className="d-block">
                                  {userDetails?.client_household?.relationship_lead
                                    ?.first_name +
                                    " " +
                                    userDetails?.client_household?.relationship_lead
                                      ?.last_name}
                                </span>
                                <span className="d-block fw-500">
                                  Relationship Lead
                                </span>
                              </div>
                            </div>
                          )}
                          {userDetails?.client_household?.partner && (
                            <div className="cu-user-block d-flex align-items-center">
                              <div className="cu-photo-outer me-8">
                                <span className="cu-photo">
                                  {utility.getProfileImage(
                                    userDetails?.client_household?.partner
                                      ?.profile_image,
                                    userDetails?.client_household?.partner?.first_name?.charAt(
                                      0
                                    ),
                                    userDetails?.client_household?.partner?.last_name?.charAt(
                                      0
                                    )
                                  )}
                                </span>
                              </div>
                              <div className="lh-20">
                                <span className="d-block">
                                  {userDetails?.client_household?.partner?.first_name +
                                    " " +
                                    userDetails?.client_household?.partner?.last_name}
                                </span>
                                <span className="d-block fw-500">Partner</span>
                              </div>
                            </div>
                          )}
                          {userDetails?.client_household?.advisor && (
                            <div className="cu-user-block d-flex align-items-center">
                              <div className="cu-photo-outer me-8">
                                <span className="cu-photo">
                                  {utility.getProfileImage(
                                    userDetails?.client_household?.advisor
                                      ?.profile_image,
                                    userDetails?.client_household?.advisor?.first_name?.charAt(
                                      0
                                    ),
                                    userDetails?.client_household?.advisor?.last_name?.charAt(
                                      0
                                    )
                                  )}
                                </span>
                              </div>
                              <div className="lh-20">
                                <span className="d-block">
                                  {userDetails?.client_household?.advisor?.first_name +
                                    " " +
                                    userDetails?.client_household?.advisor?.last_name}
                                </span>
                                <span className="d-block fw-500">Advisor</span>
                              </div>
                            </div>
                          )}
                          {userDetails?.client_household?.secondary_advisor && (
                            <div className="cu-user-block d-flex align-items-center">
                              <div className="cu-photo-outer me-8">
                                <span className="cu-photo">
                                  {utility.getProfileImage(
                                    userDetails?.client_household?.secondary_advisor
                                      ?.profile_image,
                                    userDetails?.client_household?.secondary_advisor?.first_name?.charAt(
                                      0
                                    ),
                                    userDetails?.client_household?.secondary_advisor?.last_name?.charAt(
                                      0
                                    )
                                  )}
                                </span>
                              </div>
                              <div className="lh-20">
                                <span className="d-block">
                                  {userDetails?.client_household?.secondary_advisor?.first_name +
                                    " " +
                                    userDetails?.client_household?.secondary_advisor?.last_name}
                                </span>
                                <span className="d-block fw-500">Secondary Advisor</span>
                              </div>
                            </div>
                          )}
                          {userDetails?.client_household?.csm && (
                            <div className="cu-user-block d-flex align-items-center">
                              <div className="cu-photo-outer me-8">
                                <span className="cu-photo">
                                  {utility.getProfileImage(
                                    userDetails?.client_household?.csm?.profile_image,
                                    userDetails?.client_household?.csm?.first_name?.charAt(
                                      0
                                    ),
                                    userDetails?.client_household?.csm?.last_name?.charAt(
                                      0
                                    )
                                  )}
                                </span>
                              </div>
                              <div className="lh-20">
                                <span className="d-block">
                                  {userDetails?.client_household?.csm?.first_name +
                                    " " +
                                    userDetails?.client_household?.csm?.last_name}
                                </span>
                                <span className="d-block fw-500">CSM</span>
                              </div>
                            </div>
                          )}
                          {userDetails?.client_household?.secondary_csm && (
                            <div className="cu-user-block d-flex align-items-center">
                              <div className="cu-photo-outer me-8">
                                <span className="cu-photo">
                                  {utility.getProfileImage(
                                    userDetails?.client_household?.secondary_csm
                                      ?.profile_image,
                                    userDetails?.client_household?.secondary_csm?.first_name?.charAt(
                                      0
                                    ),
                                    userDetails?.client_household?.secondary_csm?.last_name?.charAt(
                                      0
                                    )
                                  )}
                                </span>
                              </div>
                              <div className="lh-20">
                                <span className="d-block">
                                  {userDetails?.client_household?.secondary_csm?.first_name +
                                    " " +
                                    userDetails?.client_household?.secondary_csm?.last_name}
                                </span>
                                <span className="d-block fw-500">Secondary CSM</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {isEdit && (
                  <>
                    <div className="row">
                      <div className="col-md-6">
                        <span className="d-block mb-16 text-black fw-500">
                          Cell Phone
                        </span>
                        <Radio.Group
                          className=""
                          onChange={(e) => {
                            setSelectedPhoneNumber(e?.target?.value);
                          }}
                          value={selectedPhoneNumber}
                        >
                          {userDetails?.contact?.phone_numbers?.map(
                            (phone, index) => (
                              <>
                                <Radio value={phone} key={phone}>
                                  {phone}
                                </Radio>
                              </>
                            )
                          )}
                        </Radio.Group>
                      </div>
                      <div className="col-md-6">
                        <span className="d-block mb-16 text-black fw-500">
                          Email Address
                        </span>
                        <Radio.Group
                          className=""
                          onChange={(e) => {
                            setEmailErrorMsg(null);
                            setSelectedEmail(e?.target?.value);
                          }}
                          value={selectedEmail}
                        >
                          {userDetails?.contact?.emails?.map((email, index) => (
                            <>
                              <Radio value={email} key={email}>
                                {email}
                              </Radio>
                            </>
                          ))}
                        </Radio.Group>
                      </div>
                      {emailErrorMsg && (
                      <div className="alert alert-danger d-block mt-10">
                        <p className="m-0">
                          {emailErrorMsg}
                        </p>
                      </div>
                    )}
                    </div>
                    <div className="cu-alert mt-40">
                      To update client data please go to SalesForce. Allow some
                      time for your updates to reflect in Moneta portal.
                    </div>
                  </>
                )}
              </div>
            )}
            {/* User Preferences tab content */}
            {key === "2" && (
              <div className="user-permission">
                   <div className="profnotif-block profnotif-open">
                  <div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="mb-4 mb-md-0">
                      <h4 className="fs-18 lh-24 mb-md-4 mb-0">Articles</h4>
                      <p className="m-0 d-none d-md-block">
                        Article Notifications
                      </p>
                      </div>
                      <div className="d-flex align-items-center">
                        <span
                          className={showArticle ? "notif-status fs-18 lh-32 me-12 cursor-pointer text-success" : articleNotification?.find((x) => x.id === 22)?.value
                          ? "notif-status fs-18 lh-32 me-12 ": "notif-status fs-18 lh-32 me-12"}
                          onClick={(e) => {
                            if(showArticle){
                              setArticleNotification(
                                JSON.parse(
                                  JSON.stringify(articleDefaultNotification))
                                    .map((x) => {
                                      return {...x,value:x.default_value}
                                    } 
                                )
                              );
                              setArticleDefaultNotification(
                                JSON.parse(
                                  JSON.stringify(articleDefaultNotification))
                                    .map((x) => {
                                      return {...x,value:x.default_value}
                                    } 
                                )
                              );
                              setUserPreferenceReset("article",true);
                            }
                          }}
                        >
                          {showArticle
                            ? (showArticleReset ? "Reset to Default" : "")
                            : articleNotification?.find((x) => x.id === 22)?.value
                            ? "On"
                            : "Off"}
                        </span>
                        <span
                          className="cursor-pointer icon-32"
                          onClick={(e) => {
                            setShowArticle(!showArticle);
                          }}
                        >
                          <em className="icon icon-chevron-down-outline fs-16"></em>
                        </span>
                      </div>
                    </div>
                  </div>
                  {showArticle && (
                    <div className="pt-24">
                      <div className="notif-type d-flex align-items-center justify-content-between">
                        <h6 className="m-0 pe-8">
                          Allow push notifications for Articles
                        </h6>
                        <Switch
                          checked={
                            articleNotification?.find((x) => x.id === 22)?.value
                          }
                          onChange={(e) => {
                            articleNotification
                              ?.filter((x) => x.id === 22)
                              .map((x) => (x.value = e));
                            setArticleNotification([...articleNotification]);
                            setUserPreference(22, e);
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="profnotif-block profnotif-open">
                  <div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="mb-4 mb-md-0">
                        <h4 className="fs-18 lh-24 mb-md-4 mb-0">Messages</h4>
                        <p className="m-0 d-none d-md-block">
                          Read receipts, notifications.
                        </p>
                      </div>
                      <div className="d-flex align-items-center">
                        <span
                          className={showMessage ? "notif-status fs-18 lh-32 me-12 cursor-pointer text-success" : messageNotification?.find((x) => x.id === 1)?.value
                          ? "notif-status fs-18 lh-32 me-12 ": "notif-status fs-18 lh-32 me-12 "}
                          onClick={(e) => {
                            if(showMessage){
                              setMessageNotification(
                                JSON.parse(
                                  JSON.stringify(messageDefaultNotification))
                                    .map((x) => {
                                      return {...x,value:x.default_value}
                                    } 
                                )
                              );
                              setMessageDefaultNotification(
                                JSON.parse(
                                  JSON.stringify(messageDefaultNotification))
                                    .map((x) => {
                                      return {...x,value:x.default_value}
                                    } 
                                )
                              );
                              setUserPreferenceReset("msg",true);
                            }

                          }}
                        >
                          {showMessage
                            ? (showMessageReset ? "Reset to Default" : "")
                            : messageNotification?.find((x) => x.id === 1)
                                ?.value
                            ? "On"
                            : "Off"}
                        </span>
                        <span
                          className="cursor-pointer icon-32"
                          onClick={(e) => {
                            setShowMessage(!showMessage);
                          }}
                        >
                          <em className="icon icon-chevron-down-outline fs-16"></em>
                        </span>
                      </div>
                    </div>
                    <p className="m-0 d-md-none">
                      Read receipts, notifications.
                    </p>
                  </div>
                  {showMessage && (
                    <div className="pt-24">
                      <div className="notif-type d-flex align-items-center justify-content-between">
                        <h6 className="m-0 pe-8">
                          Enable Messages feature for this user
                        </h6>
                        <Switch
                          checked={
                            messageNotification?.find((x) => x.id === 1)?.value
                          }
                          onChange={(e) => {
                            console.log(e);
                            messageNotification
                              ?.filter((x) => x.id === 1)
                              .map((x) => (x.value = e));
                            setMessageNotification([...messageNotification]);
                            setUserPreference(1, e);
                          }}
                        />
                      </div>
                      {messageNotification?.find((x) => x.id === 1)?.value && (
                        <>
                          <div className="notif-type d-flex justify-content-between">
                          <div className="mb-4 mb-md-0">
                            <h6 className="m-0 pe-8 fw-normal">
                              Send Read Receipts
                            </h6>
                            <p className="text-muted mb-20 fs-14 lh-20">
                              When this is on, people are notified when you have read their messages. 
                              This enables read receipts for all conversations.
                            </p>
                            </div>
                            <Switch
                              checked={
                                messageNotification?.find((x) => x.id === 5)
                                  ?.value
                              }
                              onChange={(e) => {
                                console.log(e);
                                messageNotification
                                  ?.filter((x) => x.id === 5)
                                  .map((x) => (x.value = e));
                                setMessageNotification([
                                  ...messageNotification,
                                ]);
                                setUserPreference(5, e);
                              }}
                            />
                          </div>
                          <div className="notif-type">
                          <div className="typesof-not d-flex align-items-center justify-content-between">
                            <h6 className="mb-8">
                            Allow push notifications for messages
                            </h6>
                            <Switch
                                  checked={
                                    messageNotification?.find((x) => x.id === 6)
                                      ?.value
                                  }
                                  onChange={(e) => {
                                    console.log(e);
                                    messageNotification
                                      ?.filter((x) => x.id === 6)
                                      .map((x) => (x.value = e));
                                    setMessageNotification([
                                      ...messageNotification,
                                    ]);
                                    setUserPreference(6, e);
                                  }}
                                />
                            </div>
                            <div>
                            <div className="typesof-not d-flex align-items-center justify-content-between">
                              </div>
                              <div className="typesof-not ">
                                <div className="d-flex align-items-center justify-content-between">
                                  <span className="lh-20">
                                    Enable email notifications for missed chat
                                    messages
                                  </span>
                                  <Switch
                                    checked={
                                      messageNotification?.find(
                                        (x) => x.id === 7
                                      )?.value !== "none"
                                    }
                                    onChange={(e) => {
                                      if (
                                        messageNotification?.find(
                                          (x) => x.id === 7
                                        )?.value !== "none"
                                      ) {
                                        messageNotification
                                          ?.filter((x) => x.id === 7)
                                          .map((x) => (x.value = "none"));

                                        setMessageNotification([
                                          ...messageNotification,
                                        ]);
                                        setUserPreference(7, "none");
                                      } else {
                                        messageNotification
                                          ?.filter((x) => x.id === 7)
                                          .map(
                                            (x) => (x.value = "Once every hour")
                                          );
                                        setMessageNotification([
                                          ...messageNotification,
                                        ]);
                                      }
                                    }}
                                  />
                                </div>
                                <div className="pt-20">
                                  <Radio.Group
                                    className=""
                                    onChange={(e) => {
                                      messageNotification
                                        ?.filter((x) => x.id === 7)
                                        .map(
                                          (x) => (x.value = e?.target?.value)
                                        );
                                      setMessageNotification([
                                        ...messageNotification,
                                      ]);
                                      setUserPreference(7, e?.target?.value);
                                    }}
                                    value={
                                      messageNotification?.find(
                                        (x) => x.id === 7
                                      )?.value
                                    }
                                  >
                                    <Radio value={"Once every hour"}>
                                      Once every hour
                                    </Radio>
                                    <Radio value={"Once every 8 hours"}>
                                      Once every 8 hours
                                    </Radio>
                                    <Radio value={"Once daily"}>
                                      Once daily
                                    </Radio>
                                  </Radio.Group>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>
                <div className="profnotif-block profnotif-open">
                  <div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="mb-4 mb-md-0">
                      <h4 className="fs-18 lh-24 mb-md-4 mb-0">Investments</h4>
                      <p className="m-0 d-none d-md-block">
                        Financial Data through Orion integration.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="pt-24">
                  <div className="notif-type">
                    <div className="d-flex align-items-center justify-content-between mb-20">
                      <h6 className="m-0 pe-8">
                        Enable Investments feature for this account
                      </h6>
                      <Tooltip overlayClassName="tooptip-style" placement="bottomRight" title={!investmentTab?.investment_tab?"This feature can only be enabled for clients who have an Orion Planning client portal user.":""}>
                      <Switch
                        checked={investmentToggle}
                        onChange={(e) => {
                          setInvestmentToggle(e);
                          setToggleUpdate(!toggleUpdate)
                          // createToggleOfInvestmentTab(e);
                        }}
                        disabled={!investmentTab?.investment_tab}
                      />
                      </Tooltip>
                    </div>
                  {investmentTab?.orion_hh_id &&  investmentTab?.orion_hh_id!=="None"?
                  <div className="alert alert-warning d-block">
                    <p className="m-0">{userDetails?.first_name} {userDetails?.last_name} is connected to the following Orion Household: {investmentTab?.orion_hh_name} ({investmentTab?.orion_hh_id}). Please do not enable the feature if you believe this is incorrect.</p>
                  </div>:
                   <div className="alert alert-warning d-block">
                   <p className="m-0">We did not find an Orion Household ID connected to {userDetails?.first_name} {userDetails?.last_name}. Please confirm the client’s Orion Household is setup properly.</p>
                  </div>
                    }
                  </div>
                </div>
                <div className="pt-24">
                  <div className="notif-type d-flex justify-content-between">
                    {/* <div className="d-flex justify-content-between"> */}
                      <div>
                      <h6 className="m-0 pe-8">
                        Client Preview
                      </h6>
                      <p className="text-muted mb-20 fs-14 lh-20">
                      Preview what the client will see if this feature is enabled.
                      </p>
                      </div>
                      {!investmentTab?.investment_tab?
                      <Tooltip overlayClassName="tooptip-style" placement="bottomRight" title={!investmentTab?.investment_tab?"Only clients who have an Orion Planning client portal user can be previewed.":""}>
                      <img src={previewDisabled} alt="" disabled={true} style={{height:'36px'}}/>
                      </Tooltip>:
                      <img src={preview} alt="" className="cursor-pointer" onClick={()=>(setShowIframePreview(true))} style={{height:'36px'}}/>
                      }
                    {/* </div> */}
                    
                  </div>
                </div>
              </div>
                {/* <div className="profnotif-block profnotif-open">
                  <div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="mb-4 mb-md-0">
                        <h4 className="fs-18 lh-24 mb-md-4 mb-0">Calendar</h4>
                        <p className="m-0 d-none d-md-block">
                          Meeting duration, attendees, notifications.
                        </p>
                      </div>
                      <div className="d-flex align-items-center">
                        <span
                          className={showCalendar ? "notif-status fs-18 lh-32 me-12 cursor-pointer text-success" : calendarNotification?.find((x) => x.id === 2)?.value
                          ? "notif-status fs-18 lh-32 me-12 ": "notif-status fs-18 lh-32 me-12 "}
                          onClick={(e) => {
                            if(showCalendar){
                              setCalendarNotification(
                                JSON.parse(
                                  JSON.stringify(calendarDefaultNotification))
                                    .map((x) => {
                                      return {...x,value:x.default_value}
                                    } 
                                )
                              );
                              setCalendarDefaultNotification(
                                JSON.parse(
                                  JSON.stringify(calendarDefaultNotification))
                                    .map((x) => {
                                      return {...x,value:x.default_value}
                                    } 
                                )
                              );
                              setUserPreferenceReset("calendar",true);
                            }
                          }}
                        >
                          {showCalendar
                            ? (showCalendarReset ? "Reset to Default" : "")
                            : calendarNotification?.find((x) => x.id === 2)
                                ?.value
                            ? "On"
                            : "Off"}
                        </span>
                        <span
                          className="cursor-pointer icon-32"
                          onClick={(e) => {
                            setShowCalendar(!showCalendar);
                          }}
                        >
                          <em className="icon icon-chevron-down-outline fs-16"></em>
                        </span>
                      </div>
                    </div>
                    <p className="m-0 d-md-none">
                      Meeting duration, attendees, notifications.
                    </p>
                  </div>
                  {showCalendar && (
                    <div className="pt-24">
                      <div className="notif-type">
                        <div className="d-flex align-items-center justify-content-between mb-20">
                          <h6 className="m-0 pe-8">
                            Enable Calendar feature for this account
                          </h6>
                          <Switch
                            checked={
                              calendarNotification?.find((x) => x.id === 2)
                                ?.value
                            }
                            onChange={(e) => {
                              console.log(e);
                              calendarNotification
                                ?.filter((x) => x.id === 2)
                                .map((x) => (x.value = e));
                              setCalendarNotification([
                                ...calendarNotification,
                              ]);
                              if(!e){
                                // 21 is for Enable Meeting Request for the Client
                                calendarNotification
                                ?.filter((x) => x.id === 21)
                                .map((x) => (x.value = "false"));
                                setUserPreference(21, e?"true":"false", 2);
                              }
                              else{
                                setUserPreference(2, e);
                              }
                            }}
                          />
                        </div>
                        {( 
                          // 2 is for Enable Calendar feature for this account
                        (calendarNotification?.find((x) => x.id === 2)?.value)) &&(
                        <div className="d-flex align-items-center justify-content-between">
                        <div>
                        <h6 className="m-0 pe-8">
                            Request Meeting Option
                        </h6>
                        <p className="text-muted mb-20 fs-14 lh-20">
                          Select whether the client has the option to request a new meeting.
                        </p>
                        </div>
                        <Switch
                            checked={
                              calendarNotification?.find((x) => x.id === 21)
                                ?.value==="true"?true:false
                            }
                            onChange={(e) => {
                              console.log(e);
                              calendarNotification
                                ?.filter((x) => x.id === 21)
                                .map((x) => (x.value = e?"true":"false"));
                              setCalendarNotification([
                                ...calendarNotification,
                              ]);
                              setUserPreference(21, e?"true":"false");
                            }}
                          />
                      </div>
                        )}
                        {(
                          (calendarNotification?.find((x) => x.id === 21)?.value) === "true") &&(
                          <div className="pt-24">
                            <h6 className="fw-normal m-0 text-1E3443">
                              Default meeting duration
                            </h6>
                            <p className="text-muted mb-20 fs-14 lh-20">
                              Pre-select for meeting requests. The client will
                              still be able to choose from all options.
                            </p>
                            <div>
                              <Radio.Group
                                className=""
                                onChange={(e) => {
                                  calendarNotification
                                    ?.filter((x) => x.id === 8)
                                    .map((x) => (x.value = e?.target?.value));
                                  setCalendarNotification([
                                    ...calendarNotification,
                                  ]);
                                  setUserPreference(8, e?.target?.value);
                                }}
                                value={
                                  calendarNotification?.find((x) => x.id === 8)
                                    ?.value
                                }
                              >
                                <Radio value={"30 minutes"}>30 minutes</Radio>
                                <Radio value={"60 minutes"}>60 minutes</Radio>
                                <Radio value={"90 minutes"}>90 minutes</Radio>
                              </Radio.Group>
                            </div>
                          </div>
                        )}
                      </div>
                      {(
                          (calendarNotification?.find((x) => x.id === 21)?.value === "true")) &&(
                        <>
                          <hr className="mt-24 mb-0"></hr>

                          <div className="pt-24">
                            <h6 className="fw-normal m-0 text-1E3443">
                              Default meeting attendees
                            </h6>
                            <p className="text-muted mb-12 fs-14 lh-20">
                              Pre-select for meeting requests. The client will
                              still be able to choose from all options.
                            </p>
                            <a
                              href="#"
                              className="mb-4 fs-14 d-inline-block"
                              onClick={(e) => {
                                if (
                                  calendarNotification?.find((x) => x.id === 9)
                                    ?.value.length === 6
                                ) {
                                  setCalendarNotification(
                                    JSON.parse(
                                      JSON.stringify(calendarDefaultNotification))
                                        .map((x) => {
                                          return {...x,value:x.default_value}
                                        } 
                                    )
                                  );
                                  setCalendarDefaultNotification(
                                    JSON.parse(
                                      JSON.stringify(calendarDefaultNotification))
                                        .map((x) => {
                                          return {...x,value:x.default_value}
                                        } 
                                    )
                                  );
                                  setUserPreference(9,calendarDefaultNotification?.filter((x) => x.id === 9)[0]?.default_value);  
                                } else {
                                  calendarNotification
                                    ?.filter((x) => x.id === 9)
                                    .map(
                                      (x) =>
                                        (x.value = [
                                          "Relationship Lead",
                                          "Partner",
                                          "Advisor",
                                          "Secondary Advisor",
                                          "CSM",
                                          "Secondary CSM",
                                        ])
                                    );
                                  setCalendarNotification(calendarNotification);
                                  setUserPreference(9,calendarNotification?.filter((x) => x.id === 9)[0]?.value);
                                }
                              }}
                            >
                              {calendarNotification?.find((x) => x.id === 9)
                                ?.value.length === 6
                                ? "Reset to Default"
                                : "Select All"}
                            </a>
                            <div className="row">
                              <div className="col-md-8">
                                {userDetails?.client_household
                                                ?.relationship_lead &&
                                <div className="py-8">
                                  <div className="row align-items-center">
                                    <div className="col-md-6">
                                      <div className="form-check mb-0">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id="meeting-attend1"
                                          checked={calendarNotification
                                            ?.find((x) => x.id === 9)
                                            ?.value.includes(
                                              "Relationship Lead"
                                            )}
                                          onChange={(e) => {
                                            if (
                                              !calendarNotification
                                                ?.find((x) => x.id === 9)
                                                ?.value.includes(
                                                  "Relationship Lead"
                                                )
                                            ) {
                                              calendarNotification
                                                ?.filter((x) => x.id === 9)
                                                .map((x) =>
                                                  x.value.push(
                                                    "Relationship Lead"
                                                  )
                                                );
                                            } else {
                                              calendarNotification
                                                ?.filter((x) => x.id === 9)
                                                .map((x) => {
                                                  var index =
                                                    x.value.indexOf(
                                                      "Relationship Lead"
                                                    );
                                                  if (index >= 0 && x.value.length>1) {
                                                    x.value.splice(index, 1);
                                                  }
                                                });
                                            }
                                            setCalendarNotification([
                                              ...calendarNotification,
                                            ]);
                                            setUserPreference(9,calendarNotification?.filter((x) => x.id === 9)[0]?.value);
                                          }}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="meeting-attend1"
                                        >
                                          Relationship Lead
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="cu-user-block d-flex align-items-center">
                                        <div className="cu-photo-outer me-8">
                                          <span className="cu-photo">
                                            {utility.getProfileImage(
                                              userDetails?.client_household
                                                ?.relationship_lead
                                                ?.profile_image,
                                              userDetails?.client_household?.relationship_lead?.first_name?.charAt(
                                                0
                                              ),
                                              userDetails?.client_household?.relationship_lead?.last_name?.charAt(
                                                0
                                              )
                                            )}
                                          </span>
                                        </div>
                                        <div className="lh-20">
                                          <span className="d-block">
                                            {userDetails?.client_household
                                              ?.relationship_lead?.first_name
                                              ? userDetails?.client_household
                                                  ?.relationship_lead
                                                  ?.first_name +
                                                " " +
                                                userDetails?.client_household
                                                  ?.relationship_lead?.last_name
                                              : "--"}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                }
                                {userDetails?.client_household
                                                ?.partner &&
                                <div className="py-8">
                                  <div className="row align-items-center">
                                    <div className="col-md-6">
                                      <div className="form-check mb-0">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id="meeting-attend2"
                                          checked={calendarNotification
                                            ?.find((x) => x.id === 9)
                                            ?.value.includes("Partner")}
                                          onChange={(e) => {
                                            console.log(e.target.value);
                                            if (
                                              !calendarNotification
                                                ?.find((x) => x.id === 9)
                                                ?.value.includes("Partner")
                                            ) {
                                              calendarNotification
                                                ?.filter((x) => x.id === 9)
                                                .map((x) =>
                                                  x.value.push("Partner")
                                                );
                                            } else {
                                              calendarNotification
                                                ?.filter((x) => x.id === 9)
                                                .map((x) => {
                                                  var index =
                                                    x?.value?.indexOf(
                                                      "Partner"
                                                    );
                                                  if (index >= 0 && x.value.length>1) {
                                                    x.value.splice(index, 1);
                                                  }
                                                });
                                            }
                                            setCalendarNotification([
                                              ...calendarNotification,
                                            ]);
                                            setUserPreference(9,calendarNotification?.filter((x) => x.id === 9)[0]?.value);
                                          }}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="meeting-attend2"
                                        >
                                          Partner
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="cu-user-block d-flex align-items-center">
                                        {userDetails?.client_household?.partner && (
                                          <div className="cu-photo-outer me-8">
                                            <span className="cu-photo">
                                              {utility.getProfileImage(
                                                userDetails?.client_household?.partner
                                                  ?.profile_image,
                                                userDetails?.client_household?.partner?.first_name?.charAt(
                                                  0
                                                ),
                                                userDetails?.client_household?.partner?.last_name?.charAt(
                                                  0
                                                )
                                              )}
                                            </span>
                                          </div>
                                        )}
                                        <div className="lh-20">
                                          <span className="d-block">
                                            {userDetails?.client_household?.partner
                                              ?.first_name
                                              ? userDetails?.client_household?.partner
                                                  ?.first_name +
                                                " " +
                                                userDetails?.client_household?.partner
                                                  ?.last_name
                                              : "--"}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                }
                                {userDetails?.client_household
                                                ?.advisor &&
                                <div className="py-8">
                                  <div className="row align-items-center">
                                    <div className="col-md-6">
                                      <div className="form-check mb-0">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id="meeting-attend3"
                                          checked={calendarNotification
                                            ?.find((x) => x.id === 9)
                                            ?.value.includes("Advisor")}
                                          onChange={(e) => {
                                            console.log(e.target.value);
                                            if (
                                              !calendarNotification
                                                ?.find((x) => x.id === 9)
                                                ?.value.includes("Advisor")
                                            ) {
                                              calendarNotification
                                                ?.filter((x) => x.id === 9)
                                                .map((x) =>
                                                  x.value.push("Advisor")
                                                );
                                            } else {
                                              calendarNotification
                                                ?.filter((x) => x.id === 9)
                                                .map((x) => {
                                                  var index =
                                                    x?.value?.indexOf(
                                                      "Advisor"
                                                    );
                                                  if (index >= 0 && x.value.length>1) {
                                                    x.value.splice(index, 1);
                                                  }
                                                });
                                            }
                                            setCalendarNotification([
                                              ...calendarNotification,
                                            ]);
                                            setUserPreference(9,calendarNotification?.filter((x) => x.id === 9)[0]?.value);
                                          }}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="meeting-attend3"
                                        >
                                          Advisor
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="cu-user-block d-flex align-items-center">
                                        {userDetails?.client_household?.advisor && (
                                          <div className="cu-photo-outer me-8">
                                            <span className="cu-photo">
                                              {utility.getProfileImage(
                                                userDetails?.client_household?.advisor
                                                  ?.profile_image,
                                                userDetails?.client_household?.advisor?.first_name?.charAt(
                                                  0
                                                ),
                                                userDetails?.client_household?.advisor?.last_name?.charAt(
                                                  0
                                                )
                                              )}
                                            </span>
                                          </div>
                                        )}
                                        <div className="lh-20">
                                          <span className="d-block">
                                            {userDetails?.client_household?.advisor
                                              ?.first_name
                                              ? userDetails?.client_household?.advisor
                                                  ?.first_name +
                                                " " +
                                                userDetails?.client_household?.advisor
                                                  ?.last_name
                                              : "--"}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                }
                                {userDetails?.client_household
                                                ?.secondary_advisor &&
                                <div className="py-8">
                                  <div className="row align-items-center">
                                    <div className="col-md-6">
                                      <div className="form-check mb-0">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id="meeting-attend4"
                                          checked={calendarNotification
                                            ?.find((x) => x.id === 9)
                                            ?.value.includes(
                                              "Secondary Advisor"
                                            )}
                                          onChange={(e) => {
                                            console.log(e.target.value);
                                            if (
                                              !calendarNotification
                                                ?.find((x) => x.id === 9)
                                                ?.value.includes(
                                                  "Secondary Advisor"
                                                )
                                            ) {
                                              calendarNotification
                                                ?.filter((x) => x.id === 9)
                                                .map((x) =>
                                                  x.value.push(
                                                    "Secondary Advisor"
                                                  )
                                                );
                                            } else {
                                              calendarNotification
                                                ?.filter((x) => x.id === 9)
                                                .map((x) => {
                                                  var index =
                                                    x?.value?.indexOf(
                                                      "Secondary Advisor"
                                                    );
                                                  if (index >= 0 && x.value.length>1) {
                                                    x.value.splice(index, 1);
                                                  }
                                                });
                                            }
                                            setCalendarNotification([
                                              ...calendarNotification,
                                            ]);
                                            setUserPreference(9,calendarNotification?.filter((x) => x.id === 9)[0]?.value);
                                          }}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="meeting-attend4"
                                        >
                                          Secondary Advisor
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="cu-user-block d-flex align-items-center">
                                        {userDetails?.client_household
                                          ?.secondary_advisor && (
                                          <div className="cu-photo-outer me-8">
                                            <span className="cu-photo">
                                              {utility.getProfileImage(
                                                userDetails?.client_household
                                                  ?.secondary_advisor
                                                  ?.profile_image,
                                                userDetails?.client_household?.secondary_advisor?.first_name?.charAt(
                                                  0
                                                ),
                                                userDetails?.client_household?.secondary_advisor?.last_name?.charAt(
                                                  0
                                                )
                                              )}
                                            </span>
                                          </div>
                                        )}
                                        <div className="lh-20">
                                          <span className="d-block">
                                            {userDetails?.client_household
                                              ?.secondary_advisor?.first_name
                                              ? userDetails?.client_household
                                                  ?.secondary_advisor
                                                  ?.first_name +
                                                " " +
                                                userDetails?.client_household
                                                  ?.secondary_advisor?.last_name
                                              : "--"}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                }
                                {userDetails?.client_household
                                                ?.csm &&
                                <div className="py-8">
                                  <div className="row align-items-center">
                                    <div className="col-md-6">
                                      <div className="form-check mb-0">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id="meeting-attend5"
                                          checked={calendarNotification
                                            ?.find((x) => x.id === 9)
                                            ?.value.includes("CSM")}
                                          onChange={(e) => {
                                            console.log(e.target.value);
                                            if (
                                              !calendarNotification
                                                ?.find((x) => x.id === 9)
                                                ?.value.includes("CSM")
                                            ) {
                                              calendarNotification
                                                ?.filter((x) => x.id === 9)
                                                .map((x) =>
                                                  x.value.push("CSM")
                                                );
                                            } else {
                                              calendarNotification
                                                ?.filter((x) => x.id === 9)
                                                .map((x) => {
                                                  var index =
                                                    x?.value?.indexOf("CSM");
                                                  if (index >= 0 && x.value.length>1) {
                                                    x.value.splice(index, 1);
                                                  }
                                                });
                                            }
                                            setCalendarNotification([
                                              ...calendarNotification,
                                            ]);
                                            setUserPreference(9,calendarNotification?.filter((x) => x.id === 9)[0]?.value);
                                          }}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="meeting-attend5"
                                        >
                                          CSM
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="cu-user-block d-flex align-items-center">
                                        {userDetails?.client_household?.csm && (
                                          <div className="cu-photo-outer me-8">
                                            <span className="cu-photo">
                                              {utility.getProfileImage(
                                                userDetails?.client_household?.csm
                                                  ?.profile_image,
                                                userDetails?.client_household?.csm?.first_name?.charAt(
                                                  0
                                                ),
                                                userDetails?.client_household?.csm?.last_name?.charAt(
                                                  0
                                                )
                                              )}
                                            </span>
                                          </div>
                                        )}
                                        <div className="lh-20">
                                          <span className="d-block">
                                            {userDetails?.client_household?.csm
                                              ?.first_name
                                              ? userDetails?.client_household?.csm
                                                  ?.first_name +
                                                " " +
                                                userDetails?.client_household?.csm
                                                  ?.last_name
                                              : "--"}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                }
                                {userDetails?.client_household.secondary_csm &&
                                <div className="py-8">
                                  <div className="row align-items-center">
                                    <div className="col-md-6">
                                      <div className="form-check mb-0">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id="meeting-attend6"
                                          checked={calendarNotification
                                            ?.find((x) => x.id === 9)
                                            ?.value.includes("Secondary CSM")}
                                          onChange={(e) => {
                                            console.log(e.target.value);
                                            if (
                                              !calendarNotification
                                                ?.find((x) => x.id === 9)
                                                ?.value.includes(
                                                  "Secondary CSM"
                                                )
                                            ) {
                                              calendarNotification
                                                ?.filter((x) => x.id === 9)
                                                .map((x) =>
                                                  x.value.push("Secondary CSM")
                                                );
                                            } else {
                                              calendarNotification
                                                ?.filter((x) => x.id === 9)
                                                .map((x) => {
                                                  var index =
                                                    x?.value?.indexOf(
                                                      "Secondary CSM"
                                                    );
                                                  if (index >= 0 && x.value.length>1) {
                                                    x.value.splice(index, 1);
                                                  }
                                                });
                                            }
                                            setCalendarNotification([
                                              ...calendarNotification,
                                            ]);
                                            setUserPreference(9,calendarNotification?.filter((x) => x.id === 9)[0]?.value);
                                          }}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="meeting-attend6"
                                        >
                                          Secondary CSM
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="cu-user-block d-flex align-items-center">
                                        {userDetails?.client_household?.secondary_csm && (
                                          <div className="cu-photo-outer me-8">
                                            <span className="cu-photo">
                                              {utility.getProfileImage(
                                                userDetails?.client_household?.secondary_csm
                                                  ?.profile_image,
                                                  userDetails?.client_household?.secondary_csm?.first_name?.charAt(
                                                  0
                                                ),
                                                userDetails?.client_household?.secondary_csm?.last_name?.charAt(
                                                  0
                                                )
                                              )}
                                            </span>
                                          </div>
                                        )}
                                        <div className="lh-20">
                                          <span className="d-block">
                                            {userDetails?.client_household?.secondary_csm
                                              ?.first_name
                                              ? userDetails?.client_household?.secondary_csm
                                                  ?.first_name +
                                                " " +
                                                userDetails?.client_household?.secondary_csm
                                                  ?.last_name
                                              : "--"}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                }
                              </div>
                            </div>
                          </div>
                          <hr className="mt-0 mb-24"></hr>
                          </>
                          )}
                          {(
                          (calendarNotification?.find((x) => x.id === 2)?.value)) &&(
                          <div className="notif-type">
                              <div className="typesof-not d-flex align-items-center justify-content-between">
                            <h6 className="mb-16">Allow push notifications for the Calendar feature</h6>
                                </div>
                            <div>
                              <div className="typesof-not d-flex align-items-center justify-content-between">
                                <span className="lh-20">Meeting invites</span>
                                <Switch
                                  checked={
                                    calendarNotification?.find(
                                      (x) => x.id === 10
                                    )?.value
                                  }
                                  onChange={(e) => {
                                    console.log(e);
                                    calendarNotification
                                      ?.filter((x) => x.id === 10)
                                      .map((x) => (x.value = e));
                                    setCalendarNotification([
                                      ...calendarNotification,
                                    ]);
                                    setUserPreference(10, e);
                                  }}
                                />
                              </div>
                              <div className="typesof-not d-flex align-items-center justify-content-between">
                                <span className="lh-20">
                                  Attendee responses
                                </span>
                                <Switch
                                  checked={
                                    calendarNotification?.find(
                                      (x) => x.id === 11
                                    )?.value
                                  }
                                  onChange={(e) => {
                                    console.log(e);
                                    calendarNotification
                                      ?.filter((x) => x.id === 11)
                                      .map((x) => (x.value = e));
                                    setCalendarNotification([
                                      ...calendarNotification,
                                    ]);
                                    setUserPreference(11, e);
                                  }}
                                />
                              </div>

                            </div>
                            <div>

                            </div>
                          </div>
                          )}
                      
                    </div>
                  )}
                </div> */}
                <div className="profnotif-block profnotif-open">
                  <div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="mb-4 mb-md-0">
                        <h4 className="fs-18 lh-24 mb-md-4 mb-0">DocuSign</h4>
                        <p className="m-0 d-none d-md-block">
                          Signature requests, signature responses.
                        </p>
                      </div>
                      <div className="d-flex align-items-center">
                        <span
                          className={showDocuSign ? "notif-status fs-18 lh-32 me-12 cursor-pointer text-success" : docuSignNotification?.find((x) => x.id === 3)?.value
                          ? "notif-status fs-18 lh-32 me-12 ": "notif-status fs-18 lh-32 me-12 "}
                          onClick={(e) => {
                            if(showDocuSign){
                              setDocuSignNotification(
                                JSON.parse(
                                  JSON.stringify(docuSignDefaultNotification))
                                    .map((x) => {
                                      return {...x,value:x.default_value}
                                    } 
                                )
                              );
                              setDocuSignDefaultNotification(
                                JSON.parse(
                                  JSON.stringify(docuSignDefaultNotification))
                                    .map((x) => {
                                      return {...x,value:x.default_value}
                                    } 
                                )
                              );
                              setUserPreferenceReset("docu",true);
                            }
                          }}
                        >
                          {showDocuSign
                            ? (showDocuSignReset ? "Reset to Default" : "")
                            : docuSignNotification?.find((x) => x.id === 3)
                                ?.value
                            ? "On"
                            : "Off"}
                        </span>
                        <span
                          className="cursor-pointer icon-32"
                          onClick={(e) => {
                            setShowDocuSign(!showDocuSign);
                          }}
                        >
                          <em className="icon icon-chevron-down-outline fs-16"></em>
                        </span>
                      </div>
                    </div>
                    <p className="m-0 d-md-none">
                      Signature requests, signature responses, signature
                      reminders.
                    </p>
                  </div>
                  {showDocuSign && (
                    <div className="pt-24">
                      <div className="notif-type d-flex align-items-center justify-content-between">
                        <h6 className="m-0 pe-8">
                          Enable DocuSign feature for this account
                        </h6>
                        <Switch
                          checked={
                            docuSignNotification?.find((x) => x.id === 3)?.value
                          }
                          onChange={(e) => {
                            console.log(e);
                            docuSignNotification
                              ?.filter((x) => x.id === 3)
                              .map((x) => (x.value = e));
                            setDocuSignNotification([...docuSignNotification]);
                            setUserPreference(3, e);
                          }}
                        />
                      </div>
                      {docuSignNotification?.find((x) => x.id === 3)?.value && (
                        <>
                          <div className="notif-type">
                          <div className="typesof-not d-flex align-items-center justify-content-between">
                            <h6 className="mb-8">
                              Allow push notifications for DocuSign
                            </h6>
                            {/* <Switch
                                  checked={
                                    docuSignNotification?.find(
                                      (x) => x.id === 16
                                    )?.value ||
                                    docuSignNotification?.find(
                                      (x) => x.id === 17
                                    )?.value 
                                  }
                                  onChange={(e) => {
                                    console.log(e);
                                    docuSignNotification
                                      ?.filter(
                                        (x) =>
                                          x.id === 16 ||
                                          x.id === 17 ||
                                          x.id === 18
                                      )
                                      .map((x) => (x.value = e));
                                    setDocuSignNotification([
                                      ...docuSignNotification,
                                    ]);
                                    setUserPreferenceGroup([16, 17, 18], e);
                                  }}
                                /> */}
                                </div>
                            <div>
                            </div>
                          </div>
                          <div className="pt-8">
                            <div>
                              <div className="typesof-not d-flex align-items-center justify-content-between">
                                <span className="lh-20">
                                  Signature requests
                                </span>
                                <Switch
                                  checked={
                                    docuSignNotification?.find(
                                      (x) => x.id === 16
                                    )?.value
                                  }
                                  onChange={(e) => {
                                    console.log(e);
                                    docuSignNotification
                                      ?.filter((x) => x.id === 16)
                                      .map((x) => (x.value = e));
                                    setDocuSignNotification([
                                      ...docuSignNotification,
                                    ]);
                                    setUserPreference(16, e);

                                  }}
                                />
                              </div>
                              <div className="typesof-not d-flex align-items-center justify-content-between">
                                <span className="lh-20">
                                  Signature responses
                                </span>
                                <Switch
                                  checked={
                                    docuSignNotification?.find(
                                      (x) => x.id === 17
                                    )?.value
                                  }
                                  onChange={(e) => {
                                    console.log(e);
                                    docuSignNotification
                                      ?.filter((x) => x.id === 17)
                                      .map((x) => (x.value = e));
                                    setDocuSignNotification([
                                      ...docuSignNotification,
                                    ]);
                                    setUserPreference(17, e);
                                  }}
                                />
                              </div>

                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>
                <div className="profnotif-block profnotif-open">
                  <div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="mb-4 mb-md-0">
                        <h4 className="fs-18 lh-24 mb-md-4 mb-0">Files</h4>
                      </div>
                      <div className="d-flex align-items-center">
                        <span
                          className={showFile ? "notif-status fs-18 lh-32 me-12 cursor-pointer text-success" : fileNotification?.find((x) => x.id === 4)?.value
                          ? "notif-status fs-18 lh-32 me-12 ": "notif-status fs-18 lh-32 me-12"}
                          onClick={(e) => {
                            if(showFile){
                              setFileNotification(
                                JSON.parse(
                                  JSON.stringify(fileDefaultNotification))
                                    .map((x) => {
                                      return {...x,value:x.default_value}
                                    } 
                                )
                              );
                              setFileDefaultNotification(
                                JSON.parse(
                                  JSON.stringify(fileDefaultNotification))
                                    .map((x) => {
                                      return {...x,value:x.default_value}
                                    } 
                                )
                              );
                              setUserPreferenceReset("file",true);
                            }
                          }}
                        >
                          {showFile
                            ? (showFileReset ? "Reset to Default" : "")
                            : fileNotification?.find((x) => x.id === 4)?.value
                            ? "On"
                            : "Off"}
                        </span>
                        <span
                          className="cursor-pointer icon-32"
                          onClick={(e) => {
                            setShowFile(!showFile);
                          }}
                        >
                          <em className="icon icon-chevron-down-outline fs-16"></em>
                        </span>
                      </div>
                    </div>
                  </div>
                  {showFile && (
                    <div className="pt-24">
                      <div className="notif-type d-flex align-items-center justify-content-between">
                        <h6 className="m-0 pe-8">
                          Enable ShareFile feature for this account
                        </h6>
                        <Switch
                          checked={
                            fileNotification?.find((x) => x.id === 4)?.value
                          }
                          onChange={(e) => {
                            fileNotification
                              ?.filter((x) => x.id === 4)
                              .map((x) => (x.value = e));
                            setFileNotification([...fileNotification]);
                            setUserPreference(4, e);
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
        {key==="2" &&
        <Modal.Footer>
        <button
              className="btn btn-outline-secondary"
              onClick={() => {
                (notificationUpdate?.length>0 || defaultNotification?.length>0 || toggleUpdate)?
                setShowConfirmationModal(true):
                props?.closeModal();
              }}
            >
              Cancel
            </button>
          <button
            className="btn btn-success"
            onClick={(e) => {
                  handleSubmit()
              }}
          >
            Confirm
          </button>
          </Modal.Footer>}
        {/* for disabled fooer add class "disable-overlay" with Modal.Footer */}
        {key === "1" && (
          <Modal.Footer className="border-top pt-16 ">
            {userDetails?.is_active === false && (
              <button
                className="btn p-0 border-0 fw-500"
                onClick={(e) => {
                  e.preventDefault();
                  handleResendInviteLinkShow();
                }}
              >
                Resend Invite Link
              </button>
            )}
            {!userDetails?.is_deleted && userDetails?.is_active && (
              <button
                className="btn p-0 border-0 fw-500"
                onClick={(e) => {
                  e.preventDefault();
                  handleSendResetPasswordLinkShow();
                }}
              >
                Send Reset Password Link
              </button>
            )}
            {userDetails?.is_deleted && (
              <button
                className="btn p-0 border-0 fw-500"
                onClick={(e) => {
                  e.preventDefault();
                  activateUser(userDetails?.uuid);
                }}
              >
                Reactivate User
              </button>
            )}
            {!userDetails?.is_deleted && userDetails?.is_active && (
              <button
                className="btn text-danger mt-0 p-0 border-0 fw-500"
                onClick={(e) => {
                  e.preventDefault();
                  handleDeactivateUserShow();
                }}
              >
                Deactivate User
              </button>
            )}
          </Modal.Footer>
        )}
      </Modal>

      {/* Handle Deactivate Popup */}
      {showDeactivateUser && (
        <ClientDeactivate
          visible={showClientUserDetail}
          client={userDetails}
          closeModal={() => {
            setDeactivateUserShow(false);
            getUserDetails();
          }}
        ></ClientDeactivate>
      )}

      {/* Handle Reactivate popup */}
      {showReactivateUser && (
        <ClientReactivate
          visible={showClientUserDetail}
          client={userDetails}
          closeModal={() => {
            setReactivateUserShow(false);
            getUserDetails();
          }}
        ></ClientReactivate>
      )}

      {/* Handle Password popup */}
      {showSendResetPasswordLink && (
        <ClientResetPassword
          visible={showSendResetPasswordLink}
          client={userDetails}
          closeModal={() => {
            setSendResetPasswordLinkShow(false);
          }}
        ></ClientResetPassword>
      )}

      {/* Handle Password popup */}
      {showResendInviteLink && (
        <ClientResendInvite
          visible={showResendInviteLink}
          client={userDetails}
          closeModal={() => {
            setResendInviteLink(false);
          }}
        ></ClientResendInvite>
      )}
      <Modal
        className="modal-464 modal-full"
        show={showConfirmationModal}
        onHide={handleConfirmationModalClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-black fs-18 lh-32 mb-20">
            You have unsaved changes. Apply changes?
          </p>
        </Modal.Body>
        <Modal.Footer>
        <button
            className="btn btn-outline-secondary"
            onClick={() => props?.closeModal()}
          >
            Cancel
          </button>
          <button
            className="btn btn-success"
            onClick={handleSubmit}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
      {
        showIframePreview &&
        <PreviewIframe clientname={props?.client?.first_name + " " + props?.client?.last_name}
        investmentTab={investmentTab}
        showIframePreview={showIframePreview}
        setShowIframePreview={setShowIframePreview}
/>
      }

    </>
  );
};

export default ClientUserDetails;
