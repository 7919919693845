import { React, useEffect } from "react";
import Avatar from "react-avatar";
import ReadBookmark from "./ReadBookmark";
import { utility } from "../../components/Utility";
import cdc from "../../Assets/Images/cdc-icon.svg";
import { dashboard } from "../../redux/actions/Dashboard/dashboard";
import { useDispatch } from "react-redux";

const RetrieveArticle = ({
  backbutton,
  singleArticle,
  handleReadStatusSingleArticle,
  handleBookmarkStatusSingleArticle,
  shareArticleAction,
  relatedArticle,
  handleBookmarkRelatedStatus,
  handleRelatedArticleReadStatus,
  callRetrieveRelate,
}) => {
  const dispatch = useDispatch();


  function linkify(text) {
    var embedlink = /\[embed\](.*)(?=\[\/embed\])/g;
    return text.replace(embedlink, function (url) {
      var updatedUrl = url.replace('[embed]', '');
      updatedUrl = updatedUrl.replace('youtu.be', 'youtube.com/embed');
      updatedUrl = updatedUrl.replace('youtube.com/watch?v=', 'youtube.com/embed/');
      return `<div> <iframe width="700px" height="540" src=${updatedUrl} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>`;
    });
  }

  function refactorDescription(description) {
    var finalDescription = linkify(description);
    finalDescription = finalDescription.replaceAll('[/embed]', '');
    return finalDescription;
  }

  function postArticleLinkedClicked(href,link_interactions) {
    let payload = {
      "system": "website",
      "activity": "opened",
      "article_id" : singleArticle?.uuid,
      "external_link": href,
      "link_interactions": link_interactions
    }
    dispatch(dashboard.postArticleLinkedClicked(payload)).then(
      (response) => {
        if (response.type === "POST_ARTICLE_LINKED_CLICKED_SUCCESS") {
          console.log("clicked")
          // setIsAPICall(false);
        } else {
          console.log("error");
        }
      }
    );
  }


  window.setInterval(trackClick, 100);
  function trackClick() {
    if(document.activeElement.tagName === "IFRAME") {
      const src = document.activeElement.src
      postArticleLinkedClicked(src,true)
      window.focus();
    }
  }


  useEffect(()=>{
    postArticleLinkedClicked('',false)
  },[])


  return (
    <>
      {JSON.stringify(singleArticle) === '{}'  ? (
        <div className="aside-area">
          <div className="left-content-area pe-md-8 pt-12 pb-md-12">
            <div className="custom-style-scroll max-680">
              <div className="bookmarks-block">
                <div className="articles-wrap unpublished-wrap">
                  <span className="head-icon mx-auto">
                    <img src={cdc} alt="" />
                  </span>
                  <p>Looks like this article is no longer available</p>
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={() => window.location.reload()}
                  >
                    Browse Articles
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="aside-bar pe-24 pe-lg-8 ps-24 pt-24">
            <div className="custom-style-scroll max-680">
              <h4 className="fs-18 lh-24 mb-16">Related Articles</h4>
              <div className="featured-row p-0 pe-lg-16 mx-n24">
                {relatedArticle.length === 0 && (
                  <div className="cal-caption">
                    <div className="cal-cap-card py-20 px-24 mx-auto">
                      <p className="fw-500 text-center">No related articles</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="aside-area">
          <div className="left-content-area pe-md-8 pt-12 pb-md-12">
            <div className="custom-style-scroll max-680">
              <div className="featured-row full-row pe-md-16 border-bottom-md-0 pb-0 pb-md-24">
                <div className="featured-item">
                  <span
                    onClick={() => backbutton()}
                    className="cursor-pointer d-flex align-items-center fs-18 new-meet-back text-success mb-12"
                  >
                    <em className="icon-chevron-left-outline fs-14 me-12"></em>
                    Back
                  </span>
                  <div className="featured-content">
                    <h2 className="mb-16">
                      {singleArticle && singleArticle.title}
                    </h2>
                    <div className="topic-list d-flex flex-wrap mb-20">
                      {singleArticle.topic &&
                        singleArticle.topic.map((item) => (
                          <span key={item.uuid} className="ftr-lbl me-16 mb-4">
                            {utility.decodeEntity(item.name)}
                          </span>
                        ))}
                    </div>
                    <figure className="thumb-img">
                      <img src={singleArticle && singleArticle.image} alt="" />
                    </figure>
                    <div className="ftr-date-lbl mb-24 pt-md-12 pt-24 justify-content-between">
                      <div className="user-post-details d-flex align-items-center">
                        {/* <figure className="user-photo">
                          {singleArticle.author_image !== null ? (
                            <img src={singleArticle.author_image} alt="" />
                          ) : (
                            <Avatar
                              name={singleArticle.author_name}
                              size={40}
                              color="grey"
                            />
                          )}
                        </figure> */}
                        <div className="ms-8">
                          {/* <span className="d-block lh-20 fw-500">
                            {singleArticle.author_name}
                          </span> */}
                          <span className="d-block text-muted lh-20 fs-14">
                            {singleArticle.publish_date}
                          </span>
                        </div>
                      </div>
                      <ul className="ftr-btn d-flex">
                        <li>
                          {singleArticle.article_actions &&
                          singleArticle.article_actions[0]?.is_read ? (
                            <span className="icon-36 btn btn-outline-secondary p-0 text-success">
                              <em
                                className="icon icon-check-outline"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleReadStatusSingleArticle(singleArticle);
                                }}
                              ></em>
                            </span>
                          ) : (
                            <span className="icon-36 btn btn-outline-secondary p-0">
                              <em
                                className="icon icon-check-outline"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleReadStatusSingleArticle(singleArticle);
                                }}
                              ></em>
                            </span>
                          )}
                        </li>
                        {singleArticle.is_published === true && <li>
                          <div
                            className="upload-file"
                            onClick={() => shareArticleAction(singleArticle)}
                          >
                            <span className="icon-36 btn btn-outline-secondary p-0">
                              <em className="icon icon-upload-filled"></em>
                            </span>
                            <input type="file" id="ftr-file1" />
                          </div>
                          {/* :
                          <div className="upload-file opacity-50 disabled">
                            <span className="icon-36 btn btn-outline-secondary p-0">
                              <em className="icon icon-upload-filled"></em>
                            </span>
                            <input type="file" id="ftr-file1" />
                          </div> */}
                        </li>
                        }
                        <li className="text-success">
                          <span className="icon-36 btn btn-outline-secondary p-0">
                            {singleArticle.article_actions[0].is_bookmark ? (
                              <em
                                className="icon icon-bookmark-filled text-success"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleBookmarkStatusSingleArticle(
                                    singleArticle
                                  );
                                }}
                              ></em>
                            ) : (
                              <em
                                className="icon icon-bookmark-outline text-success"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleBookmarkStatusSingleArticle(
                                    singleArticle
                                  );
                                }}
                              ></em>
                            )}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="ftr-detailsarea" onClick={(e)=>{e.target.href && postArticleLinkedClicked(e.target.href,true)}}>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: refactorDescription(
                            singleArticle.description
                          ),
                        }}
                      ></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="aside-bar pe-24 pe-lg-8 ps-24 pt-24">
            <div className="custom-style-scroll max-680">
              <h4 className="fs-18 lh-24 mb-16">Related Articles</h4>
              <div className="featured-row p-0 pe-lg-16 mx-n24">
                {relatedArticle.length === 0 && (
                  <div className="cal-caption">
                    <div className="cal-cap-card py-20 px-24 mx-auto">
                      <p className="fw-500 text-center">No related articles</p>
                    </div>
                  </div>
                )}
                {relatedArticle &&
                  relatedArticle.map((item, index) => (
                    <div key={index} className="featured-item">
                      <div className="featured-content">
                        <div
                          className="d-flex"
                          onClick={() => callRetrieveRelate(item.uuid,true)}
                        >
                          <div className="ftr-caption">
                            <a to="#">
                              <h4 className="fs-18 lh-24 mb-0">{item.title}</h4>
                            </a>
                          </div>
                          <figure className="thumb-img">
                            <img src={item.image} alt="" />
                          </figure>
                        </div>
                        <div className="ftr-date-lbl justify-content-between mt-12 mt-md-8">
                          <div className="d-flex align-items-center">
                            <span className="fs-12 lh-16 text-muted d-block me-8 text-nowrap">
                              {item.publish_date}
                            </span>
                            {item.is_featured ? (
                              <span className="ftr-lbl">Featured</span>
                            ) : (
                              ""
                            )}
                          </div>
                          <ReadBookmark
                            handleReadStatusArticle={
                              handleRelatedArticleReadStatus
                            }
                            item={item}
                            shareArticleAction={shareArticleAction}
                            handleBookmarkStatusArticle={
                              handleBookmarkRelatedStatus
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RetrieveArticle;
