import React from 'react';
import { Route, Routes } from 'react-router'
import Auth from '../src/pages/Login/Login'
import Dashboard from './pages/Dashboard/Dashboard'
import Mainlayout from './layout/MainLayout'
import LoginLayout from './pages/Login/LoginLayout';
import UserDeatils from './pages/UserDeatils/UserDeatils';
import ForgotEmailAddress from './pages/Login/ForgotEmailAddress';
import PasswordAndOtp from './pages/Login/PasswordAndOtp';
import ResetPassword from './pages/Login/ResetPassword';
import ForgotPasswordVerification from './pages/Login/ForgotPasswordVerification';
import ForgotPasswordCode from './pages/Login/ForgotPassswordCode';
import LoginCodeVerify from './pages/Login/LoginCodeVerify';
import BlankRoute from './pages/BlankRoute/BlankRoute';
import PasswordOnly from './pages/Login/PasswordOnly';
import ResetForgotPassword from './pages/Login/ResetForgotPassword';
import ShareFile from './pages/ShareFile/ShareFile';
import DocuSign from './pages/DocuSign/DocuSign';
import SendBird from './pages/Messages/Messages';
import Notification from './pages/Notification/notification';
import NotFound from './pages/NotFound/notfound';
import CalendarLogin from './pages/Calendar/CalendarLogin';
import CalendarMain from './pages/Calendar/CalendarMain';
import ClientUser from './pages/ClientUser/ClinetUser';
import ProfileSetting from './pages/ProfileSetting/ProfileSetting';
import Investment from './pages/Financial/Investment';
import CDC from './pages/CDC/CDC';
import EmailVerification from './pages/EmailVerification/EmailVerification';
const AppRoutes = (props) => {

  return (
    <Routes>
      <Route path="/sso/initiate" element={<BlankRoute />} />
      {props.loggedIn !== "false" ? (
        <>
          <Route path="/notfound" element={<NotFound />}></Route>
          <Route path="/dashboard" element={<Mainlayout />}>
            <Route index element={<Dashboard />} />
            <Route path="/dashboard/userdeatils" element={<UserDeatils />} />
            <Route path="/dashboard/share-file" element={<ShareFile />} />
            <Route path="/dashboard/docu-sign" element={<DocuSign />} />
            <Route path="/dashboard/Messages" element={<SendBird />} />
            <Route path="/dashboard/notification" element={<Notification />} />
            {/* <Route path="/dashboard/calendar" element={<CalendarMain />} /> */}
            <Route path="/dashboard/client-user" element={<ClientUser />} />
            <Route path="/dashboard/profile" element={<ProfileSetting />} />
            <Route path="/dashboard/content-distribution-center" element={<CDC />} />
            <Route path="/dashboard/financial" element={<Investment />} />
          </Route>
        </>
      ) : < Route path="/" element={<Auth />} />}
      <Route path="/verify-email" element={<EmailVerification />}></Route>
      <Route path="/" element={<LoginLayout />} >
        <Route index element={<Auth />} />
        <Route path="/forgot-password-verify" element={<ForgotPasswordVerification />} />
        <Route path="/forgot-email" element={<ForgotEmailAddress />} />
        <Route path="/send-otp" element={<PasswordAndOtp />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/forgot-password-code" element={<ForgotPasswordCode />} />
        <Route path="/verify-code" element={<LoginCodeVerify />} />
        <Route path="/login-password" element={<PasswordOnly />} />
        <Route path="/reset-forgot-password" element={<ResetForgotPassword />} />
      </Route>
    </Routes>
  )
}

export default AppRoutes
