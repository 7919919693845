import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { Switch } from "antd";
import timeRange from "./TimeRange";
import { profileSetting } from "../../redux/actions/ProfileSetting/ProfileSetting";
import { utility } from "../../components/Utility";
import { notification } from "antd";
import { Select } from "antd";
import Modal from "react-bootstrap/Modal";

const NotificationUpdate = (props) => {
  const { Option } = Select;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loader, setLoader] = useState(false);

  const [toggleScheduleNotification, setToggleScheduleNotification] =
    useState(false);

  //Selected Start and End Date
  const [selectedStartDate, setSelectedStartDate] = useState(1);
  const [selectedEndDate, setSelectedEndDate] = useState(2);
  const [isEveryDay, setIsEveryDay] = useState(false);

  const [showFrequency, setFrequencyShow] = useState(false);
  const handleFrequencyClose = () => {
    setFrequencyShow(false);
  };

  const [showTime, setTimeShow] = useState(false);
  const handleTimeClose = () => {
    setTimeShow(false);
  };
  const handleTimeShow = () => setTimeShow(true);

  const [showEndTime, setEndTimeShow] = useState(false);
  const handleEndTimeClose = () => {
    setEndTimeShow(false);
  };
  const handleEndTimeShow = () => setEndTimeShow(true);
  //Range List
  const [startTimeRange, setStartTimeRange] = useState();
  const [endTimeRange, setEndTimeRange] = useState();

  const handleFrequencyShow = () => setFrequencyShow(true);

  useEffect(() => {
    settingUpComponent();
    setStartTimeRange(timeRange);
    //setEndTimeRange(timeRange);
  }, []);

  function settingUpComponent() {
    console.log(props, utility.tConvert(props?.profile?.notification_end_time));
    let endTime = utility.tConvert(props?.profile?.notification_end_time);
    let getEndTimeIndex = timeRange.filter((t) =>
      t.value.includes(("0" + endTime).slice(-8))
    );

    let startTime = utility.tConvert(props?.profile?.notification_start_time);
    let getStartTimeIndex = timeRange.filter((t) =>
      t.value.includes(("0" + startTime).slice(-8))
    );
    setIsEveryDay(props?.profile?.notification_every_day);
    setSelectedEndDate(getEndTimeIndex[0]?.index);
    setSelectedStartDate(getStartTimeIndex[0]?.index);
    setToggleScheduleNotification(props?.profile?.notification_toggle);
  }

  useEffect(() => {
    let filt = timeRange.filter((c) => c.index > parseInt(selectedStartDate));
    setEndTimeRange(filt);
  }, [selectedStartDate]);

  const Notify = (type, mesage, icons) => {
    notification[type]({
      description: mesage,
      icon: icons,
      top: 65,
    });
  };

  function notificationSetting() {
    let startDate = timeRange.filter(
      (c) => c.index === parseInt(selectedStartDate)
    );
    let endDate = timeRange.filter(
      (c) => c.index === parseInt(selectedEndDate)
    );
    console.log(startDate, selectedStartDate, isEveryDay);

    let param = {
      start_time: utility.convertTime(startDate[0].value),
      end_time: utility.convertTime(endDate[0].value),
      is_every_day: isEveryDay,
      notification_toggle: toggleScheduleNotification,
    };

    setLoader(true);
    dispatch(profileSetting.notificationSetting(param)).then((response) => {
      setLoader(false);
      if (response.type === "NOTIFICATION_SETTING_SUCCESS") {
        if (toggleScheduleNotification) {
          Notify(
            "success",
            `Updated notification schedule`,
            <em className="icon-check-outline"></em>
          );
        }
      }
    });
  }

  function getTime(value) {
    let startDate = timeRange.filter((c) => c.index === parseInt(value));
    return startDate[0]?.value;
  }

  function notificationSettingOff(value) {
    let startDate = timeRange.filter(
      (c) => c.index === parseInt(selectedStartDate)
    );
    let endDate = timeRange.filter(
      (c) => c.index === parseInt(selectedEndDate)
    );

    let param = {
      start_time: utility.convertTime(startDate[0]?.value),
      end_time: utility.convertTime(endDate[0]?.value),
      is_every_day: isEveryDay,
      notification_toggle: value,
    };

    setLoader(true);
    dispatch(profileSetting.notificationSetting(param)).then((response) => {
      setLoader(false);
      if (response.type === "NOTIFICATION_SETTING_SUCCESS") {
        Notify(
          "success",
          `Updated notification schedule`,
          <em className="icon-check-outline"></em>
        );
      }
    });
  }

  return (
    <>
      <div className="py-24 border-bottom">
        <h4 className="mb-24 fs-18 lh-24">Notification Settings</h4>
        <div className="d-flex align-items-center justify-content-between">
          <span>Enable quiet time</span>
          <Switch
            checked={toggleScheduleNotification}
            onChange={(e) => {
              setToggleScheduleNotification(!toggleScheduleNotification);
              if (e === false) {
                notificationSettingOff(false);
              }else{
                notificationSettingOff(true);

              }
              //setSelectedStartDate("2");
            }}
          />
        </div>
        {toggleScheduleNotification && (
          <div className="pt-24">
            <p className="mb-8 fs-14 lh-24 text-muted">
            You will only receive push notifications during the selected timeframe.
            </p>
            <div className="mb-8">
              <Select
                className="d-none d-md-block"
                value={isEveryDay}
                onChange={(e) => {
                  setIsEveryDay(e);
                }}
              >
                <Option value={true}>Everyday</Option>
                <Option value={false}>Weekdays</Option>
              </Select>
              {/* <select className="form-select d-none d-md-block" name="" id="" value={isEveryDay} onChange={(e)=>{setIsEveryDay(e.target.value)}}>
                <option value={true}>Everyday</option>
                <option value={false}>Weekdays</option>
              </select> */}
              <div
                className="form-select d-md-none"
                onClick={(e) => {
                  e.preventDefault();
                  handleFrequencyShow();
                }}
              >
                {isEveryDay ? "EveryDay" : "Weekdays"}
              </div>
            </div>
            <div className="row gx-28">
              <div className="col-sm-6 position-relative">
                <div className="mb-sm-8 mb-24">
                  <Select
                    className="d-none d-md-block"
                    // defaultValue={selectedStartDate}
                    value={selectedStartDate}
                    onChange={(e) => {
                      setSelectedStartDate(e);
                    }}
                  >
                    {startTimeRange?.map((value, index) => (
                      <Option key={index} value={value?.index}>
                        {value?.value}
                      </Option>
                    ))}
                  </Select>
                  <div
                    className="form-select d-md-none"
                    onClick={(e) => {
                      e.preventDefault();
                      handleTimeShow();
                    }}
                  >
                    {getTime(selectedStartDate)}
                  </div>
                </div>
                <span className="totext">To</span>
              </div>
              <div className="col-sm-6">
                <div className="mb-8">
                  <Select
                    className="d-none d-md-block"
                    name=""
                    id=""
                    value={selectedEndDate}
                    onChange={(e) => {
                      setSelectedEndDate(e);
                    }}
                  >
                    {endTimeRange?.map((value, index) => (
                      <Option key={index} value={value.index}>
                        {value.value}
                      </Option>
                    ))}
                  </Select>
                  <div
                    className="form-select d-md-none"
                    onClick={(e) => {
                      e.preventDefault();
                      handleEndTimeShow();
                    }}
                  >
                    {getTime(selectedEndDate)}
                  </div>
                </div>
              </div>
            </div>
            <button
              type="button"
              className="btn btn-success mt-16"
              onClick={(e) => {
                notificationSetting();
              }}
            >
              Update
            </button>
          </div>
        )}
      </div>

      {/* Select Frequency Model */}
      <Modal size="sm" show={showFrequency} onHide={handleFrequencyClose}>
        <Modal.Header closeButton>
          <Modal.Title>Select Frequency</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <ul className="list-unstyled bulk-list m-0">
            <li
              onClick={(e) => {
                setIsEveryDay(true);
                setFrequencyShow(false);
              }}
            >
              Every day
            </li>
            <li
              onClick={(e) => {
                setIsEveryDay(false);
                setFrequencyShow(false);
              }}
            >
              Weekdays
            </li>
          </ul>
        </Modal.Body>
      </Modal>

      <Modal size="sm" show={showTime} onHide={handleTimeClose}>
        <Modal.Header closeButton>
          <Modal.Title>Select Time</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <ul className="list-unstyled bulk-list m-0">
            {startTimeRange?.map((value, index) => (
              <li
                key={index}
                onClick={() => {
                  setSelectedStartDate(value?.index);
                  setTimeShow(false);
                }}
              >
                {value?.value}
              </li>
            ))}

            {/* <li>09:30 AM</li> */}
          </ul>
        </Modal.Body>
      </Modal>

      <Modal size="sm" show={showEndTime} onHide={handleEndTimeClose}>
        <Modal.Header closeButton>
          <Modal.Title>Select Time</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <ul className="list-unstyled bulk-list m-0">
            {endTimeRange?.map((value, index) => (
              <li
                key={index}
                onClick={() => {
                  setSelectedEndDate(value?.index);
                  setEndTimeShow(false);
                }}
              >
                {value?.value}
              </li>
            ))}

            {/* <li>09:30 AM</li> */}
          </ul>
        </Modal.Body>
      </Modal>

    </>
  );
};

export default NotificationUpdate;
