import {React,useState,useEffect} from 'react'
import { useDispatch, useSelector } from "react-redux";
import { dashboard } from "../../redux/actions/Dashboard/dashboard";
import { articleConstants } from "../../redux/constants/ArticleConstant";
import { utility } from '../../components/Utility';


const TopicComponent = ({topic,getArticle,setArticlesDataList,setNext,bookmark}) => {
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const [currentuuid, setcurrentuuid] = useState();
  const [callTopicFunc, setcallTopicFunc] = useState(false);
  const [topicuuid, setTopicUuid] = useState([]);

  //  useeffect to call function on topicuuid change
  useEffect(() => {
    if (topicuuid.length !== 0 || callTopicFunc) {
      article_url_func(topicuuid);
    }
  }, [topicuuid, currentuuid]);

  //  this function used in topic search , for to deactivate the button
  function getOccurrence(topicuuid, value) {
    return topicuuid.filter((v) => v === value).length;
  }

  //  on topic click this function will call
  function topicWiseArticleSearch(uuid) {
    setcurrentuuid(uuid);
    var uuid_count = getOccurrence(topicuuid, uuid);
    setcallTopicFunc(true);
    var applyclass = document.getElementById(uuid);
    if (uuid_count === 1) {
      applyclass.classList.remove("active");
      setTopicUuid(topicuuid.filter((item) => item !== uuid));
    } else {
      applyclass.className += " active";
      setTopicUuid([...topicuuid, uuid]);
    }
  }

  // to get article_url to get article according to topic uuid
  function article_url_func(topicuuid) {
    setLoader(true);
    var uuid = currentuuid;
    var applyclass = document.getElementById(uuid);
    var applyclassalltopic = document.getElementById("all_topics");
    if (topicuuid.length === 0) {
      applyclassalltopic.className += " active";
      applyclass.classList.remove("active");
      var topic_uuid = "";
    } else {
      topic_uuid = topicuuid.join(",");
      applyclassalltopic.classList.remove("active");
    }
    if (bookmark) {
      var article_url = "article/bookmarked?topic=" + topic_uuid
      dispatch(dashboard.getBookmarkedArticles(article_url))
    }
    else {
      article_url = "article?topic=" + topic_uuid;
      dispatch(dashboard.getArticles(article_url)).then((response) => {
        if (response.type === "GET_ARTICLE_SUCCESS") {
          setArticlesDataList([...response.data.results]);
          dispatch({
            type: articleConstants.SET_ARTICLES_LIST,
            data: [...response.data.results],
          });
          setNext(response.data.next);
          setLoader(false);
        } else {
          setLoader(false);
        }
      });
    }
  }


  return (
    <ul className="topic-list d-flex flex-wrap">
      <li
        id="all_topics"
        className="active cursor-pointer ftr-lbl"
        onClick={() => getArticle("all")}
      >
        {" "}
        All Topics{" "}
      </li>
      {topic &&
        topic?.map((data, index) => (
          <li
            id={data.uuid}
            key={data.uuid}
            onClick={() => topicWiseArticleSearch(data.uuid)}
            className="topicclass cursor-pointer ftr-lbl"
          >
            {utility.decodeEntity(data.name)}
          </li>
        ))}
    </ul>
  );
}

export default TopicComponent
