import { React, useEffect, useState } from "react";
import { notification } from "antd";
import { useLocation } from "react-router-dom";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import cdc from "../../Assets/Images/cdc-icon.svg";

import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { dashboard } from "../../redux/actions/Dashboard/dashboard";
import ReadBookmark from "./ReadBookmark";
import { utility } from "../../components/Utility";
import EmailPhoto from "../../Assets/Images/mail.svg";

import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";
import { articleConstants } from "../../redux/constants/ArticleConstant";
import RetrieveArticle from "./RetrieveArticle";
import TopicComponent from "./TopicComponent";

const ArticleDashboard = () => {
  const reducers = useSelector((state) => state);
  const articleInfoReducer = reducers.articleInfoReducer;
  const commonInfoReducer = reducers.commonInfoReducer;
  const [key, setKey] = useState("1");
  const [bookmarkArticles, setBookmarkArticles] = useState([]);
  const [showShare, setShowShare] = useState(false);
  const handleShareClose = () => setShowShare(false);

  const [topic, setTopic] = useState(false);
  const [singleArticle, setSingleArticle] = useState({});
  const [isRetrieveArticle, setIsRetrieveArticle] = useState(false);
  const [isRetrieveBookmarkedArticle, setIsRetrieveBookmarkedArticle] =
    useState(false);
  const [relatedArticle, setRelatedArticle] = useState([]);
  const [shareArticle, setShareArticle] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [articlesDataList, setArticlesDataList] = useState([]);
  const [topicuuid, setTopicUuid] = useState([]);
  const [showFeatured, isShowFeatured] = useState(false)
  const [showDiscover, isShowDiscover] = useState(false)

  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [next, setNext] = useState(false);
  const location = useLocation();
  

  const Notify = (type, mesage, articleInfo, undoFunc) => {
    notification[type]({
      message: mesage,
      description: (
        <a
          href="javascript:void(0)"
          onClick={() => {
            undoFunc(articleInfo);
          }}
        >
          Undo
        </a>
      ),
    });
  };

  const NotifyBookmark = (type, mesage, articleInfo, undoFunc) => {
    notification[type]({
      message: mesage,
    });
  };

  function notificationRead(articleInfo, articleReadStatus, undoFunc) {
    if (articleReadStatus) {
      var marked_as = "read";
    } else {
      marked_as = "unread";
    }
    Notify(
      "success",
      `You've marked an article as ` + marked_as,
      articleInfo,
      undoFunc
    );
  }

  function notificationBookmark(articleBookmarkStatus) {
    if (articleBookmarkStatus) {
      var marked_as = "bookmark";
    } else {
      marked_as = "unbookmark";
    }
    NotifyBookmark("success", `You've marked an article as ` + marked_as);
  }

  function handleReadStatusSingleArticle(articleInfo) {
    let is_read = false;
    if (
      articleInfo.article_actions &&
      articleInfo.article_actions[0]?.is_read
    ) {
      is_read = articleInfo.article_actions[0]?.is_read;
    }
    dispatch(
      dashboard.handleReadStatusSingleArticle(articleInfo, {
        is_read: !is_read,
      })
    );
    notificationRead(articleInfo, !is_read, handleReadStatusSingleArticle);
  }

  function handleReadStatusCommonArticle(articleInfo) {
    let is_read = false;
    if (
      articleInfo.article_actions &&
      articleInfo.article_actions[0]?.is_read
    ) {
      is_read = articleInfo.article_actions[0]?.is_read;
    }
    dispatch(
      dashboard.handleReadStatusCommonArticle(articleInfo, {
        is_read: !is_read,
      })
    );
    notificationRead(articleInfo, !is_read, handleReadStatusCommonArticle);
  }

  function handleReadStatusBookmarkedArticle(articleInfo) {
    let is_read = false;
    if (
      articleInfo.article_actions &&
      articleInfo.article_actions[0]?.is_read
    ) {
      is_read = articleInfo.article_actions[0]?.is_read;
    }
    dispatch(
      dashboard.handleReadStatusBookmarkedArticle(articleInfo, {
        is_read: !is_read,
      })
    );
    notificationRead(articleInfo, !is_read, handleReadStatusBookmarkedArticle);
  }

  function handleRelatedArticleReadStatus(articleInfo) {
    let is_read = false;
    if (
      articleInfo.article_actions &&
      articleInfo.article_actions[0]?.is_read
    ) {
      is_read = articleInfo.article_actions[0]?.is_read;
    }
    dispatch(
      dashboard.handleRelatedReadStatus(articleInfo, { is_read: !is_read })
    );
    notificationRead(articleInfo, !is_read, handleRelatedArticleReadStatus);
  }

  function handleBookmarkStatusSingleArticle(articleInfo) {
    let is_bookmark = false;
    if (
      articleInfo.article_actions &&
      articleInfo.article_actions[0]?.is_bookmark
    ) {
      is_bookmark = articleInfo.article_actions[0]?.is_bookmark;
    }
    dispatch(
      dashboard.handleBookmarkStatusSingleArticle(articleInfo, {
        is_bookmark: !is_bookmark,
      })
    );
    notificationBookmark(!is_bookmark);
  }
  function handleBookmarkStatusBookmarkedArticle(articleInfo) {
    let is_bookmark = false;
    if (
      articleInfo.article_actions &&
      articleInfo.article_actions[0]?.is_bookmark
    ) {
      is_bookmark = articleInfo.article_actions[0]?.is_bookmark;
    }
    dispatch(
      dashboard.handleBookmarkStatusBookmarkedArticle(articleInfo, {
        is_bookmark: !is_bookmark,
      })
    );
    notificationBookmark(!is_bookmark);
  }

  function handleBookmarkRelatedStatus(articleInfo) {
    let is_bookmark = false;
    if (
      articleInfo.article_actions &&
      articleInfo.article_actions[0]?.is_bookmark
    ) {
      is_bookmark = articleInfo.article_actions[0]?.is_bookmark;
    }
    dispatch(
      dashboard.handleBookmarkRelatedArticles(articleInfo, {
        is_bookmark: !is_bookmark,
      })
    );
    notificationBookmark(!is_bookmark);
  }

  function handleBookmarkStatusCommonArticle(articleInfo) {
    let is_bookmark = false;
    if (
      articleInfo.article_actions &&
      articleInfo.article_actions[0]?.is_bookmark
    ) {
      is_bookmark = articleInfo.article_actions[0]?.is_bookmark;
    }
    dispatch(
      dashboard.handleBookmarkStatusCommonArticle(articleInfo, {
        is_bookmark: !is_bookmark,
      })
    );
    notificationBookmark(!is_bookmark);
  }

  function shareArticles(shareArticle){
    dispatch(dashboard.sharearticle(shareArticle.uuid))
    postArticleLinkedClicked(false,shareArticle.uuid)
  }

  function postArticleLinkedClicked(link_interactions,articleUUID,activity="shared") {
    let payload = {
      "system": "website",
      "activity": activity,
      "article_id" : articleUUID,
      "external_link": '',
      "link_interactions": link_interactions
    }
    dispatch(dashboard.postArticleLinkedClicked(payload)).then(
      (response) => {
        if (response.type === "POST_ARTICLE_LINKED_CLICKED_SUCCESS") {
          console.log("clicked")
          // setIsAPICall(false);
        } else {
          console.log("error");
        }
      }
    );
  }

  // useEffect to call topic , article and bookmark apis
  useEffect(() => {    
    getTopic();
    setLoader(true);
    dispatch(dashboard.getBookmarkedArticles("article/bookmarked?topic="));
    setLoader(false);
    getArticle("article?limit=11&offset=0&topic=");
    if (localStorage.getItem("article_uuid")){
      retrieveArticle(localStorage.getItem("article_uuid"))
      localStorage.removeItem("article_uuid");
    }
  }, []);

  useEffect(() => {
    setBookmarkArticles(reducers.articleInfoReducer.bookmarkedArticles);
    setRelatedArticle(reducers.articleInfoReducer.relatedArticles);
    setArticlesDataList(reducers.articleInfoReducer.articles);
    setSingleArticle(reducers.articleInfoReducer.singleArticle);
  }, [articleInfoReducer]);

  function getTopic() {
    dispatch(dashboard.getTopics("topic")).then((response) => {
      if (response.type === "GET_TOPIC_SUCCESS") {
        setTopic(response.data);
      }
    });
  }

  function getallbookmarkarticles(value) {
    var applyclassalltopic = document.getElementById("all_topics");
    var applyclassontopic = document.getElementsByClassName("topicclass");
    applyclassalltopic.className += " active";
    for (var i = 0; i < applyclassontopic.length; i++) {
      applyclassontopic[i].classList.remove("active");
      dispatch(dashboard.getBookmarkedArticles("article/bookmarked?topic="));
    }
  }

  //  function to get list of Articles
  function getArticle(article_url) {
    setLoader(true);
    if (article_url === "all") {
      setTopicUuid([]);
      var data = "all";
      article_url = "article?topic=";
      var applyclassalltopic = document.getElementById("all_topics");
      var applyclassontopic = document.getElementsByClassName("topicclass");
      applyclassalltopic.className += " active";
      for (var i = 0; i < applyclassontopic.length; i++) {
        applyclassontopic[i].classList.remove("active");
      }
    }
    dispatch(dashboard.getArticles(article_url)).then((response) => {
      if (response.type === "GET_ARTICLE_SUCCESS") {
        if (data === "all") {
          setArticlesDataList([...response.data.results]);
          dispatch({
            type: articleConstants.SET_ARTICLES_LIST,
            data: [...response.data.results],
          });
        } else {
          setArticlesDataList([...articlesDataList, ...response.data.results]);
          console.log(articlesDataList)
          dispatch({
            type: articleConstants.SET_ARTICLES_LIST,
            data: [...articlesDataList, ...response.data.results],
          });
        }
        setNext(response.data.next);
        setLoader(false);
      } else {
        setLoader(false);
      }
    });
  }

  // function to retrieve a particular article details
  const retrieveArticle = (uuid) => {
    setLoader(true);
    dispatch(dashboard.retrieveArticle(uuid)).then((response) => {
      if (response.type === "RETRIEVE_ARTICLE_SUCCESS") {
        setLoader(false);
        setSingleArticle(response.data);
        setIsRetrieveArticle(true);
        setKey("1");
      } else {
        setIsRetrieveArticle(false);
        setLoader(false);
      }
    });
  };

  const retrieveBookmarkedArticle = (uuid) => {
    setLoader(true);
    dispatch(dashboard.retrieveArticle(uuid)).then((response) => {
      if (response.type === "RETRIEVE_ARTICLE_SUCCESS") {
        setLoader(false);
        setSingleArticle(response.data);
        setIsRetrieveBookmarkedArticle(true);
        setKey("2");
      } else {
        setLoader(false);
      }
    });
  };

  // to get relatedarticles
  const callRelatedArticles = (uuid) => {
    setLoader(true);
    dispatch(dashboard.getRelatedArticles(uuid)).then((response) => {
      if (response.type === "GET_RELATED_ARTICLE_SUCCESS") {
        setLoader(false);
        setRelatedArticle(response.data);
      } else {
        setLoader(false);
      }
    });
  };

  // to call callRelatedArticles function
  const callRetrieveRelate = (uuid,isRelated=false) => {
    retrieveArticle(uuid);
    callRelatedArticles(uuid);
    if (isRelated){
      postArticleLinkedClicked(false,uuid,"opened")
    }
  };

  const callBookmarkedRetriveRealtedArticle = (uuid) => {
    retrieveBookmarkedArticle(uuid);
    callRelatedArticles(uuid);
  };

  // function for back button
  const backToArticleList = () => {
    window.location.reload()
  };

  const backToBookmarkedArticle = () => {
    setIsRetrieveBookmarkedArticle(false);
    setLoader(true);
    dispatch(dashboard.getBookmarkedArticles("article/bookmarked?topic="));
    setLoader(false);
  };

  // function to call share article option
  const shareArticleAction = (article_data) => {
    setShareArticle(article_data);
    setShowShare(true);
  };

  setTimeout(() => {
    if(articlesDataList.length !== 0){
      articlesDataList.map((art)=>{
        if(art.is_featured === true){
          isShowFeatured(true)
        }else{
          isShowDiscover(true)
        } 
      })
    }
  }, 500);



  return (
    <>
      <div className="dash-page ">
        <div className="heading-wrap d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <span className="head-icon me-16">
              <em className="icon-home-filled"></em>
            </span>
            <div className="position-relative">
              <div className="d-flex align-items-center">
                <h4 className="m-0">Home</h4>
              </div>
            </div>
          </div>
          <button
            className="btn btn-outline-secondary btn-icon-only d-md-none"
            type="button"
            onClick={(e) => {
              setShowFilter(true);
            }}
          >
            <em className="icon-adjustments-outline"></em>
          </button>
        </div>
        <div className="tab-wrap">
          {/* Tabs */}
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => {
              setKey(k);
              setLoader(true);
              dispatch(
                dashboard.getBookmarkedArticles("article/bookmarked?topic=")
              );
              setArticlesDataList([]);
              setLoader(false);
              // setArticlesDataList([])
              // dispatch({ type: articleConstants.RESET_ARTICLES })
            }}
            className=""
          >
            <Tab
              eventKey={1}
              title={
                <span
                  className="d-md-flex align-items-center justify-content-center"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  <span className="tab-icon">
                    <em className="icon-view-grid-filled"></em>
                  </span>{" "}
                  <span className="tab-text">Explore</span>
                </span>
              }
            >
              {/* {setSharedTab(true)} */}
            </Tab>

            <Tab
              eventKey={2}
              title={
                <span className="d-md-flex align-items-center justify-content-center">
                  <span className="tab-icon">
                    <em className="icon-bookmark-filled fs-14"></em>
                  </span>{" "}
                  <span className="tab-text">Bookmarks</span>
                </span>
              }
            >
              {/* {setSharedTab(true)} */}
            </Tab>
          </Tabs>
        </div>
        <div className="content-area p-0">
          <div className="tab-content show">
            {/* Show to hide and to active Explore*/}
            {key === "1" &&
              (isRetrieveArticle ? (
                <RetrieveArticle
                  backbutton={backToArticleList}
                  singleArticle={singleArticle}
                  handleReadStatusSingleArticle={handleReadStatusSingleArticle}
                  handleBookmarkStatusSingleArticle={
                    handleBookmarkStatusSingleArticle
                  }
                  shareArticleAction={shareArticleAction}
                  notificationRead={notificationRead}
                  notificationBookmark={notificationBookmark}
                  relatedArticle={relatedArticle}
                  handleRelatedArticleReadStatus={
                    handleRelatedArticleReadStatus
                  }
                  handleBookmarkRelatedStatus={handleBookmarkRelatedStatus}
                  callRetrieveRelate={callRetrieveRelate}
                />
              ) : (
                <div
                  role="tabpanel"
                  id="controlled-tab-example-tabpane-1"
                  className="fade tab-pane show active"
                >
                  <div className="topic-wrap d-md-block d-none">
                    <h4 className="mb-16">Topics</h4>

                    <TopicComponent
                      topic={topic}
                      getArticle={getArticle}
                      setArticlesDataList={setArticlesDataList}
                      setNext={setNext}
                      bookmark={0}
                    />
                  </div>

                  {articlesDataList.length === 0 ? (
                  <div className="bookmarks-block">
                    <div className="articles-wrap main-articles-wrap">
                      <span className="head-icon mx-auto">
                        <img src={cdc} alt="" />
                      </span>
                      <p>
                        Articles published by Moneta will be displayed here
                      </p>
                    </div>
                  </div>
                ):
                  <div className="featured-section">
                    {showFeatured === true && <div><div className="title-row">
                      <h4 className="fs-18 lh-24">Featured</h4>
                    </div>
                    <div className="featured-row full-row">
                      {articlesDataList?.map(
                        (article_data, index) =>
                          article_data?.is_featured && (
                            <div
                              key={article_data.uuid}
                              className="featured-item"
                            >
                              <figure
                                className="thumb-img cursor-pointer"
                                onClick={() =>
                                  callRetrieveRelate(article_data.uuid)
                                }
                              >
                                <img src={article_data.image} alt="" />
                              </figure>
                              <div className="featured-content pt-md-16 pt-8">
                                <p
                                  className="fs-18 lh-24 mb-md-8 mb-0 fw-500 text-172935 cursor-pointer"
                                  onClick={() =>
                                    callRetrieveRelate(article_data.uuid)
                                  }
                                >
                                  {article_data.title}
                                </p>
                                <div className="ftr-date-lbl justify-content-between">
                                  <div className="d-flex align-items-center">
                                    <span className="fs-12 lh-16 text-muted d-block me-8 text-nowrap">
                                      {article_data.publish_date}
                                    </span>
                                    {/* <span className="ftr-lbl"> */}
                                    {article_data.topic &&
                                            article_data.topic?.slice(0, 3).map(
                                              (topic, index) => (
                                                <span
                                                  key={topic.uuid}
                                                  className="ftr-lbl me-4 my-2"
                                                >
                                                  {utility.decodeEntity(topic.name)}
                                                </span>
                                              )
                                            )}
                                    {article_data.topic && article_data.topic.length > 3 ? 
                                            <span>+{parseInt(article_data.topic.length) - 3}</span>
                                          : ""
                                    }
                                    {/* </span> */}
                                  </div>
                                  <ReadBookmark
                                    handleReadStatusArticle={
                                      handleReadStatusCommonArticle
                                    }
                                    item={article_data}
                                    shareArticleAction={shareArticleAction}
                                    handleBookmarkStatusArticle={
                                      handleBookmarkStatusCommonArticle
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          )
                      )}
                    </div>
                    </div>
                    }
                    {showDiscover === true && <div><div className="title-row d-flex justify-content-start pt-md-32 pt-24 pb-md-16 pb-8 px-24">
                      <h4 className="fs-18 lh-24">Discover More</h4>
                    </div>
                    <div className="featured-row px-0 px-md-24">
                      <div className="row gx-md-24 gx-0">
                        {articlesDataList &&
                          articlesDataList?.map(
                            (article_data, index) =>
                              article_data?.is_featured === false && (
                                <div
                                  key={article_data.uuid}
                                  className="col-xl-6 d-flex align-items-stretch"
                                >
                                  <div className="featured-item">
                                    <div className="featured-content">
                                      <div
                                        className="d-flex cursor-pointer"
                                        onClick={() =>
                                          callRetrieveRelate(article_data.uuid)
                                        }
                                      >
                                        <div className="ftr-caption">
                                          <a to="#">
                                            <h4 className="fs-18 lh-24 mb-md-8 mb-0">
                                              {article_data.title}
                                            </h4>
                                          </a>
                                          <div className="mb-4 description">
                                            <div
                                              id="target"
                                              dangerouslySetInnerHTML={{
                                                __html: utility.ellipsify(
                                                  article_data.description
                                                    .replaceAll(
                                                      /<img .*?>/g,
                                                      ""
                                                    )
                                                    .replaceAll(
                                                      /<iframe .*?>/g,
                                                      ""
                                                    )
                                                    .replaceAll(
                                                      /\[embed\](.*)(?=\[\/embed\])/g,
                                                      ""
                                                    ).replaceAll(
                                                      '[/embed]', ''
                                                    ),
                                                  200
                                                ),
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <figure className="thumb-img">
                                          <img
                                            src={article_data.image}
                                            alt=""
                                          />
                                        </figure>
                                      </div>
                                      <div className="ftr-date-lbl justify-content-between mt-16">
                                        <div className="d-md-flex align-items-start">
                                          <span className="fs-12 lh-16 text-muted d-block me-8 text-nowrap mb-8 mb-md-0 pt-4">
                                            {article_data.publish_date}
                                          </span>
                                          <div className="d-flex flex-fill flex-wrap lbl-group">
                                            {article_data.topic &&
                                              article_data.topic?.slice(0, 3).map(
                                                (topic, index) => (
                                                  <span
                                                    key={topic.uuid}
                                                    className="ftr-lbl me-4 my-2"
                                                  >
                                                    {utility.decodeEntity(topic.name)}
                                                  </span>
                                                )
                                              )}
                                            {article_data.topic && article_data.topic.length > 3 ? 
                                              <span>+{parseInt(article_data.topic.length) - 3}</span>
                                            : ""
                                            }
                                          </div>
                                        </div>

                                        <ReadBookmark
                                          handleReadStatusArticle={
                                            handleReadStatusCommonArticle
                                          }
                                          item={article_data}
                                          shareArticleAction={
                                            shareArticleAction
                                          }
                                          handleBookmarkStatusArticle={
                                            handleBookmarkStatusCommonArticle
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                          )}
                      </div>
                      {next !== null && (
                        <div className="text-center pb-40 d-none d-md-block">
                          <button
                            type="button"
                            onClick={() => getArticle(next)}
                            className="btn btn-success"
                          >
                            Load More
                          </button>
                        </div>
                      )}
                    </div>
                    </div>
                    }                 
                  </div>
                  }
                </div>
              ))}
          </div>

          {key === "2" &&
            (isRetrieveBookmarkedArticle ? (
              <RetrieveArticle
                backbutton={backToBookmarkedArticle}
                singleArticle={singleArticle}
                handleReadStatusSingleArticle={handleReadStatusSingleArticle}
                handleBookmarkStatusSingleArticle={
                  handleBookmarkStatusSingleArticle
                }
                shareArticleAction={shareArticleAction}
                notificationRead={notificationRead}
                notificationBookmark={notificationBookmark}
                relatedArticle={relatedArticle}
                handleRelatedArticleReadStatus={handleRelatedArticleReadStatus}
                handleBookmarkRelatedStatus={handleBookmarkRelatedStatus}
                callRetrieveRelate={callBookmarkedRetriveRealtedArticle}
              />
            ) : (
              <div
                role="tabpanel"
                id="controlled-tab-example-tabpane-2"
                className="fade tab-pane show active "
              >
                <div className="topic-wrap d-md-block d-none">
                  <h4 className="mb-16">Topics</h4>

                  <TopicComponent
                    topic={topic}
                    getArticle={getallbookmarkarticles}
                    setArticlesDataList={false}
                    setNext={setNext}
                    bookmark={1}
                  />
                </div>
                {/* this section for empty bookmark */}
                {bookmarkArticles.length === 0 && (
                  <div className="bookmarks-block">
                    <div className="articles-wrap bookmarks-wrap">
                      <span className="head-icon mx-auto">
                        <em className="icon-bookmark-filled icon "></em>
                      </span>
                      <p>
                        Your bookmarks are empty. Click the{" "}
                        <em className="icon-bookmark-outline icon"></em> on any
                        Moneta article to get started.{" "}
                      </p>
                      <button
                        type="button"
                        className="btn btn-success"
                        onClick={() => window.location.reload()}
                      >
                        Browse Articles
                      </button>
                    </div>
                  </div>
                )}
               
                {bookmarkArticles &&
                  bookmarkArticles.map((article_info, index) => {
                    return (
                      <div
                        className="featured-section bookmark-feat"
                        key={article_info.uuid}
                      >
                        <div className="featured-row pt-md-20 px-0 px-md-24">
                          <div className="row gx-0">
                            <div className="col-12">
                              <div className="featured-item">
                                <div className="featured-content">
                                  <div className="d-flex">
                                    <div
                                      className="ftr-caption cursor-pointer"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        callBookmarkedRetriveRealtedArticle(
                                          article_info.uuid
                                        );
                                      }}
                                    >
                                      <h4 className="fs-18 lh-24 mb-md-8 mb-0">{article_info.title}</h4>
                                      <div className="mb-4">
                                        <div
                                          lines={3}
                                          dangerouslySetInnerHTML={{
                                            __html: utility.ellipsify(
                                              article_info.description
                                                .replaceAll(/<img .*?>/g, "")
                                                .replaceAll(
                                                  /\[embed\](.*)(?=\[\/embed\])/g,
                                                  ""
                                                ).replaceAll(
                                                  '[/embed]', ''
                                                ),
                                              200
                                            ),
                                          }}
                                        ></div>
                                      </div>
                                    </div>
                                    <figure className="thumb-img">
                                      <img src={article_info.image} alt="" />
                                    </figure>
                                  </div>
                                  <div className="ftr-date-lbl justify-content-between">
                                    <div className="d-md-flex align-items-center">
                                      <span className="fs-12 lh-16 text-muted d-block me-8 text-nowrap mb-8 mb-md-0">
                                        {article_info.publish_date}
                                      </span>
                                      <div className="d-flex flex-fill flex-wrap lbl-group">
                                        {article_info.topic &&
                                          article_info.topic?.slice(0, 3).map(
                                            (topic, index) => (
                                              <span
                                                key={topic.uuid}
                                                className="ftr-lbl me-4 my-2"
                                              >
                                                {utility.decodeEntity(topic.name)}
                                              </span>
                                            )
                                          )}
                                        {article_info.topic && article_info.topic.length > 3 ? 
                                            <span>+{parseInt(article_info.topic.length) - 3}</span>
                                          : ""
                                        }
                                      </div>
                                    </div>

                                    <ReadBookmark
                                      handleReadStatusArticle={
                                        handleReadStatusBookmarkedArticle
                                      }
                                      item={article_info}
                                      shareArticleAction={shareArticleAction}
                                      handleBookmarkStatusArticle={
                                        handleBookmarkStatusBookmarkedArticle
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                {/* <div className="aside-area">
            <div className="left-content-area pe-md-8 pt-12 pb-md-12">
              <div className="custom-style-scroll max-680">
                <div className="featured-row full-row pe-md-16 border-bottom-md-0 pb-0 pb-md-24">
                  <div className="featured-item">
                    <span className="cursor-pointer d-flex align-items-center fs-18 new-meet-back text-success mb-12">
                      <em className="icon-chevron-left-outline fs-14 me-12"></em>
                      Back
                    </span>
                    <div className="featured-content">
                      <h2 className="mb-16">
                        Investment Report: An End To Summer Cheer as
                        Concerns Start To Fester
                      </h2>
                      <div className="topic-list d-flex flex-wrap mb-24">
                        <span className="ftr-lbl me-16">Investing</span>
                        <span className="ftr-lbl me-16">Technology</span>
                      </div>
                      <figure className="thumb-img">
                        <img src={featuredImg} alt="" />
                      </figure>
                      <div className="ftr-date-lbl mb-24 pt-md-12 pt-24 justify-content-between">
                        <div className="user-post-details d-flex align-items-center">
                          <figure className="user-photo">
                            <img src={featuredImg} alt="" />
                          </figure>
                          <div className="ms-8">
                            <span className="d-block lh-20 fw-500">
                              Jeremy Davies
                            </span>
                            <span className="d-block text-muted lh-20 fs-14">
                              Mar 29, 2022
                            </span>
                          </div>
                        </div>
                        <ul className="ftr-btn d-flex">
                          <li>
                            <span className="icon-36 btn btn-outline-secondary p-0">
                              <em className="icon icon-check-outline"></em>
                            </span>
                          </li>
                          <li>
                            <div
                              className="upload-file"
                              onClick={() => setShowShare(true)}
                            >
                              <span className="icon-36 btn btn-outline-secondary p-0">
                                <em className="icon icon-upload-filled"></em>
                              </span>
                              <input type="file" id="ftr-file1" />
                            </div>
                          </li>
                          <li className="text-success">
                            <span className="icon-36 btn btn-outline-success p-0">
                              <em className="icon icon-bookmark-filled"></em>
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div className="ftr-detailsarea">
                        <h3 className="fs-24 lh-32 mb-24">
                          Five Considerations Impacting the Remainder of
                          2021
                        </h3>
                        <h4 className="fs-18 lh-24 mb-8">
                          Vaccine Rates and Reopening
                        </h4>
                        <p>
                          As we head into the second half of the year,
                          vaccine rates are largely determining the
                          emergence of countries into the post-COVID era.
                          Unfortunately, the pattern is far from uniform.
                          As the Delta variant rages through Europe and
                          developing countries, it is particularly younger
                          people who are becoming infected, but this is
                          putting pressure on businesses striving to
                          reopen for the popular tourist season.{" "}
                        </p>
                        <h4 className="fs-18 lh-24 mb-8">
                          How “Real” is the Market Growth to Date?{" "}
                        </h4>
                        <p>
                          The desire to write the next chapter of the
                          recovery and economic growth is real, but, for
                          now, things remain a little distorted by the
                          “base effect.” With buoyant economic growth
                          forecasted for the balance of 2021, we are still
                          trying to pick apart if this represents just a
                          recovery from a low base (in 2020) or a
                          re-establishment of the strong growth trajectory
                          that persisted since the financial crisis of
                          2008.
                        </p>
                        <h4 className="fs-18 lh-24 mb-8">
                          Familiar Market Uncertainty
                        </h4>
                        <p>
                          There is also a mild sense of whiplash as the
                          COVID recovery narrative seems to have been
                          twisted by the rise of the Delta variant, at
                          times referred to as a pandemic for the
                          unvaccinated. Faced with this ongoing
                          uncertainty, markets seem to be less plumbing
                          for a bottom than fishing for answers. The noise
                          of bitcoin and other cryptocurrencies as well as
                          meme stocks, while a spectacle, is unlikely to
                          move the needle much for mainstream investors
                          who have broadly steered clear of this area.
                        </p>
                        <h4 className="fs-18 lh-24 mb-8">
                          The Looming Concern Over Inflation
                        </h4>
                        <p>
                          Supply chain disruptions and rising energy
                          prices have stoked inflationary concerns again,
                          although the US Fed remains steadfast that this
                          is a transitory phenomenon not currently
                          meriting a policy response.
                        </p>
                        <h4 className="fs-18 lh-24 mb-8">
                          Mixed Signals Between Equity and Bond Markets
                        </h4>
                        <p>
                          Equity markets continue to inch higher, although
                          perhaps with a few more jitters over past weeks.
                          Bond yields have also headed lower, reflecting
                          strong demand and little wariness of imminent
                          rate rises. Lower yields have been a catalyst
                          for increased demand for private assets.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="aside-bar pe-24 pe-lg-8 ps-24 pt-24">
              <div className="custom-style-scroll max-680">
                <h4 className="fs-18 lh-24 mb-16">Related Articles</h4>
                <div className="featured-row p-0 pe-lg-16 mx-n24">
                  <div className="featured-item">
                    <div className="featured-content">
                      <div className="d-flex">
                        <div className="ftr-caption">
                          <a to="#">
                            <h4 className="fs-18 lh-24 mb-0">
                              Investment Report: An End To Summer Cheer as
                              Concerns Start To Fester
                            </h4>
                          </a>
                        </div>
                        <figure className="thumb-img">
                          <img src={featuredImg} alt="" />
                        </figure>
                      </div>
                      <div className="ftr-date-lbl justify-content-between mt-12 mt-md-8">
                        <div className="d-flex align-items-center">
                          <span className="fs-12 lh-16 text-muted d-block me-8 text-nowrap">
                            Mar 29, 2022
                          </span>
                          <span className="ftr-lbl">Featured</span>
                        </div>
                        <ul className="ftr-btn d-flex align-items-center">
                          <li>
                            <span className="icon-24">
                              <em className="icon icon-check-circle-filled text-success"></em>
                            </span>
                          </li>
                          <li>
                            <div className="upload-file">
                              <span className="icon-24">
                                <em className="icon icon-upload-filled"></em>
                              </span>
                              <input type="file" id="ftr-file1" />
                            </div>
                          </li>
                          <li>
                            <span className="icon-24">
                              <em className="icon icon-bookmark-filled text-success"></em>
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="featured-item">
                    <div className="featured-content">
                      <div className="d-flex">
                        <div className="ftr-caption">
                          <a to="#">
                            <h4 className="fs-18 lh-24 mb-0">
                              Investment Report: An End To Summer Cheer as
                              Concerns Start To Fester
                            </h4>
                          </a>
                        </div>
                        <figure className="thumb-img">
                          <img src={featuredImg} alt="" />
                        </figure>
                      </div>
                      <div className="ftr-date-lbl justify-content-between mt-12 mt-md-8">
                        <div className="d-flex align-items-center">
                          <span className="fs-12 lh-16 text-muted d-block me-8 text-nowrap">
                            Mar 29, 2022
                          </span>
                          <span className="ftr-lbl">Featured</span>
                        </div>
                        <ul className="ftr-btn d-flex align-items-center">
                          <li>
                            <span className="icon-24">
                              <em className="icon  icon-check-circle-outline"></em>
                            </span>
                          </li>
                          <li>
                            <div className="upload-file">
                              <span className="icon-24">
                                <em className="icon icon-upload-filled"></em>
                              </span>
                              <input type="file" id="ftr-file1" />
                            </div>
                          </li>
                          <li>
                            <span className="icon-24">
                              <em className="icon icon-bookmark-filled text-success"></em>
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="featured-item">
                    <div className="featured-content">
                      <div className="d-flex">
                        <div className="ftr-caption">
                          <a to="#">
                            <h4 className="fs-18 lh-24 mb-0">
                              Investment Report: An End To Summer Cheer as
                              Concerns Start To Fester
                            </h4>
                          </a>
                        </div>
                        <figure className="thumb-img">
                          <img src={featuredImg} alt="" />
                        </figure>
                      </div>
                      <div className="ftr-date-lbl justify-content-between mt-12 mt-md-8">
                        <div className="d-flex align-items-center">
                          <span className="fs-12 lh-16 text-muted d-block me-8 text-nowrap">
                            Mar 29, 2022
                          </span>
                          <span className="ftr-lbl">Featured</span>
                        </div>
                        <ul className="ftr-btn d-flex align-items-center">
                          <li>
                            <span className="icon-24">
                              <em className="icon  icon-check-circle-outline"></em>
                            </span>
                          </li>
                          <li>
                            <div className="upload-file">
                              <span className="icon-24">
                                <em className="icon icon-upload-filled"></em>
                              </span>
                              <input type="file" id="ftr-file1" />
                            </div>
                          </li>
                          <li>
                            <span className="icon-24">
                              <em className="icon icon-bookmark-filled text-success"></em>
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
              </div>
            ))}
        </div>
      </div>
      <Modal className=" modal-full" show={showShare} onHide={handleShareClose}>
        <Modal.Header closeButton>
          <Modal.Title>Share</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="featured-item">
            <div className="d-flex">
              <div className="ftr-caption">
                <a to="#">
                  <h4 className="fs-18 lh-24 mb-0">
                    {shareArticle && shareArticle.title}
                  </h4>
                </a>
              </div>
              <figure className="thumb-img">
                <img src={shareArticle && shareArticle.image} alt="" />
              </figure>
            </div>
          </div>
          <ul className="social-share list-unstyled d-flex justify-content-between m-0">
            <li>
              <FacebookShareButton onClick={()=> shareArticles(shareArticle)}
                url={shareArticle && shareArticle.public_url}
              >
                <FacebookIcon size={50} round />
                <br /> Facebook
              </FacebookShareButton>
            </li>
            <li>
              <LinkedinShareButton onClick={()=> shareArticles(shareArticle)}
                url={shareArticle && shareArticle.public_url}
              >
                <LinkedinIcon size={50} round />
                <br /> LinkedIn
              </LinkedinShareButton>
            </li>
            <li>
              <TwitterShareButton url={shareArticle && shareArticle.public_url}
                onClick={()=> shareArticles(shareArticle)}>
                <TwitterIcon size={50} round />
                <br /> Twitter
              </TwitterShareButton>
            </li>
            <li>
              <EmailShareButton url={shareArticle && shareArticle.public_url}>
                {/* <EmailIcon size={50} round /> */}
                <span onClick={()=> shareArticles(shareArticle)} className="emailphoto">
                  <img src={EmailPhoto} alt="" />
                </span>
                Email
              </EmailShareButton>
            </li>
          </ul>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ArticleDashboard;
