import React, { useEffect, useState } from "react";
import { clientUser } from "../../redux/actions/ClientUser/ClientUser";
import Modal from "react-bootstrap/Modal";
import { Switch, Select, notification } from "antd";
import { useDispatch } from "react-redux";

const UpdateDefaultSettings = (props) => {
  const dispatch = useDispatch();
  const { Option } = Select;
  const { closeModal, ApplySettings, selectedPreference } = props;
  const [Value, setValue] = useState(selectedPreference.Value);
  const [new_user, setnew_user] = useState(true);
  const [existing_user, setexisting_user] = useState((selectedPreference.Preference_id===19 || selectedPreference.Preference_id===20)?true: false);
  const [user_current_default, setuser_current_default] = useState(false);
  const [user_custom_settings, setuser_custom_settings] = useState(false);

  const Notify = (type, mesage, icons) => {
    notification[type]({
      description: mesage,
      icon: icons,
      top: 65,
    });
  };

  function setUserPreference() {
    let param = {
      new_user: new_user,
      existing_user: existing_user,
      user_current_default: user_current_default,
      user_custom_settings: user_custom_settings,
      value: selectedPreference.Preference_id === 21?Value?"true":"false":Value,
      team_id: selectedPreference.TeamId,
    };
    dispatch(
      clientUser.setTeamPreference(param, selectedPreference.Preference_id)
    ).then((response) => {
      if (response.type === "SET_NOTIFICATION_SUCCESS") {
        Notify(
          "success",
          `Default setting in “${selectedPreference?.Setting}” has been updated for your team’s client users.`,
          <em className="icon-check-outline"></em>
        );
        ApplySettings();
      }
    });
  }

  function oncheckChange(name) {
    if (!Value?.includes(name)) {
      Value.push(name);
    } else {
      if (Value.length > 1) {
        var index = Value.indexOf(name);
        if (index >= 0) {
          Value.splice(index, 1);
        }
      }
    }
    setValue([...Value]);
  }
  return (
    <>
      <Modal
        className="modal-800 modal-full"
        show={true}
        onHide={() => {
          closeModal();
        }}
      >
        <Modal.Header closeButton className="pb-24 border-bottom">
          <Modal.Title>Update Default Setting</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-md-48">
          <p className="mb-16 text-black fs-18 lh-34">
            You are about to set the following user preference as a default for
            your team’s clients:
          </p>
          <div className="uds-block">
            {selectedPreference.Title && (
              <h4 className="fs-18 lh-24 mb-24">{selectedPreference.Title}</h4>
            )}
            {selectedPreference.Type === "Switch" && (
              selectedPreference?.Setting === "Push" ? (
              <div className="notif-type">
                <div className="typesof-not d-flex align-items-center justify-content-between">
                  <h6 className="mb-8">{selectedPreference.Heading}</h6>
                  <Switch
                    checked={Value}
                    onChange={(e) => {
                      console.log(e);
                      setValue(e);
                    }}
                  />
                </div>
              </div>):
              (
              <div className="notif-type">
                <h6 className="mb-8">{selectedPreference.Heading}</h6>
                <div>
                  <div className="typesof-not d-flex align-items-center justify-content-between">
                    {!selectedPreference.isBold && (
                      <span className="lh-20">
                        {selectedPreference.Setting}
                      </span>
                    )}
                    {selectedPreference.isBold && (
                      <h6 className="m-0 pe-8">{selectedPreference.Setting}</h6>
                    )}
                    <Switch
                      checked={Value}
                      onChange={(e) => {
                        console.log(e);
                        setValue(e);
                      }}
                    />
                  </div>
                </div>
              </div>
              )
            )}
            {selectedPreference.Type === "Radio" && (
              <div className="typesof-not ">
                <div className="d-flex align-items-center justify-content-between">
                  <span className="lh-20">{selectedPreference.Setting}</span>
                  {/* {selectedPreference.subSetting && <p className="text-muted mb-20 fs-14 lh-20">
                    {selectedPreference.subSetting}
                  </p>} */}
                  {!selectedPreference.subSetting && (
                    <Switch
                      checked={Value !== "none"}
                      onChange={(e) => {
                        console.log(e);
                        setValue(e === true ? e : "none");
                      }}
                    />
                  )}
                </div>
                <div className="pt-20">
                  {selectedPreference?.items?.map((item, indx) => (
                    <div key={indx} className="form-check mb-12">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="cnu-misschat"
                        id="cnu-misschat1"
                        checked={item === Value}
                        value={item}
                        onChange={(e) => {
                          setValue(e?.target?.value);
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="cnu-misschat1"
                      >
                        {item}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {selectedPreference.Type === "Drop" && (
              <div className="row">
                <div className="pt-24">
                  <h6 className="fw-normal m-0 text-1E3443">
                    Scheduling Conditions
                  </h6>
                  <p className="text-muted mb-20 fs-14 lh-20">
                    Set the minimum number of days before a client can request a new meeting.
                  </p>
                  <h6 className="m-0 text-1E3443 lh-24">Number of Business Days</h6>
                  <div className="row">
                    <div className="col-xl-3 col-md-4">
                      <Select
                        className="d-none d-md-block"
                        // defaultValue={selectedStartDate}
                        value={Value}
                        onChange={(e) => {
                          setValue(e);
                        }}
                        dropdownStyle={{ zIndex: 2000 }}
                      >
                        {selectedPreference.items?.map((value, index) => (
                          <Option key={index} value={value}>
                            {value}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {selectedPreference.Type === "Check" && (
              <div className="row">
                <div className="pt-24">
                  <h6 className="fw-normal m-0 text-1E3443">
                    {selectedPreference.Heading}
                  </h6>
                  <p className="text-muted mb-12 fs-14 lh-20">
                    {selectedPreference.subSetting}
                  </p>
                  <div className="col-md-8">
                    <div className="py-8">
                      <div className="row align-items-center">
                        <div className="col-md-6">
                          <div className="form-check mb-0">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="meeting-attend"
                              checked={Value?.includes("Relationship Lead")}
                              onChange={(e) => {
                                oncheckChange("Relationship Lead");
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="meeting-attend"
                            >
                              Relationship Lead
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="py-8">
                      <div className="row align-items-center">
                        <div className="col-md-6">
                          <div className="form-check mb-0">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="meeting-attend"
                              checked={Value.includes("Partner")}
                              onChange={(e) => {
                                oncheckChange("Partner");
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="meeting-attend"
                            >
                              Partner
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="py-8">
                      <div className="row align-items-center">
                        <div className="col-md-6">
                          <div className="form-check mb-0">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="meeting-attend"
                              checked={Value?.includes("Advisor")}
                              onChange={(e) => {
                                oncheckChange("Advisor");
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="meeting-attend"
                            >
                              Advisor
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="py-8">
                      <div className="row align-items-center">
                        <div className="col-md-6">
                          <div className="form-check mb-0">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="meeting-attend"
                              checked={Value?.includes("Secondary Advisor")}
                              onChange={(e) => {
                                oncheckChange("Secondary Advisor");
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="meeting-attend"
                            >
                              Secondary Advisor
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="py-8">
                      <div className="row align-items-center">
                        <div className="col-md-6">
                          <div className="form-check mb-0">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="meeting-attend"
                              checked={Value?.includes("CSM")}
                              onChange={(e) => {
                                oncheckChange("CSM");
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="meeting-attend"
                            >
                              CSM
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="py-8">
                      <div className="row align-items-center">
                        <div className="col-md-6">
                          <div className="form-check mb-0">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="meeting-attend"
                              checked={Value?.includes("Secondary CSM")}
                              onChange={(e) => {
                                oncheckChange("Secondary CSM");
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="meeting-attend"
                            >
                              Secondary CSM
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="px-md-20">
            <h4 className="fs-18 lh-24 mb-20">
              Apply this change to (choose all that applies):
            </h4>
            {(selectedPreference.Preference_id===19 || selectedPreference.Preference_id ===20) &&
            <p>Note: This is a team-wide setting and cannot be changed separately between new and existing users.</p>}
            <div className="pb-md-28">
              <div className="form-check mb-12">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="apply-user"
                  checked={new_user}
                  // onChange={(e) => {
                  //   setnew_user(e.target.checked);
                  // }}
                />
                <label className="form-check-label" htmlFor="apply-user">
                  New users
                </label>
              </div>
              {/* {(selectedPreference.Type === "Check" ||
                selectedPreference.Type === "Radio" || selectedPreference.Type === "Drop") &&<div className="form-check mb-12">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="apply-user1"
                  checked={user_current_default}
                  onChange={(e) => {
                    setuser_current_default(e.target.checked);
                  }}
                />
                <label className="form-check-label" htmlFor="apply-user1">
                  Existing users with the old default setting (
                  {selectedPreference?.DefaultCount}{" "}
                  {selectedPreference?.DefaultCount === 1 ||
                  selectedPreference?.DefaultCount === 0
                    ? "user"
                    : "users"}
                  )
                </label>
              </div>} */}
              {<div className="form-check mb-12">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="apply-user1"
                  checked={existing_user}
                  onChange={(e) => {
                    if(selectedPreference.Preference_id!==19 && selectedPreference.Preference_id!==20)
                    {
                      setexisting_user(e.target.checked);
                    }
                  }}
                />
                <label className="form-check-label" htmlFor="apply-user1">
                  Existing users(
                  {(selectedPreference?.DefaultCount+selectedPreference?.CustomCount)}{" "}
                  {(selectedPreference?.DefaultCount+selectedPreference?.CustomCount) === 1 ||
                  (selectedPreference?.DefaultCount+selectedPreference?.CustomCount) === 0
                    ? "user"
                    : "users"}
                  )
                </label>
              </div>}
              {/* {(selectedPreference.Type === "Check" ||
                selectedPreference.Type === "Radio" || selectedPreference.Type === "Drop") && (
                <div className="form-check mb-12">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="apply-user2"
                    checked={user_custom_settings}
                    onChange={(e) => {
                      setuser_custom_settings(e.target.checked);
                    }}
                  />
                  <label className="form-check-label" htmlFor="apply-user2">
                    Existing users with a custom setting (
                    {selectedPreference?.CustomCount}{" "}
                    {selectedPreference?.CustomCount === 1 ||
                    selectedPreference?.CustomCount === 0
                      ? "user"
                      : "users"}
                    )
                  </label>
                </div>
              )} */}
              {new_user === false &&
                user_current_default === false &&
                user_custom_settings === false && (
                <div className="alert alert-danger">
                  <span className="icon-24 ">
                    <em className="icon-exclamation-circle-filled"></em>
                  </span>
                  <p className="m-0 fw-500">
                      Please select a user group above to apply this change.
                  </p>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-secondary"
            onClick={(e) => {
              e.preventDefault();
              closeModal();
            }}
          >
            Cancel
          </button>
          <button
            disabled={
              new_user === false &&
              user_current_default === false &&
              user_custom_settings === false
            }
            className="btn btn-success"
            onClick={(e) => {
              e.preventDefault();
              setUserPreference();
            }}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default UpdateDefaultSettings;
