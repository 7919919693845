import React, { useEffect, useState, useRef } from "react";
import { Switch } from "antd";
import { useDispatch } from "react-redux";
import { dashboard } from "../../redux/actions/Dashboard/dashboard";
import Avatar from "react-avatar";
import Modal from "react-bootstrap/Modal";
import DashboardCalendarLocale from "./DashboardCalendarLocale"
import moment from "moment";
import { Calendar, utils } from "react-modern-calendar-datepicker";
import { notification , Dropdown, Menu, Tooltip} from "antd";
import FilterDropdown from "./FilterDropdown";
import FilterRelatedDropdown from "./FilterRelatedDropdown";
import MessageDashboard from "./MessageDashboard";
// import CalendarDashboard from "./CalendarDashboard";
import { utility } from "../../components/Utility";


const TaskDashboard = ({ userDetails }) => {
  const [advisorListData, setAdvisorListData] = useState("");
  const handleFilterTaskClose = () => {
    setFilterTaskShow(false);
  };
  const [showFilterTask, setFilterTaskShow] = useState(false);
  const [commentsupdatedValue, setCommentsupdatedValue] = useState(false);
  const [statusUpdatedValue, setStatusUpdatedValue] = useState(false);
  const [assignedtoupdatedValue, setAssignedtoupdatedValue] = useState(false);
  const [assignedtoupdatedname, setAssignedtoupdatedname] = useState(false);
  const [dueDate, setDueDate] = useState("");
  const [tasksDataList, setTasksDataList] = useState([]);
  const [showDiv, setShowDiv] = useState(false);
  const [uuid, setUuid] = useState(false);
  const [subject, setSubject] = useState(false);
  const [currentValue, setCurrentValue] = useState("2");
  const [rCurrentValue, setRCurrentValue] = useState("1");
  const [selected, setSelected] = useState([]);
  const [relatedSelected, setRelatedSelected] = useState([]);
  const [clientNext, setClientNext] = useState(false);
  const [taskUrl, setTaskUrl] = useState("");
  const [retrieveTaskData, setRetrieveTaskData] = useState("");
  const [filterData, setfilterData] = useState([
    {
      uuid: userDetails?.employee?.salesforce_id,
      label:
        "Assigned To: " + userDetails?.first_name + " " + (userDetails?.last_name!==null?userDetails?.last_name:""),
      type: 1,
    },
  ]);
  const [showAssignedToDanger, setShowAssignedToDanger] = useState(false);
  const [showRelatedToDanger, setShowRelatedToDanger] = useState(false);
  const [clientData, setClientData] = useState(false);
  const dispatch = useDispatch();
  const [next, setNext] = useState(false);
  const [loader, setLoader] = useState(false);
  const [advisorTeamData, setadvisorTeamData] = useState([]);
  const [showCalender, setshowCalender] = useState(false);
  const [showCalenderFilter, setshowCalenderFilter] = useState(false);
  const [selectedDay, setSelectedDay] = useState(moment());
  const [selectedDayString, setSelectedDayString] = useState(
    moment().format("DD-MM-YYYY")
  );
  const [selectedDayRange, setSelectedDayRange] = useState({
    from: null,
    to: null,
  });
  const [selectedDaySingle, setSelectedDaySingle] = useState("");
  const [currentDateText, setcurrentDateText] = useState();
  const [showRangeCalender, setShowRangeCalender] = useState(false);
  const [updateDueDate, setUpdateDueDate] = useState(false);
  const [dropdownShown, setDropdownShown] = useState(false);
  const [dropDownAssignedto, setDropDownAssignedto] = useState("");
  const [dropdownRelatedShown, setDropdownRelatedShown] = useState(false);
  const [dropDownRelatedto, setDropDownRelatedto] = useState([]);
  const [dropdownUpdatedShown, setDropdownUpdatedShown] = useState(false);
  const [dropDownUpdatedAssignedto, setdropDownUpdatedAssignedto] =
    useState("");
  const [dropdownStatusShown, setDropdownStatusShown] = useState(false);
  const [ischecked, setIsChecked] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isActiveRealtedDD, setIsActiveRelatedDD] = useState(false);
  const [removed, setRemoved] = useState("");
  const [removedRelated, setRemovedRelated] = useState("");
  const [  disabledsave, setDisabledSave] = useState(false);
  const [  disabledSaveComment, setDisabledSaveComment] = useState(false);
  const [ messageRefreshTime, setMessageRefreshTime] = useState();
  const [ rotate, setRotate] = useState(0);
  // const [ taskDate, setTaskDate] = useState();
  const [ taskRefreshTime, setTaskRefreshTime] = useState();
  const [relatedState, setRelatedState] = useState({
    searchText: "",
    filterList: [],
  });
  const statusOptions = [
    { value: "In Progress", label: "In Progress" },
    { value: "Completed", label: "Completed" },
    { value: "Waiting on Someone Else", label: "Waiting on Someone Else" },
    { value: "Open", label: "Open" },
    { value: "Deferred", label: "Deferred" },
  ];

  const Notifyupdate = (type, mesage) => {
    notification[type]({
      message: mesage,
    });
  };

  const taskDate = useRef();
  const messageDate = useRef();

  const ref = React.createRef();

  const scroll_screen = () => {
    document.getElementsByClassName("modal-486")[0].scrollTo({top:document.getElementsByClassName("modal-486")[0].scrollHeight, behavior:"smooth"})
  };


  const onScroll = (e) => {
    const bottom =
    parseInt((e.target.scrollHeight - e.target.scrollTop-e.target.clientHeight)) <
    2;
    if (
      bottom === true &&
      next
    )
    {
      dispatch(dashboard.getTasks(next)).then((response) => {
        if (response.type === "GET_TASK_SUCCESS") {
          setTasksDataList([...tasksDataList, ...response.data.results]);
          setLoader(false);
          setNext(response.data.next);
        } else {
          setLoader(false);
        }
      });
    }
  };

  const scrollrelateddd = (e) => {
    const bottom =
    parseInt((e.target.scrollHeight - e.target.scrollTop-e.target.clientHeight)) <
    2;
    if (
      bottom === true &&
      clientNext
    )  {
      dispatch(dashboard.getHousehold(clientNext)).then((response) => {
        if (response.type === "GET_HOUSEHOLD_SUCCESS") {
          setClientData([...clientData, ...response.data.results]);
          setLoader(false);
          setClientNext(response.data.next);
          setRelatedState({
            ...relatedState,
            filterList: [...relatedState.filterList, ...response.data.results],
          });
        } else {
          setLoader(false);
        }
      });
    }
  };

  const Notify = (type, mesage, updatedstatus,status, task_uuid,val , undoFunc) => {
    notification[type]({
      message: mesage,
      description: (
        <a
          href="javascript:void(0)"
          onClick={() => {
            undoFunc(updatedstatus , status , task_uuid, val);
          }}
        >
          Undo
        </a>
      ),
    });
  };

  

  useEffect(() => {
    getTaskList("task?assigned_to=" + userDetails?.employee?.salesforce_id + "&refresh=1");
    setTimeout(() => {
      getRefreshTime();
    }, 1500);
    advisorydata();
  }, []);


  useEffect(() => {
    if (selectedDayRange.to !== null) {
      setcurrentDateText(
        moment(
          selectedDayRange.from.month +
            "/" +
            selectedDayRange.from.day +
            "/" +
            selectedDayRange.from.year
        ).format("MMM DD, yyyy") +
          " - " +
          moment(
            selectedDayRange.to.month +
              "/" +
              selectedDayRange.to.day +
              "/" +
              selectedDayRange.to.year
          ).format("MMM DD, yyyy")
      );
      setshowCalenderFilter(!showCalenderFilter);
    }
  }, [selectedDayRange]);

  function onChangeToggle(checked) {
    setShowRangeCalender(checked);
    if (checked === true) {
      setIsChecked(true);
      setSelectedDaySingle("");
    } else {
      setIsChecked(false);
      setSelectedDayRange({
        from: null,
        to: null,
      });
    }
  }

  function handleSelectedDay(value) {
    setSelectedDay(
      moment(value.month + "-" + value.day + "-" + value.year, "MM-DD-YYYY")
    );
    setSelectedDayString(
      ("0" + value.day).slice(-2) +
        "-" +
        ("0" + value.month).slice(-2) +
        "-" +
        value.year
    );
    setcurrentDateText(
      moment(value.month + "/" + value.day + "/" + value.year).format(
        "MMM DD, yyyy"
      )
    );
    setUpdateDueDate(true);
    setshowCalender(!showCalender);
    document.getElementById("update-section-calendar").className += " text-success"
  }

  function handleSelectedDaySingle(value) {
    setSelectedDaySingle({
      year: parseInt(value.year),
      month: parseInt(("0" + value.month).slice(-2)),
      day: parseInt(("0" + value.day).slice(-2)),
    });
    setcurrentDateText(
      moment(value.month + "/" + value.day + "/" + value.year).format(
        "MMM DD, yyyy"
      )
    );
    setUpdateDueDate(true);
    setshowCalenderFilter(!showCalenderFilter);
  }



  function undoFunc(statusupdatedvalue, status, task_uuid, val) {
    var payload = {status:status}
    updatetaskdata(payload, task_uuid,false, status, statusupdatedvalue, val);
  }

  function markascomplete(task_uuid, subject, status) {
    const payload = { status: "Completed" };
    updatetaskdata(payload, task_uuid);
    Notify(
      "success",
      `You've marked a task ''${subject} '', as Completed`,
      "Completed",
      status,
      task_uuid,
      subject,
      undoFunc
    );
  }

  function resetandclosefilter(reset) {
    if (!reset) {
      setFilterTaskShow(false);
    } 
    resetallvalues();
  }

  function showdivfunc(task_id) {
    dispatch(dashboard.getTasks('task/'+task_id)).then((response) => {
      setRetrieveTaskData([])
      if (response.type === "GET_TASK_SUCCESS") {
        // setTasksDataList([...tasksDataList, ...response.data.results]);
        // setLoader(false);
        // setNext(response.data.next);
        setShowDiv(true);
        setUuid(task_id);
        setcurrentDateText(response.data.due_date);
        setUpdateDueDate(false);
        const assigned_name =  ((response.data.assigned_name?.split(" ")[1]!==null && response.data.assigned_name?.split(" ")[1]!=="")?response.data.assigned_name?.split(" ")[1] + ", ":"") + " " + response.data.assigned_name?.split(" ")[0]
        setdropDownUpdatedAssignedto([assigned_name,response.data.profile_image]);
        setStatusUpdatedValue(response.data.status);
        setRetrieveTaskData(response.data)
        const month = moment().month(response.data.due_date.slice(0, 4)).format("M");
        if (response.data.due_date !== "Today" && response.data.due_date !== "Yesterday") {
          setSelectedDay(
            moment(
              ("0" + month).slice(-2) +
                "-" +
                ("0" + response.data.due_date.slice(4, 6).trim()).slice(-2) +
                "-" +
                response.data.due_date.slice(-4),
              "MM-DD-YYYY"
            )
          );
          setSelectedDayString(("0" + response.data.due_date.slice(4, 6).trim()).slice(-2));
        } else if (response.data.due_date.trim() === "Yesterday") {
          setSelectedDay(moment().subtract(1, "day"));
          setSelectedDayString(moment().subtract(1, "day").format("DD-MM-YYYY"));
        } else {
          setSelectedDay(moment());
          setSelectedDayString(moment().format("DD-MM-YYYY"));
        }
      } else {
        setLoader(false);
      }
    });
  }

  const handleFilterTaskShow = () => {
    filterData.length === 0 && (setCurrentValue("1"),
    setDropDownAssignedto([]),
    setRCurrentValue("1"),
    setDropDownRelatedto([]),
    resethouseholddropdown(),
    setSelected([]),
    setRelatedSelected([]),
    resetassignedtodropdown(),
    resethouseholddropdown(),
    setIsActive(false))
    setDropdownShown(false),
    setDropdownRelatedShown(false),
    setFilterTaskShow(true);
    setcurrentDateText("")
    selectedDaySingle && (
      setcurrentDateText(moment(selectedDaySingle.month + "/" + selectedDaySingle.day + "/" + selectedDaySingle.year).format(
        "MMM DD, yyyy"
      )))
    if (relatedState.searchText !== ""){
      dispatch(dashboard.getHousehold("household")).then(
        (response) => {
          if (response.type === "GET_HOUSEHOLD_SUCCESS") {
            setClientNext(response.data.next);
            setClientData(response.data.results)
            const allData = []
            const uuidList = []
            for (var i=0;i<=dropDownRelatedto.length-1;i++){
              uuidList.push(dropDownRelatedto[i].uuid)
            }

            for (var j=0;j<=response.data.results.length-1;j++){
              if (uuidList.includes(response.data.results[j].uuid)){
                response.data.results[j].isSelected = true
                allData.push(response.data.results[j])
              }
              else{
                allData.push(response.data.results[j])
              }
            }
            setRelatedState((s) => ({
              ...s,
              searchText: "",
              filterList: allData,
            }));
          }
        }
      );
    }
  };

  function handleSubmit(e, task_uuid, status) {
    e.preventDefault();
    const payload = {};
    const val = document.getElementById(task_uuid + "subject_input").value;
    if (updateDueDate) {
      var due_date = moment(currentDateText).format("MM/DD/YYYY");
      payload["due_date"] = due_date;
      Notifyupdate(
        "success",
        "You've updated the due date to a task ''" + val + "''"
      );
    }
    if (subject) {
      payload["subject"] = subject.trim()
      Notifyupdate(
        "success",
        "You've updated the subject to a task ''" + subject + "''"
      )}
    if (commentsupdatedValue !== false) {
      payload["comments"] = commentsupdatedValue.trim();
      Notifyupdate("success", "You've added a comment to a task ''" + val);
    }
    if (statusUpdatedValue !== status) {
      payload["status"] = statusUpdatedValue;
      Notify(
        "success",
        `You've marked a task ''${val} '', as ${statusUpdatedValue}`,
        statusUpdatedValue,
        status,
        task_uuid,
        val,
        undoFunc
      );
    }
    if (assignedtoupdatedValue) {
      payload["assigned_to"] = assignedtoupdatedValue;
      Notifyupdate(
        "success",
        "You've reassigned a task ''" + val + "'' to " + assignedtoupdatedname
      );
    }
    setLoader(true);
    updatetaskdata(payload, task_uuid, assignedtoupdatedValue);
  }

  function updatetaskdata(payload, task_uuid, assignedtoupdatedValue, status=false, statusUpdatedValue=false, val=false) {
    dispatch(dashboard.updatetask(`task/${task_uuid}`, payload)).then(
      (response) => {
        setLoader(false);
        if (response.type === "UPDATE_TASK_SUCCESS") {
          const newState = tasksDataList.map((obj) => {
            if (obj.uuid === task_uuid && !assignedtoupdatedValue) {
              
              // setPreviousValue(nextValue)
              return {
                ...obj,
                subject: response.data.subject,
                assigned_to: response.data.assigned_to,
                status: response.data.status,
                comments: response.data.comments,
                due_date: response.data.due_date,
                expire:response.data.expire,
                assigned_name: response.data.assigned_name,
              };
            }
            return obj;
          });
          if (status !== false){
            notification.destroy()
            Notify(
              "success",
              `You've marked a task ''${val} '', as ${status}`,
              status,
              statusUpdatedValue,
              task_uuid,
              val,
              undoFunc
            );
          }
          let updatedState = newState.filter((item)=> item.status !== "Completed")
          setTasksDataList(updatedState);
          if (assignedtoupdatedValue){
            if (taskUrl === ""){
              const task_url = "task?assigned_to=" + userDetails?.employee?.salesforce_id
              setTaskUrl(task_url)
              taskapiforfilter(task_url)
            }
            else{
              taskapiforfilter(taskUrl)
            }
            setAssignedtoupdatedValue(false)
          }
          setShowDiv(false), setUuid(task_uuid);
          setcurrentDateText("")
          setSubject(false)
          setUpdateDueDate(false)
          setCommentsupdatedValue(false)
        } else {
          console.log("error");
        }
      }
    );
  }

  function advisorydata() {
    dispatch(dashboard.getAdvisoryList()).then((response) => {
      if (response.type === "GET_ADVISORYLIST_SUCCESS") {
        setAdvisorListData(response.data);
        setadvisorTeamData(response.data);
      }
    });
  }

  function getTaskList(task_url) {
    setLoader(true);
    setTaskUrl(task_url)
    dispatch(dashboard.getTasks(task_url)).then((response) => {
      if (response.type === "GET_TASK_SUCCESS") {
        setTasksDataList([...tasksDataList, ...response.data.results]);
        setLoader(false);
        setNext(response.data.next);
      } else {
        setLoader(false);
      }
    });
    dispatch(dashboard.getHousehold("household")).then((response) => {
      if (response.type === "GET_HOUSEHOLD_SUCCESS") {
        setClientData(response.data.results);
        setClientNext(response.data.next);
        setRelatedState({ searchText: "", filterList: response.data.results });
      }
    });
  }

  function settimediffinstate(task_refresh_time, message_refresh_time){
    const today_date = moment(new Date())
    const task_refresh_date = moment(task_refresh_time)
    const message_refresh_date = moment(message_refresh_time)
    const task_diff = today_date.diff(task_refresh_date, 'days');
    const msg_diff = today_date.diff(message_refresh_date, 'days');
    if (task_refresh_time){
      if (task_diff > 0){
        setTaskRefreshTime("Last refresh " + task_diff + (task_diff===1?" day ago":" days ago"))
      }
      else{
        const task_duration = moment.duration(today_date.diff(task_refresh_date));
        const hours = parseInt(task_duration.asHours());
        hours>0?
          setTaskRefreshTime("Last refresh " + hours + (hours===1?" hour ago":" hours ago")):
          (setTaskRefreshTime("Last refresh " + (parseInt(task_duration.asMinutes())) + ((parseInt(task_duration.asMinutes()))<=1 ? " minute ago":" minutes ago")))
      }}
    else{
      setTaskRefreshTime("Yet not refreshed")
    }
    if (message_refresh_time){
      if (msg_diff > 0){
        setMessageRefreshTime("Last refresh " + msg_diff + (msg_diff===1?" day ago":" days ago"))
      }
      else{
        const duration = moment.duration(today_date.diff(message_refresh_date));
        const hours = parseInt(duration.asHours());
        hours>0?
        setMessageRefreshTime("Last refresh " +hours + (hours===1?" hour ago":" hours ago")):
        setMessageRefreshTime("Last refresh " +(parseInt(duration.asMinutes())) + ((parseInt(duration.asMinutes()))<=1?" minute ago":" minutes ago"))
      }}
    else{
      setMessageRefreshTime("Yet not refreshed")
    }
  }

  function getRefreshTime() {
    setLoader(true);
    dispatch(dashboard.getRefreshTime()).then((response) => {
      if (response.type === "GET_REFRESHTIME_SUCCESS") {
        messageDate.current = response.data[0]?.message_refresh_time
        taskDate.current = response.data[0]?.task_refresh_time
        settimediffinstate(response.data[0]?.task_refresh_time,response.data[0]?.message_refresh_time)
        setLoader(false);
      } else {
        setLoader(false);
      }
    });
  }

  function getClients(filterarr) {
    dispatch(dashboard.getmyclient()).then((response) => {
      if (response.type === "GET_MYCLIENT_SUCCESS") {
        {
          filterarr.push({
            uuid: userDetails?.uuid,
            label: "Related To: " + userDetails?.first_name +
            " " +
            (userDetails?.last_name !== null? userDetails?.last_name:""),
            type: 2,
          });
        }
        setLoader(false);
      } else {
        setLoader(false);
      }
    });
  }

  function filterapply(refresh=0) {
    var filterarr = [];
    setLoader(true);
    var assigned_to = "";
    var related_to = "";
    if (filterData.length === 0 && refresh){
      filterarr = []
    }
    else if (currentValue === "2") {
      assigned_to = userDetails?.employee?.salesforce_id;
      filterarr.push({
        uuid: userDetails?.employee?.salesforce_id,
        label:
          "Assigned To: " +
          userDetails?.first_name +
          " " +
          (userDetails?.last_name !== null? userDetails?.last_name:"") ,
        type: 1,
      });
    }
    if (rCurrentValue === "2") {
      related_to = 2;
      getClients(filterarr);
    }
    setTimeout(() => {
      if (
        (currentValue === "3" && selected.length === 0 && !refresh) ||
        (rCurrentValue === "3" && relatedSelected.length === 0 && !refresh)
      ) {
        if (currentValue === "3" && selected.length === 0) {
          setShowAssignedToDanger(true);
          setShowRelatedToDanger(false);
        } else {
          setShowAssignedToDanger(false);
          setShowRelatedToDanger(true);
        }
        setLoader(false);
      } else {
        setShowAssignedToDanger(false);
        setShowRelatedToDanger(false);
        setLoader(true);
        {
          selected?.map((select) => {
            filterarr.push({
              uuid: select.value,
              label: "Assigned To: " + select.label,
              type: 1,
            });
            if (assigned_to === "") {
              assigned_to = select.value;
            } else {
              assigned_to += "," + select.value;
            }
          });
        }
        {
          relatedSelected?.map((select) => {
            filterarr.push({
              uuid: select.value,
              label: "Related To: " + select.label,
              type: 2,
            });
            if (related_to === "") {
              related_to = select.value;
            } else {
              related_to += "," + select.value;
            }
          });
        }
  
        if (selectedDayRange.from !== null && selectedDayRange.from !== undefined) {
          var start_date =
            selectedDayRange.from.year +
            "-" +
            selectedDayRange.from.month +
            "-" +
            selectedDayRange.from.day;
          var end_date =
            selectedDayRange.to.year +
            "-" +
            selectedDayRange.to.month +
            "-" +
            selectedDayRange.to.day;
          filterarr.push({
            uuid: 1,
            label: "Due Date: " + currentDateText,
            type: "range",
            start_date: start_date,
            end_date: end_date,
          });
        }
        if (selectedDaySingle && currentDateText !== undefined) {
          start_date = moment(currentDateText).format("YYYY-MM-DD");
          end_date = moment(currentDateText).format("YYYY-MM-DD");
          filterarr.push({
            uuid: 1,
            label: "Due Date: " + currentDateText,
            type: "single",
            start_date: start_date,
            end_date: end_date,
          });
        }
        setfilterData(filterarr);
        if (currentDateText && currentDateText !== undefined && start_date !== undefined) {
          var task_url =
            "task?assigned_to=" +
            assigned_to +
            "&related_to=" +
            related_to +
            "&start_date=" +
            start_date +
            "&end_date=" +
            end_date +
            "&refresh=" +
            refresh
            ;
        } 
        else{
          task_url =
            "task?assigned_to=" + assigned_to + "&related_to=" + related_to + "&refresh=" +
            refresh;
        }
        taskapiforfilter(task_url)
        setFilterTaskShow(false);
      }
    }, 1000);
  }

  function taskapiforfilter(task_url){
    setTasksDataList([])
    setTaskUrl(task_url)
    dispatch(dashboard.getTasks(task_url)).then((response) => {
      if (response.type === "GET_TASK_SUCCESS") {
        setTasksDataList(response.data.results);
        setLoader(false);
        setNext(response.data.next);
      } else {
        setLoader(false);
      }
    });
  }

  function resetallvalues() {
    setRelatedSelected([]);
    setSelected([]);
    setSelectedDayRange({
      from: null,
      to: null,
    });
    setDropdownShown(false);
    setDropDownAssignedto("");
    setDropDownRelatedto([]);
    setRCurrentValue("1");
    setCurrentValue("2");
    setDropdownRelatedShown(false);
    setDueDate({
      from: null,
      to: null,
    });
    setIsActive(false);
    setIsActiveRelatedDD(false);
    setshowCalenderFilter(false);
    setSelectedDaySingle("");
    setcurrentDateText("");
    resetassignedtodropdown();
    resethouseholddropdown();
    setShowAssignedToDanger(false);
    setShowRelatedToDanger(false);
  }

  function resetassignedtodropdown(){
    const newState = advisorTeamData?.map(obj => {
      if (obj.isSelected === true) {
        return {...obj, isSelected: false};
      }
      return obj;
    })
    setadvisorTeamData(newState);
  }

  function resethouseholddropdown(){
    const newStateClient = relatedState?.filterList.map(clientobj => {
      if (clientobj.isSelected === true) {
        return {...clientobj, isSelected: false};
      }
      return clientobj;
    })
    setRelatedState({
      ...relatedState,
      filterList: newStateClient,
    });
  }

  function removefilter(uuid,type) {
    var assigned_to = "";
    var related_to = "";
    var start_date = "";
    var end_date = "";
    const filtered = filterData.filter((filter) => {
      return filter.uuid !== uuid;
    });
    if (type === 1) {
      const newState = advisorTeamData.map(obj => {
        if (obj.user_id === uuid) {
          return { ...obj, isSelected: false };
        }
        return obj;
      })
      setadvisorTeamData(newState);
      if (dropDownAssignedto) {
        const newfilteredvalues = dropDownAssignedto?.filter((filter) => {
          return filter.uuid !== uuid;
        });
        setDropDownAssignedto(newfilteredvalues);
      }
      const newselected = selected?.filter((filter) => {
        return filter.value !== uuid;
      });
      setSelected(newselected)
    }
    else if (type === 2) {
      const newRelatedState = relatedState?.filterList?.map(obj => {
        if (obj.uuid === uuid) {
          return { ...obj, isSelected: false };
        }
        return obj;
      })
      setRelatedState({
        ...relatedState,
        filterList: newRelatedState,
      });
      if (dropDownRelatedto) {
        const newRelatedfilteredvalues = dropDownRelatedto?.filter((filter) => {
          return filter.uuid !== uuid;
        });
        setDropDownRelatedto(newRelatedfilteredvalues);
      }
      const newRelatedselected = relatedSelected?.filter((filter) => {
        return filter.value !== uuid;
      });
      setRelatedSelected(newRelatedselected)
    }
    else if (uuid === 1) {
      setcurrentDateText("")
      setSelectedDayRange({
        from: null,
        to: null,
      })
      setSelectedDaySingle("")
      setIsChecked(false)
      setShowRangeCalender(false)
    }
    setfilterData(filtered);
    filtered.map((data) => {
      if (data.type === 1) {
        assigned_to === ""
          ? (assigned_to = data.uuid)
          : (assigned_to += "," + data.uuid);
      } else if (data.type === 2 && data.uuid !== userDetails?.uuid) {
        related_to === ""
          ? (related_to = data.uuid)
          : (related_to += "," + data.uuid);
      }
      else if (data.type === 2 && data.uuid === userDetails?.uuid){
        related_to = '2'
      }
      else if (data.uuid === 1) {
        start_date = data.start_date;
        end_date = data.end_date;
      }
    });
    const task_url =
      "task?assigned_to=" +
      assigned_to +
      "&related_to=" +
      related_to +
      "&start_date=" +
      start_date +
      "&end_date=" +
      end_date;
    taskapiforfilter(task_url)
    setFilterTaskShow(false);
  }

  var timerId;
  clearInterval(timerId);

  timerId = setInterval(() => {
    settimediffinstate(taskDate.current, messageDate.current)
  }, 60000);

  // Calculate card body height based on card height - card header height
  const cardHeaderHeightRef = useRef(null);
  const cardHeightRef = useRef(null);
  const cardBodyHeightRef = useRef(null);
  useEffect(() => {
    if (cardHeightRef.current === null || cardHeaderHeightRef.current === null || cardBodyHeightRef.current === null) return;
    const cardHeight = cardHeightRef.current.clientHeight;
    const cardHeaderHeight = cardHeaderHeightRef.current.clientHeight;
    cardBodyHeightRef.current.style.height = cardHeight - cardHeaderHeight + "px";
  }, []);

  

  return (
    <div className="advisor-page" >
      <div
        className="heading-wrap d-flex align-items-center 
                      justify-content-between"
      >
        <div className="d-flex align-items-center">
          <span className="head-icon me-16">
            <em className="icon-home-filled"></em>
          </span>
          <div className="position-relative">
            <div className="d-flex align-items-center">
              <h4 className="m-0">Home</h4>
            </div>
          </div>
        </div>
        <button
          className="btn btn-outline-secondary btn-icon-only d-md-none"
          type="button"
          onClick={(e) => {
            e.preventDefault();
            handleFilterTaskShow();
          }}
        >
          <em className="icon-adjustments-outline"></em>
        </button>
      </div>
      <div className="content-area">
        <div className="advisor-block h-100">
          <div className="row gx-0 gx-md-24 h-100">
            {/* <div className="col-xl-8 advisor-vh-height"> */}
            <div className="col-xl-8 h-100">
              <div className="advisor-left h-100">
                <div className="card h-100" ref={cardHeightRef}> 
                  <div className="card-header" ref={cardHeaderHeightRef}>
                    <div className="d-flex">
                      <h4 className="mb-12">Tasks</h4>
                      <Tooltip placement="bottomLeft" title={taskRefreshTime}>
                        {/* <button className="btn btn-outline-secondary "
                        type="button" >Refresh</button> */}
                        <span className="icon-24 cursor-pointer" onClick={()=>{setRotate(1),setShowDiv(false),filterapply(1),
                        setTimeout(() => {
                          getRefreshTime(),
                          setRotate(0)
                        }, 1500); }}>
                          {rotate?
                          <em className="icon-refresh-outline rotate down"></em>:
                          <em className="icon-refresh-outline"></em>}
                        </span>
                      </Tooltip>
                    </div>
                    <div className="filter-row d-flex justify-content-between">
                      <div className="d-flex align-items-start">
                        <span
                          className="filt-by-client text-success fw-500 
                                      cursor-pointer align-items-center 
                                      d-none d-md-flex text-nowrap"
                          onClick={(e) => {
                            e.preventDefault();
                            handleFilterTaskShow();
                          }}
                        >
                          <span className="me-8">
                            <em className="icon-adjustments-outline"></em>
                          </span>
                          Filter By
                        </span>
                        <div
                          className="ms-md-16 d-flex align-items-center 
                                        flex-wrap task-filt"
                        >
                          {filterData?.map((filter, index) => (
                            <span key={filter.uuid} className="filt-badge">
                              {filter.label}
                              <em
                                className="icon-x-outline cursor-pointer"
                                onClick={() => removefilter(filter.uuid, filter.type)}
                              ></em>
                            </span>
                          ))}
                        </div>
                      </div>
                      {(filterData.length === 0 ||
                        (filterData[0].uuid !== userDetails?.employee?.salesforce_id ||
                          filterData.length > 1)) && (
                        <a href="" className="fw-500 text-nowrap">
                          Clear Filter
                        </a>
                      )}
                    </div>
                  </div>
                  <div className="card-body ps-0 pe-4 pt-0" ref={cardBodyHeightRef}>
                    {tasksDataList?.length !== 0 && (
                      <div
                        className="priority-block custom-style-scroll"
                        onScroll={onScroll}
                      >
                        {tasksDataList?.map((taskdata, index) => (
                          <div className="priority-list" key={taskdata.uuid}>
                            <div className="prty-item-card">
                              <div className="prty-item">
                                <div
                                  className="prty-caption cursor-pointer"
                                  onClick={() => {
                                    showdivfunc(
                                      taskdata.uuid
                                    );
                                  }}
                                >
                                  <h6>{taskdata.subject}</h6>
                                  <span className="d-block lh-20 text-muted">
                                    {taskdata.related_name}
                                  </span>
                                </div>
                                <div className="prty-status">
                                  {taskdata.expire &&
                                    taskdata.due_date !== "Yesterday" && (
                                    <span className="badge badge-outline-danger">
                                      {taskdata.due_date}
                                    </span>
                                  )}
                                  {taskdata.due_date === "Yesterday" && (
                                    <span className="badge badge-outline-danger">
                                      {taskdata.due_date}
                                    </span>
                                  )}
                                  {!taskdata.expire &&
                                    taskdata.due_date === "Today" && (
                                    <span className="badge badge-outline-success">
                                      {taskdata.due_date}
                                    </span>
                                  )}
                                  {!taskdata.expire &&
                                    taskdata.due_date !== "Today" && (
                                    <span className="badge badge-outline-secondary">
                                      {taskdata.due_date}
                                    </span>
                                  )}
                                  {taskdata.profile_image !== "" ? (
                                    <figure className="user-photo">
                                      <img
                                        src={taskdata.profile_image}
                                        alt=""
                                      />
                                    </figure>
                                  ) : (
                                    <figure className="user-photo">
                                      <Avatar
                                        color="#d3d6de"
                                        name={taskdata.assigned_name}
                                        size={32}
                                      />
                                    </figure>
                                  )}
                                </div>
                                <div className="prty-btn">
                                  {taskdata?.status?.toLowerCase() ===
                                    "in progress" ||
                                  taskdata?.status?.toLowerCase() === "open" ? (
                                    <a
                                      className="btn btn-outline-secondary d-none d-md-block"
                                      onClick={() =>
                                        markascomplete(
                                          taskdata.uuid,
                                          taskdata.subject,
                                          taskdata.status
                                        )
                                      }
                                    >
                                      Mark as Complete
                                    </a>
                                  ) : (
                                    <div className="d-none d-md-block">
                                      {taskdata.status}
                                    </div>
                                  )}

                                  <Dropdown
                                    trigger={["click"]}
                                    placement="bottomRight"
                                    overlay={
                                      <Menu>
                                        <Menu.Item
                                          key="1"
                                          onClick={(e) => {
                                            showdivfunc(
                                              taskdata.uuid
                                            );
                                          }}
                                        >
                                          View Details
                                        </Menu.Item>
                                        {(taskdata?.status?.toLowerCase() ===
                                          "in progress" ||
                                          taskdata?.status?.toLowerCase() ===
                                            "open") && (
                                          <Menu.Item
                                            key="2"
                                            onClick={(e) => {
                                              markascomplete(
                                                taskdata.uuid,
                                                taskdata.subject,
                                                taskdata.status
                                              );
                                            }}
                                          >
                                            Mark as Complete
                                          </Menu.Item>
                                        )}
                                      </Menu>
                                    }
                                  >
                                    <span className="btn btn-outline-secondary btn-icon-only d-md-none icon-36 ms-auto">
                                      <em className="icon-dots-horizontal-outline"></em>
                                    </span>
                                  </Dropdown>
                                </div>
                              </div>
                              {showDiv && taskdata.uuid === uuid && (
                                <div className="">
                                  {/* <div className="p-24 d-md-none border-bottom">
                                    <div className="row gx-8">
                                      <div className="col-4">
                                        <span className="cursor-pointer">
                                          Cancel
                                        </span>
                                      </div>
                                      <div className="col-4">
                                        <div className="text-center fw-500">
                                          Task Details
                                        </div>
                                      </div>
                                      <div className="col-4 d-flex justify-content-end">
                                        <a href="" className="fw-500">
                                          Save
                                        </a>
                                      </div>
                                    </div>
                                  </div> */}
                                  <div className="card">
                                    <div className="card-body">
                                      <form
                                        action=""
                                        onSubmit={(e) =>
                                          handleSubmit(
                                            e,
                                            retrieveTaskData.uuid,
                                            retrieveTaskData.status
                                          )
                                        }
                                      >
                                        <div className="mb-16">
                                          <label className="form-label">
                                            Subject
                                            <sup>*</sup>
                                          </label>
                                          <input
                                            id={retrieveTaskData.uuid + "subject_input"}
                                            type="text"
                                            className="form-control fw-500"
                                            defaultValue={retrieveTaskData.subject}
                                            onChange={(e) =>
                                            {setSubject(e.target.value),
                                              e.target.value.trim() === "" ?
                                              setDisabledSave(true):
                                              setDisabledSave(false)
                                            }
                                            }
                                          />
                                        </div>
                                        <div className="row gx-16">
                                          <div className="col-md-6">
                                            <div className="mb-16">
                                              <label className="form-label text-AAB1B7">
                                                Related To
                                              </label>
                                              <input
                                                type="text"
                                                className="form-control text-AAB1B7"
                                                defaultValue={
                                                  retrieveTaskData.related_name
                                                }
                                                readOnly
                                              />
                                            </div>
                                          </div>
                                          <div className="col-md-6">
                                            <div className="mb-16">
                                              <label className="form-label">
                                                Assigned To
                                                <sup>*</sup>
                                              </label>
                                              
                                              <div className="dropdown-img">
                                                {/* <input
                                                  type="text"
                                                  className="form-control"
                                                  name="team-members"
                                                  value={dropDownUpdatedAssignedto}
                                                /> */}
                                                <div
                                                  className="form-select d-flex align-items-center cursor-pointer"
                                                  onClick={(e) => {
                                                    dropdownUpdatedShown
                                                      ? setDropdownUpdatedShown(
                                                        false
                                                      )
                                                      : setDropdownUpdatedShown(
                                                        true
                                                      );
                                                  }}
                                                >
                                                  {/* <span className="badge badge-outline-secondary cursor pointer"> */}
                                                  <div className="user-photo icon-32 me-8">
                                                    {dropDownUpdatedAssignedto[1] !==
                                                      "" &&
                                                    dropDownUpdatedAssignedto[1] !==
                                                      null ? (
                                                      <img
                                                        src={
                                                          dropDownUpdatedAssignedto[1]
                                                        }
                                                        alt=""
                                                      />
                                                    ) : (
                                                      <Avatar
                                                        name={
                                                          ((dropDownUpdatedAssignedto[0]?.split(", ")[1] !== undefined) ? (dropDownUpdatedAssignedto[0]?.split(", ")[1]): ("")) + " " + dropDownUpdatedAssignedto[0]?.split(", ")[0]
                                                        }
                                                        color="#d3d6de"
                                                        size={32}
                                                      />
                                                    )}
                                                  </div>
                                                  <label>
                                                    {
                                                      dropDownUpdatedAssignedto[0]
                                                    }
                                                  </label>
                                                  {/* </span> */}
                                                  <span className="icon-32 select-arrow">
                                                    {/* <em
                                                  className={
                                                    dropdownStatusShown
                                                      ? "icon-chevron-up-outline"
                                                      : "icon-chevron-down-outline"
                                                  } style={{marginLeft:'416px'}} 
                                                 
                                                ></em> */}
                                                  </span>
                                                  {/* </div> */}
                                                </div>
                                                {/* <em
                                                  className={
                                                    dropdownUpdatedShown
                                                      ? "icon-chevron-up-outline"
                                                      : "icon-chevron-down-outline"
                                                  }
                                                  onClick={(e) => {
                                                    dropdownUpdatedShown
                                                      ? setDropdownUpdatedShown(false)
                                                      : setDropdownUpdatedShown(true);
                                                  }}
                                                ></em> */}
                                                {dropdownUpdatedShown && (
                                                  <div className="dropdown-content">
                                                    <ul className="check-list list-unstyled custom-style-scroll">
                                                      {advisorListData &&
                                                        advisorListData?.map(
                                                          (option, index) => (
                                                            <li
                                                              className="px-16"
                                                              key={option.user_id}
                                                              onClick={(e) => {
                                                                setAssignedtoupdatedValue(
                                                                  option?.user_id
                                                                ),
                                                                setdropDownUpdatedAssignedto(
                                                                  [
                                                                    ((option?.last_name !== null)? (option?.last_name + ", "):("")) +
                                                                      " " +
                                                                      option?.first_name,
                                                                    option?.profile_image,
                                                                  ]
                                                                ),
                                                                setDropdownUpdatedShown(
                                                                  false
                                                                ),
                                                                setAssignedtoupdatedname(
                                                                  ((option?.last_name !== null)? (option?.last_name + ", "):("")) +
                                                                    " " +
                                                                    option?.first_name
                                                                );
                                                              }}
                                                            >
                                                              <div className="cursor-pointer d-flex">
                                                                {/* <div className="user-photo icon-32 mx-8"> */}

                                                                <div className="user-photo icon-32 mx-8">
                                                                  {option.profile_image !==
                                                                    "" &&
                                                                  option.profile_image !==
                                                                    null ? (
                                                                    <img
                                                                      src={
                                                                        option?.profile_image
                                                                      }
                                                                      alt=""
                                                                    />
                                                                  ) : (
                                                                    <Avatar
                                                                      color="#d3d6de"
                                                                      name={
                                                                        option?.first_name +
                                                                        " " +
                                                                        ((option?.last_name !== null)? (option?.last_name):(""))
                                                                      }
                                                                      size={32}
                                                                    />
                                                                  )}
                                                                </div>
                                                                {/* </div> */}
                                                                <label
                                                                  className="form-check-label cursor-pointer"
                                                                  htmlFor="filtclient2"
                                                                  key={
                                                                    option?.uuid
                                                                  }
                                                                >
                                                                  {((option?.last_name !== null)? (option?.last_name + ", "):("")) +
                                                                    " " +
                                                                    option?.first_name}
                                                                </label>
                                                              </div>
                                                            </li>
                                                          )
                                                        )}
                                                    </ul>
                                                  </div>
                                                )}
                                              </div>

                                              {/* <select
                                                className="form-select opacity-50"
                                                defaultValue={
                                                  taskdata.assigned_to
                                                }
                                                onChange={(e) =>
                                                {setAssignedtoupdatedValue(e.target.value), setAssignedtoupdatedname(e.target.options[e.target.selectedIndex].text)}
                                                }
                                              >
                                                {advisorListData &&
                                                  advisorListData?.map(
                                                    (option) => (
                                                      <option
                                                        key={option.value}
                                                        value={option.value}
                                                      >
                                                        {option.label}
                                                      </option>
                                                    )
                                                  )}
                                              </select> */}
                                            </div>
                                          </div>
                                          <div className="col-md-6">
                                            <div className="mb-16">
                                              <label className="form-label">
                                                Status
                                                <sup>*</sup>
                                              </label>
                                              
                                              <div className="position-relative">
                                                {/* <input
                                                  type="text"
                                                  className="form-control"
                                                  name="team-members"
                                                  value={statusUpdatedValue}
                                                /> */}

                                                <div
                                                  className="form-select d-flex align-items-center"
                                                  onClick={(e) => {
                                                    dropdownStatusShown
                                                      ? setDropdownStatusShown(
                                                        false
                                                      )
                                                      : setDropdownStatusShown(
                                                        true
                                                      );
                                                  }}
                                                >
                                                  <span className={statusUpdatedValue ===
                                                        "Completed"
                                                          ? "badge badge-outline-secondary cursor pointer text-success"
                                                          : "badge badge-outline-secondary cursor pointer"}>
                                                    <em
                                                      className={
                                                        statusUpdatedValue ===
                                                        "Completed"
                                                          ? "icon icon-check-circle-filled text-success me-4 fs-12"
                                                          : "icon-clock-outline me-4"
                                                      }
                                                    ></em>
                                                    {statusUpdatedValue}
                                                  </span>
                                                  {/* <span className="icon-24 select-arrow">
                                                    <em
                                                      className={
                                                        dropdownStatusShown
                                                          ? "icon-chevron-up-outline"
                                                          : "icon-chevron-down-outline"
                                                      }
                                                    ></em>
                                                  </span> */}
                                                </div>
                                                {dropdownStatusShown && (
                                                  <div className="dropdown-content">
                                                    <ul className="check-list list-unstyled custom-style-scroll">
                                                      {statusOptions &&
                                                        statusOptions?.map(
                                                          (option, index) => (
                                                            <li
                                                              key={index}
                                                              onClick={(e) => {
                                                                setStatusUpdatedValue(
                                                                  option?.value
                                                                ),
                                                                setDropdownStatusShown(
                                                                  false
                                                                );
                                                              }}
                                                            >
                                                              <div className="cursor-pointer d-flex">
                                                                <span className={
                                                                  option?.label ===
                                                                  "Completed"
                                                                    ? "badge badge-outline-success"
                                                                    : "badge badge-outline-secondary"
                                                                }>
                                                                  <em
                                                                    className={
                                                                    option?.label ===
                                                                    "Completed"
                                                                      ? "icon icon-check-circle-filled text-success me-4 fs-12"
                                                                      : "icon-clock-outline me-4"
                                                                    }
                                                                  ></em>
                                                                  {
                                                                    option?.label
                                                                  }
                                                                </span>
                                                              </div>
                                                            </li>
                                                          )
                                                        )}
                                                    </ul>
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          </div>

                                          <div className="col-md-6">
                                            <div className="mb-16">
                                            
                                              <label className="form-label">
                                                Due Date
                                                <sup>*</sup>
                                              </label>
                                              <div className="password-field">
                                                <div
                                                  onClick={(e) => {
                                                    setshowCalender(
                                                      !showCalender
                                                    );
                                                  }}
                                                >
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    name="due_date"
                                                    value={currentDateText}
                                                    onChange={() =>
                                                      console.log(
                                                        currentDateText
                                                      )
                                                    }
                                                  />
                                                  <em
                                                    className="icon-calendar-filled"
                                                    id="update-section-calendar"
                                                  ></em>
                                                </div>
                                                {showCalender && (
                                                  <div className="dropdown-content-calendar task-calendar-drop">
                                                    <div className="d-flex align-items-center">
                                                      <Calendar
                                                        value={{
                                                          year: parseInt(
                                                            selectedDay.format(
                                                              "yyyy"
                                                            )
                                                          ),
                                                          month: parseInt(
                                                            selectedDay.format(
                                                              "MM"
                                                            )
                                                          ),
                                                          day: parseInt(
                                                            selectedDayString.substring(
                                                              0,
                                                              2
                                                            )
                                                          ),
                                                        }}
                                                        onChange={(e) => {
                                                          handleSelectedDay(e);
                                                        }}
                                                        locale={
                                                          DashboardCalendarLocale
                                                        }
                                                        minimumDate={utils().getToday()}
                                                        calendarSelectedDayClassName="bg-success text-white adjust-calendar-inside-text"
                                                      />
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="mb-16">
                                          <label className="form-label">
                                            Comments
                                          </label>
                                          <textarea
                                            name=""
                                            id=""
                                            rows="10"
                                            className="form-control textarea-88 resize-none"
                                            defaultValue={retrieveTaskData.comments}
                                            onChange={(e) =>
                                            {setCommentsupdatedValue(
                                              e.target.value
                                            ),
                                              e.target.value.length >= 1 && e.target.value.trim() === "" ?
                                              setDisabledSaveComment(true):
                                              setDisabledSaveComment(false)}
                                            }
                                          ></textarea>
                                        </div>
                                        <div className="d-md-flex justify-content-end">
                                          <button
                                            type="button"
                                            className="btn btn-outline-secondary"
                                            onClick={() => {
                                              setShowDiv(false),
                                              setUuid(retrieveTaskData.uuid);
                                              setcurrentDateText("")
                                            }}
                                          >
                                            Cancel
                                          </button>
                                          {(disabledsave || disabledSaveComment) ? (
                                          <button disabled={true}
                                            type="submit"
                                            className="btn btn-success ms-16"
                                          >
                                            Save
                                          </button>):(
                                          <button
                                            type="submit"
                                            className="btn btn-success ms-16"
                                          >
                                           Save
                                          </button>)
                                          }

                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                    {tasksDataList.length === 0 && (
                      <div className="task-noresult d-flex align-items-center justify-content-center text-center">
                        <div className="max-258">
                          <span className="task-nores-icon mb-16 mx-auto">
                            <em className="icon-search-outline"></em>
                          </span>
                          <p className="fs-18 lh-24 m-0">
                            No results found in your filtered view. Try using a
                            different set of filters.
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4">
              <MessageDashboard 
                    messageRefreshTime={messageRefreshTime}
                    getRefreshTime={getRefreshTime}/>
            </div>
            {/* <CalendarDashboard /> */}
          </div>
        </div>
      </div>
      <Modal
        className="modal-486 modal-full"
        show={showFilterTask}
        onHide={handleFilterTaskClose}
      >
        <Modal.Header closeButton className="border-bottom pb-md-24">
          <Modal.Title>Filter Tasks</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-24">
            <div>
              <label className="text-black fs-14 lh-20 fw-500 mb-16">
                Assigned To
              </label>
              <div className="form-check mb-16">
                <input
                  className="form-check-input"
                  type="radio"
                  name="Anyone"
                  id="Anyone"
                  value="1"
                  onChange={(e) => {
                    setCurrentValue(e.target.value),
                    setIsActive(false),
                    setDropDownAssignedto([]),
                    setSelected([]);
                    setDropdownShown(false);
                    resetassignedtodropdown();
                    resethouseholddropdown();
                  }}
                  checked={currentValue === "1"}
                />
                <label className="form-check-label" htmlFor="Anyone">
                  Anyone
                </label>
              </div>
              <div className="form-check mb-16">
                <input
                  className="form-check-input"
                  type="radio"
                  name="OnlyMe"
                  id="OnlyMe"
                  value="2"
                  checked={currentValue === "2"}
                  onChange={(e) => {
                    setCurrentValue(e.target.value),
                    setIsActive(false),
                    setDropDownAssignedto([]),
                    setSelected([]);
                    setDropdownShown(false);
                    resetassignedtodropdown();
                    resethouseholddropdown();
                  }}
                />
                <label className="form-check-label" htmlFor="OnlyMe">
                  Only Me (
                  {userDetails?.first_name + " " + userDetails?.last_name})
                </label>
              </div>
              <div className="form-check mb-16">
                <input
                  className="form-check-input"
                  type="radio"
                  name="SelectedMember"
                  id="SelectedMember"
                  value="3"
                  checked={currentValue === "3"}
                  onChange={(e) => {
                    setCurrentValue(e.target.value), setIsActive(true);
                  }}
                />
                <label
                  className="form-check-label fs-16 lh-20 fw-normal mb-16"
                  htmlFor="SelectedMember"
                >
                  Selected Team Members
                </label>
                <div className="position-relative">
                  <div
                    id="assgined_to_id"
                    className={
                      isActive
                        ? "dropdown-img cursor-pointer"
                        : "dropdown-img cursor-pointer disabled-div"
                    }
                    onClick={(e) => {
                      dropdownShown
                        ? setDropdownShown(false)
                        : setDropdownShown(true);
                      setDropdownRelatedShown(false);
                      setshowCalenderFilter(false);
                    }}
                  >
                    {dropDownAssignedto.length === 0 ? (
                      <div className="form-control">Select team members</div>
                    ) : (
                      <div className="form-control d-flex align-items-center">
                        {dropDownAssignedto &&
                          dropDownAssignedto.filter((item, index) => index <= 2).map((option,index) =>
                            index <= 1 ? (
                             
                              <React.Fragment key={index}>
                                <div className="user-photo icon-32 mx-8">
                                  {(option?.image !== "" && option?.image !== null)? (
                                      <img
                                        src={
                                          option?.image
                                        }
                                        alt=""
                                      />):(
                                      <Avatar
                                        name={(option?.name.split(", ")[1] !== undefined?option?.name.split(", ")[1]:"" )+ " " +option?.name.split(", ")[0] }
                                        color="#d3d6de"
                                        size={32}
                                      />)
                                  }
                                </div>
                                <label>{utility.ellipsify(option?.name, 15)}</label>
                              </React.Fragment>
                            ) : (
                              <label className="text-success ms-8" key={index}>
                                + {dropDownAssignedto.length-2}
                              </label>
                            )
                          )}
                      </div>
                    )}
                    <em
                      className={
                        dropdownShown
                          ? "icon-chevron-up-outline"
                          : "icon-chevron-down-outline"
                      }
                    ></em>
                  </div>
                  {dropdownShown && (
                    <FilterDropdown
                      dropdowndata={advisorTeamData}
                      setadvisorTeamData={setadvisorTeamData}
                      setSelected={setSelected}
                      selected={selected}
                      setDropDownAssignedto={setDropDownAssignedto}
                      dropDownAssignedto={dropDownAssignedto}
                      dropdownShown={dropdownShown}
                      setRemoved={setRemoved}
                    />
                  )}
                </div>
              </div>
            </div>
            <div>
              {/* <MultiSelect
                options={advisorTeamData}
                value={selected}
                onChange={setSelected}
                labelledBy="Select"
              /> */}
            </div>
            {showAssignedToDanger && dropDownAssignedto.length === 0 && (
              <div className="alert alert-danger d-flex align-items-center mt-16 mb-0">
                <em className="icon icon-exclamation-circle-filled text-danger lh-24"></em>
                <div className="flex-fill">
                  <h6 className="mb-0 text-danger">
                    Please select a team member to apply this filter.
                  </h6>
                </div>
              </div>
            )}
          </div>
          <div className="mb-24">
            <div>
              <label className="text-black fs-14 lh-20 fw-500 mb-16">
                Related To
              </label>
              <div className="form-check mb-16">
                <input
                  className="form-check-input"
                  type="radio"
                  name="rAnyone"
                  id="rAnyone"
                  value="1"
                  checked={rCurrentValue === "1"}
                  onChange={(e) => {
                    setRCurrentValue(e.target.value),
                    setIsActiveRelatedDD(false);
                    setDropDownRelatedto([]);
                    setRelatedSelected([]);
                    setDropdownRelatedShown(false);
                    resetassignedtodropdown();
                    resethouseholddropdown();
                  }}
                />
                <label className="form-check-label" htmlFor="rAnyone">
                  Anyone
                </label>
              </div>
              <div className="form-check mb-16">
                <input
                  className="form-check-input"
                  type="radio"
                  name="rOnlyMe"
                  id="rOnlyMe"
                  value="2"
                  checked={rCurrentValue === "2"}
                  onChange={(e) => {
                    setRCurrentValue(e.target.value),
                    setIsActiveRelatedDD(false);
                    setDropDownRelatedto([]);
                    setRelatedSelected([]);
                    setDropdownRelatedShown(false);
                    resetassignedtodropdown();
                    resethouseholddropdown();
                  }}
                />
                <label className="form-check-label" htmlFor="rOnlyMe">
                  Only My Clients
                </label>
              </div>
              <div className="form-check mb-16">
                <input
                  className="form-check-input"
                  type="radio"
                  name="SelectedClient"
                  id="SelectedClient"
                  value="3"
                  checked={rCurrentValue === "3"}
                  onChange={(e) => {
                    setRCurrentValue(e.target.value),
                    setIsActiveRelatedDD(true);
                  }}
                />
                <label
                  className="form-check-label fs-16 lh-20 fw-normal mb-16"
                  htmlFor="SelectedClient"
                >
                  Selected Clients
                </label>
                <div className="position-relative">
                  <div
                    className={
                      isActiveRealtedDD
                        ? "password-field cursor-pointer"
                        : "password-field cursor-pointer disabled-div"
                    }
                    onClick={(e) => {
                      dropdownRelatedShown
                        ? setDropdownRelatedShown(false)
                        : setDropdownRelatedShown(true);
                      setshowCalenderFilter(false);
                      setDropdownShown(false);
                    }}
                  >
                    <div className="form-control d-flex">
                      {dropDownRelatedto.length !== 0
                        ? dropDownRelatedto.filter((item,index) => index <= 2).map((option,index) =>
                            index <= 1 ? (
                              <label key={index} className="ms-8">
                                {utility.ellipsify(option.name, 20)}
                              </label>
                            ) : (
                              <label key={index} className="text-success ms-8">
                                 + {dropDownRelatedto.length-2}
                              </label>
                            )
                        )
                        : "Select household"}
                    </div>

                    <em
                      className={
                        dropdownRelatedShown
                          ? "icon-chevron-up-outline"
                          : "icon-chevron-down-outline"
                      }
                    ></em>
                  </div>
                  {dropdownRelatedShown && (
                    <FilterRelatedDropdown
                      dropdowndata={clientData}
                      setClientData={setClientData}
                      setRelatedSelected={setRelatedSelected}
                      relatedSelected={relatedSelected}
                      setDropDownRelatedto={setDropDownRelatedto}
                      dropDownRelatedto={dropDownRelatedto}
                      setRemovedRelated={setRemovedRelated}
                      setClientNext={setClientNext}
                      scrollrelateddd={scrollrelateddd}
                      setRelatedState={setRelatedState}
                      relatedstate={relatedState}
                    />
                  )}
                </div>
              </div>
            </div>
            <div>
              {/* <MultiSelect
                options={clientData}
                value={relatedSelected}
                onChange={setRelatedSelected}
                labelledBy="Select household"   
              /> */}
            </div>
            {showRelatedToDanger && dropDownRelatedto.length === 0 && (
              <div className="alert alert-danger d-flex align-items-center mt-16 mb-0">
                <em className="icon icon-exclamation-circle-filled text-danger lh-24"></em>
                <div className="flex-fill">
                  <h6 className="mb-0 text-danger">
                    Please select a household to apply this filter.
                  </h6>
                </div>
              </div>
            )}
          </div>
          <div>
            <label className="form-check-label">Due Date</label>
            <div className="password-field">
              <div
                onClick={(e) => {
                  setshowCalenderFilter(!showCalenderFilter);
                  setTimeout(() => {
                    scroll_screen()
                  }, 100);
                }}
              >
                <input
                  type="text"
                  className="form-control"
                  placeholder="Select due date"
                  name="due_date"
                  value={currentDateText}
                  onChange={() => console.log(currentDateText)}
                />
                {!currentDateText ? (
                  <em
                    className="icon-calendar-filled"
                    id="due-date-calendar"
                  ></em>
                ) : (
                  <em
                    className="icon-calendar-filled text-success"
                    id="due-date-calendar"
                  ></em>
                )}
              </div>
              {showCalenderFilter && (
                <div className="dropdown-content-calendar">
                  <div className="d-flex align-items-center pb-16 mb-16 border-bottom">
                    <h6 className="m-0">Date Range</h6>
                    <Switch
                      className="ms-8"
                      onChange={onChangeToggle}
                      checked={ischecked}
                    />
                  </div>
                  {showRangeCalender ? (
                    <Calendar
                      value={selectedDayRange}
                      onChange={(e) => setSelectedDayRange(e)}
                      minimumDate={utils().getToday()}
                      locale={DashboardCalendarLocale}
                      showNeighboringMonth={true}
                      calendarSelectedDayClassName="bg-success text-white adjust-calendar-inside-text"
                    />
                  ) : (
                    <Calendar
                      value={selectedDaySingle}
                      onChange={(e) => {
                        handleSelectedDaySingle(e);
                      }}
                      locale={DashboardCalendarLocale}
                      minimumDate={utils().getToday()}
                      showPreviousMonth={true}
                      calendarSelectedDayClassName="bg-success text-white adjust-calendar-inside-text"
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-between border-top pt-24 flex-md-row-reverse">
          <div className="d-md-flex align-items-center">
            <button
              className="btn btn-outline-secondary d-none d-md-block"
              onClick={() => setFilterTaskShow(false)}
            >
              Cancel
            </button>
            <button
              className="btn btn-success mt-0"
              onClick={(e) => {
                e.preventDefault();
                filterapply();
              }}
            >
              Apply
            </button>
          </div>
          <div className="text-center pt-16 pt-md-0 text-md-start">
            <a 
              href="#"
              className={`fw-500 ${
                (currentValue === '2' && 
                 rCurrentValue === '1'  &&
                 !currentDateText)
                    ? "text-AAB1B7"
                    : ""
                }`}
              onClick={() => resetandclosefilter(true)}
            >
              Reset
            </a>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TaskDashboard;
