import React, { useEffect, useState } from "react";
import {notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link, useLocation } from "react-router-dom";
import { userConstants } from "../../redux/constants/user.constant";
import { auth } from "../../redux/actions/Auth/Auth";
import Loading from "../../components/Loader";
import { useReadOTP } from "react-read-otp";
import ReactCodeInput from "react-verification-code-input";
const ForgotPasswordCode = () => {
  const [verificationCode, setVerificationCode] = useState("");
  const [loader, setLoader] = useState(false);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const validCode = useSelector((state) => state.ValidForgotPasswordOTP);
  const [onSubmit, setOnSubmit] = useState(false);
  const [isCodeInvalid, setIsCodeInvalid] = useState(false);
  useEffect(() => {
    setEmail(localStorage.getItem("Email"));
  }, []);
  useReadOTP(setVerificationCode);
  const Notify = (type, mesage, icons) => {
    notification[type]({
      description: mesage,
      icon: icons,
      top: 65,
    });
  };
  function handleSubmit(e) {
    setLoader(true);
    e.preventDefault();
    setOnSubmit(true);
    let param = { email: email, otp: verificationCode };
    if (!verificationCode) {
      setLoader(false);

      return;
    }
    //setNext(true);
    dispatch(auth.validForgotPasswordCode(param)).then((response) => {
      setLoader(false);
      if (response.type === "FORGOT_OTP_VALID_SUCCESS") {
        localStorage.setItem("reset-token", JSON.stringify(response.data));
        navigate("/reset-forgot-password");
      } else {
        setIsCodeInvalid(true);
        //navigate("/login-password")
      }
    });
  }

  function reSendOTP(e) {
    setLoader(true);
    e.preventDefault();
    let param = { email: email };
    //setNext(true);
    dispatch(auth.forgotPasswordCode(param)).then((user) => {
      setLoader(false);
      if (user.type === "FORGOT_OTP_RECEIVED_SUCCESS") {
        Notify(
          "success",
          `One-Time Passcode (OTP) resent successfully.`
          // <em className="icon-folder-download-filled"></em>
        );
      } else {
      }
    });
  }

  return (
    <>
      {loader && <Loading active={loader} />}
      <form name="form" onSubmit={handleSubmit}>
        <div
          className={`form-group ${
            onSubmit && !verificationCode ? "has-error" : ""
          }  `}
        >
          <label className="form-label">
            Verification Code <sup>*</sup>
          </label>
          <ReactCodeInput
            values={[...verificationCode]}
            fields={8}
            onChange={(newValue) => {
              setVerificationCode(newValue);
              setOnSubmit(false);
              setIsCodeInvalid(false);
            }}
          />
          {onSubmit && !verificationCode && (
            <div className="invalid-feedback d-block">
              Verification code is required
            </div>
          )}
          {onSubmit && verificationCode && isCodeInvalid && (
            <div className="invalid-feedback d-block">
              {validCode?.error?.otp[0]}
            </div>
          )}
        </div>
        <div className="mb-16">
          <button className="btn btn-success w-100">Verify Code</button>
        </div>
        <div className="text-center mb-8">
          <Link
            to="/forgot-password-code"
            className="btn btn-link"
            onClick={reSendOTP}
          >
            Resend Code
          </Link>
        </div>
      </form>
    </>
  );
};

export default ForgotPasswordCode;
