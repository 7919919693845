import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { docuSign } from '../../redux/actions/DocuSign/DocuSign';
import { useDispatch, useSelector } from 'react-redux';


const DocuSignLogin = (props) => {

    
  const dispatch = useDispatch();
  const authentication = useSelector((state) => state.DocuSignCheck);
  useEffect(() => {
    
  }, [props]);

  function LoginDocuSign(e){
    e.preventDefault();
    let uuid = JSON.parse(localStorage.getItem("userDetails"))?.uuid;
    let state = btoa(`False::${uuid.replace(/"/g, "").trim()}`);
    
    window.location.href = authentication.error.auth;
    //dispatch(docuSign.Login(state))
  }

  return (
    <div className='share-login-block h-100 d-flex align-items-center justify-content-center'>
      <div className='text-center mw-280'>
        {props.props === "0" &&<p className='fs-18 mb-24'>Please log into DocuSign to see documents requiring your signature.</p>}
        {props.props === "2" &&<p className='fs-18 mb-24'>Please log into DocuSign to see completed documents.</p>}
        {props.props === "3" &&<p className='fs-18 mb-24'>Please log in to DocuSign to view documents that require your clients' signature.</p>}
        
        <button type='button'  onClick={LoginDocuSign} className='btn btn-success'>Log In to DocuSign</button>
      </div>
    </div> 
  )
}

export default DocuSignLogin
