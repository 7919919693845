/* eslint-disable indent */

import React, { useEffect, useState } from "react";
import { Steps, Switch, Tooltip, notification } from "antd";

import Loading from "../../components/Loader";
import Modal from "react-bootstrap/Modal";
import PreviewIframe from "./previewIframe";
import { Radio } from "antd";
import { clientUser } from "../../redux/actions/ClientUser/ClientUser";
import preview from "../../Assets/Images/preview.svg";
import previewDisabled from "../../Assets/Images/preview-disabled.svg";
import useDebounce from "../ShareFile/searchdebounce";
import { useDispatch } from "react-redux";
import { utility } from "../../components/Utility";

const CreateUser = (props) => {
  const { Step } = Steps;
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);

  // Step One State
  const [next, setnext] = useState(null);

  const [houseHoldList, sethouseHoldList] = useState();
  const [showHouseHold, setShowHouseHold] = useState(false);
  const [selectedHouseHold, setSelectedHouseHold] = useState(false);
  const [searchText, setSearchText] = useState("");

  const [clientHouseHoldList, setClientHouseHoldList] = useState();
  const [showClientHouseHold, setShowClientHouseHold] = useState(false);
  const [selectedClientHouseHold, setSelectedClientHouseHold] = useState(false);
  const [searchTextClient, setSearchTextClient] = useState("");
  const [rotate, setRotate] = useState(0);

  //Step Two State
  const [houseHoldDetails, setHouseHoldDetails] = useState();
  const [showCreateUser, setCreateUserShow] = useState(false);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState();
  const [selectedEmail, setSelectedEmail] = useState();

  //Step Three State
  // const [calendarNotification, setCalendarNotification] = useState([]);
  const [messageNotification, setMessageNotification] = useState([]);
  const [docuSignNotification, setDocuSignNotification] = useState([]);
  const [fileNotification, setFileNotification] = useState([]);

  const [loader, setLoader] = useState(false);

  // const [calendarDefaultNotification, setCalendarDefaultNotification] =
  //   useState([]);
  const [messageDefaultNotification, setMessageDefaultNotification] = useState(
    []
  );
  const [docuSignDefaultNotification, setDocuSignDefaultNotification] =
    useState([]);

  const [fileDefaultNotification, setFileDefaultNotification] = useState([]);

  const [showMessage, setShowMessage] = useState(true);
  // const [showCalendar, setShowCalendar] = useState(true);
  const [showDocuSign, setShowDocuSign] = useState(true);
  const [showFile, setShowFile] = useState(true);
  const [showMessageReset, setShowMessageReset] = useState(false);
  // const [showCalendarReset, setShowCalendarReset] = useState(false);
  const [showDocuSignReset, setShowDocuSignReset] = useState(false);
  const [showFileReset, setShowFileReset] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState(null);
  const [investmentTab, setInvestmentTab] = useState([]);
  const [investmentToggle, setInvestmentToggle] = useState(false);

  const [articleNotification, setArticleNotification] = useState([]);
  const [showArticleReset, setShowArticleReset] = useState(false);
  const [showArticle, setShowArticle] = useState(true);
  const [articleDefaultNotification, setArticleDefaultNotification] = useState([]);
  const [showIframePreview, setShowIframePreview] = useState(false);


  const handleCreateUserClose = () => {
    setCreateUserShow(false);
  };
  const handleCreateUserShow = () => setCreateUserShow(true);

  useEffect(() => {
    const { visible, closeModal, isReschedule } = props;
    setCreateUserShow(visible);
    getHouseHoldList();
    // getInvestmentToggleButtonPermission();
  }, []);

  useEffect(() => {
    if (selectedHouseHold) {
      getClientHouseHoldList();
    }
  }, [selectedHouseHold]);

  useEffect(() => {
    testResetToDefault();
  }, [
    // calendarNotification,
    messageNotification,
    docuSignNotification,
    fileNotification,
    articleNotification,
  ]);



  const Notify = (type, mesage, icons) => {
    notification[type]({
      description: mesage,
      icon: icons,
      top: 65,
    });
  };

  const handleScroll = (e) => {
    const bottom =
      parseInt(
        e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight
      ) < 2;
    if (bottom === true && next !== null) {
      getNextHouseHoldList(next);
    }
  };

  function getHouseHoldList() {
    dispatch(clientUser.getHouseHoldList()).then((response) => {
      if (response.type === "GET_HOUSE_HOLD_LIST_SUCCESS") {
        sethouseHoldList(response?.data?.results);
        setnext(response.data.next);
      }
    });
  }

  function getInvestmentToggleButtonPermission() {
    dispatch(clientUser.getInvestmentToggleButtonPermission(houseHoldDetails?.sf_external_id)).then((response) => {
      if (response.type === "GET_INVESTMENT_TOGGLE_BUTTON_PERMISSION_SUCCESS") {
        setInvestmentTab(response?.data)
      }
    });
  }

  function getHouseHoldSearchList(value) {
    dispatch(clientUser.getHouseHoldSearchList(value)).then((response) => {
      if (response.type === "GET_HOUSE_HOLD_LIST_SUCCESS") {
        sethouseHoldList(response?.data?.results);
        setnext(response.data.next);
      }
    });
  }

  function getNextHouseHoldList() {
    dispatch(clientUser.getNextHouseHoldList(next)).then((response) => {
      if (response.type === "GET_HOUSE_HOLD_LIST_SUCCESS") {
        sethouseHoldList([...houseHoldList, ...response.data.results]);
        setnext(response.data.next);
      }
    });
  }

  function getClientHouseHoldList(refresh = false) {
    dispatch(clientUser.getClientHouseHoldList(selectedHouseHold?.id)).then(
      (response) => {
        if (response.type === "GET_CLIENT_HOUSE_HOLD_LIST_SUCCESS") {
          setClientHouseHoldList(response?.data);
          if (refresh) {
            const selectedClient = response?.data?.filter((ele) => ele?.id === selectedClientHouseHold?.id)
            setSelectedClientHouseHold(selectedClient[0]);
            getHouseHoldDetails()
          }
        }
      }
    );
  }

  function getHouseHoldDetails() {
    dispatch(clientUser.getHouseHoldDetails(selectedHouseHold?.id)).then(
      (response) => {
        if (response.type === "GET_HOUSE_HOLD_DETAILS_SUCCESS") {
          setHouseHoldDetails(response?.data);
        }
      }
    );
  }

  function createUser() {
    let param = {
      client_id: selectedClientHouseHold.id,
      email: selectedEmail,
      phone_number: selectedPhoneNumber,
      investments_toggle: investmentToggle,
      orion_household_id: investmentTab?.orion_hh_id,
    };
    dispatch(clientUser.createUser(selectedHouseHold?.id, param)).then(
      (response) => {
        if (response.type === "CREATE_USER_SUCCESS") {
          setUserPreference(response?.data?.uuid);
        }
      }
    );
  }

  function setUserPreference(data) {
    let param = {
      preferences: createSetPreferenceParam(),
      user: data,
    };
    if (param?.preferences?.length > 0) {
      dispatch(clientUser.setUserPreference(param)).then((response) => {
        if (response.type === "SET_NOTIFICATION_SUCCESS") {
          const { closeModal } = props;
          closeModal();
          Notify(
            "success",
            `User for ${selectedClientHouseHold?.first_name +
            " " +
            selectedClientHouseHold?.last_name
            } has been created, invite link has been sent to ${selectedEmail}`,
            <em className="icon-check-outline"></em>
          );
        }
      });
    } else {
      const { closeModal } = props;
      closeModal();
      Notify(
        "success",
        `User for ${selectedClientHouseHold?.first_name +
        " " +
        selectedClientHouseHold?.last_name
        } has been created, invite link has been sent to ${selectedEmail}`,
        <em className="icon-check-outline"></em>
      );
    }
  }

  function checkEmailMobile(e) {
    setLoader(true);
    dispatch(
      clientUser.checkEmailMobile(
        { phone_number: selectedPhoneNumber, email: selectedEmail },
        selectedHouseHold?.id
      )
    ).then((response) => {
      if (response.type === "POST_CHECK_EMAILMOBILE_SUCCESS") {
        setLoader(false);
        setEmailErrorMsg(null);
        {
          response?.data?.message && Notify("warning", response?.data?.message);
          nextStep(e);
        }
      } else {
        setLoader(false);
        setEmailErrorMsg(response.error.error);
      }
    });
  }

  function getNotificationPreference() {
    setLoader(true);
    dispatch(
      clientUser.getNotificationPreferences(selectedHouseHold?.team?.id)
    ).then((response) => {
      if (response.type === "GET_NOTIFICATION_SUCCESS") {
        setLoader(false);
        let data = JSON.parse(JSON.stringify(response?.data));

        //data?.map((c) => (c.value = c.value));

        //Create Calendar Object
        // let calendar = data?.filter((c) => c?.preference_type === "calendar");
        // setCalendarDefaultNotification(calendar);

        //Create Message Object
        let message = data?.filter((c) => c?.preference_type === "message");
        setMessageDefaultNotification(message);

        //Create docusign Object
        let docuSign = data?.filter((c) => c?.preference_type === "docusign");
        setDocuSignDefaultNotification(docuSign);

        //Create docusign Object
        let file = data?.filter((c) => c?.preference_type === "files");
        setFileDefaultNotification(file);

        //Create Article Object
        let article = data?.filter((c) => c?.preference_type === "articles");
        setArticleDefaultNotification(article);

        //This is setting
        setNotificationValue(data);
      }
    });
  }

  function setNotificationValue(data) {
    let value = JSON.parse(JSON.stringify(data));

    //Setting the default value to value
    //value?.map((c) => (c.value = c.value));

    //Create Calendar Object
    // let calendar = value?.filter((c) => c?.preference_type === "calendar");
    // setCalendarNotification(calendar);

    //Create Message Object
    let message = value?.filter((c) => c?.preference_type === "message");
    setMessageNotification(message);

    //Create docusign Object
    let docuSign = value?.filter((c) => c?.preference_type === "docusign");
    setDocuSignNotification(docuSign);

    //Create docusign Object
    let file = value?.filter((c) => c?.preference_type === "files");
    setFileNotification(file);

    let article = value?.filter((c) => c?.preference_type === "articles");
    setArticleNotification(article);
  }

  function testResetToDefault() {
    // if (
    //   JSON.stringify(calendarDefaultNotification) !==
    //   JSON.stringify(calendarNotification)
    // ) {
    //   setShowCalendarReset(true);
    // } else {
    //   setShowCalendarReset(false);
    // }
    if (
      JSON.stringify(fileDefaultNotification) !==
      JSON.stringify(fileNotification)
    ) {
      setShowFileReset(true);
    } else {
      setShowFileReset(false);
    }
    if (
      JSON.stringify(docuSignDefaultNotification) !==
      JSON.stringify(docuSignNotification)
    ) {
      setShowDocuSignReset(true);
    } else {
      setShowDocuSignReset(false);
    }
    if (
      JSON.stringify(messageDefaultNotification) !==
      JSON.stringify(messageNotification)
    ) {
      setShowMessageReset(true);
    } else {
      setShowMessageReset(false);
    }
    if (
      JSON.stringify(articleDefaultNotification) !==
      JSON.stringify(articleNotification)
    ) {
      setShowArticleReset(true);
    } else {
      setShowArticleReset(false);
    }
  }

  function createSetPreferenceParam() {
    let param = [];

    messageNotification?.map((c) => {
      if (c?.value !== c?.default_value) {
        param.push({
          preference_id: c?.id,
          value: c?.value,
        });
      }
    });

    // calendarNotification?.map((c) => {
    //   if ((c?.id !== 9 && c?.value !== c?.default_value) || c?.id === 21) {
    //     param.push({
    //       preference_id: c?.id,
    //       value: c?.value,
    //     });
    //   }
    // });
    // let cld = calendarNotification?.find((x) => x.id === 9)?.value;
    // let cldDefault = calendarDefaultNotification?.find(
    //   (x) => x.id === 9
    // )?.default_value;

    // if (JSON.stringify(cld) !== JSON.stringify(cldDefault)) {
    //   param.push({
    //     preference_id: 9,
    //     value: calendarNotification?.find((x) => x.id === 9)?.value,
    //   });
    // }

    docuSignNotification?.map((c) => {
      if (c?.value !== c?.default_value) {
        param.push({
          preference_id: c?.id,
          value: c?.value,
        });
      }
    });

    fileNotification?.map((c) => {
      if (c?.value !== c?.default_value) {
        param.push({
          preference_id: c?.id,
          value: c?.value,
        });
      }
    });

    articleNotification?.map((c) => {
      if (c?.value !== c?.default_value) {
        param.push({
          preference_id: c?.id,
          value: c?.value,
        });
      }
    });

    return param;
  }

  const debouncedSearchTerm = useDebounce(searchText);

  useEffect(
    () => {
      // Fire off our API call
      getHouseHoldSearchList(searchText);
    },
    [debouncedSearchTerm]
  );

  const stepOne = (
    <div className="">
      <div className="row">
        <div className="col-md-6">
          <div className="mb-16">
            <label className="form-label">Household</label>
            <div className="position-relative">
              <div
                id="assgined_to_id"
                className={"password-field cursor-pointer"}
                onClick={(e) => {
                  setShowHouseHold(!showHouseHold);
                  setShowClientHouseHold(false);
                  setSearchText("");
                  getHouseHoldList();
                }}
              >
                <div className="form-control text-ellipsis">
                  {selectedHouseHold?.household_name
                    ? selectedHouseHold?.household_name
                    : "Select household"}
                </div>

                <em
                  className={
                    showHouseHold
                      ? "icon-chevron-up-outline"
                      : "icon-chevron-down-outline"
                  }
                  onClick={(e) => {
                    setShowHouseHold(!showHouseHold);
                    setShowClientHouseHold(false);
                    setSearchText("");
                    getHouseHoldList();
                  }}
                ></em>
              </div>
              {showHouseHold && (
                <div className="dropdown-content">
                  <div className="px-24 py-md-8 py-12 border-mt-top">
                    <div className="search-area">
                      <input
                        className="form-control"
                        value={searchText}
                        onChange={(e) => {
                          setSearchText(e.target.value);
                        }}
                      />
                      {searchText === "" && (
                        <button className="search-btn icon-20">
                          <em className="icon-search-outline"></em>
                        </button>
                      )}
                      {searchText !== "" && (
                        <button
                          className="close-search-btn icon-20"
                          onClick={(event) => {
                            setSearchText("");
                            getHouseHoldList();
                          }}
                        >
                          <em className="icon-x-circle-filled"></em>
                        </button>
                      )}
                    </div>
                  </div>
                  <ul
                    className="check-list list-unstyled custom-style-scroll"
                    onScroll={handleScroll}
                  >
                    {houseHoldList?.map((house, index) => (
                      <li
                        className="cursor-pointer"
                        key={index}
                        onClick={(e) => {
                          // setSelectedTimeZone(time);
                          setSelectedHouseHold(house);
                          setSelectedClientHouseHold();
                          setShowHouseHold(false);
                        }}
                      >
                        <span>{house?.household_name}</span>
                      </li>
                    ))}
                    {houseHoldList?.length === 0 && (
                      // <li>{"No Results Found"}</li>
                      <div className="drop-noresult border-top d-flex align-items-center justify-content-center text-center">
                        <div className="max-258">
                          <span className="task-nores-icon mb-16 mx-auto">
                            <em className="icon-search-outline"></em>
                          </span>
                          <p className="lh-20 m-0">No results found</p>
                        </div>
                      </div>
                    )}
                  </ul>
                </div>
              )}
            </div>
          </div>
          {selectedHouseHold && (
            <div className="mb-16">
              <label className="form-label">Client</label>
              <div className="position-relative mb-8">
                <div
                  id="assgined_to_id"
                  className={"password-field cursor-pointer"}
                  onClick={(e) => {
                    setShowClientHouseHold(!showClientHouseHold);
                    setShowHouseHold(false);
                    setSearchTextClient("");
                  }}
                >
                  <div className="form-control">
                    {selectedClientHouseHold?.first_name
                      ? selectedClientHouseHold?.first_name +
                      " " +
                      selectedClientHouseHold?.last_name
                      : "Select Client"}
                  </div>

                  <em
                    className={
                      showClientHouseHold
                        ? "icon-chevron-up-outline"
                        : "icon-chevron-down-outline"
                    }
                    onClick={(e) => {
                      setShowClientHouseHold(!showClientHouseHold);
                      setShowHouseHold(false);
                      setSearchTextClient("");
                    }}
                  ></em>
                </div>
                {showClientHouseHold && (
                  <div className="dropdown-content">
                    <div className="px-24 py-md-8 py-12 border-mt-top">
                      <div className="search-area">
                        <input
                          className="form-control"
                          value={searchTextClient}
                          onChange={(e) => {
                            setSearchTextClient(e.target.value);
                          }}
                        />
                        {searchTextClient === "" && (
                          <button className="search-btn icon-20">
                            <em className="icon-search-outline"></em>
                          </button>
                        )}
                        {searchTextClient !== "" && (
                          <button
                            className="close-search-btn icon-20"
                            onClick={(event) => {
                              setSearchTextClient("");
                            }}
                          >
                            <em className="icon-x-circle-filled"></em>
                          </button>
                        )}
                      </div>
                    </div>
                    <ul className="check-list list-unstyled custom-style-scroll">
                      {clientHouseHoldList
                        ?.filter(
                          (c) =>
                            searchTextClient === "" ||
                            c.first_name
                              .toLowerCase()
                              .includes(searchTextClient?.toLowerCase()) ||
                            c.last_name
                              .toLowerCase()
                              .includes(searchTextClient?.toLowerCase())
                        )
                        ?.map((client, index) => (
                          <li
                            className={
                              client?.is_active === null
                                ? "cursor-pointer d-flex align-items-center"
                                : "d-flex align-items-center"
                            }
                            key={index}
                            onClick={(e) => {
                              // setSelectedTimeZone(time);
                              if (client?.is_active === null) {
                                setSelectedClientHouseHold(client);
                                setShowClientHouseHold(false);
                              }
                            }}
                          >
                            <span className="me-16">
                              {client?.last_name + ", " + client?.first_name}
                            </span>
                            {!client?.is_deleted && (
                              <span
                                className={
                                  client?.is_active
                                    ? "badge badge-outline-success py-2"
                                    : client?.is_active === false
                                      ? "badge badge-outline-secondary py-2"
                                      : ""
                                }
                              >
                                <em
                                  className={
                                    client?.is_active
                                      ? "icon-check-circle-outline me-4"
                                      : client?.is_active === false
                                        ? "icon-clock-outline me-4"
                                        : ""
                                  }
                                ></em>
                                {client?.is_active
                                  ? "Active"
                                  : client?.is_active === false
                                    ? "Invite Sent"
                                    : ""}
                              </span>
                            )}
                            {client?.is_deleted && (
                              <span
                                className={"badge badge-outline-secondary py-2"}
                              >
                                <em
                                  className={"icon-x-circle-outline me-4"}
                                ></em>
                                {"Deactivated"}
                              </span>
                            )}
                          </li>
                        ))}
                      {clientHouseHoldList?.filter(
                        (c) =>
                          searchTextClient === "" ||
                          c.first_name
                            .toLowerCase()
                            .includes(searchTextClient?.toLowerCase()) ||
                          c.last_name
                            .toLowerCase()
                            .includes(searchTextClient?.toLowerCase())
                      ).length === 0 && (
                          <div className="drop-noresult border-top d-flex align-items-center justify-content-center text-center">
                            <div className="max-258">
                              <span className="task-nores-icon mb-16 mx-auto">
                                <em className="icon-search-outline"></em>
                              </span>
                              <p className="lh-20 m-0">No results found</p>
                            </div>
                          </div>
                        )}
                    </ul>
                  </div>
                )}
              </div>
              {selectedClientHouseHold?.preferred_name && (
                <div className="alert alert-warning d-block">
                  <p className="m-0">
                    <h6 className="fs-14">Note:</h6> This client has a preferred
                    name "{selectedClientHouseHold?.preferred_name}" in
                    Salesforce, which will be used by the portal instead of
                    their first name.
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
  const stepTwo = (
    <div className="row">
      <div className="col-md-6">
        <div className="very-info-block">
          <span className="d-block fs-14 lh-24 mb-4 text-172935 fw-500">
            Client
          </span>
          <div className="cu-user-block d-flex align-items-center">
            <div className="cu-photo-outer me-8">
              <span className="cu-photo">
                {utility.getProfileImage(
                  selectedClientHouseHold?.profile_image,
                  selectedClientHouseHold?.first_name?.charAt(0),
                  selectedClientHouseHold?.last_name?.charAt(0)
                )}
              </span>
            </div>
            <div className="lh-20">
              <span className="d-block">
                {selectedClientHouseHold?.last_name +
                  ", " +
                  selectedClientHouseHold?.first_name}
              </span>
            </div>
          </div>
        </div>
        <div className="very-info-block">
          <span className="d-block fs-14 lh-24 mb-8 text-172935 fw-500">
            Household
          </span>
          <p className="m-0">{houseHoldDetails?.household_name}</p>
        </div>
        <div className="very-info-block">
          <span className="d-block mb-16 fs-14 lh-20 text-black fw-500">
            Cell Phone <sup className="text-danger fs-14">*</sup>
          </span>
          {selectedClientHouseHold?.phone_numbers?.length > 0 && (
            <Radio.Group
              className=""
              onChange={(e) => {
                setSelectedPhoneNumber(e?.target?.value);
              }}
              value={selectedPhoneNumber}
            >
              {selectedClientHouseHold?.phone_numbers?.map((phone, index) => (
                <>
                  <Radio value={phone} key={phone}>
                    {phone}
                  </Radio>
                </>
              ))}
            </Radio.Group>
          )}
          {selectedClientHouseHold?.phone_numbers?.length === 0 && (
            <div className="alert alert-danger d-block">
              <h6 className="fs-14 mb-4">Data missing</h6>
              <p className="m-0">
                Please update records in Salesforce and allow a few minutes for
                the Portal to reflect changes.
              </p>
            </div>
          )}
        </div>
        <div className="very-info-block">
          <span className="d-block mb-16 fs-14 lh-20 text-black fw-500">
            Email Address <sup className="text-danger fs-14">*</sup>
          </span>
          {selectedClientHouseHold?.emails?.length > 0 && (
            <Radio.Group
              className=""
              onChange={(e) => {
                setEmailErrorMsg(null);
                setSelectedEmail(e?.target?.value);
              }}
              value={selectedEmail}
            >
              {selectedClientHouseHold?.emails?.map((email, index) => (
                <>
                  <Radio value={email} key={email}>
                    {email}
                  </Radio>
                </>
              ))}
            </Radio.Group>
          )}
          {selectedClientHouseHold?.emails?.length === 0 && (
            <div className="alert alert-danger d-block">
              <h6 className="fs-14 mb-4">Data missing</h6>
              <p className="m-0">
                Please update records in Salesforce and allow a few minutes for
                the Portal to reflect changes.
              </p>
            </div>
          )}
          {emailErrorMsg && (
            <div className="alert alert-danger d-block mt-10">
              <p className="m-0">{emailErrorMsg}</p>
            </div>
          )}
        </div>
      </div>
      <div className="col-md-5">
        <div className="very-info-block">
          <span className="d-block fs-14 lh-24 mb-4 fw-500">
            Relationship Lead <sup className="text-danger fs-14">*</sup>
          </span>
          {houseHoldDetails?.relationship_lead ? (
            <div className="cu-user-block d-flex align-items-center">
              <div className="cu-photo-outer me-8">
                <span className="cu-photo">
                  {/* <img src={Avatar} alt="" /> */}
                  {utility.getProfileImage(
                    houseHoldDetails?.relationship_lead?.profile_image,
                    houseHoldDetails?.relationship_lead?.first_name?.charAt(0),
                    houseHoldDetails?.relationship_lead?.last_name?.charAt(0)
                  )}
                </span>
              </div>
              <div className="lh-20">
                <span className="d-block">
                  {houseHoldDetails?.relationship_lead?.first_name +
                    " " +
                    houseHoldDetails?.relationship_lead?.last_name}
                </span>
              </div>
            </div>
          ) : (
            <div className="alert alert-danger d-block">
              <h6 className="fs-14 mb-4">Data missing</h6>
              <p className="m-0">
                Please update records in Salesforce and allow a few minutes for
                the Portal to reflect changes.
              </p>
            </div>
          )}
        </div>
        {houseHoldDetails?.partner && (
          <div className="very-info-block">
            <span className="d-block fs-14 lh-24 mb-4 fw-500">Partner</span>
            <div className="cu-user-block d-flex align-items-center">
              <div className="cu-photo-outer me-8">
                <span className="cu-photo">
                  {/* <img src={Avatar} alt="" /> */}
                  {utility.getProfileImage(
                    houseHoldDetails?.partner?.profile_image,
                    houseHoldDetails?.partner?.first_name?.charAt(0),
                    houseHoldDetails?.partner?.last_name?.charAt(0)
                  )}
                </span>
              </div>
              <div className="lh-20">
                <span className="d-block">
                  {houseHoldDetails?.partner?.first_name +
                    " " +
                    houseHoldDetails?.partner?.last_name}
                </span>
              </div>
            </div>
          </div>
        )}
        {houseHoldDetails?.advisor && (
          <div className="very-info-block">
            <span className="d-block fs-14 lh-24 mb-4 fw-500">Advisor</span>
            <div className="cu-user-block d-flex align-items-center">
              <div className="cu-photo-outer me-8">
                <span className="cu-photo">
                  {/* <img src={Avatar} alt="" /> */}
                  {utility.getProfileImage(
                    houseHoldDetails?.advisor?.profile_image,
                    houseHoldDetails?.advisor?.first_name?.charAt(0),
                    houseHoldDetails?.advisor?.last_name?.charAt(0)
                  )}
                </span>
              </div>
              <div className="lh-20">
                <span className="d-block">
                  {houseHoldDetails?.advisor?.first_name +
                    " " +
                    houseHoldDetails?.advisor?.last_name}
                </span>
              </div>
            </div>
          </div>
        )}
        {houseHoldDetails?.secondary_advisor && (
          <div className="very-info-block">
            <span className="d-block fs-14 lh-24 mb-4 fw-500">Secondary Advisor</span>
            <div className="cu-user-block d-flex align-items-center">
              <div className="cu-photo-outer me-8">
                <span className="cu-photo">
                  {/* <img src={Avatar} alt="" /> */}
                  {utility.getProfileImage(
                    houseHoldDetails?.secondary_advisor?.profile_image,
                    houseHoldDetails?.secondary_advisor?.first_name?.charAt(0),
                    houseHoldDetails?.secondary_advisor?.last_name?.charAt(0)
                  )}
                </span>
              </div>
              <div className="lh-20">
                <span className="d-block">
                  {houseHoldDetails?.secondary_advisor?.first_name +
                    " " +
                    houseHoldDetails?.secondary_advisor?.last_name}
                </span>
              </div>
            </div>
          </div>
        )}
        {houseHoldDetails?.csm && (
          <div className="very-info-block">
            <span className="d-block fs-14 lh-24 mb-4 fw-500">CSM</span>
            <div className="cu-user-block d-flex align-items-center">
              <div className="cu-photo-outer me-8">
                <span className="cu-photo">
                  {/* <img src={Avatar} alt="" /> */}
                  {utility.getProfileImage(
                    houseHoldDetails?.csm?.profile_image,
                    houseHoldDetails?.csm?.first_name?.charAt(0),
                    houseHoldDetails?.csm?.last_name?.charAt(0)
                  )}
                </span>
              </div>
              <div className="lh-20">
                <span className="d-block">
                  {houseHoldDetails?.csm?.first_name +
                    " " +
                    houseHoldDetails?.csm?.last_name}
                </span>
              </div>
            </div>
          </div>
        )}
        {houseHoldDetails?.secondary_csm && (
          <div className="very-info-block">
            <span className="d-block fs-14 lh-24 mb-4 fw-500">Secondary CSM</span>
            <div className="cu-user-block d-flex align-items-center">
              <div className="cu-photo-outer me-8">
                <span className="cu-photo">
                  {/* <img src={Avatar} alt="" /> */}
                  {utility.getProfileImage(
                    houseHoldDetails?.secondary_csm?.profile_image,
                    houseHoldDetails?.secondary_csm?.first_name?.charAt(0),
                    houseHoldDetails?.secondary_csm?.last_name?.charAt(0)
                  )}
                </span>
              </div>
              <div className="lh-20">
                <span className="d-block">
                  {houseHoldDetails?.secondary_csm?.first_name +
                    " " +
                    houseHoldDetails?.secondary_csm?.last_name}
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="col-md-1">
        <Tooltip placement="bottomRight" title="Refresh">
          <span className="icon-24 cursor-pointer" onClick={() => {
            setRotate(1), getClientHouseHoldList(true)
            setTimeout(() => {
              setRotate(0)
            }, 1500);
          }}>
            {rotate ?
              <em className="icon-refresh-outline rotate down"></em> :
              <em className="icon-refresh-outline"></em>}
          </span>
        </Tooltip>
      </div>
    </div>
  );
  const stepThree = (
    <div className="user-permission">
      <div className="profnotif-block profnotif-open">
        <div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="mb-4 mb-md-0">
              <h4 className="fs-18 lh-24 mb-md-4 mb-0">Articles</h4>
              <p className="m-0 d-none d-md-block">
                Article Notifications
              </p>
            </div>
            <div className="d-flex align-items-center">
              <span
                className={
                  showArticle
                    ? "notif-status fs-18 lh-32 me-12 cursor-pointer text-success"
                    : articleNotification?.find((x) => x.id === 22)?.value
                      ? "notif-status fs-18 lh-32 me-12 "
                      : "notif-status fs-18 lh-32 me-12 "
                }
                onClick={(e) => {
                  if (showArticle) {
                    setArticleNotification(
                      JSON.parse(JSON.stringify(articleDefaultNotification))
                    );
                  }
                }}
              >
                {showArticle
                  ? showArticleReset
                    ? "Reset to Default"
                    : ""
                  : articleNotification?.find((x) => x.id === 22)?.value
                    ? "On"
                    : "Off"}
              </span>
              <span
                className="cursor-pointer icon-32"
                onClick={(e) => {
                  setShowArticle(!showArticle);
                }}
              >
                <em className="icon icon-chevron-down-outline fs-16"></em>
              </span>
            </div>
          </div>
        </div>
        {showArticle && (
          <div className="pt-24">
            <div className="notif-type d-flex align-items-center justify-content-between">
              <h6 className="m-0 pe-8">
                Allow push notifications for Articles
              </h6>
              <Switch
                checked={articleNotification?.find((x) => x.id === 22)?.value}
                onChange={(e) => {
                  articleNotification
                    ?.filter((x) => x.id === 22)
                    .map((x) => (x.value = e));
                  setArticleNotification([...articleNotification]);
                }}
              />
            </div>
          </div>
        )}
      </div>
      <div className="profnotif-block profnotif-open">
        <div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="mb-4 mb-md-0">
              <h4 className="fs-18 lh-24 mb-md-4 mb-0">Messages</h4>
              <p className="m-0 d-none d-md-block">
                Read receipts, notifications.
              </p>
            </div>
            <div className="d-flex align-items-center">
              <span
                className={
                  showMessage
                    ? "notif-status fs-18 lh-32 me-12 cursor-pointer text-success"
                    : messageNotification?.find((x) => x.id === 1)?.value
                      ? "notif-status fs-18 lh-32 me-12 "
                      : "notif-status fs-18 lh-32 me-12 "
                }
                onClick={(e) => {
                  if (showMessage) {
                    setMessageNotification(
                      JSON.parse(JSON.stringify(messageDefaultNotification))
                    );
                  }
                }}
              >
                {showMessage
                  ? showMessageReset
                    ? "Reset to Default"
                    : ""
                  : messageNotification?.find((x) => x.id === 1)?.value
                    ? "On"
                    : "Off"}
              </span>
              <span
                className="cursor-pointer icon-32"
                onClick={(e) => {
                  setShowMessage(!showMessage);
                }}
              >
                <em className="icon icon-chevron-down-outline fs-16"></em>
              </span>
            </div>
          </div>
          <p className="m-0 d-md-none">Read receipts, notifications.</p>
        </div>
        {showMessage && (
          <div className="pt-24">
            <div className="notif-type d-flex align-items-center justify-content-between">
              <h6 className="m-0 pe-8">
                Enable Messages feature for this user
              </h6>
              <Switch
                checked={messageNotification?.find((x) => x.id === 1)?.value}
                onChange={(e) => {
                  console.log(e);
                  messageNotification
                    ?.filter((x) => x.id === 1)
                    .map((x) => (x.value = e));
                  setMessageNotification([...messageNotification]);
                }}
              />
            </div>
            {messageNotification?.find((x) => x.id === 1)?.value && (
              <>
                <div className="notif-type d-flex justify-content-between">
                  <div className="mb-4 mb-md-0">
                    <h6 className="m-0 pe-8 fw-normal">Send Read Receipts</h6>
                    <p className="text-muted mb-20 fs-14 lh-20">
                      When this is on, people are notified when you have read their messages.
                      This enables read receipts for all conversations.
                    </p>
                  </div>
                  <Switch
                    checked={
                      messageNotification?.find((x) => x.id === 5)?.value
                    }
                    onChange={(e) => {
                      console.log(e);
                      messageNotification
                        ?.filter((x) => x.id === 5)
                        .map((x) => (x.value = e));
                      setMessageNotification([...messageNotification]);
                    }}
                  />
                </div>
                <div className="notif-type">
                  <div className="typesof-not d-flex align-items-center justify-content-between">
                    <h6>Allow push notifications for messages</h6>
                    <Switch
                      checked={
                        messageNotification?.find((x) => x.id === 6)?.value
                      }
                      onChange={(e) => {
                        console.log(e);
                        messageNotification
                          ?.filter((x) => x.id === 6)
                          .map((x) => (x.value = e));
                        setMessageNotification([...messageNotification]);
                      }}
                    />
                  </div>
                  <div>
                    <div className="typesof-not d-flex align-items-center justify-content-between">
                    </div>
                    <div className="typesof-not ">
                      <div className="d-flex align-items-center justify-content-between">
                        <span className="lh-20">
                          Enable email notifications for missed chat messages
                        </span>
                        <Switch
                          checked={
                            messageNotification?.find((x) => x.id === 7)
                              ?.value !== "none"
                          }
                          onChange={(e) => {
                            if (
                              messageNotification?.find((x) => x.id === 7)
                                ?.value !== "none"
                            ) {
                              messageNotification
                                ?.filter((x) => x.id === 7)
                                .map((x) => (x.value = "none"));

                              setMessageNotification([...messageNotification]);
                            } else {
                              messageNotification
                                ?.filter((x) => x.id === 7)
                                .map((x) => (x.value = "Once every hour"));
                              setMessageNotification([...messageNotification]);
                            }
                          }}
                        />
                      </div>
                      <div className="pt-20">
                        <Radio.Group
                          className=""
                          onChange={(e) => {
                            messageNotification
                              ?.filter((x) => x.id === 7)
                              .map((x) => (x.value = e?.target?.value));
                            setMessageNotification([...messageNotification]);
                          }}
                          value={
                            messageNotification?.find((x) => x.id === 7)?.value
                          }
                        >
                          <Radio value={"Once every hour"}>
                            Once every hour
                          </Radio>
                          <Radio value={"Once every 8 hours"}>
                            Once every 8 hours
                          </Radio>
                          <Radio value={"Once daily"}>Once daily</Radio>
                        </Radio.Group>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
      <div className="profnotif-block profnotif-open">
        <div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="mb-4 mb-md-0">
              <h4 className="fs-18 lh-24 mb-md-4 mb-0">Investments</h4>
              <p className="m-0 d-none d-md-block">
                Financial Data through Orion integration.
              </p>
            </div>
          </div>
        </div>
        <div className="pt-24">
          <div className="notif-type">
            <div className="d-flex align-items-center justify-content-between mb-20">
              <h6 className="m-0 pe-8">
                Enable Investments feature for this account
              </h6>
              <Tooltip overlayClassName="tooptip-style" placement="bottomRight" title={!investmentTab?.investment_tab ? "This feature can only be enabled for clients who have an Orion Planning client portal user." : ""}>
                <Switch
                  checked={investmentToggle}
                  onChange={(e) => {
                    setInvestmentToggle(e);
                  }}
                  disabled={!investmentTab?.investment_tab}
                />
              </Tooltip>
            </div>
            {investmentTab?.orion_hh_id && investmentTab?.orion_hh_id !== "None" ?
              <div className="alert alert-warning d-block">
                <p className="m-0">{selectedClientHouseHold?.first_name} {selectedClientHouseHold?.last_name} is connected to the following Orion Household: {investmentTab?.orion_hh_name} ({investmentTab?.orion_hh_id}). Please do not enable the feature if you believe this is incorrect.</p>
              </div> :
              <div className="alert alert-warning d-block">
                <p className="m-0">We did not find an Orion Household ID connected to {selectedClientHouseHold?.first_name} {selectedClientHouseHold?.last_name}. Please confirm the client’s Orion Household is setup properly.</p>
              </div>
            }
          </div>
        </div>
        <div className="pt-24">
          <div className="notif-type d-flex justify-content-between">
            {/* <div className="d-flex justify-content-between"> */}
            <div>
              <h6 className="m-0 pe-8">
                Client Preview
              </h6>
              <p className="text-muted mb-20 fs-14 lh-20">
                Preview what the client will see if this feature is enabled.
              </p>
            </div>
            {!investmentTab?.investment_tab ?
              <Tooltip overlayClassName="tooptip-style" placement="bottomRight" title={!investmentTab?.investment_tab ? "Only clients who have an Orion Planning client portal user can be previewed." : ""}>
                <img src={previewDisabled} alt="" disabled={true} style={{ height: '36px' }} />
              </Tooltip> :
              <img src={preview} alt="" className="cursor-pointer" onClick={() => (setShowIframePreview(true))} style={{ height: '36px' }} />
            }
            {/* </div> */}

          </div>
        </div>
      </div>
      {/* <div className="profnotif-block profnotif-open">
        <div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="mb-4 mb-md-0">
              <h4 className="fs-18 lh-24 mb-md-4 mb-0">Calendar</h4>
              <p className="m-0 d-none d-md-block">
                Meeting duration, attendees, notifications.
              </p>
            </div>
            <div className="d-flex align-items-center">
              <span
                className={
                  showCalendar
                    ? "notif-status fs-18 lh-32 me-12 cursor-pointer text-success"
                    : calendarNotification?.find((x) => x.id === 2)?.value
                      ? "notif-status fs-18 lh-32 me-12 "
                      : "notif-status fs-18 lh-32 me-12 "
                }
                onClick={(e) => {
                  if (showCalendar) {
                    setCalendarNotification(
                      JSON.parse(JSON.stringify(calendarDefaultNotification))
                    );
                  }
                }}
              >
                {showCalendar
                  ? showCalendarReset
                    ? "Reset to Default"
                    : ""
                  : calendarNotification?.find((x) => x.id === 2)?.value
                    ? "On"
                    : "Off"}
              </span>
              <span
                className="cursor-pointer icon-32"
                onClick={(e) => {
                  setShowCalendar(!showCalendar);
                }}
              >
                <em className="icon icon-chevron-down-outline fs-16"></em>
              </span>
            </div>
          </div>
          <p className="m-0 d-md-none">
            Meeting duration, attendees, notifications.
          </p>
        </div>
        {showCalendar && (
          <div className="pt-24">
            <div className="notif-type">
              <div className="d-flex align-items-center justify-content-between mb-20">
                <h6 className="m-0 pe-8">
                  Enable Calendar feature for this account
                </h6>
                <Switch
                  checked={calendarNotification?.find((x) => x.id === 2)?.value}
                  onChange={(e) => {
                    console.log(e);
                    calendarNotification
                      ?.filter((x) => x.id === 2)
                      .map((x) => (x.value = e));
                    if (!e) {
                      calendarNotification
                        ?.filter((x) => x.id === 21)
                        .map((x) => (x.value = "false"));
                    }
                    setCalendarNotification([...calendarNotification]);
                  }}
                />
              </div>
              {calendarNotification?.find((x) => x.id === 2)?.value && (
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <h6 className="m-0 pe-8">Request Meeting Option</h6>
                    <p className="text-muted mb-20 fs-14 lh-20">
                      Select whether the client has the option to request a new
                      meeting.
                    </p>
                  </div>
                  <Switch
                    checked={
                      calendarNotification?.find((x) => x.id === 21)?.value ===
                        "true"
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      console.log(e);
                      calendarNotification
                        ?.filter((x) => x.id === 21)
                        .map((x) => (x.value = e ? "true" : "false"));
                      setCalendarNotification([...calendarNotification]);
                    }}
                  />
                </div>
              )}
              {calendarNotification?.find((x) => x.id === 21)?.value ===
                "true" && (
                  <div className="pt-24">
                    <h6 className="fw-normal m-0 text-1E3443">
                      Default meeting duration
                    </h6>
                    <p className="text-muted mb-20 fs-14 lh-20">
                      Pre-select for meeting requests. The client will still be
                      able to choose from all options.
                    </p>
                    <div>
                      <Radio.Group
                        className=""
                        onChange={(e) => {
                          calendarNotification
                            ?.filter((x) => x.id === 8)
                            .map((x) => (x.value = e?.target?.value));
                          setCalendarNotification([...calendarNotification]);
                        }}
                        value={
                          calendarNotification?.find((x) => x.id === 8)?.value
                        }
                      >
                        <Radio value={"30 minutes"}>30 minutes</Radio>
                        <Radio value={"60 minutes"}>60 minutes</Radio>
                        <Radio value={"90 minutes"}>90 minutes</Radio>
                      </Radio.Group>
                    </div>
                  </div>
                )}
            </div>
            {calendarNotification?.find((x) => x.id === 21)?.value ===
              "true" && (
                <>
                  <hr className="mt-24 mb-0"></hr>
                  <div className="pt-24">
                    <h6 className="fw-normal m-0 text-1E3443">
                      Default meeting attendees
                    </h6>
                    <p className="text-muted mb-12 fs-14 lh-20">
                      Pre-select for meeting requests. The client will still be
                      able to choose from all options.
                    </p>
                    <a
                      href="#"
                      className="mb-4 fs-14 d-inline-block"
                      onClick={(e) => {
                        if (
                          calendarNotification?.find((x) => x.id === 9)?.value
                            .length === 6
                        ) {
                          setCalendarNotification(
                            JSON.parse(
                              JSON.stringify(calendarDefaultNotification)
                            )
                          );
                        } else {
                          calendarNotification
                            ?.filter((x) => x.id === 9)
                            .map(
                              (x) =>
                              (x.value = [
                                "Relationship Lead",
                                "Partner",
                                "Advisor",
                                "Secondary Advisor",
                                "CSM",
                                "Secondary CSM",
                              ])
                            );
                          setCalendarNotification(JSON.parse(
                            JSON.stringify(calendarNotification)
                          ));
                        }
                      }}
                    >
                      {calendarNotification?.find((x) => x.id === 9)?.value
                        .length === 6
                        ? "Reset to Default"
                        : "Select All"}
                    </a>
                    <div className="row">
                      <div className="col-md-8">
                        {houseHoldDetails?.relationship_lead && (
                          <div className="py-8">
                            <div className="row align-items-center">
                              <div className="col-md-6">
                                <div className="form-check mb-0">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="meeting-attend"
                                    checked={calendarNotification
                                      ?.find((x) => x.id === 9)
                                      ?.value.includes("Relationship Lead")}
                                    onChange={(e) => {
                                      if (
                                        !calendarNotification
                                          ?.find((x) => x.id === 9)
                                          ?.value.includes("Relationship Lead")
                                      ) {
                                        calendarNotification
                                          ?.filter((x) => x.id === 9)
                                          .map((x) =>
                                            x.value.push("Relationship Lead")
                                          );
                                      } else {
                                        calendarNotification
                                          ?.filter((x) => x.id === 9)
                                          .map((x) => {
                                            var index =
                                              x.value.indexOf(
                                                "Relationship Lead"
                                              );
                                            if (index >= 0) {
                                              x.value.splice(index, 1);
                                            }
                                          });
                                      }
                                      setCalendarNotification([
                                        ...calendarNotification,
                                      ]);
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="meeting-attend"
                                  >
                                    Relationship Lead
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="cu-user-block d-flex align-items-center">
                                  <div className="cu-photo-outer me-8">
                                    <span className="cu-photo">
                                      {utility.getProfileImage(
                                        houseHoldDetails?.relationship_lead
                                          ?.profile_image,
                                        houseHoldDetails?.relationship_lead?.first_name?.charAt(
                                          0
                                        ),
                                        houseHoldDetails?.relationship_lead?.last_name?.charAt(
                                          0
                                        )
                                      )}
                                    </span>
                                  </div>
                                  <div className="lh-20">
                                    <span className="d-block">
                                      {houseHoldDetails?.relationship_lead
                                        ?.first_name
                                        ? houseHoldDetails?.relationship_lead
                                          ?.first_name +
                                        " " +
                                        houseHoldDetails?.relationship_lead
                                          ?.last_name
                                        : "--"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {houseHoldDetails?.partner && (
                          <div className="py-8">
                            <div className="row align-items-center">
                              <div className="col-md-6">
                                <div className="form-check mb-0">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="meeting-attend"
                                    checked={calendarNotification
                                      ?.find((x) => x.id === 9)
                                      ?.value.includes("Partner")}
                                    onChange={(e) => {
                                      console.log(e.target.value);
                                      if (
                                        !calendarNotification
                                          ?.find((x) => x.id === 9)
                                          ?.value.includes("Partner")
                                      ) {
                                        calendarNotification
                                          ?.filter((x) => x.id === 9)
                                          .map((x) => x.value.push("Partner"));
                                      } else {
                                        calendarNotification
                                          ?.filter((x) => x.id === 9)
                                          .map((x) => {
                                            var index =
                                              x?.value?.indexOf("Partner");
                                            if (index >= 0) {
                                              x.value.splice(index, 1);
                                            }
                                          });
                                      }
                                      setCalendarNotification([
                                        ...calendarNotification,
                                      ]);
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="meeting-attend"
                                  >
                                    Partner
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="cu-user-block d-flex align-items-center">
                                  {houseHoldDetails?.partner && (
                                    <div className="cu-photo-outer me-8">
                                      <span className="cu-photo">
                                        {utility.getProfileImage(
                                          houseHoldDetails?.partner
                                            ?.profile_image,
                                          houseHoldDetails?.partner?.first_name?.charAt(
                                            0
                                          ),
                                          houseHoldDetails?.partner?.last_name?.charAt(
                                            0
                                          )
                                        )}
                                      </span>
                                    </div>
                                  )}
                                  <div className="lh-20">
                                    <span className="d-block">
                                      {houseHoldDetails?.partner?.first_name
                                        ? houseHoldDetails?.partner?.first_name +
                                        " " +
                                        houseHoldDetails?.partner?.last_name
                                        : "--"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {houseHoldDetails?.advisor && (
                          <div className="py-8">
                            <div className="row align-items-center">
                              <div className="col-md-6">
                                <div className="form-check mb-0">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="meeting-attend"
                                    checked={calendarNotification
                                      ?.find((x) => x.id === 9)
                                      ?.value.includes("Advisor")}
                                    onChange={(e) => {
                                      console.log(e.target.value);
                                      if (
                                        !calendarNotification
                                          ?.find((x) => x.id === 9)
                                          ?.value.includes("Advisor")
                                      ) {
                                        calendarNotification
                                          ?.filter((x) => x.id === 9)
                                          .map((x) => x.value.push("Advisor"));
                                      } else {
                                        calendarNotification
                                          ?.filter((x) => x.id === 9)
                                          .map((x) => {
                                            var index =
                                              x?.value?.indexOf("Advisor");
                                            if (index >= 0) {
                                              x.value.splice(index, 1);
                                            }
                                          });
                                      }
                                      setCalendarNotification([
                                        ...calendarNotification,
                                      ]);
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="meeting-attend"
                                  >
                                    Advisor
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="cu-user-block d-flex align-items-center">
                                  {houseHoldDetails?.advisor && (
                                    <div className="cu-photo-outer me-8">
                                      <span className="cu-photo">
                                        {utility.getProfileImage(
                                          houseHoldDetails?.advisor
                                            ?.profile_image,
                                          houseHoldDetails?.advisor?.first_name?.charAt(
                                            0
                                          ),
                                          houseHoldDetails?.advisor?.last_name?.charAt(
                                            0
                                          )
                                        )}
                                      </span>
                                    </div>
                                  )}
                                  <div className="lh-20">
                                    <span className="d-block">
                                      {houseHoldDetails?.advisor?.first_name
                                        ? houseHoldDetails?.advisor?.first_name +
                                        " " +
                                        houseHoldDetails?.advisor?.last_name
                                        : "--"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {houseHoldDetails?.secondary_advisor && (
                          <div className="py-8">
                            <div className="row align-items-center">
                              <div className="col-md-6">
                                <div className="form-check mb-0">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="meeting-attend"
                                    checked={calendarNotification
                                      ?.find((x) => x.id === 9)
                                      ?.value.includes("Secondary Advisor")}
                                    onChange={(e) => {
                                      console.log(e.target.value);
                                      if (
                                        !calendarNotification
                                          ?.find((x) => x.id === 9)
                                          ?.value.includes("Secondary Advisor")
                                      ) {
                                        calendarNotification
                                          ?.filter((x) => x.id === 9)
                                          .map((x) =>
                                            x.value.push("Secondary Advisor")
                                          );
                                      } else {
                                        calendarNotification
                                          ?.filter((x) => x.id === 9)
                                          .map((x) => {
                                            var index =
                                              x?.value?.indexOf(
                                                "Secondary Advisor"
                                              );
                                            if (index >= 0) {
                                              x.value.splice(index, 1);
                                            }
                                          });
                                      }
                                      setCalendarNotification([
                                        ...calendarNotification,
                                      ]);
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="meeting-attend"
                                  >
                                    Secondary Advisor
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="cu-user-block d-flex align-items-center">
                                  {houseHoldDetails?.secondary_advisor && (
                                    <div className="cu-photo-outer me-8">
                                      <span className="cu-photo">
                                        {utility.getProfileImage(
                                          houseHoldDetails?.secondary_advisor
                                            ?.profile_image,
                                          houseHoldDetails?.secondary_advisor?.first_name?.charAt(
                                            0
                                          ),
                                          houseHoldDetails?.secondary_advisor?.last_name?.charAt(
                                            0
                                          )
                                        )}
                                      </span>
                                    </div>
                                  )}
                                  <div className="lh-20">
                                    <span className="d-block">
                                      {houseHoldDetails?.secondary_advisor
                                        ?.first_name
                                        ? houseHoldDetails?.secondary_advisor
                                          ?.first_name +
                                        " " +
                                        houseHoldDetails?.secondary_advisor
                                          ?.last_name
                                        : "--"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {houseHoldDetails?.csm && (
                          <div className="py-8">
                            <div className="row align-items-center">
                              <div className="col-md-6">
                                <div className="form-check mb-0">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="meeting-attend"
                                    checked={calendarNotification
                                      ?.find((x) => x.id === 9)
                                      ?.value.includes("CSM")}
                                    onChange={(e) => {
                                      console.log(e.target.value);
                                      if (
                                        !calendarNotification
                                          ?.find((x) => x.id === 9)
                                          ?.value.includes("CSM")
                                      ) {
                                        calendarNotification
                                          ?.filter((x) => x.id === 9)
                                          .map((x) => x.value.push("CSM"));
                                      } else {
                                        calendarNotification
                                          ?.filter((x) => x.id === 9)
                                          .map((x) => {
                                            var index = x?.value?.indexOf("CSM");
                                            if (index >= 0) {
                                              x.value.splice(index, 1);
                                            }
                                          });
                                      }
                                      setCalendarNotification([
                                        ...calendarNotification,
                                      ]);
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="meeting-attend"
                                  >
                                    CSM
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="cu-user-block d-flex align-items-center">
                                  {houseHoldDetails?.csm && (
                                    <div className="cu-photo-outer me-8">
                                      <span className="cu-photo">
                                        {utility.getProfileImage(
                                          houseHoldDetails?.csm?.profile_image,
                                          houseHoldDetails?.csm?.first_name?.charAt(
                                            0
                                          ),
                                          houseHoldDetails?.csm?.last_name?.charAt(
                                            0
                                          )
                                        )}
                                      </span>
                                    </div>
                                  )}
                                  <div className="lh-20">
                                    <span className="d-block">
                                      {houseHoldDetails?.csm?.first_name
                                        ? houseHoldDetails?.csm?.first_name +
                                        " " +
                                        houseHoldDetails?.csm?.last_name
                                        : "--"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {houseHoldDetails?.secondary_csm && (
                          <div className="py-8">
                            <div className="row align-items-center">
                              <div className="col-md-6">
                                <div className="form-check mb-0">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="meeting-attend"
                                    checked={calendarNotification
                                      ?.find((x) => x.id === 9)
                                      ?.value.includes("Secondary CSM")}
                                    onChange={(e) => {
                                      console.log(e.target.value);
                                      if (
                                        !calendarNotification
                                          ?.find((x) => x.id === 9)
                                          ?.value.includes("Secondary CSM")
                                      ) {
                                        calendarNotification
                                          ?.filter((x) => x.id === 9)
                                          .map((x) =>
                                            x.value.push("Secondary CSM")
                                          );
                                      } else {
                                        calendarNotification
                                          ?.filter((x) => x.id === 9)
                                          .map((x) => {
                                            var index =
                                              x?.value?.indexOf("Secondary CSM");
                                            if (index >= 0) {
                                              x.value.splice(index, 1);
                                            }
                                          });
                                      }
                                      setCalendarNotification([
                                        ...calendarNotification,
                                      ]);
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="meeting-attend"
                                  >
                                    Secondary CSM
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="cu-user-block d-flex align-items-center">
                                  {houseHoldDetails?.secondary_csm && (
                                    <div className="cu-photo-outer me-8">
                                      <span className="cu-photo">
                                        {utility.getProfileImage(
                                          houseHoldDetails?.secondary_csm
                                            ?.profile_image,
                                          houseHoldDetails?.secondary_csm?.first_name?.charAt(
                                            0
                                          ),
                                          houseHoldDetails?.secondary_csm?.last_name?.charAt(
                                            0
                                          )
                                        )}
                                      </span>
                                    </div>
                                  )}
                                  <div className="lh-20">
                                    <span className="d-block">
                                      {houseHoldDetails?.secondary_csm?.first_name
                                        ? houseHoldDetails?.secondary_csm
                                          ?.first_name +
                                        " " +
                                        houseHoldDetails?.secondary_csm
                                          ?.last_name
                                        : "--"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <hr className="mt-0 mb-24"></hr>
                </>
              )}
            {calendarNotification?.find((x) => x.id === 2)?.value && (
              <div className="notif-type">
                <div className="typesof-not d-flex align-items-center justify-content-between">
                  <h6 className="mb-16">Allow push notifications for the Calendar feature</h6>
                </div>
                <div>
                  <div className="typesof-not d-flex align-items-center justify-content-between">
                    <span className="lh-20">Meeting invites</span>
                    <Switch
                      checked={
                        calendarNotification?.find((x) => x.id === 10)?.value
                      }
                      onChange={(e) => {
                        console.log(e);
                        calendarNotification
                          ?.filter((x) => x.id === 10)
                          .map((x) => (x.value = e));
                        setCalendarNotification([...calendarNotification]);
                      }}
                    />
                  </div>
                  <div className="typesof-not d-flex align-items-center justify-content-between">
                    <span className="lh-20">Attendee responses</span>
                    <Switch
                      checked={
                        calendarNotification?.find((x) => x.id === 11)?.value
                      }
                      onChange={(e) => {
                        console.log(e);
                        calendarNotification
                          ?.filter((x) => x.id === 11)
                          .map((x) => (x.value = e));
                        setCalendarNotification([...calendarNotification]);
                      }}
                    />
                  </div>
                </div>

                <div></div>
              </div>
            )}
          </div>
        )}
      </div> */}
      <div className="profnotif-block profnotif-open">
        <div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="mb-4 mb-md-0">
              <h4 className="fs-18 lh-24 mb-md-4 mb-0">DocuSign</h4>
              <p className="m-0 d-none d-md-block">
                Signature requests, signature responses.
              </p>
            </div>
            <div className="d-flex align-items-center">
              <span
                className={
                  showDocuSign
                    ? "notif-status fs-18 lh-32 me-12 cursor-pointer text-success"
                    : docuSignNotification?.find((x) => x.id === 3)?.value
                      ? "notif-status fs-18 lh-32 me-12 "
                      : "notif-status fs-18 lh-32 me-12"
                }
                onClick={(e) => {
                  if (showDocuSign) {
                    setDocuSignNotification(
                      JSON.parse(JSON.stringify(docuSignDefaultNotification))
                    );
                  }
                }}
              >
                {showDocuSign
                  ? showDocuSignReset
                    ? "Reset to Default"
                    : ""
                  : docuSignNotification?.find((x) => x.id === 3)?.value
                    ? "On"
                    : "Off"}
              </span>
              <span
                className="cursor-pointer icon-32"
                onClick={(e) => {
                  setShowDocuSign(!showDocuSign);
                }}
              >
                <em className="icon icon-chevron-down-outline fs-16"></em>
              </span>
            </div>
          </div>
          <p className="m-0 d-md-none">
            Signature requests, signature responses, signature reminders.
          </p>
        </div>
        {showDocuSign && (
          <div className="pt-24">
            <div className="notif-type d-flex align-items-center justify-content-between">
              <h6 className="m-0 pe-8">
                Enable DocuSign feature for this account
              </h6>
              <Switch
                checked={docuSignNotification?.find((x) => x.id === 3)?.value}
                onChange={(e) => {
                  console.log(e);
                  docuSignNotification
                    ?.filter((x) => x.id === 3)
                    .map((x) => (x.value = e));
                  setDocuSignNotification([...docuSignNotification]);
                }}
              />
            </div>
            {docuSignNotification?.find((x) => x.id === 3)?.value && (
              <>
                <div className="notif-type">
                  <div className="typesof-not d-flex align-items-center justify-content-between">
                    <h6 className="mb-8">Allow push notifications for DocuSign</h6>
                    {/* <Switch
                        checked={
                          docuSignNotification?.find((x) => x.id === 16)
                            ?.value ||
                          docuSignNotification?.find((x) => x.id === 17)?.value
                        }
                        onChange={(e) => {
                          console.log(e);
                          docuSignNotification
                            ?.filter(
                              (x) => x.id === 16 || x.id === 17 || x.id === 18
                            )
                            .map((x) => (x.value = e));
                          setDocuSignNotification([...docuSignNotification]);
                        }}
                      /> */}
                  </div>
                  <div>
                  </div>
                </div>
                <div className="pt-8">
                  <div>
                    <div className="typesof-not d-flex align-items-center justify-content-between">
                      <span className="lh-20">Signature requests</span>
                      <Switch
                        checked={
                          docuSignNotification?.find((x) => x.id === 16)?.value
                        }
                        onChange={(e) => {
                          console.log(e);
                          docuSignNotification
                            ?.filter((x) => x.id === 16)
                            .map((x) => (x.value = e));
                          setDocuSignNotification([...docuSignNotification]);
                        }}
                      />
                    </div>
                    <div className="typesof-not d-flex align-items-center justify-content-between">
                      <span className="lh-20">Signature responses</span>
                      <Switch
                        checked={
                          docuSignNotification?.find((x) => x.id === 17)?.value
                        }
                        onChange={(e) => {
                          console.log(e);
                          docuSignNotification
                            ?.filter((x) => x.id === 17)
                            .map((x) => (x.value = e));
                          setDocuSignNotification([...docuSignNotification]);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
      <div className="profnotif-block profnotif-open">
        <div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="mb-4 mb-md-0">
              <h4 className="fs-18 lh-24 mb-md-4 mb-0">Files</h4>
            </div>
            <div className="d-flex align-items-center">
              <span
                className={
                  showFile
                    ? "notif-status fs-18 lh-32 me-12 cursor-pointer text-success"
                    : fileNotification?.find((x) => x.id === 4)?.value
                      ? "notif-status fs-18 lh-32 me-12 "
                      : "notif-status fs-18 lh-32 me-12 "
                }
                onClick={(e) => {
                  if (showFile) {
                    setFileNotification(
                      JSON.parse(JSON.stringify(fileDefaultNotification))
                    );
                  }
                }}
              >
                {showFile
                  ? showFileReset
                    ? "Reset to Default"
                    : ""
                  : fileNotification?.find((x) => x.id === 4)?.value
                    ? "On"
                    : "Off"}
              </span>
              <span
                className="cursor-pointer icon-32"
                onClick={(e) => {
                  setShowFile(!showFile);
                }}
              >
                <em className="icon icon-chevron-down-outline fs-16"></em>
              </span>
            </div>
          </div>
        </div>
        {showFile && (
          <div className="pt-24">
            <div className="notif-type d-flex align-items-center justify-content-between">
              <h6 className="m-0 pe-8">
                Enable ShareFile feature for this account
              </h6>
              <Switch
                checked={fileNotification?.find((x) => x.id === 4)?.value}
                onChange={(e) => {
                  fileNotification
                    ?.filter((x) => x.id === 4)
                    .map((x) => (x.value = e));
                  setFileNotification([...fileNotification]);
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
  const steps = [
    {
      title: "Select Client",
      content: stepOne,
    },
    {
      title: "Verify Information",
      content: stepTwo,
    },
    {
      title: "Set User Permissions",
      content: stepThree,
    },
  ];
  function nextStep(e) {
    e.preventDefault;
    setStep(step + 1);
    if (step + 1 === 1) {
      getHouseHoldDetails();
    } else if (step + 1 === 2) {
      getNotificationPreference();
      getInvestmentToggleButtonPermission();
    }
  }
  function prevStep(e) {
    e.preventDefault;
    if (step !== 0) {
      setStep(step - 1);
    }
  }

  function checkButtonDisabled() {
    if (step === 0) {
      if (selectedHouseHold && selectedClientHouseHold) {
        return false;
      } else {
        return true;
      }
    } else if (step === 1) {
      if (
        selectedPhoneNumber &&
        selectedEmail &&
        houseHoldDetails?.relationship_lead &&
        !emailErrorMsg
      ) {
        return false;
      } else {
        return true;
      }
    }
  }

  return (
    <>
      {loader && <Loading noTimeOut={loader} />}

      <Modal
        className="modal-800 modal-full"
        show={showCreateUser}
        onHide={() => {
          handleCreateUserClose;
          const { closeModal } = props;
          closeModal();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create New User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Steps className="create-steps" current={step}>
            <Step title={steps[0].title}></Step>
            <Step title={steps[1].title}></Step>
            <Step title={steps[2].title}></Step>
          </Steps>
          <div className="steps-content">{steps[step].content}</div>
        </Modal.Body>
        <Modal.Footer>
          {step === 0 && (
            <button
              className="btn btn-outline-secondary"
              onClick={(e) => {
                const { visible, closeModal, isReschedule } = props;
                closeModal();
              }}
            >
              Cancel
            </button>
          )}
          {step !== 0 && (
            <button
              className="btn btn-success"
              onClick={(e) => {
                prevStep(e);
              }}
            >
              Back
            </button>
          )}
          <button
            className="btn btn-success"
            disabled={checkButtonDisabled()}
            onClick={(e) => {
              if (step === 2) {
                createUser();
                //createSetPreferenceParam();
              } else {
                if (step + 1 === 2) {
                  checkEmailMobile(e);
                } else {
                  nextStep(e);
                }
              }
            }}
          >
            {step === 2 ? "Send Invite" : "Next"}
          </button>
        </Modal.Footer>
      </Modal>
      {
        showIframePreview &&
        <PreviewIframe clientname={selectedClientHouseHold?.first_name +
          " " +
          selectedClientHouseHold?.last_name}
          investmentTab={investmentTab}
          showIframePreview={showIframePreview}
          setShowIframePreview={setShowIframePreview}
        />
      }
    </>
  );
};

export default CreateUser;
