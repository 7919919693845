class config {
  /*
   * Return dynamic url based on host
   */
  getHost = () => {
    const host = window.location.host;
    const proto = window.location.protocol;
    // eslint-disable-next-line
    if (host.substring(host, 9) === "localhost") {
      return `https://devportal.monetagroup.com/api`;
    } else {  
      return `https://${host}/api`;
    }
  };

  getShareFileURL = () => {
    //let subDomain = JSON.parse(localStorage.getItem("shareFile")) ? JSON.parse(localStorage.getItem("shareFile"))?.subdomain.replace(/"/g, "").trim() : "";
    return "https://moneta.sf-api.com/sf/v3";
  };

  getDocuSignURL = () => {
    // let subDomain = JSON.parse(localStorage.getItem("shareFile"))?.["sub-domain"].replace(/"/g, "").trim();
    return "https://demo.docusign.net/restapi/v2.1";
  };
}
export default new config();
